import React from 'react'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'
import NotificationsView from '../modules/PreviewList/containers/NotificationsView/NotificationsView'
//import styles from '../modules/Notification/containers/NotificationList.module.scss'

function NotificationListPage() {
    return (
        <ContainerMenu>
            <NotificationsView/>
        </ContainerMenu>
    )
}

export default NotificationListPage
