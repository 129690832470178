import React from 'react'

import styles from './Feedbacks.module.scss'
import FeedbacksList from '../../../Feedbacks/containers/FeedbacksList'

export default function Feedbacks(props) {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h3 className={styles.title}>Project Feedbacks</h3>
            </div>
            <FeedbacksList
                feedback={props.feedback}
                listClassName={styles.feedbacks}
                containerClassName={styles.container}
                isAdmin
            />
        </div>
    )
}
