import React from 'react'
import Menu from '../../../Menu/containers/Menu'

import styles from './ContainerMenu.module.scss'

export default function ContainerMenu(props) {
    return (
        <div className={styles.root}>
            <Menu/>
            {props.children}
        </div>
    )
}
