import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Filter from '../../../UI/containers/Filter/Filter'
import Button from '../../../UI/containers/Button/Button'
import StatusFilter from '../../../UI/containers/StatusFilter/StatusFilter'

import { TRoles, TUsersActivityStatusFilter } from '../../../../shared/types'

import styles from './UserHeader.module.scss'

export default function UserHeader(props) {
    const handleFilterActiveUsersClick = () => {
        props.onChangeUserActivityFilter(TUsersActivityStatusFilter.ACTIVE)
    }

    const handleFilterDeletedUsersClick = () => {
        props.onChangeUserActivityFilter(TUsersActivityStatusFilter.DELETED)
    }

    const isInvestor = props.activeUserType === TRoles.INVESTOR

    return (
        <div className={styles.root}>
            <div className={styles.sort}>
                <div className={styles.col}>
                    <div className={styles.buttons}>
                        <Button
                            bgColor={isInvestor ? '#9dce6b' : '#ffffff'}
                            className={clsx(
                                styles.leftBtn,
                                styles.userTypeButton,
                                isInvestor && styles.userTypeButtonActive
                            )}
                            onClick={() => props.onSelectUser(TRoles.INVESTOR)}
                        >
              Investors
                        </Button>
                        <Button
                            bgColor={!isInvestor ? '#9dce6b' : '#ffffff'}
                            className={clsx(
                                styles.userTypeButton,
                                !isInvestor && styles.userTypeButtonActive
                            )}
                            onClick={() => props.onSelectUser(TRoles.OWNER)}
                        >
              Business Owners
                        </Button>
                    </div>
                    <div className={styles.deletedFilter}>
                        <StatusFilter
                            isActive={
                                props.userActivityFilter === TUsersActivityStatusFilter.ACTIVE
                            }
                            onClick={handleFilterActiveUsersClick}
                            text="Active users"
                        />
                        <StatusFilter
                            isActive={
                                props.userActivityFilter === TUsersActivityStatusFilter.DELETED
                            }
                            onClick={handleFilterDeletedUsersClick}
                            text="Archived users"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.filter}>
                <div className={clsx(styles.col, styles.colActions)}>
                    <Filter
                        onClick={order => props.onFilter({ order, name: 'name' })}
                        value={
                            props.activeFilter.name === 'name' ? props.activeFilter.order : ''
                        }
                    >
            Sort by Name
                    </Filter>
                </div>
                <div className={clsx(styles.col, styles.colActions)}>
                    <Filter
                        onClick={order => props.onFilter({ order, name: 'date' })}
                        value={
                            props.activeFilter.name === 'date' ? props.activeFilter.order : ''
                        }
                    >
            Sort by Date
                    </Filter>
                </div>
            </div>
        </div>
    )
}

UserHeader.propTypes = {
    activeUserType: PropTypes.string.isRequired,
    onSelectUser: PropTypes.func.isRequired,

    activeFilter: PropTypes.shape({
        name: PropTypes.string.isRequired,
        order: PropTypes.string.isRequired,
    }).isRequired,
    onFilter: PropTypes.func.isRequired,
    userActivityFilter: PropTypes.string.isRequired,
    onChangeUserActivityFilter: PropTypes.func.isRequired,
}
