import React, { useState, useRef } from 'react'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'

import TextField from '../../../UI/containers/Field/TextField'

import PhoneField from '../../../UI/containers/Field/PhoneField'
import Button from '../../../UI/containers/Button/Button'

import * as service from '../../services/contactUs'

import styles from './ContactUsForm.module.scss'

export default function ContactUsForm() {
    const [isLoading, setLoading] = useState(false)
    const phoneField = useRef(null)

    const { register, handleSubmit, errors, control, reset } = useForm({
        resolver: zodResolver(
            z.object({
                name: z.string().nonempty(),
                email: z.string().email().nonempty(),
                telephoneNumber: z
                    .string()
                    .nonempty()
                    .refine(() => phoneField.current.isValid, {
                        message: 'Phone is not valid',
                    }),
                companyName: z.string().optional(),
                message: z.string().nonempty(),
            })
        ),
    })

    const onSubmit = data => {
        setLoading(true)
        service
            .sendFeedback(data)
            .then(() => {
                toast.success('Feedback was sent successfully')
                reset()
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
            .finally(() => setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className={styles.row}>
                <TextField
                    error={errors.name}
                    className={styles.field}
                    label="Name"
                    name="name"
                    placeholder="Your name"
                    ref={register}
                />
                <TextField
                    error={errors.email}
                    className={styles.field}
                    label="Email"
                    name="email"
                    ref={register}
                    placeholder="example@email.com"
                />
            </div>
            <div className={styles.row}>
                <PhoneField
                    error={errors.telephoneNumber}
                    ref={phoneField}
                    control={control}
                    name="telephoneNumber"
                    label="Phone"
                    className={styles.field}
                />
                <TextField
                    error={errors.companyName}
                    className={styles.field}
                    label="Company Name"
                    name="companyName"
                    placeholder="Company Name (optional)"
                    ref={register}
                />
            </div>
            <TextField
                fieldClass={styles.textarea}
                error={errors.message}
                isTextarea
                name="message"
                ref={register}
                placeholder="Your Message"
            />
            <Button
                isLoading={isLoading}
                className={styles.submit}
                // bgColor="#587b77"
                bgColor="#216626"
                type="submit"
            >
                Send
            </Button>
        </form>
    )
}
