import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'

import { TRoles } from '../shared/types'
import AdminListView from '../modules/Admin/containers/AdminListView'

function AdminPanel(props) {
    return (
        <ContainerMenu>
            {(props.role === TRoles.SUPER_ADMIN) && (
                <AdminListView/>
            )}
        </ContainerMenu>
    )
}

AdminPanel.propTypes = {
    role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    role: state.user.role
})

export default connect(mapStateToProps)(AdminPanel)
