const name = 'user'

const LOG_IN = `${name}/LOG_IN`
const CLEAN = `${name}/CLEAN`
const SET_AUTH_INVITATION = `${name}/SET_AUTH_INVITATION`
const SET_SHOULD_SHOW_DISCLAIMER = `${name}/SET_SHOULD_SHOW_DISCLAIMER`
const SET_USER_INFO = `${name}/SET_USER_INFO`
const SET_COUNT_ALL_UNREAD_MESSAGE = `${name}/SET_COUNT_ALL_UNREAD_MESSAGE`
const SET_COUNT_ALL_MENU = `${name}/SET_COUNT_ALL_MENU`
const CLEAN_AUTH_INVITATION = `${name}/CLEAN_AUTH_INVITATION`

const authInfo = JSON.parse(localStorage.getItem('auth') || '{}')

const initialState = () => ({
    isAuth: !!authInfo.token,
    shouldShowDisclaimer: false,
    role: authInfo.role || null,
    userProfile: {
        photoLink: '',
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        position: '',
        telephoneNumber: ''
    },
    authInvitation: {
        role: '',
        name: '',
        hasPassword: null,
        companyName: '',
        position: '',
        telephoneNumber: ''
    },
    menu: {
        countAllUnreadMessage: 0,
        countUnreadNotifications: 0,
        countNotAnswerQuestion: 0
    }
})

export default function reducer(state = initialState(), action = {}) {
    const { payload } = action
    switch (action.type) {
        case LOG_IN: {
            return {
                ...state,
                isAuth: true,
                ...payload
            }
        }

        case CLEAN_AUTH_INVITATION: {
            return {
                ...state,
                authInvitation: {
                    role: '',
                    name: '',
                    hasPassword: null,
                    companyName: '',
                    position: '',
                    telephoneNumber: ''
                }
            }
        }

        case SET_SHOULD_SHOW_DISCLAIMER : {
            return {
                ...state,
                shouldShowDisclaimer: payload
            }
        }

        case SET_USER_INFO : {
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    ...payload
                }
            }
        }

        case SET_AUTH_INVITATION: {
            return {
                ...state,
                authInvitation: {
                    ...state.authInvitation,
                    ...payload
                }
            }
        }

        case SET_COUNT_ALL_MENU: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    ...payload
                }
            }
        }

        case SET_COUNT_ALL_UNREAD_MESSAGE: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    countAllUnreadMessage: payload
                }
            }
        }

        case CLEAN: {
            return {
                ...state,
                ...initialState(),
                isAuth: false,
                role: null
            }
        }

        default: {
            return state
        }
    }
}

export function logIn({ shouldShowDisclaimer, role }) {
    return {
        type: LOG_IN,
        payload: { shouldShowDisclaimer, role }
    }
}

export function setAuthInvitation(data) {
    return {
        type: SET_AUTH_INVITATION,
        payload: data
    }
}

export function setUserInfo(data) {
    return {
        type: SET_USER_INFO,
        payload: data
    }
}

export function cleanAuthInvitation(data) {
    return {
        type: CLEAN_AUTH_INVITATION,
        payload: data
    }
}

export function clean() {
    return {
        type: CLEAN
    }
}

export function setShouldShowDisclaimer(value) {
    return {
        type: SET_SHOULD_SHOW_DISCLAIMER,
        payload: value
    }
}

export function setCountAllUnreadMessage(value) {
    return {
        type: SET_COUNT_ALL_UNREAD_MESSAGE,
        payload: value
    }
}

export function setCountAllMenu(value) {
    return {
        type: SET_COUNT_ALL_MENU,
        payload: value
    }
}
