import React from 'react'

import Header from '../../../UI/containers/Header/Header'

import ProjectList from '../ProjectList/ProjectList'

import styles from './ProjectListProSellerView.module.scss'

export default function ProjectListProSellerView() {
    return (
        <div className={styles.root}>
            <Header title="Project List" />
            <ProjectList />
        </div>
    )
}
