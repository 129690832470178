import { useRef, useEffect } from 'react'
import http from './http'
import { useLocation } from 'react-router-dom'

import socketChat from './socketChat.js'
import socketNda from './socketNda.js'
import { TRoles } from './types'

import store from '../redux'
import { clean as userStoreClean } from '../redux/ducks/user'

export function setToken({ token, role }) {
    http.defaults.headers['Authorization'] = `Bearer ${token}`
    localStorage.setItem('auth', JSON.stringify({ token, role }))

    if ([TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.OWNER, TRoles.PRO_SELLER].includes(role)) {
        socketChat.connect()
    }
    if ([TRoles.PRO_BUYER, TRoles.INVESTOR].includes(role)) {
        socketNda.connect()
    }
}

export function cleanToken() {
    delete http.defaults.headers['Authorization']
    localStorage.removeItem('auth')

    store.dispatch(userStoreClean())
    socketChat.disconnect()
    socketNda.disconnect()
}

export function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export function convertNameRole(role) {
    switch (role) {
        case TRoles.ADMIN: {
            return 'Admin'
        }
        case TRoles.INVESTOR: {
            return 'Investor'
        }
        case TRoles.OWNER: {
            return 'Business Owner'
        }
        case TRoles.SUPER_ADMIN: {
            return 'Super Admin'
        }
        case TRoles.PRO_BUYER: {
            return 'Pro Buyer'
        }
        case TRoles.PRO_SELLER: {
            return 'Pro Seller'
        }
        default: {
            return ''
        }
    }
}

export function convertRole(role) {
    switch (role) {
        case TRoles.ADMIN: {
            return 'admin'
        }
        case TRoles.INVESTOR: {
            return 'investor'
        }
        case TRoles.OWNER: {
            return 'owner'
        }
        case TRoles.SUPER_ADMIN: {
            return 'super_admin'
        }
        case TRoles.PRO_BUYER: {
            return 'buyer'
        }
        case TRoles.PRO_SELLER: {
            return 'seller'
        }
        default: {
            return ''
        }
    }
}

export function addPropToObject(name, value) {
    return value ? { [name]: value } : {}
}

export function readURL(file) {
    return new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = e => res(e.target.result)
        reader.onerror = e => rej(e)
        reader.readAsDataURL(file)
    })
}

export function useFirstRender() {
    const firstRender = useRef(true)

    useEffect(() => {
        firstRender.current = false
    }, [])

    return firstRender.current
}

export function downloadFile(blob, filename) {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.target = '_blank'
    a.click()
    a.remove()
    setTimeout(() => window.URL.revokeObjectURL(url), 100)
}
