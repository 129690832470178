import React, { useState } from 'react'
import clsx from 'clsx'
import BaseModal from '../../components/BaseModal/BaseModal'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'
import { Switch, Case } from 'react-if'

import { TRoles } from '../../../../shared/types'
import styles from './AdminProfileInfoModal.module.scss'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import Button from '../../../UI/containers/Button/Button'

// import * as service from '../../services/notification'

import Tag from '../../../UI/containers/Tag/Tag'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

export default function AdminProfileInfoModal() {
    const [userInfo, setUserInfo] = useState('')

    const handleOpen = info => {
        info && setUserInfo(info)
        /** this logic can be used later TODO check it after some time **/
        // info.userId &&
        // service.getAdminProfile(info.userId).then(({ data }) => {})
    }

    const handleEditProfile = role => {
        if (role === TRoles.ADMIN || role === TRoles.SUPER_ADMIN)
            return types.openCreateAdminModal
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            nameEvent={types.openAdminProfileInfoModal}
        >
            {({ closeModal }) => (
                <>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <div className={styles.profileInfo}>
                                <Avatar
                                    name={`${userInfo.firstName} ${userInfo.lastName}`}
                                    src={userInfo.photoLink}
                                    size="82"
                                    color="#d2e1df"
                                    textSizeRatio={1.75}
                                    round={5}
                                    fgColor="#9ab8ac"
                                />
                                <div className={styles.name}>
                                    <span className={styles.firstLastName}>
                                        {userInfo.firstName} {userInfo.lastName}
                                    </span>
                                    <Switch>
                                        <Case
                                            condition={
                                                userInfo.role ===
                                                TRoles.ADMIN
                                            }
                                        >
                                            <Tag
                                                className={styles.role}
                                                text="Admin"
                                                bgColor="#eaf5df"
                                                textColor="#9dce6c"
                                            />
                                        </Case>
                                        <Case
                                            condition={
                                                userInfo.role === TRoles.SUPER_ADMIN
                                            }
                                        >
                                            <Tag
                                                className={styles.role}
                                                text="Super admin"
                                                bgColor="#eaf5df"
                                                textColor="#9dce6c"
                                            />
                                        </Case>
                                    </Switch>
                                </div>
                            </div>
                            <div className={styles.profileBtns}>
                                <Button
                                    className={styles.btnImage}
                                    bgColor="#9dce6c"
                                    onClick={() => {
                                        closeModal()
                                        eventEmitter.emit(
                                            handleEditProfile(userInfo.role),
                                            userInfo
                                        )
                                    }}
                                >
                                    Edit Profile
                                </Button>
                                <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                                    <Button
                                        className={clsx(
                                            styles.btn,
                                            styles.btnImage
                                        )}
                                        bgColor="#ffffff"
                                        onClick={() => {
                                            closeModal()
                                            eventEmitter.emit(
                                                types.openArchiveUserModal,
                                                {
                                                    userId: userInfo.userId,
                                                    header: 'Archive',
                                                    message: `Are you sure you want to archive ${userInfo.firstName} ${userInfo.lastName}?`,
                                                }
                                            )
                                        }}
                                    >
                                        Delete User
                                    </Button>
                                </VisibilityAccess>
                            </div>
                        </div>

                        <div className={styles.content}>
                            <div className={styles.contactInfo}>
                                <p className={styles.infoTitle}>
                                    contact Information:
                                </p>
                                <p className={styles.contactPhone}>
                                    {userInfo.phone}
                                </p>
                                <p className={styles.contactEmail}>
                                    {userInfo.email}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </BaseModal>
    )
}

AdminProfileInfoModal.propTypes = {
    info: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }),
}
