import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { If, Then, Else } from 'react-if'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/proseller'

import SearchForm from '../../../UI/containers/SearchForm/SearchForm'
import SellerItem from './SellerItem'
import Scrollbars from 'react-custom-scrollbars'
import { addPropToObject } from '../../../../shared/helpers'

import styles from './SellerAccessModal.module.scss'

export default function SellerAccessModal() {
    const [sellers, setSellers] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [projectId, setProjectId] = useState('')
    const [search, setSearch] = useState('')

    const fetchSellers = useDebouncedCallback(() => {
        service
            .getNotAssignedProSellers(projectId, {
                ...addPropToObject('search', search),
            })
            .then(({ data }) => {
                setSellers(data.sellers)
            })
            .finally(() => setLoading(false))
    }, 300)

    const handleOpen = projectId => {
        fetchSellers()
        setProjectId(projectId)
    }

    useEffect(() => {
        if (projectId) {
            fetchSellers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, projectId])

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openSellerAccessModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        titleClassName={styles.titleHeader}
                        title="Add access to project"
                    />

                    <form autoComplete="off">
                        <div className={styles.content}>
                            <div className={styles.filter}>
                                <SearchForm
                                    onSubmit={value => setSearch(value)}
                                    placeholder="Search ProSellers"
                                />
                            </div>
                            <Scrollbars
                                className={styles.wrapperContent}
                                renderThumbVertical={({ style, ...props }) => (
                                    <div
                                        style={{
                                            ...style,
                                            backgroundColor: '#9dce6c',
                                        }}
                                        {...props}
                                    />
                                )}
                            >
                                <If condition={sellers.length !== 0}>
                                    <Then>
                                        {sellers.map(item => (
                                            <SellerItem
                                                key={item.id}
                                                info={{
                                                    sellerId: item.id,
                                                    name: item.fullName,
                                                    projectId: projectId,
                                                }}
                                            />
                                        ))}
                                    </Then>
                                    <Else>
                                        <div className={styles.empty}>
                                            <p
                                                className={
                                                    styles.emptyDescription
                                                }
                                            >
                                                No ProSellers
                                            </p>
                                        </div>
                                    </Else>
                                </If>
                            </Scrollbars>
                        </div>
                        <div className={styles.footer}>
                            <Button
                                isLoading={isLoading}
                                bgColor="#9dce6c"
                                className={styles.submit}
                                type="submit"
                                onClick={() => closeModal()}
                            >
                                Continue
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
