import http from '../../../shared/http'

export function archiveProject(projectId) {
    return http.delete(`/projects/super-admin/${projectId}`)
}

export function deleteUser(userId) {
    return http.delete(`/users/super-admin/${userId}`)
}
export function deleteAdmin(userId) {
    return http.delete(`/users/super-admin/admins/${userId}`)
}

export function archiveUser(userId) {
    return http.put(`/users/super-admin/${userId}/archive`)
}
export function unarchiveUser(userId) {
    return http.put(`/users/super-admin/${userId}/unarchive`)
}

export function createAdmin(data) {
    return http.post('/users/super-admin/create', {
        shouldSendInvitation: !!data.shouldSendInvitation,
        role: data.userType,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        telephoneNumber: data.phone,
        companyName: data.companyName,
    })
}

export function editAdmin(userId, data) {
    return http.put(`/users/super-admin/${userId}`,
        {
            shouldSendInvitation: !!data.shouldSendInvitation,
            role: data.userType,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            telephoneNumber: data.phone
        })
}
export function editUserEmailSettings(userId, emailSettingsToJSON) {
    return http.put(`/users/super-admin/${userId}/email-settings`,
        {
            emailSettings: emailSettingsToJSON
        })
}

export function getAdminProfile(userId) {
    return http.get(`/users/super-admin/${userId}/profile`)
}
