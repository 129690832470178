export const ICONS = {
    OVERVIEW:
    'M15.107 4.335l-5.999-4C8.771.11 8.385-.001 7.999-.001c-.387 0-.775.111-1.11.336l-6 4c-.556.37-.891.995-.891 1.664v8c0 1.105.896 2 2.001 2h11.999c1.105 0 2.001-.895 2.001-2v-8c0-.669-.336-1.294-.892-1.664zm-8.108 9.664v-4h1.999v4H6.999zm6.999 0h-3.001v-4c0-1.104-.895-2.001-1.999-2.001H6.999c-1.105 0-2.001.897-2.001 2.001v4H1.999v-8l6-4 5.999 4v8z',
    USERS_LIST:
    'M12.965 9.967c-.782-.579-1.633-1.028-2.525-1.354.96-.911 1.567-2.191 1.567-3.615 0-2.552-1.897-4.671-4.423-4.961-.245-.039-.448-.039-.59-.039-.14 0-.345 0-.588.039-2.528.29-4.424 2.409-4.424 4.961 0 1.424.607 2.704 1.568 3.615-.892.326-1.743.775-2.526 1.354-.643.476-1.028 1.236-1.028 2.031 0 .796.385 1.555 1.028 2.032 1.742 1.288 3.805 1.968 5.97 1.968 2.165 0 4.229-.68 5.971-1.968.643-.477 1.027-1.236 1.027-2.032 0-.795-.384-1.555-1.027-2.031zM3.989 4.998c0-1.559 1.181-2.831 2.695-2.98.101-.02.2-.02.31-.02.111 0 .211 0 .312.02 1.514.149 2.695 1.421 2.695 2.98 0 1.66-1.342 3-3.007 3-1.664 0-3.005-1.34-3.005-3zm7.782 7.424c-1.334.988-2.985 1.576-4.777 1.576-1.79 0-3.441-.588-4.775-1.576-.29-.213-.29-.634 0-.849 1.334-.986 2.985-1.576 4.775-1.576 1.792 0 3.443.59 4.777 1.576.29.215.29.636 0 .849z',
    ACCOUNTS_LIST:
    'M 14.398438 3.367188 L 11.199219 3.367188 L 11.199219 1.6875 C 11.199219 0.753906 10.488281 0 9.601562 0 L 6.398438 0 C 5.511719 0 4.800781 0.753906 4.800781 1.6875 L 4.800781 3.375 L 1.601562 3.375 C 0.710938 3.375 0.0078125 4.128906 0.0078125 5.0625 L 0 14.3125 C 0 15.246094 0.710938 16 1.601562 16 L 14.398438 16 C 15.289062 16 16 15.246094 16 14.3125 L 16 5.054688 C 16 4.121094 15.289062 3.367188 14.398438 3.367188 Z M 9.601562 3.367188 L 6.398438 3.367188 L 6.398438 1.6875 L 9.601562 1.6875 Z M 2.015625 5.167969 C 1.929688 5.167969 1.855469 5.238281 1.855469 5.328125 L 1.855469 14.304688 C 1.855469 14.390625 1.929688 14.464844 2.015625 14.464844 L 13.984375 14.464844 C 14.070312 14.464844 14.144531 14.390625 14.144531 14.304688 L 14.144531 5.328125 C 14.144531 5.238281 14.070312 5.167969 13.984375 5.167969 Z M 2.015625 5.167969',
    PROJECTS_LIST:
    'M11.999 15.999H1.998c-1.103 0-1.999-.896-1.999-2v-12C-.001.894.895 0 1.998 0h7c2.76 0 5.001 2.239 5.001 4.999v9c0 1.104-.897 2-2 2zm-2.998-14l-.002.1v2.9h3c0-1.65-1.349-2.999-2.998-3zm2.998 7v-2h-5v-5H1.998v12h10.001v-5zm-8.001-3c.552 0 1.001.448 1.001 1v4c0 .552-.449 1-1.001 1-.553 0-1.001-.448-1.001-1v-4c0-.552.448-1 1.001-1zm3.001 2c.551 0 .999.448.999 1v2c0 .552-.448 1-.999 1-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1zm2.998 1c.554 0 1.002.447 1.002 1v1c0 .552-.448 1-1.002 1-.552 0-.999-.448-.999-1v-1c0-.553.447-1 .999-1z',
    NDA_LIST:
    'M14.893 3.21l-6-3.001c-.282-.14-.59-.21-.894-.21-.307 0-.613.07-.895.21L1.102 3.21C.427 3.549-.002 4.241-.002 4.999c0 6.677 4.82 10.048 7.368 10.898.206.067.419.102.633.102.213 0 .426-.035.632-.102 2.548-.85 7.368-4.221 7.368-10.898 0-.758-.429-1.45-1.106-1.789zM7.999 13.999s-6-2-6-9l6-3 5.999 3c0 7-5.999 9-5.999 9z',
    NOTIFICATIONS:
    'M13.997 7.17c0-3.007-2.169-5.573-5-6.062v-.11c0-.55-.45-1-1-1s-1 .45-1 1v.09c-2.833.479-5 2.944-5 5.91v.172c-1.164.412-2 1.524-2 2.828 0 1.654 1.346 3 3 3h1.999c0 1.654 1.347 3.001 3.001 3.001 1.655 0 2.999-1.347 2.999-3.001h2.001c1.655 0 3-1.346 3-3 0-1.304-.835-2.416-2-2.828zm-6 6.828c-.552 0-1-.448-1-1h2c0 .552-.447 1-1 1zm5-3h-10c-.552 0-1-.447-1-1 0-.552.448-1 1-1h1v-2c0-2.209 1.791-4 4-4 .13 0 .26.006.393.019 2.08.197 3.607 1.892 3.607 3.981v2h1c.552 0 1 .448 1 1 0 .553-.448 1-1 1z',
    QUESTIONS:
    'M13.999 13.998H7.125l-1.849 1.538c-.366.305-.819.463-1.277.463-.29 0-.579-.063-.85-.19-.702-.329-1.15-1.035-1.15-1.811-1.105 0-2.001-.894-2.001-2v-10c0-1.103.896-2 2.001-2h12c1.104 0 1.999.897 1.999 2v10c0 1.106-.895 2-1.999 2zm0-12h-12v10h2v2l2.405-2h7.595v-10zm-9 3.001V3.998c0-.551.447-.999.999-.999h4c.553 0 1.001.448 1.001.999v2.001c0 .379-.214.725-.553.894l-2.002 1c-.142.072-.295.106-.445.106-.367 0-.72-.202-.895-.554-.248-.493-.047-1.094.448-1.341l1.446-.723v-.382h-2c0 .552-.447 1-1 1-.552 0-.999-.448-.999-1zm3 3.999c.551 0 .999.448.999 1.001 0 .552-.448 1-.999 1-.554 0-1.001-.448-1.001-1 0-.553.447-1.001 1.001-1.001z',
    FEEDBACKS:
    'M13.998 4.999H9.999V1.998c0-1.104-.897-1.999-2.001-1.999h-.999c-.818 0-1.554.498-1.858 1.257L3.644 4.999H1.998c-1.105 0-1.999.894-1.999 1.999v7.001c0 1.104.894 2 1.999 2h2.001c.354 0 .698-.094.999-.268.302.174.647.268 1 .268h4.001c.53 0 1.039-.212 1.414-.586l2.562-2.563 1.437-1.437c.376-.375.587-.884.587-1.414V6.998c0-1.105-.896-1.999-2.001-1.999zm-12 9V6.998h1.001v7.001H1.998zm12-4l-1.437 1.438-2.562 2.562H5.998l-1-1V6.998l2.001-5h.999v5h6v3.001z',
    OFFERS:
    'M15.172 6.301l-5.87 7c-.372.443-.914.698-1.486.698-.571 0-1.114-.255-1.486-.698l-5.87-7c-.665-.793-.622-1.977.102-2.715L3.498.585c.366-.375.864-.586 1.382-.586h5.872c.519 0 1.017.211 1.383.586l2.936 3.001c.723.738.767 1.922.101 2.715zM10.752 2H4.88L1.945 5l5.871 7 5.87-7-2.934-3zM4.88 5l.979-1.001h3.914L10.752 5 7.816 9 4.88 5z',
    PROFILE_DROPDOWN:
    'M-.002 1c0-.254.099-.51.294-.705.391-.389 1.025-.389 1.415 0l3.298 3.283L8.301.295c.391-.389 1.026-.389 1.416 0 .392.39.392 1.021 0 1.41L5.713 5.693c-.391.389-1.024.389-1.416 0L.292 1.705C.097 1.51-.002 1.255-.002 1z',
    TABLE_EDIT:
    'M1.999 9.999c-1.106 0-2.001-.895-2.001-2s.895-2 2.001-2c1.103 0 1.999.895 1.999 2s-.896 2-1.999 2zm0-6c-1.106 0-2.001-.895-2.001-2.001 0-1.104.895-1.999 2.001-1.999 1.103 0 1.999.895 1.999 1.999 0 1.106-.896 2.001-1.999 2.001zm0 8c1.103 0 1.999.895 1.999 2 0 1.104-.896 2-1.999 2-1.106 0-2.001-.896-2.001-2 0-1.105.895-2 2.001-2z',
    DELETE:
    'M14.999 5h-1.001v8.999c0 1.105-.894 2-1.999 2h-8c-1.105 0-2.001-.895-2.001-2V5h-1c-.551 0-.999-.448-.999-1.001 0-.552.448-1 .999-1H4.999v-1c0-1.104.895-2 2-2h2c1.105 0 2 .896 2 2v1h4c.552 0 1 .448 1 1 0 .553-.448 1.001-1 1.001zm-6-3.001h-2v1h2v-1zm3 3.001h-8v8.999h8V5zm-5 1.999h2c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.552 0-1-.448-1-1s.448-1 1-1z',
    SEARCH:
    'M14.602 12.29l-2.796-2.821c.696-.981 1.109-2.178 1.109-3.471 0-3.308-2.677-6-5.968-6-3.29 0-5.967 2.692-5.967 6 0 3.309 2.677 6 5.967 6 1.287 0 2.477-.416 3.453-1.116l2.796 2.823c.194.195.448.293.702.293.254 0 .51-.098.704-.293.389-.39.389-1.023 0-1.415zM6.947 9.998c-2.196 0-3.977-1.791-3.977-4 0-2.21 1.781-4 3.977-4 2.198 0 3.979 1.79 3.979 4 0 2.209-1.781 4-3.979 4z',
    UPLOAD:
    'M14.999 13.999h-1v1c0 .55-.45 1.001-1 1.001s-1-.451-1-1.001v-1h-1c-.55 0-1.001-.45-1.001-1s.451-1 1.001-1h1v-1c0-.549.45-1 1-1s1 .451 1 1v1h1c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6c-.55 0-1-.449-1-1h-5V2h-5v11.999h5c.55 0 1 .45 1 1S7.549 16 6.999 16h-5c-1.1 0-2-.901-2-2.001V2c0-1.1.9-2.001 2-2.001h7c2.76 0 5 2.24 5 5v2c0 .551-.45 1-1 1zM8.999 2v2.999h3c0-1.65-1.35-2.999-3-2.999z',
    CREATE_FOLDER:
    'M13.999 13.999h-12c-1.105 0-2.001-.895-2.001-2v-10c0-1.105.896-2 2.001-2h5c.758 0 1.45.428 1.789 1.106l.447.894h4.764c1.104 0 2 .896 2 2v8c0 1.105-.896 2-2 2zm0-10h-6l-1-2h-5v10h12v-8zm-8.001 3h1.001v-1c0-.55.45-1 1-1 .549 0 1 .45 1 1v1h1c.55 0 1 .45 1 1.001 0 .549-.45.999-1 .999h-1v1c0 .55-.451 1-1 1-.55 0-1-.45-1-1v-1H5.998c-.549 0-.999-.45-.999-.999 0-.551.45-1.001.999-1.001z',
    ROLL_DOWN:
    'M.001.999c0-.255.098-.51.294-.704.391-.389 1.024-.389 1.414 0l3.299 3.283L8.305.295c.391-.389 1.025-.389 1.416 0s.391 1.021 0 1.409L5.716 5.691c-.392.39-1.025.39-1.416 0L.295 1.704C.099 1.509.001 1.254.001.999z',
    ROLLUP:
    'M9.998 4.999c0 .255-.098.51-.293.705-.391.389-1.022.389-1.412 0L5.005 2.421 1.72 5.704c-.392.389-1.023.389-1.413 0-.39-.388-.39-1.021 0-1.41L4.3.307c.391-.389 1.022-.389 1.411 0l3.994 3.987c.195.195.293.451.293.705z',
    FOLDER:
    'M13.999 1.999H9.235l-.447-.894C8.449.427 7.757 0 6.999 0h-5C.894 0-.002.894-.002 1.999v10c0 1.105.896 2 2.001 2h12c1.105 0 2-.895 2-2v-8c0-1.104-.895-2-2-2zm-3 3.05v2.45c0 .28-.221.5-.5.5-.28 0-.5-.22-.5-.5v-3.5h1v1.05zm3 6.95h-12v-10h5l1 2v3.5c0 1.21.86 2.22 2 2.45.16.03.33.05.5.05.17 0 .34-.02.5-.05 1.139-.23 2-1.24 2-2.45v-3.5h1v8z',
    OPEN_FOLDER:
    'M15.434 2.679c-.377-.429-.924-.68-1.491-.68H9.212l-.447-.89C8.427.429 7.74-.001 6.986-.001h-4.97c-.566 0-1.093.24-1.471.66-.378.421-.566.98-.507 1.54l.308 3.07.149 1.581.537 5.349c.1 1.021.954 1.8 1.978 1.8h9.939c1.004 0 1.849-.75 1.968-1.75l.994-7.999c.08-.57-.1-1.14-.477-1.571zm-8.448-.68l.994 2h5.963l-.119 1H2.314l-.298-3h4.97zm5.963 10H3.01l-.498-5h11.064l-.627 5z',
    FILE:
    'M8.999 0h-7c-1.105 0-2 .895-2 1.999v12c0 1.105.895 2 2 2h10c1.105 0 2-.895 2-2v-9c0-2.76-2.24-4.999-5-4.999zm3 4.999h-3v-3c1.65 0 3 1.351 3 3zm-7 9v-2.5c0-.279.22-.499.5-.499s.5.22.5.499v2.5h-1zm7 0h-4v-2.5c0-1.21-.86-2.22-2-2.45-.16-.03-.33-.05-.5-.05-.17 0-.34.02-.5.05-1.14.23-2 1.24-2 2.45v2.5h-1v-12h4.999v5h5.001v7z',
    DOWNLOAD:
    'M14.999 15.999H.998c-.552 0-1-.447-1-.999v-2.001c0-.553.448-1 1-1s1 .447 1 1v1h12v-1c0-.553.448-1 1.001-1 .552 0 1 .447 1 1V15c0 .552-.448.999-1 .999zm-6.293-4.293c-.191.191-.448.293-.708.293-.276 0-.53-.116-.706-.293l-3-3c-.391-.391-.391-1.023 0-1.414.389-.391 1.023-.391 1.414 0l1.292 1.293V.998c0-.551.448-.999 1-.999s.999.448.999.999v7.587l1.295-1.293c.389-.391 1.023-.391 1.413 0 .392.391.392 1.023 0 1.414l-2.999 3z',
    CHECKBOX:
    'M5.001 10.009c-.266 0-.522-.105-.711-.294L.28 5.706c-.393-.392-.393-1.028 0-1.421.393-.392 1.029-.392 1.422 0l3.19 3.191L11.193.4c.334-.442.963-.534 1.406-.2.446.332.536.962.201 1.406L5.805 9.607c-.174.234-.442.379-.733.399-.023.002-.048.003-.071.003z',
    EXCLAMATION:
    'M7.997 15.999c-4.411 0-7.999-3.589-7.999-8.001 0-4.411 3.588-8 7.999-8 4.412 0 8 3.589 8 8 0 4.412-3.588 8.001-8 8.001zm0-14c-3.313 0-5.999 2.685-5.999 5.999 0 3.314 2.686 6 5.999 6 3.313 0 6-2.686 6-6s-2.687-5.999-6-5.999zm0 9.999c-.552 0-1.001-.447-1.001-1 0-.552.449-1 1.001-1 .553 0 1.001.448 1.001 1 0 .553-.448 1-1.001 1zm0-3c-.552 0-1.001-.447-1.001-1V4.999c0-.553.449-1.001 1.001-1.001.553 0 1.001.448 1.001 1.001v2.999c0 .553-.448 1-1.001 1z',
}

export class TRoutes {
  static MAIN = '/';
  static LOGIN = '/login';
  static FORGOT_PASSWORD = '/forgot-password';
  static NOT_FOUND = '/404';

  static DASHBOARD = '/dashboard';
  static PROJECTS = '/projects';
  static PROJECT = '/project';
  static PROJECT_ID = (id = ':id') => `/projects/${id}`;

  static USERS = '/users';
  static ACCOUNTS = '/accounts';

  static NDA_LIST = '/nda-list';
  static NOTIFICATION = '/notification';

  static OFFERS = '/offers';
  static FEEDBACKS = '/feedbacks';

  static STATISTIC = '/statistic';

  static QUESTIONS = '/questions';

  static SIGN_UP_OPTIONAL = '/sign-up/optional';
  static SIGN_UP_PASSWORD = '/sign-up/password';
  static ADMIN_PANEL = '/admin_panel';
}

export class TRoles {
  static INVESTOR = 'INVESTOR';
  static OWNER = 'BUSINESS_OWNER';
  static ADMIN = 'ADMIN';
  static SUPER_ADMIN = 'SUPER_ADMIN';
  static PRO_BUYER = 'PRO_BUYER';
  static PRO_SELLER = 'PRO_SELLER';
  static ACCOUNT = 'ACCOUNT';
}

export class TStatusProject {
  static DRAFT = 'DRAFT';
  static PUBLISHED = 'PUBLISHED';
  static ARCHIVED = 'ARCHIVED';
}

export class TStatistic {
  static PROJECTS = 'PROJECTS';
  static BUSINESS_OWNER = 'BUSINESS_OWNER'; // investor and owner
  static INVESTOR = 'INVESTOR'; // investor and owner
  static ACCOUNTS = 'ACCOUNTS';

  static NDA_SIGNED = 'NDA_SIGNED';
  static WATCH_TEASER_MODAL = 'WATCH_TEASER_MODAL';
  static QUESTIONS_ASKED = 'QUESTIONS_ASKED';
  static DEALS = 'DEALS';
  static PROJECT_VIEWS = 'PROJECT_VIEWS';
  static FEEDBACKS_LEFT = 'FEEDBACKS_LEFT';

  static QUESTIONS_ANSWERED = 'QUESTIONS_ANSWERED';

  static FILE_VIEWS = 'FILE_VIEWS';
}

export class TNotifications {
  static INVESTOR_LEAVES_QUESTION = 'INVESTOR_LEAVES_QUESTION';
  static BUSINESS_OWNER_LEAVES_ANSWER = 'BUSINESS_OWNER_LEAVES_ANSWER';
  static INVESTOR_LEAVES_FEEDBACK = 'INVESTOR_LEAVES_FEEDBACK';
  static VISIBILITY_INVESTOR_LEAVES_FEEDBACK =
    'VISIBILITY_INVESTOR_LEAVES_FEEDBACK';
  static ADMIN_SHARE_STATISTICS = 'ADMIN_SHARE_STATISTICS';
  static INVESTOR_CREATE_OFFER = 'INVESTOR_CREATE_OFFER';
  static OFFLINE_NDA_SIGN_REQUEST = 'OFFLINE_NDA_SIGN_REQUEST';
  static INVESTOR_CREATE_OFFER_CONFIRMED = 'INVESTOR_CREATE_OFFER_CONFIRMED';
  static ADMIN_DECLINED_OFFER = 'ADMIN_DECLINED_OFFER';
  static ADMIN_ACCEPTED_OFFER = 'ADMIN_ACCEPTED_OFFER';
  static BUSINESS_OWNER_ACCEPTED_OFFER = 'BUSINESS_OWNER_ACCEPTED_OFFER';
  static BUSINESS_OWNER_DECLINED_OFFER = 'BUSINESS_OWNER_DECLINED_OFFER';
}

export class TNotificationsStatus {
  static SEEN = 'SEEN';
  static NOT_SEEN = 'NOT_SEEN';
}

export class TProjects {
  static REGULAR = 'REGULAR';
  static M_AND_A = 'M_AND_A';
}

export class TUsersActivityStatusFilter {
  static ACTIVE = 'ACTIVE';
  static DELETED = 'DELETED';
}

export class TOfferStatus {
  static ADMIN_ACCEPTED = 'ADMIN_ACCEPTED'
  static ADMIN_PENDING ='ADMIN_PENDING'
  static ADMIN_DECLINED = 'ADMIN_DECLINED'
  static BO_ACCEPTED = 'BO_ACCEPTED'
  static BO_DECLINED = 'BO_DECLINED'
}