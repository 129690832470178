import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import NotificationContent from '../../components/NotificationContent/NotificationContent'

import { parseISO, format } from 'date-fns'

import styles from './NotificationItem.module.scss'
import { TNotifications, TRoutes } from '../../../../shared/types'

function getLayoutNotifications(type, info, actionClassName) {
    switch (type) {
        case TNotifications.INVESTOR_LEAVES_QUESTION: {
            return (
                <NotificationContent
                    name={`${info.investor.firstName} ${info.investor.lastName}`}
                    photoLink={info.investor.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>
                            {info.investor.firstName} {info.investor.lastName}
                        </span>{' '}
                        leaves question{' '}
                        <span className={styles.boldText}>
                            {info.project.name}
                        </span>
                        {'. '}
                        <Link className={styles.link} to={TRoutes.QUESTIONS}>
                            Go to questions.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.INVESTOR_LEAVES_FEEDBACK: {
            return (
                <NotificationContent
                    name={`${info.investor.firstName} ${info.investor.lastName}`}
                    photoLink={info.investor.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>
                            {info.investor.firstName} {info.investor.lastName}
                        </span>{' '}
                        leaves feedback{' '}
                        <span className={styles.boldText}>
                            {info.project.name}
                        </span>
                        {'. '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.VISIBILITY_INVESTOR_LEAVES_FEEDBACK: {
            return (
                <NotificationContent
                    name={`${info.investor.firstName} ${info.investor.lastName}`}
                    photoLink={info.investor.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>
                            {info.investor.firstName} {info.investor.lastName}
                        </span>{' '}
                        leaves feedback{' '}
                        <span className={styles.boldText}>
                            {info.project.name}
                        </span>
                        {'. '}
                        <Link className={styles.link} to={TRoutes.FEEDBACKS}>
                            Go to feedbacks.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.ADMIN_SHARE_STATISTICS: {
            return (
                <NotificationContent name="Admin">
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>Admin</span> share
                        statistic.{' '}
                        <a
                            className={styles.link}
                            rel="noreferrer noopener"
                            target="_blank"
                            href={info.linkPdf}
                        >
                            Download file.
                        </a>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.BUSINESS_OWNER_LEAVES_ANSWER: {
            return (
                <NotificationContent
                    name={info.project.name}
                    photoLink={info.project.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        The answer to the question was left in the{' '}
                        <span className={styles.boldText}>
                            {info.project.name}
                        </span>
                        {'. '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.INVESTOR_CREATE_OFFER_CONFIRMED: {
            return (
                <NotificationContent
                    name={`${info.investor.firstName} ${info.investor.lastName}`}
                    photoLink={info.investor.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>
                            {info.investor.firstName} {info.investor.lastName}{' '}
                        </span>
                        creates offer for your projects.{' '}
                        <Link className={styles.link} to={TRoutes.OFFERS}>
                            Go to offers.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.INVESTOR_CREATE_OFFER: {
            return (
                <NotificationContent
                    name={`${info.investor.firstName} ${info.investor.lastName}`}
                    photoLink={info.investor.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>
                            {info.investor.firstName} {info.investor.lastName}{' '}
                        </span>
                        creates offer for{' '}
                        <span className={styles.boldText}>
                            {info.project.name}.
                        </span>{' '}
                        Please review.{' '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.OFFLINE_NDA_SIGN_REQUEST: {
            return (
                <NotificationContent
                    name={`${info.proBuyer.firstName} ${info.proBuyer.lastName}`}
                    photoLink={info.proBuyer.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>
                            {info.proBuyer.firstName} {info.proBuyer.lastName}{' '}
                        </span>
                        requests offline NDA for{' '}
                        <span className={styles.boldText}>
                            {info.project.name}.
                        </span>{' '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.ADMIN_DECLINED_OFFER: {
            return (
                <NotificationContent  
                    name={info.project.name}
                    photoLink={info.project.photoLink}>
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>Admin</span> rejected your offer for project {' '}
                        <span className={styles.boldText}>
                            {info.project.name}.
                        </span>{' '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.ADMIN_ACCEPTED_OFFER: {
            return (
                <NotificationContent
                    name={info.project.name}
                    photoLink={info.project.photoLink}>
                    <div className={clsx(actionClassName, styles.action)}>
                        <span className={styles.boldText}>Admin</span> approved your offer for {' '}
                        <span className={styles.boldText}>
                            {info.project.name}.
                        </span>{' '}
                        <span >
                            The offer has been submitted to the business owner for consideration.
                        </span>{' '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }
        case TNotifications.BUSINESS_OWNER_ACCEPTED_OFFER: {
            return (
                <NotificationContent
                    name={info.project.name}
                    photoLink={info.project.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        Your offer for{' '}
                        <span className={styles.boldText}>
                            {info.project.name}
                        </span>
                        {' '}was accepted
                        {'. '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }

        case TNotifications.BUSINESS_OWNER_DECLINED_OFFER: {
            return (
                <NotificationContent
                    name={info.project.name}
                    photoLink={info.project.photoLink}
                >
                    <div className={clsx(actionClassName, styles.action)}>
                        Your offer for{' '}
                        <span className={styles.boldText}>
                            {info.project.name}
                        </span>
                        {' '}was declined
                        {'. '}
                        <Link
                            className={styles.link}
                            to={TRoutes.PROJECT_ID(info.project.id)}
                        >
                            Go to project.
                        </Link>
                    </div>
                </NotificationContent>
            )
        }

        default:
            return null
    }
}

export default function NotificationItem(props) {
    return (
        <div className={styles.notificationsItem}>
            {getLayoutNotifications(
                props.notification.type,
                props.notification.info,
                props.actionClassName
            )}
            <div>
                {format(
                    parseISO(props.notification.createdAt),
                    'HH:mm dd.MM.yyyy'
                )}
            </div>
        </div>
    )
}

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        info: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    actionClassName: PropTypes.string
}
