import React from 'react'
import PropTypes from 'prop-types'

// Assets
import { ReactComponent as EyeActiveIcon } from '../../../../assets/icons/eye.svg'
import { ReactComponent as EyeNotActiveIcon } from '../../../../assets/icons/eye-no.svg'

// Styles
import styles from './StatusFilter.module.scss'

const StatusFilter = ({ isActive, text, onClick }) => {
    return (
        <div className={styles.filter} onClick={onClick}>
            {isActive ? <EyeActiveIcon /> : <EyeNotActiveIcon />}
            <div className={styles.text}>{text}</div>
        </div>
    )
}

export default StatusFilter

StatusFilter.propTypes = {
    isActive: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}
