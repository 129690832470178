import React, { useState, useRef } from 'react'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
// import { toast } from 'react-toastify'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import { If, Then, Else } from 'react-if'

import PhoneField from '../../../UI/containers/Field/PhoneField'
import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'
import Checkbox from '../../../UI/containers/Checkbox/Checkbox'
import Select from '../../../UI/containers/Field/Select'

import * as service from '../../services/probuyer'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import { TRoles } from '../../../../shared/types'

import styles from './ProbuyerModal.module.scss'

export default function ProbuyerModal() {
    const [isLoading, setLoading] = useState(false)
    const phoneField = useRef(null)
    const [userId, setUserId] = useState('')
    const [accountId, setAccountId] = useState('')
    const [isEditMode, setEditMode] = useState(false)

    const {
        register,
        handleSubmit,
        errors,
        control,
        reset,
        setValue,
    } = useForm({
        resolver: zodResolver(
            z.object({
                userType: z.string().nonempty({
                    message: 'You should select user type',
                }),
                firstName: z
                    .string()
                    .regex(/^(?!\s+$)[A-Za-zæøåÆØÅ0-9'\s-]+$/, {
                        message: 'Incorrect name',
                    })
                    .nonempty(),
                lastName: z
                    .string()
                    .regex(/^(?!\s+$)[A-Za-zæøåÆØÅ0-9'\s-]+$/, {
                        message: 'Incorrect name',
                    })
                    .nonempty(),
                email: z.string().email().nonempty(),
                phone: z.string().refine(() => phoneField.current.isValid, {
                    message: 'Phone is not valid',
                }),
                position: z.string(),
                shouldSendInvitation: z.boolean(),
            })
        ),
    })

    const handleOpen = info => {
        setEditMode(false)
        setAccountId(info.accountId)
        if (info.firstName) {
            setEditMode(true)
            setUserId(info.userId)
            service
                .getProBuyerInfo(info.userId)
                .then(({ data }) => {
                    setTimeout(() => {
                        setValue('userType', data.userProfile.role)
                        setValue('firstName', data.userProfile.firstName)
                        setValue('lastName', data.userProfile.lastName)
                        setValue('email', data.userProfile.email)
                        setValue('phone', data.userProfile.telephoneNumber)
                        setValue('position', data.userProfile.position)
                    }, 0)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        } else {
            setTimeout(() => {
                setValue('shouldSendInvitation', true)
                setValue('userType', TRoles.PRO_BUYER)
            }, 0)
        }
    }

    const onSubmit = closeModal => data => {
        setLoading(true)
        if (isEditMode) {
            setLoading(true)
            service
                .editProBuyer(userId, data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Edit ProBuyer',
                        message: 'ProBuyer successfully edited',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateAccountList)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        } else {
            service
                .addProbuyer(accountId, data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Create ProBuyer',
                        message: 'ProBuyer successfully created',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateAccountList)
                    eventEmitter.emit(types.updateOwnAccount)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        }
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openCreateProbuyerModal}
        >
            {({ closeModal }) => (
                <>
                    <If condition={isEditMode}>
                        <Then>
                            <ModalHeader
                                className={styles.header}
                                title="Edit User"
                            />
                        </Then>
                        <Else>
                            <ModalHeader
                                className={styles.header}
                                title="Add New User"
                            />
                        </Else>
                    </If>
                    <form
                        onSubmit={handleSubmit(onSubmit(closeModal))}
                        autoComplete="off"
                    >
                        <div className={styles.content}>
                            <div className={styles.row}>
                                <Select
                                    error={errors.userType}
                                    className={styles.field}
                                    control={control}
                                    label="User Type"
                                    name="userType"
                                    placeholder="Select user type"
                                    isRequired
                                    options={[
                                        {
                                            value: TRoles.PRO_BUYER,
                                            label: 'ProBuyer',
                                        },
                                    ]}
                                />
                                <TextField
                                    error={errors.email}
                                    className={styles.field}
                                    label="User Email"
                                    name="email"
                                    placeholder="example@email.com"
                                    isRequired
                                    ref={register}
                                />
                            </div>
                            <div className={styles.row}>
                                <TextField
                                    error={errors.firstName}
                                    className={styles.field}
                                    label="First Name"
                                    name="firstName"
                                    placeholder="First Name"
                                    isRequired
                                    ref={register}
                                />
                                <TextField
                                    error={errors.lastName}
                                    className={styles.field}
                                    label="Last Name"
                                    name="lastName"
                                    placeholder="Last Name"
                                    isRequired
                                    ref={register}
                                />
                            </div>
                            <div className={styles.row}>
                                <PhoneField
                                    error={errors.phone}
                                    ref={phoneField}
                                    control={control}
                                    name="phone"
                                    label="Phone"
                                    className={styles.field}
                                />
                                {/*<Select
                                    error={errors.assignAccount}
                                    className={styles.field}
                                    control={control}
                                    label="Assign to account"
                                    name="assignAccount"
                                    placeholder="Select account"
                                    isRequired
                                    options={[
                                        {
                                            value: 'value0',
                                            label: 'Not assign',
                                        },
                                        {
                                            value: 'value1',
                                            label: 'Coca-Cola',
                                        },
                                    ]}
                                />*/}
                            </div>
                            <div className={styles.row}>
                                <TextField
                                    error={errors.position}
                                    className={styles.field}
                                    label="Position"
                                    name="position"
                                    placeholder="User Position (optional)"
                                    ref={register}
                                />
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.row}>
                                    <Checkbox
                                        error={errors.shouldSendInvitation}
                                        name="shouldSendInvitation"
                                        register={register}
                                        label="Send Invitational Email"
                                    />
                                    <If condition={isEditMode}>
                                        <Then>
                                            <Button
                                                isLoading={isLoading}
                                                className={styles.actionButton}
                                                bgColor="#9dce6c"
                                                type="submit"
                                            >
                                                Edit User
                                            </Button>
                                        </Then>
                                        <Else>
                                            <Button
                                                isLoading={isLoading}
                                                className={styles.actionButton}
                                                bgColor="#9dce6c"
                                                type="submit"
                                            >
                                                Create User
                                            </Button>
                                        </Else>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
