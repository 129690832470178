import http, {mock} from '../../../shared/http'

var textArray = ['BUSINESS_OWNER','INVESTOR']

mock.onGet('/allusers/admin').reply(200, {
    status: 'ok',
    count: 55,
    users: new Array(20).fill('').map((_, index) => ({   
        userId: 'fa98299a-fd2d-463b-95fa-e1af7e0b8f9b' + index,
        firstName: 'Sam' + Math.floor(Math.random() * 90 + 10),
        lastName: 'Jackson',
        role: textArray[Math.floor(Math.random()*textArray.length)],
        telephoneNumber: '+45 39481010',
        email: 's_jackson@gmail.com',
        activeProject: 1,
        feedbacks: 'OFF',
    })), 
    accounts: new Array(3).fill('').map((_, index) => ({
        name: 'Coca-Cola' + index,
        accountId: 'f54339a-fd2d-463b-95fa-e1af7e0b8f9b' + index,
        usersList: new Array(3).fill('').map((_, index) => ({   
            userId: 'fa98299a-fd2d-463b-95fa-e1af7e0b8f9b' + index,
            firstName: 'Sam' + Math.floor(Math.random() * 90 + 10),
            lastName: 'Jackson',
            telephoneNumber: '+45 39481010',
            email: 's_jackson@gmail.com',
        }))
    }))
})

export function getAllUsers(params) {
    return http.get('/allusers/admin', {params})
}

export function getUsers(params) {
    return http.get('/users/admin', {params})
}

