import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'

import { TRoles } from '../shared/types'
import UserListView from '../modules/Users/containers/UserListView'

function Users(props) {
    return (
        <ContainerMenu>
            {(
                props.role === TRoles.ADMIN || props.role === TRoles.SUPER_ADMIN) && (
                <UserListView/>
            )}
        </ContainerMenu>
    )
}

Users.propTypes = {
    role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    role: state.user.role
})

export default connect(mapStateToProps)(Users)
