import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import { If, Then, Else } from 'react-if'

import ProfileTitle from './ProfileTitle'

import Button from '../../UI/containers/Button/Button'

import styles from './ProfileInfo.module.scss'
import { TRoles, TRoutes } from '../../../shared/types'

import eventEmitter, { types } from '../../../shared/eventEmitter'
import { Link } from 'react-router-dom'

export default function ProfileInfo(props) {
    return (
        <div className={clsx(styles.root, props.className)}>
            <div className={styles.avatar}>
                <Avatar
                    src={props.photoLink}
                    name={props.name}
                    size="86"
                    color="#d2e1df"
                    textSizeRatio={1.75}
                    round={5}
                    fgColor="#9ab8ac"
                />
            </div>

            <div className={styles.data}>
                <ProfileTitle name={props.name} />
                <If condition={props.role === TRoles.OWNER}>
                    <Then>
                        <div className={styles.actions}>
                            <Link
                                to={TRoutes.PROJECT}
                                className={styles.actionProject}
                            >
                                <Button
                                    className={clsx(
                                        styles.btn,
                                        styles.btnGoToProject
                                    )}
                                    bgColor="#9dce6c"
                                >
                                    Go to project
                                </Button>
                            </Link>
                            <Button
                                className={styles.btn}
                                bgColor="#ffffff"
                                onClick={() =>
                                    eventEmitter.emit(
                                        types.openEditProfileModal
                                    )
                                }
                            >
                                Edit Profile
                            </Button>
                        </div>
                    </Then>
                    <Else>
                        <Button
                            className={styles.btn}
                            bgColor="#ffffff"
                            onClick={() =>
                                eventEmitter.emit(types.openEditProfileModal)
                            }
                        >
                            Edit Profile
                        </Button>
                    </Else>
                </If>
            </div>
        </div>
    )
}

ProfileInfo.propTypes = {
    className: PropTypes.string,
}

ProfileInfo.defaultProps = {
    className: '',
}
