import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import Avatar from 'react-avatar'

import AsignedDocuments from '../../containers/AsignedDocuments/AsignedDocuments'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'
import NdaDoc from '../../containers/NdaDoc/NdaDoc'

import { ReactComponent as MiniArrowIcon } from '../../../../assets/icons/mini-arrow.svg'
import { ReactComponent as CreateFolderIcon } from '../../../../assets/icons/create-folder.svg'
import { ReactComponent as UploadDocumentIcon } from '../../../../assets/icons/upload-document.svg'

import { TRoles } from '../../../../shared/types'

import styles from './TeaserAccordion.module.scss'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

export default function TeaserAccordion(props) {
    const [folders, setFolders] = useState([])
    const handleOpenCreateFolderModal = e => {
        e.preventDefault()
        e.stopPropagation()

        eventEmitter.emit(types.openFolderModal, { projectId: props.projectId })
    }

    const handleOpenFileModal = e => {
        e.preventDefault()
        e.stopPropagation()

        eventEmitter.emit(types.openFileModal, { folders })
    }

    return (
        <Accordion
            className={styles.accordion}
            allowMultipleExpanded
            allowZeroExpanded
        >
            <VisibilityAccess
                access={[
                    TRoles.ADMIN,
                    TRoles.SUPER_ADMIN,
                    TRoles.OWNER,
                    TRoles.PRO_BUYER,
                    TRoles.INVESTOR,
                ]}
            >
                <AccordionItem
                    className={styles.accordionItem}
                    activeClassName={styles.accordionItemActive}
                >
                    <AccordionItemHeading>
                        <AccordionItemButton
                            className={clsx(
                                styles.accordionButton,
                                styles.accordionButtonHeight
                            )}
                        >
                            <MiniArrowIcon className={styles.accordionIcon} />
                            Documents
                            <VisibilityAccess
                                access={[
                                    TRoles.SUPER_ADMIN,
                                    TRoles.ADMIN,
                                    TRoles.OWNER,
                                ]}
                            >
                                <button
                                    className={clsx(
                                        styles.actionFile,
                                        styles.actionFileUpload
                                    )}
                                    onClick={handleOpenFileModal}
                                >
                                    <UploadDocumentIcon
                                        className={styles.actionFileIcon}
                                    />
                                    Upload Document
                                </button>
                                <button
                                    className={styles.actionFile}
                                    onClick={handleOpenCreateFolderModal}
                                >
                                    <CreateFolderIcon
                                        className={styles.actionFileIcon}
                                    />
                                    Create Folder
                                </button>
                            </VisibilityAccess>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accordionItemPanel}>
                        <AsignedDocuments
                            onLoadFolders={folders => setFolders(folders)}
                            projectId={props.projectId}
                            showDocuments={props.showDocuments}
                        />
                    </AccordionItemPanel>
                </AccordionItem>
            </VisibilityAccess>
            <VisibilityAccess
                access={[
                    TRoles.ADMIN,
                    TRoles.SUPER_ADMIN,
                    TRoles.OWNER,
                    TRoles.PRO_SELLER,
                ]}
            >
                <AccordionItem
                    className={styles.accordionItem}
                    activeClassName={styles.accordionItemActive}
                >
                    <AccordionItemHeading>
                        <AccordionItemButton
                            className={clsx(
                                styles.accordionButton,
                                styles.accordionButtonNDA
                            )}
                        >
                            <MiniArrowIcon className={styles.accordionIcon} />
                            NDAs
                            <div className={styles.ndaUsers}>
                                {Array.isArray(props.nda) &&
                                    props.nda.length !== 0 &&
                                    props.nda.map(item => (
                                        <Avatar
                                            key={item}
                                            className={styles.ndaUser}
                                            name={
                                                item.investor?.fullName ||
                                                item.account?.name
                                            }
                                            size="30"
                                            color="#d2e1df"
                                            textSizeRatio={1.75}
                                            round={true}
                                            src={item.investor?.photoLink}
                                            fgColor="#9ab8ac"
                                        />
                                    ))}
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <NdaDoc signedDocuments={props.nda} />
                    </AccordionItemPanel>
                </AccordionItem>
            </VisibilityAccess>
        </Accordion>
    )
}

TeaserAccordion.propTypes = {
    projectId: PropTypes.string.isRequired,
    showDocuments: PropTypes.bool,
}
