import React from 'react'
import Avatar from 'react-avatar'

//import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
//import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'

import { parseISO, format as formatDate } from 'date-fns'
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg'

import styles from './NdaDoc.module.scss'
//import { ReactComponent as ArrowTableIcon } from '../../../../assets/icons/mini-arrow-table.svg'
import NoItems from '../../../UI/containers/NoItems/NoItems'

export default function NdaDoc(props) {
    return (
        <div>
            <NoItems
                isShow={!props.signedDocuments.length}
                text="You have no NDAs at this time"
            />
            {props.signedDocuments &&
                props.signedDocuments.map(item => (
                    <div className={styles.ndaItem}>
                        <Avatar
                            name={
                                item.investor?.investorFullName ||
                                item.investor?.fullName ||
                                item.investor?.accountName ||
                                item.account?.name
                            }
                            src={item.investor?.photoLink}
                            size="30"
                            color="#d2e1df"
                            textSizeRatio={1.75}
                            round={true}
                            fgColor="#9ab8ac"
                            className={styles.avatar}
                        />
                        <span className={styles.userName}>
                            {item.investor?.investorFullName ||
                                item.investor?.fullName ||
                                item.investor?.accountName ||
                                item.account?.name}
                        </span>
                        <span className={styles.fileName}>{item.name}</span>
                        <span className={styles.time}>
                            {formatDate(parseISO(item.createdAt), 'HH:mm')}
                        </span>
                        <span className={styles.date}>
                            {formatDate(
                                parseISO(item.createdAt),
                                'MMM, dd, yyyy'
                            )}
                        </span>

                        <div className={styles.actions}>
                            <button className={styles.actionDownload}>
                                <a
                                    href={item.url}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className={styles.actionDownload}
                                >
                                    <DownloadIcon />
                                </a>
                            </button>
                            {/*<Tooltip
                                renderIcon={() => <ArrowTableIcon />}
                                id="fileMenu12"
                            >
                                <TooltipItem onClick={() => {}}>
                                    Action 1
                                </TooltipItem>
                            </Tooltip>*/}
                        </div>
                    </div>
                ))}
        </div>
    )
}
