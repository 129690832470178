import React, { useState } from 'react'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import { types } from '../../../../shared/eventEmitter'


import styles from './TeaserModal.module.scss'

export default function TeaserModal() {
    const [url, setUrl] = useState('')
    const [name, setName] = useState('')

    const handleOpen = ({ url, name }) => {
        setUrl(url)
        setName(name)
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            nameEvent={types.openTeaserModal}
        >
            {() => (
                <>
                    <ModalHeader className={styles.header} title={name}/>
                    <div className={styles.container}>
                        <iframe
                            title="Google presentation"
                            className={styles.presentationFrame}
                            src={url}
                            frameBorder="0"
                            allowFullScreen={true}
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                        />
                    </div>
                </>
            )}
        </BaseModal>
    )
}
