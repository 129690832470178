import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { If, Then } from 'react-if'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
// import { toast } from 'react-toastify'
import { zodResolver } from '@hookform/resolvers/zod'
import { connect } from 'react-redux'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import PhoneField from '../../../UI/containers/Field/PhoneField'
import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'
import UploadAvatar from '../../../UI/containers/UploadAvatar/UploadAvatar'

import * as service from '../../services/editProfile'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import { setUserInfo } from '../../../../redux/ducks/user'

import styles from './EditOwnProfileModal.module.scss'
import { readURL } from '../../../../shared/helpers'
import ChangePasswordForm from './ChangePasswordForm'
import { TRoles } from '../../../../shared/types'

function EditOwnProfileModal(props) {
    const [isLoading, setLoading] = useState(false)
    const phoneField = useRef(null)
    const [avatarSrc, setAvatarSrc] = useState('')
    const [markRemovePhoto, setMarkRemovePhoto] = useState(false)
    const [file, setFile] = useState(null)
    const [companyRequired, setCompanyRequired] = useState(false)
    const [addressRequired, setAddressRequired] = useState(false)

    const {
        register,
        handleSubmit,
        errors,
        control,
        reset,
        setValue,
    } = useForm({
        resolver: zodResolver(
            z.object({
                firstName: z.string().nonempty(),
                lastName: z.string().nonempty(),
                email: z.string().email().nonempty(),
                telephoneNumber: z
                    .string()
                    .refine(() => phoneField.current.isValid, {
                        message: 'Phone is not valid',
                    }),
                position: z.string().optional(),
                companyName: companyRequired
                    ? z.string().nonempty()
                    : z.string().optional(),
                address: addressRequired
                    ? z.string().nonempty()
                    : z.string().optional(),
                zipCode: addressRequired
                    ? z.string().nonempty()
                    : z.string().optional(),
                town: addressRequired
                    ? z.string().nonempty()
                    : z.string().optional(),
            })
        ),
    })

    const handleRemoveFile = () => {
        setAvatarSrc('')
        setFile([])
        setMarkRemovePhoto(true)
    }

    const handleLoadFiles = file => {
        if (!file) return

        setAvatarSrc('')
        setMarkRemovePhoto(false)
        setFile(file)

        readURL(file).then(src => setAvatarSrc(src))
    }

    const handleOpen = () => {
        setAddressRequired(
            props.role === TRoles.INVESTOR || props.role === TRoles.OWNER
        )
        setCompanyRequired(props.role === TRoles.OWNER)

        setTimeout(() => {
            setAvatarSrc(props.userProfile.photoLink)
            setValue('firstName', props.userProfile.firstName)
            setValue('lastName', props.userProfile.lastName)
            setValue('email', props.userProfile.email)
            setValue('telephoneNumber', props.userProfile.telephoneNumber)
            setValue('position', props.userProfile.position)
            setValue('companyName', props.userProfile.companyName)
            setValue('town', props.userProfile.town)
            setValue('zipCode', props.userProfile.zipCode)
            setValue('address', props.userProfile.address)
        }, 0)
        setFile([])
    }

    const onSubmit = closeModal => data => {
        setLoading(true)

        const formData = new FormData()
        formData.append('firstName', data.firstName)
        formData.append('lastName', data.lastName)
        formData.append('email', data.email)
        formData.append('telephoneNumber', data.telephoneNumber)
        formData.append('position', data.position)
        formData.append('companyName', data.companyName)
        formData.append('town', data.town)
        formData.append('zipCode', data.zipCode)
        formData.append('address', data.address)
        file && formData.append('photoFile', file)
        formData.append('shouldDeletePhoto', markRemovePhoto)

        service
            .editProfile(formData)
            .then(response => {
                eventEmitter.emit(types.openNotificationModal, {
                    header: 'Edit Profile',
                    message: 'Your changes successfully saved',
                })
                reset()
                closeModal()
                props.setUserInfo(response.data.userProfile)
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
            .finally(() => setLoading(false))
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            nameEvent={types.openEditProfileModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title="Edit Own Profile"
                    />
                    <div className={styles.container}>
                        <form autoComplete="off">
                            <div className={styles.content}>
                                <UploadAvatar
                                    src={avatarSrc}
                                    onFiles={handleLoadFiles}
                                    onRemove={handleRemoveFile}
                                    fileSize="3145728"
                                    size="165"
                                    className={styles.profile}
                                    label="Profile Picture"
                                />
                                <div className={styles.data}>
                                    <TextField
                                        error={errors.firstName}
                                        className={styles.field}
                                        label="First Name"
                                        name="firstName"
                                        placeholder="Your First Name"
                                        ref={register}
                                    />
                                    <TextField
                                        error={errors.lastName}
                                        className={styles.field}
                                        label="Last Name"
                                        name="lastName"
                                        placeholder="Your Last Name"
                                        ref={register}
                                    />
                                    <TextField
                                        error={errors.email}
                                        className={styles.field}
                                        label="Email"
                                        name="email"
                                        ref={register}
                                        placeholder="example@email.com"
                                    />
                                    <If
                                        condition={
                                            props.role === TRoles.ADMIN ||
                                            props.role === TRoles.SUPER_ADMIN ||
                                            props.role === TRoles.PRO_SELLER ||
                                            props.role === TRoles.PRO_BUYER
                                        }
                                    >
                                        <Then>
                                            <PhoneField
                                                error={errors.telephoneNumber}
                                                ref={phoneField}
                                                control={control}
                                                name="telephoneNumber"
                                                label="Phone"
                                                className={styles.field}
                                            />
                                        </Then>
                                    </If>
                                </div>
                            </div>
                            <If
                                condition={
                                    props.role === TRoles.INVESTOR ||
                                    props.role === TRoles.OWNER
                                }
                            >
                                <Then>
                                    <div className={styles.content}>
                                        <div className={styles.data}>
                                            <TextField
                                                className={styles.field}
                                                error={errors.address}
                                                label="Address"
                                                name="address"
                                                ref={register}
                                                placeholder="Address"
                                            />
                                            <div className={styles.zipCity}>
                                                <TextField
                                                    className={styles.zipField}
                                                    error={errors.zipCode}
                                                    label="Zip code"
                                                    name="zipCode"
                                                    ref={register}
                                                    placeholder="Zip code"
                                                />
                                                <TextField
                                                    className={styles.field}
                                                    error={errors.town}
                                                    label="Town"
                                                    name="town"
                                                    ref={register}
                                                    placeholder="Town"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.data}>
                                            <PhoneField
                                                error={errors.telephoneNumber}
                                                ref={phoneField}
                                                control={control}
                                                name="telephoneNumber"
                                                label="Phone"
                                                className={styles.field}
                                            />
                                            <TextField
                                                error={errors.position}
                                                className={styles.field}
                                                label="Position"
                                                name="position"
                                                placeholder="Position"
                                                ref={register}
                                            />
                                            <TextField
                                                error={errors.companyName}
                                                className={styles.field}
                                                label="Company Name"
                                                name="companyName"
                                                placeholder="Company Name"
                                                ref={register}
                                            />
                                        </div>
                                    </div>
                                </Then>
                            </If>
                            <div className={styles.content}>
                                <div className={styles.emptyBlock} />
                                <div className={styles.data}>
                                    <ChangePasswordForm />
                                </div>
                            </div>

                            <div className={styles.footer}>
                                <Button
                                    isLoading={isLoading}
                                    className={styles.submitBtn}
                                    bgColor="#9dce6c"
                                    type="submit"
                                    onClick={handleSubmit(onSubmit(closeModal))}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </BaseModal>
    )
}

EditOwnProfileModal.propTypes = {
    userProfile: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        companyName: PropTypes.string,
        position: PropTypes.string,
        telephoneNumber: PropTypes.string,
        town: PropTypes.string,
        zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        address: PropTypes.string,
        photoLink: PropTypes.string,
    }).isRequired,
    role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    userProfile: {
        photoLink: state.user.userProfile.photoLink,
        firstName: state.user.userProfile.firstName,
        lastName: state.user.userProfile.lastName,
        email: state.user.userProfile.email,
        companyName: state.user.userProfile.companyName,
        position: state.user.userProfile.position,
        telephoneNumber: state.user.userProfile.telephoneNumber,
        town: state.user.userProfile.town,
        zipCode: state.user.userProfile.zipCode,
        address: state.user.userProfile.address,
    },
    role: state.user.role,
})

const mapDispatchToProps = dispatch => ({
    setUserInfo: data => dispatch(setUserInfo(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditOwnProfileModal)
