import React, { useState } from 'react'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import TextField from '../../../UI/containers/Field/TextField'
import Button from '../../../UI/containers/Button/Button'

import { TRoutes } from '../../../../shared/types'
import * as service from '../../services/auth'

import styles from './ForgotPasswordForm.module.scss'

export default function ForgotPasswordForm() {
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)

    const { register, handleSubmit, errors } = useForm({
        resolver: zodResolver(
            z.object({
                email: z.string().email().nonempty(),
            })
        ),
    })

    const onSubmit = data => {
        setLoading(true)
        service
            .forgotPassword(data)
            .then(() => {
                toast.success('Your email was sent successfully')
                history.push(TRoutes.LOGIN)
            })
            .finally(() => setLoading(false))
    }

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <TextField
                className={styles.filed}
                error={errors.email}
                label="Email"
                name="email"
                ref={register}
                placeholder="Your email"
            />
            <div className={styles.actions}>
                <Button isLoading={isLoading} bgColor="#9dce6c" type="submit">
                    Password recovery
                </Button>
            </div>
        </form>
    )
}
