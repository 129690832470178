import React, {useEffect, useState} from 'react'
// import { toast } from 'react-toastify'

import { ReactComponent as AcceptIcon } from '../../../../assets/icons/accept.svg'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'

import eventEmitter, { types } from '../../../../shared/eventEmitter'
import * as service from '../../services/project'

import styles from './TrackerModal.module.scss'

export default function TrackerCommentModal() {
    const [isLoading, setLoading] = useState(false)
    const [projectInfo, setProjectInfo] = useState({
        projectId: null,
        accountId: null,
        commentId: null,
        comment: '',
        projectName: 'Account' //default value
    })
    const [isSuccess, setSuccess] = useState(false)

    const { register, handleSubmit, errors, reset, setValue } = useForm({
        resolver: zodResolver(
            z.object({
                message: z.string().nonempty(),
            })
        ),
    })

    const handleOpen = ({ projectId, accountId, commentId, comment, projectName }) => {
        setSuccess(false)
        setProjectInfo({ projectId, accountId, commentId, comment, projectName })
    }

    const onSubmit = () => data => {
        setLoading(true)
        service
            .sendComment(projectInfo.projectId, projectInfo.accountId, projectInfo.commentId, data.message)
            .then(() => {
                setSuccess(true)
                reset()
                eventEmitter.emit(types.updateAccountTrackerList)
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setTimeout(() => setValue('message', projectInfo.comment))
    })

    return (
        <BaseModal
            onOpen={handleOpen}
            disabledBgClose
            classModalName={styles.commentModal}
            classActionClose={styles.close}
            nameEvent={types.openTrackerCommentModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        titleClassName={styles.headerTitle}
                        title= {`Add comment for ${projectInfo.projectName}`}
                    />

                    {isSuccess ? (
                        <form autoComplete="off">
                            <div className={styles.notificationMessage}>
                                <div className={styles.messageContainer}>
                                    <div className={styles.acceptIcon}>
                                        <AcceptIcon />
                                    </div>
                                    <span>Comment successfully added</span>
                                </div>
                                <Button
                                    onClick={() => closeModal()}
                                    className={styles.sendBtn}
                                >
                                    Done
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <form
                            onSubmit={handleSubmit(onSubmit(closeModal))}
                            autoComplete="off"
                        >
                            <div className={styles.container}>
                                <TextField
                                    fieldClass={styles.textarea}
                                    error={errors.message}
                                    ref={register}
                                    isTextarea
                                    name="message"
                                    placeholder="Type  your comment..."
                                    value={setValue('message', projectInfo.comment)}

                                />
                                <div className={styles.footer}>
                                    <Button
                                        isLoading={isLoading}
                                        className={styles.sendBtn}
                                        type="submit"
                                        bgColor="#9dce6c"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </>
            )}
        </BaseModal>
    )
}
