import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { TRoutes } from '../../../shared/types'
import AddButton from '../../UI/containers/AddButton/AddButton'

import Title from '../components/Title'
import AllButton from '../components/AllButton'

import eventEmitter, { types } from '../../../shared/eventEmitter'
import stylesCommon from './List.module.scss'
import List from '../components/List'
import UsersItem from './UsersItem'

import * as service from '../../Users/services/users'

export default function UsersList(props) {
    const [users, setUsers] = useState([])

    const fetchUsers = () => {
        service
            .getUsers({
                listType: 'USERS',
                filter: ['INVESTOR', 'BUSINESS_OWNER'],
                limit: 5,
                offset: 0,
            })
            .then(({ data }) => {
                setUsers(data.data.users)
            })
    }

    useEffect(() => {
        fetchUsers(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        eventEmitter.on(types.updateDashboardUser, fetchUsers)

        return () => eventEmitter.off(types.updateDashboardUser, fetchUsers)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={clsx(stylesCommon.root, props.className)}>
            <Title title="Users">
                <AddButton
                    onClick={() => eventEmitter.emit(types.openCreateUserModal)}
                    text="Add User"
                />
            </Title>
            <List
                className={stylesCommon.usersList}
                items={users}
                renderEmpty={() => (
                    <div className={stylesCommon.empty}>
                        <p className={stylesCommon.emptyDescription}>
                            You have no users at this time
                        </p>
                        <AddButton onClick={() => {}} text="Add User" />
                    </div>
                )}
            >
                {item => <UsersItem key={item.id} item={item} /> }
            </List>
            <AllButton text="See all users" to={TRoutes.ADMIN_PANEL} />
        </div>
    )
}

UsersList.propTypes = {
    className: PropTypes.string,
}

UsersList.defaultProps = {
    className: '',
}
