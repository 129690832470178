import React from 'react'
import Avatar from 'react-avatar'
import { If, Then, Else } from 'react-if'
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'

import { ReactComponent as MiniArrowIcon } from '../../../../assets/icons/mini-arrow.svg'

import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/projects'

import { ReactComponent as ArrowTableIcon } from '../../../../assets/icons/mini-arrow-table.svg'

import styles from './AccessAccountAccordion.module.scss'
import Button from '../../../UI/containers/Button/Button'

export default function AccessAccountAccordion(props) {
    const handleUnassign = accountId => {
        service.deleteAccountAccess(props.projectId, accountId).then(() => {
            eventEmitter.emit(types.updateProjectList)
            eventEmitter.emit(types.updateProjectView)
        })
    }

    const handleFileAccess = (accountId, data) => {
        service.provideFileAccess(accountId, props.projectId, data).then(() => {
            eventEmitter.emit(types.openNotificationModal, {
                header: 'Document Access',
                message: 'Access to the documents was changed',
            })
            eventEmitter.emit(types.updateProjectList)
            eventEmitter.emit(types.updateProjectView)
        })
    }

    return (
        <Accordion
            className={styles.accordion}
            allowMultipleExpanded
            allowZeroExpanded
        >
            <AccordionItem
                className={styles.accordionItem}
                activeClassName={styles.accordionItemActive}
            >
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.accordionButton}>
                        <MiniArrowIcon className={styles.accordionIcon} />
                        Accessible for accounts
                        <Button
                            bgColor="#9dce6b"
                            className={styles.inviteAction}
                            onClick={() =>
                                eventEmitter.emit(
                                    types.openProjectAccessModal,
                                    props.projectId
                                )
                            }
                        >
                            Invite
                        </Button>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.accordionPanel}>
                    <If condition={props.accounts.length !== 0}>
                        <Then>
                            {props.accounts &&
                                props.accounts.map(item => (
                                    <div className={styles.accounts}>
                                        <Avatar
                                            key={item}
                                            className={styles.accountUser}
                                            name={item.name}
                                            size="30"
                                            color="#d2e1df"
                                            textSizeRatio={1.75}
                                            round={true}
                                            fgColor="#9ab8ac"
                                        />
                                        {item.name}
                                        <Tooltip
                                            renderIcon={() => (
                                                <ArrowTableIcon />
                                            )}
                                            className={styles.actionMenu}
                                            id={`account-list-${item.id}`}
                                        >
                                            <TooltipItem
                                                onClick={() =>
                                                    handleFileAccess(
                                                        item.id,
                                                        !item.documentsVisible
                                                    )
                                                }
                                            >
                                                {item.documentsVisible
                                                    ? 'Lock '
                                                    : 'Provide '}
                                                access to file
                                            </TooltipItem>
                                            <TooltipItem
                                                onClick={() =>
                                                    handleUnassign(item.id)
                                                }
                                            >
                                                Unassign
                                            </TooltipItem>
                                        </Tooltip>
                                    </div>
                                ))}
                        </Then>
                        <Else>
                            <div className={styles.empty}>
                                <p className={styles.emptyDescription}>
                                    No accounts
                                </p>
                            </div>
                        </Else>
                    </If>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    )
}

AccessAccountAccordion.propTypes = {
    info: PropTypes.shape({
        accountId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        usersList: PropTypes.string.isRequired,
    }),
}
