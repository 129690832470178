import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import LogIn from '../pages/LogIn'
import ForgotPassword from '../pages/ForgotPassword'

import Landing from '../pages/Landing'

import Dashboard from '../pages/Dashboard'

import Projects from '../pages/Projects'
import Project from '../pages/Project'

import NdaList from '../pages/NdaList'
import NotificationList from '../pages/NotificationList'

import OffersList from '../pages/OffersList'

import FeedbacksList from '../pages/FeedbacksList'

import Questions from '../pages/Questions'

import Statistic from '../pages/Statistic'

import Users from '../pages/Users'
import Accounts from '../pages/Accounts'

import SignUpOptional from '../pages/SignUpOptional'
import SignUpPassword from '../pages/SignUpPassword'

import NotFound from '../pages/NotFound'

import PrivateRoute from './PrivateRoute'
import OnlyPublicRoute from './OnlyPublicRoute'

import ToastNewMessage from '../modules/UI/containers/ToastNewMessage/ToastNewMessage'

import { TRoles, TRoutes } from '../shared/types'
import { setCountAllUnreadMessage } from '../redux/ducks/user'

import socketChat from '../shared/socketChat'

import styleToast from '../modules/UI/containers/ToastNewMessage/ToastNewMessage.module.scss'
import history from '../shared/history'
import http from '../shared/http'
import AdminPanel from '../pages/AdminPanel'

function Routes(props) {
    const handleNewMessage = ({ message, projectName }) => {
        //countUnreadAll was previously in props
        http.get('/notifications/menu-count').then(({ data }) => {
            console.log(data)
            props.setCountAllUnreadMessage(data.counts.countAllUnreadMessage
            )
        })


        if (
            [TRoutes.PROJECT, TRoutes.PROJECT_ID(message.projectId)].includes(
                history.location.pathname
            )
        )
            return

        toast(
            <ToastNewMessage
                projectName={projectName}
                projectId={message.projectId}
            />,
            {
                className: styleToast.toast,
                position: 'top-center',
                autoClose: 10000,
                toastId: message.projectId,
                closeButton: false,
                toastClassName: styleToast.toast
            }
        )
    }

    useEffect(() => {
        const isPermission = [
            TRoles.ADMIN,
            TRoles.SUPER_ADMIN,
            TRoles.OWNER,
            TRoles.PRO_SELLER
        ].includes(props.role)
        if (isPermission) socketChat.on('new-message', handleNewMessage)

        return () => {
            if (isPermission) socketChat.off('new-message', handleNewMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.role])

    return (
        <Switch>
            <Route exact path={TRoutes.MAIN} component={Landing}/>

            <PrivateRoute
                exact
                path={TRoutes.DASHBOARD}
                component={Dashboard}
            />

            <PrivateRoute exact path={TRoutes.PROJECTS} component={Projects}/>
            <PrivateRoute exact path={TRoutes.PROJECT} component={Project}/>
            <PrivateRoute
                exact
                path={TRoutes.PROJECT_ID()}
                component={Project}
            />

            <PrivateRoute exact path={TRoutes.USERS} component={Users}/>
            <PrivateRoute exact path={TRoutes.ADMIN_PANEL} component={AdminPanel}/>
            <PrivateRoute exact path={TRoutes.ACCOUNTS} component={Accounts}/>
            <PrivateRoute exact path={TRoutes.NDA_LIST} component={NdaList}/>
            <PrivateRoute
                exact
                path={TRoutes.QUESTIONS}
                component={Questions}
            />
            <PrivateRoute
                exact
                path={TRoutes.STATISTIC}
                component={Statistic}
            />
            <PrivateRoute
                exact
                path={TRoutes.NOTIFICATION}
                component={NotificationList}
            />
            <PrivateRoute exact path={TRoutes.OFFERS} component={OffersList}/>
            <PrivateRoute
                exact
                path={TRoutes.FEEDBACKS}
                component={FeedbacksList}
            />
            <OnlyPublicRoute exact path={TRoutes.LOGIN} component={LogIn}/>
            <OnlyPublicRoute
                exact
                path={TRoutes.FORGOT_PASSWORD}
                component={ForgotPassword}
            />
            <OnlyPublicRoute
                exact
                path={TRoutes.SIGN_UP_OPTIONAL}
                component={SignUpOptional}
            />
            <OnlyPublicRoute
                exact
                path={TRoutes.SIGN_UP_PASSWORD}
                component={SignUpPassword}
            />

            <Route exact path={TRoutes.NOT_FOUND} component={NotFound}/>
            <Redirect exact from="*" to={TRoutes.NOT_FOUND}/>
        </Switch>
    )
}

Routes.propTypes = {
    role: PropTypes.string.isRequired,
    setCountAllUnreadMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    role: state.user.role
})

const mapDispatchToProps = dispatch => ({
    setCountAllUnreadMessage: value =>
        dispatch(setCountAllUnreadMessage(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
