import React from 'react'

import Title from '../../components/Title'
import NotificationList from '../../../Notification/containers/NotificationList'

import { TRoutes } from '../../../../shared/types'
import AllButton from '../../components/AllButton'

import styles from './NotificationsListMini.module.scss'

export default function NotificationsListMini() {
    return (
        <div className={styles.root}>
            <Title title="Notifications" />
            <NotificationList
                mini
                className={styles.notificationsList}
                actionClassName={styles.action}
            />
            <AllButton text="See all notifications" to={TRoutes.NOTIFICATION} />
        </div>
    )
}
