import React, { useEffect } from 'react'

import Header from '../../../UI/containers/Header/Header'

import ProjectList from '../ProjectList/ProjectList'

import styles from './ProjectListInvestorView.module.scss'
import socket from '../../../../shared/socketNda'
import eventEmitter, {types} from '../../../../shared/eventEmitter'

export default function ProjectListInvestorView() {

    const handleSignedNda = () => {
        eventEmitter.emit(types.handleSocketNDASign)
    }

    useEffect(() => {
        socket.connect()
        socket.on('nda-resolved', handleSignedNda)

        return () => {
            socket.close()
            socket.off('nda-resolved', handleSignedNda)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.root}>
            <Header title="Project List"/>
            <ProjectList/>
        </div>
    )
}
