import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './Button.module.scss'

export default function Button(props) {
    return (
        <button
            disabled={props.isLoading || props.disabled}
            style={{ backgroundColor: props.bgColor }}
            onClick={props.onClick}
            type={props.type}
            className={clsx(props.className, styles.root)}
        >
            {props.renderIcon()}
            {props.isLoading
                ? props.isLoadingMessage || 'Loading...'
                : props.children}
        </button>
    )
}

Button.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    bgColor: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    renderIcon: PropTypes.func,
}

Button.defaultProps = {
    bgColor: '#9dce6c',
    type: 'button',
    className: '',
    isLoading: false,
    disabled: false,
    renderIcon: () => null,
    onClick: () => {},
}
