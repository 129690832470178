import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

import styles from './Paginate.module.scss'

export default function Paginate(props) {
    if (!props.pageCount) return null

    return (
        <ReactPaginate
            forcePage={props.forcePage}
            pageCount={props.pageCount}
            onPageChange={props.onPageChange}
            previousLabel={'<<'}
            nextLabel={'>>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            activeLinkClassName={styles.activeLink}
            breakLinkClassName={styles.breakLink}
            containerClassName={styles.root}
            pageClassName={styles.page}
            pageLinkClassName={styles.pageLink}
            previousLinkClassName={styles.previousLink}
            nextLinkClassName={styles.nextLink}
        />
    )
}

Paginate.propTypes = {
    pageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    forcePage: PropTypes.number
}
