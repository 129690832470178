import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import styles from './Header.module.scss'
import ContentContainer from '../Container/ContentContainer'

export default function Header(props) {
    return (
        <header className={styles.root}>
            <ContentContainer>
                <div className={clsx(props.className, styles.wrapper)}>
                    <h2 className={styles.title}>{props.title}</h2>
                    {props.children}
                </div>
            </ContentContainer>
        </header>
    )
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}
