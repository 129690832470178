import React from 'react'
import Avatar from 'react-avatar'

import { connect } from 'react-redux'

import Tooltip from '../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../UI/containers/Tooltip/TooltipItem'

import eventEmitter, { types } from '../../../shared/eventEmitter'

import styles from './ProfileDropdown.module.scss'

function ProfileDropdown(props) {

    return (
        <div className={styles.root}>
            <Avatar
                src={props.userProfile.photoLink}
                name={`${props.userProfile.firstName} ${props.userProfile.lastName}`}
                className={styles.avatar}
                size="40"
                color="#d2e1df"
                fgColor="#9ab8ac"
                textSizeRatio={1.75}
            />
            <span className={styles.name}>
                {props.userProfile.firstName} {props.userProfile.lastName}
            </span>
            <Tooltip
                className={styles.tooltip}
                classNameToggle={styles.actionActiveTooltip}
                id="userMenu"
            >
                <TooltipItem
                    onClick={() =>
                        eventEmitter.emit(types.openEditProfileModal)
                    }
                >
                    Edit Profile
                </TooltipItem>
                <TooltipItem
                    onClick={() =>
                        eventEmitter.emit(types.openLogoutNotificationModal, {
                            header: 'Log out',
                            message: 'Are you sure you want to Log out?',
                        })
                    }
                >
                    Log Out
                </TooltipItem>
            </Tooltip>
        </div>
    )
}

const mapStateToProps = state => ({
    userProfile: {
        firstName: state.user.userProfile.firstName,
        lastName: state.user.userProfile.lastName,
        photoLink: state.user.userProfile.photoLink,
    },
})

export default connect(mapStateToProps)(ProfileDropdown)
