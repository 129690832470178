import React, { useState } from 'react'
import PropsTypes from 'prop-types'
import { format as formatDate, parseISO } from 'date-fns'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
// import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import TextField from '../../UI/containers/Field/TextField'
import Button from '../../UI/containers/Button/Button'

import { ReactComponent as QuestionIcon } from '../../../assets/icons/question-icon.svg'

import * as service from '../services/questions'

import styles from './QuestionsItem.module.scss'
import { setCountAllMenu } from '../../../redux/ducks/user'

function QuestionItem(props) {
    const { question } = props
    const [isLoading, setLoading] = useState(false)

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: zodResolver(z.object({
            message: z.string().max(500).nonempty()
        }))
    })

    const onSubmit = data => {
        setLoading(true)

        service.sendAnswer(props.question.id, data.message)
            .then(({ data }) => {
                props.onAnswer(data.question)
                reset()
                props.setCountAllMenu({ countNotAnswerQuestion: data.countNotAnswerQuestion })
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
            .finally(() => setLoading(false))
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h3 className={styles.title}>
                    <QuestionIcon class={styles.titleIcon}/>
                    {question.investorFullName}
                </h3>
            </div>
            <div className={styles.messages}>
                {(
                    <div className={clsx(styles.message, !question.answer && styles.messagesWithoutAnswer)}>
                        <div className={styles.messageInfo}>
                            <span className={styles.time}>
                                {formatDate(
                                    parseISO(question.createdAt),
                                    'HH:mm'
                                )}
                            </span>
                            <span className={styles.date}>
                                {formatDate(
                                    parseISO(question.createdAt),
                                    'MMM, dd, yyyy'
                                )}
                            </span>
                        </div>
                        <p className={styles.messageContent}>
                            {question.question}
                        </p>
                    </div>
                )}
                {!!question.answer && (
                    <div className={clsx(styles.message, styles.messageRight)}>
                        <div className={styles.messageInfo}>
                            <span className={styles.time}>
                                {formatDate(
                                    parseISO(question.answeredAt),
                                    'HH:mm'
                                )}
                            </span>
                            <span className={styles.date}>
                                {formatDate(
                                    parseISO(question.answeredAt),
                                    'MMM, dd, yyyy'
                                )}
                            </span>
                        </div>
                        <p className={styles.messageContent}>
                            {question.answer}
                        </p>
                    </div>
                )}
            </div>
            {!question.answer && (
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <label className={styles.filed}>
                        <span className={styles.nameField}>Ask a question:</span>

                        <TextField
                            fieldClass={styles.textarea}
                            error={errors.message}
                            isTextarea
                            disabled={isLoading}
                            name="message"
                            ref={register}
                            placeholder="Type your question"/>
                    </label>
                    <Button isLoading={isLoading} className={styles.submit} type="submit">Reply</Button>
                </form>
            )}
        </div>
    )
}

QuestionItem.propTypes = {
    question: PropsTypes.shape({
        id: PropsTypes.string.isRequired,
        answer: PropsTypes.string.isRequired,
        question: PropsTypes.string.isRequired,
        answeredAt: PropsTypes.string.isRequired,
        createdAt: PropsTypes.string.isRequired,
        investorFullName: PropsTypes.string.isRequired
    }).isRequired,
    onAnswer: PropsTypes.func.isRequired,
    setCountAllMenu: PropsTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    setCountAllMenu: value => dispatch(setCountAllMenu(value))
})

export default connect(null, mapDispatchToProps)(QuestionItem)
