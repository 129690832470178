import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import Header from '../../UI/containers/Header/Header'
import Paginate from '../../UI/containers/Paginate/Paginate'
import AddButton from '../../UI/containers/AddButton/AddButton'
import SearchForm from '../../UI/containers/SearchForm/SearchForm'
import ContentContainer from '../../UI/containers/Container/ContentContainer'

import * as service from '../services/admins'

import { TRoles, TUsersActivityStatusFilter } from '../../../shared/types'
import { ITEMS_PER_PAGE } from '../../../shared/config'
import { addPropToObject } from '../../../shared/helpers'
import eventEmitter, { types } from '../../../shared/eventEmitter'

import styles from './AdminListView.module.css'
import AdminUserHeader from '../components/AdminUserHeader/AdminUserHeader'
import AdminUserItem from '../components/AdminUserItem/AdminUserItem'


export default function AdminListView() {
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(1)
    const [usersCount, setUsersCount] = useState(0)
    const [activeFilter, setActiveFilter] = useState({ name: '', order: '' })
    const [search, setSearch] = useState('')
    const [activeUserType, setActiveUserType] = useState(TRoles.ADMIN)
    const [userActivityFilter, setUserActivityFilter] = useState(
        TUsersActivityStatusFilter.ACTIVE
    )
    const handlePageChange = ({ selected }) => {
        setPage(selected)
        fetchUsers(selected)

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const fetchUsers = useDebouncedCallback(page => {
        service
            .getAdmins({
                // listType: ['ADMIN', 'SUPER_ADMIN'],
                listType: 'ADMIN',
                filter: [activeUserType],
                // filter: ['ADMIN', 'SUPER_ADMIN'],
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
                deletedOnly: userActivityFilter === TUsersActivityStatusFilter.DELETED,
                ...addPropToObject('search', search),
                ...addPropToObject(
                    'sortBy',
                    activeFilter.order && activeFilter.name.toUpperCase()
                ),
                ...addPropToObject('sortType', activeFilter.order),
            })
            .then(({ data }) => {
                setUsers(data.data.users)
                setUsersCount(data.data.count)
            })
    }, 300)

    const updateAdminList = () =>
        TRoles.ADMIN === activeUserType && handlePageChange({ selected: page })
    const updateSuperAdminList= () =>
        TRoles.SUPER_ADMIN === activeUserType && handlePageChange({ selected: page })

    useEffect(() => {
        setPage(0)
        fetchUsers(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter, search, activeUserType, userActivityFilter])

    useEffect(() => {
        eventEmitter.on(types.updateAdminList, updateAdminList)
        eventEmitter.on(types.updateSuperAdminList, updateSuperAdminList)

        return () => {
            eventEmitter.off(types.updateAdminList, updateAdminList)
            eventEmitter.off(types.updateSuperAdminList, updateSuperAdminList)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    return (
        <div className={styles.root}>
            <Header title="Admin panel">
                <div className={styles.headerActions}>
                    <div className={styles.headerBtn}>
                        <AddButton
                            onClick={() => eventEmitter.emit(types.openCreateAdminModal)}
                            text="Create Admin"
                        />
                    </div>
                    <SearchForm
                        onEmpty={() => setSearch('')}
                        onSubmit={value => setSearch(value)}
                        placeholder="Search Users"
                    />
                </div>
            </Header>
            <ContentContainer>
                <AdminUserHeader
                    onSelectUser={setActiveUserType}
                    activeUserType={activeUserType}
                    onFilter={setActiveFilter}
                    activeFilter={activeFilter}
                    userActivityFilter={userActivityFilter}
                    onChangeUserActivityFilter={setUserActivityFilter}
                />
                <div className={styles.list}>
                    {users &&
                        users.map(item => (
                            <AdminUserItem
                                key={item.id}
                                info={{
                                    userId: item.id,
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    photoLink: item.photoLink,
                                    role: item.role,
                                    emailSettings: item.emailSettings,
                                    phone: item.telephoneNumber,
                                    position: item.position,
                                    companyName: item.companyName,
                                    email: item.email,
                                    activeProject: item.activeProject,
                                    feedbacks: item.feedbacks,
                                    deletedAt: item.deletedAt,
                                }}
                            />
                        ))}
                </div>
                <Paginate
                    forcePage={page}
                    pageCount={Math.ceil(usersCount / ITEMS_PER_PAGE)}
                    onPageChange={handlePageChange}
                />
            </ContentContainer>
        </div>
    )
}
