import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import Header from '../../UI/containers/Header/Header'
import Paginate from '../../UI/containers/Paginate/Paginate'
import AddButton from '../../UI/containers/AddButton/AddButton'
import SearchForm from '../../UI/containers/SearchForm/SearchForm'
import ContentContainer from '../../UI/containers/Container/ContentContainer'

import AccountHeader from '../components/AccountHeader/AccountHeader'

import * as service from '../services/users'

import { ITEMS_PER_PAGE } from '../../../shared/config'
import { addPropToObject } from '../../../shared/helpers'
import eventEmitter, { types } from '../../../shared/eventEmitter'

import UserItem from '../components/UserItem/UserItem'
import styles from './AccountListView.module.scss'
import AccountItem from '../components/AccountItem/AccountItem'
import { TRoles, TUsersActivityStatusFilter } from '../../../shared/types'

export default function AccountListView() {
    const [accounts, setAccounts] = useState([])
    const [page, setPage] = useState(1)
    const [accountsCount, setAccountsCount] = useState(0)
    const [search, setSearch] = useState('')
    const [activeFilter, setActiveFilter] = useState({ name: '', order: '' })
    const [users, setUsers] = useState([])
    const [usersCount, setUsersCount] = useState(0)
    const [activeUserType, setActiveUserType] = useState(TRoles.ACCOUNT)
    const [userActivityFilter, setUserActivityFilter] = useState(
        TUsersActivityStatusFilter.ACTIVE
    )

    const handlePageChange = ({ selected }) => {
        setPage(selected)
        fetchAccounts(selected)
        fetchSellers(selected)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const fetchAccounts = page => {
        service
            .getUsers({
                listType: 'ACCOUNTS',
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
                deletedOnly: userActivityFilter === TUsersActivityStatusFilter.DELETED,
                ...addPropToObject('search', search),
                ...addPropToObject(
                    'sortBy',
                    activeFilter.order && activeFilter.name.toUpperCase()
                ),
                ...addPropToObject('sortType', activeFilter.order),
            })
            .then(({ data }) => {
                setAccounts(data.data.accounts)
                setAccountsCount(data.data.count)
            })
    }

    const fetchSellers = useDebouncedCallback(page => {
        service
            .getUsers({
                listType: 'USERS',
                filter: [TRoles.PRO_SELLER],
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
                deletedOnly: userActivityFilter === TUsersActivityStatusFilter.DELETED,
                ...addPropToObject('search', search),
                ...addPropToObject(
                    'sortBy',
                    activeFilter.order && activeFilter.name.toUpperCase()
                ),
                ...addPropToObject('sortType', activeFilter.order),
            })
            .then(({ data }) => {
                setUsers(data.data.users)
                setUsersCount(data.data.count)
            })
    }, 300)

    const handleUpdateAccountList = () =>
        TRoles.ACCOUNT === activeUserType && handlePageChange({ selected: page })
    const handleUpdateSellerList = () =>
        TRoles.PRO_SELLER === activeUserType &&
    handlePageChange({ selected: page })

    useEffect(() => {
        setPage(0)
        fetchAccounts(0)
        fetchSellers(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter, search, activeUserType, userActivityFilter])

    useEffect(() => {
        eventEmitter.on(types.updateAccountList, handleUpdateAccountList)
        eventEmitter.on(types.updateSellerList, handleUpdateSellerList)

        return () => {
            eventEmitter.off(types.updateAccountList, handleUpdateAccountList)
            eventEmitter.off(types.updateSellerList, handleUpdateSellerList)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    })

    return (
        <div className={styles.root}>
            <Header title="M&A Users List">
                <div className={styles.headerActions}>
                    <div className={styles.headerBtn}>
                        <AddButton
                            onClick={() => eventEmitter.emit(types.openCreateAccountModal)}
                            text="Create Account"
                        />
                        <AddButton
                            onClick={() => eventEmitter.emit(types.openCreateSellerModal)}
                            text="Create User"
                        />
                    </div>
                    <SearchForm
                        onSubmit={value => setSearch(value)}
                        placeholder={
                            activeUserType === 'ACCOUNT' ? 'Search Accounts' : 'Search Users'
                        }
                    />
                </div>
            </Header>
            <ContentContainer>
                <AccountHeader
                    onSelectUser={setActiveUserType}
                    activeUserType={activeUserType}
                    onFilter={setActiveFilter}
                    activeFilter={activeFilter}
                    userActivityFilter={userActivityFilter}
                    onChangeUserActivityFilter={setUserActivityFilter}
                />
                <div className={styles.list}>
                    {activeUserType === 'ACCOUNT' &&
            accounts &&
            accounts.map(item => (
                <AccountItem
                    key={item.id}
                    info={{
                        accountId: item.id,
                        zip: item.zipCode,
                        street: item.street,
                        city: item.city,
                        country: item.country,
                        name: item.name,
                        usersList: item.members,
                    }}
                />
            ))}
                    {activeUserType === 'PRO_SELLER' &&
            users &&
            users.map(item => (
                <UserItem
                    key={item.id}
                    info={{
                        userId: item.id,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        role: item.role,
                        phone: item.telephoneNumber,
                        email: item.email,
                        deletedAt: item.deletedAt,
                        photoLink: item.photoLink,
                    }}
                />
            ))}
                </div>
                <Paginate
                    forcePage={page}
                    pageCount={Math.ceil(
                        activeUserType === 'ACCOUNT'
                            ? accountsCount / ITEMS_PER_PAGE
                            : usersCount / ITEMS_PER_PAGE
                    )}
                    onPageChange={handlePageChange}
                />
            </ContentContainer>
        </div>
    )
}
