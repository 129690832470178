import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Menu from '../components/MenuList'
import ProfileDropdown from '../components/ProfileDropdown'
import { convertRole } from '../../../shared/helpers'

import { ICONS, TRoutes } from '../../../shared/types'

import styles from './Menu.module.scss'
import Logo from '../../Landing/components/Logo/Logo'

import { ReactComponent as IconStatistics } from '../../../assets/icons/statistics.svg'
import { ReactComponent as IconAdminPanel } from '../../../assets/icons/users-solid.svg'

function MenuAdmin(props) {
    const rolesLinks = {
        super_admin: [
            {
                label: 'Overview',
                icon: ICONS.OVERVIEW,
                href: TRoutes.DASHBOARD
            },
            {
                label: 'Connect Users',
                icon: ICONS.USERS_LIST,
                href: TRoutes.USERS
            },
            {
                label: 'M&A Users',
                icon: ICONS.ACCOUNTS_LIST,
                href: TRoutes.ACCOUNTS
            },
            {
                label: 'Projects List',
                icon: ICONS.PROJECTS_LIST,
                href: TRoutes.PROJECTS,
                value: props.menu.countAllUnreadMessage
            },
            {
                label: 'NDA List',
                icon: ICONS.NDA_LIST,
                href: '/nda-list'
            },
            {
                label: 'Notifications',
                icon: ICONS.NOTIFICATIONS,
                href: '/notification',
                value: props.menu.countUnreadNotifications
            },
            {
                label: 'Statistic',
                icon: IconStatistics,
                isFile: true,
                href: '/statistic'
            },
            {
                label: 'Admin panel',
                icon: IconAdminPanel,
                isFile: true,
                href: TRoutes.ADMIN_PANEL
            }
        ],
        admin: [
            {
                label: 'Overview',
                icon: ICONS.OVERVIEW,
                href: TRoutes.DASHBOARD
            },
            {
                label: 'Connect Users',
                icon: ICONS.USERS_LIST,
                href: TRoutes.USERS
            },
            {
                label: 'M&A Users',
                icon: ICONS.ACCOUNTS_LIST,
                href: TRoutes.ACCOUNTS
            },
            {
                label: 'Projects List',
                icon: ICONS.PROJECTS_LIST,
                href: TRoutes.PROJECTS,
                value: props.menu.countAllUnreadMessage
            },
            {
                label: 'NDA List',
                icon: ICONS.NDA_LIST,
                href: '/nda-list'
            },
            {
                label: 'Notifications',
                icon: ICONS.NOTIFICATIONS,
                href: '/notification',
                value: props.menu.countUnreadNotifications
            },
            {
                label: 'Statistic',
                icon: IconStatistics,
                isFile: true,
                href: '/statistic'
            },
        ],
        investor: [
            {
                label: 'Overview',
                icon: ICONS.OVERVIEW,
                href: TRoutes.DASHBOARD
            },
            {
                label: 'Projects List',
                icon: ICONS.PROJECTS_LIST,
                href: TRoutes.PROJECTS
            }
        ],
        owner: [
            {
                label: 'Overview',
                icon: ICONS.OVERVIEW,
                href: TRoutes.DASHBOARD
            },
            {
                label: 'My Project',
                icon: ICONS.PROJECTS_LIST,
                href: TRoutes.PROJECT,
                value: props.menu.countAllUnreadMessage
            },
            {
                label: 'Questions',
                icon: ICONS.QUESTIONS,
                href: TRoutes.QUESTIONS,
                value: props.menu.countNotAnswerQuestion
            },
            {
                label: 'Feedbacks',
                icon: ICONS.FEEDBACKS,
                href: TRoutes.FEEDBACKS
            },
            {
                label: 'Offers',
                icon: ICONS.OFFERS,
                href: TRoutes.OFFERS
            }
        ],
        buyer: [
            {
                label: 'Overview',
                icon: ICONS.OVERVIEW,
                href: TRoutes.DASHBOARD
            },
            {
                label: 'Projects List',
                icon: ICONS.PROJECTS_LIST,
                href: TRoutes.PROJECTS
            }
        ],
        seller: [
            {
                label: 'Projects List',
                icon: ICONS.PROJECTS_LIST,
                href: TRoutes.PROJECTS,
                value: props.menu.countAllUnreadMessage
            }
        ]
    }

    return (
        <div className={styles.root}>
            <Logo className={styles.logo}/>

            <Menu menu={rolesLinks[convertRole(props.role)]}/>

            <ProfileDropdown/>
        </div>
    )
}

MenuAdmin.propTypes = {
    role: PropTypes.string.isRequired,
    menu: PropTypes.shape({
        countAllUnreadMessage: PropTypes.number.isRequired,
        countUnreadNotifications: PropTypes.number.isRequired,
        countNotAnswerQuestion: PropTypes.number.isRequired
    }).isRequired
}

const mapStateToProps = state => ({
    role: state.user.role,
    menu: state.user.menu
})

export default connect(mapStateToProps)(MenuAdmin)
