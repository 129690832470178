import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { convertNameRole } from '../../../../shared/helpers'

import Logo from '../../components/Logo/Logo'

import styles from './FormView.module.scss'
import stylesLanding from '../../components/Landing.module.scss'

export default function FormView(props) {
    return (
        <div className={styles.root}>
            <div className={clsx(stylesLanding.container, styles.container)}>
                <div className={styles.leftCol}>
                    <Logo/>
                    <div className={styles.wrapperTitle}>
                        <h1 className={clsx(stylesLanding.titleMain, styles.titleMain)}>
                            <span className={styles.title}>
                                Sign Up
                                <br/>
                                <span className={styles.titleMini}>
                                    <span className={styles.titleColor}>as</span> <span>{convertNameRole(props.role).toLowerCase()}</span>
                                </span>
                            </span>
                        </h1>
                        <p className={styles.paragraph}>
                            Buying or selling a company is always a big decision
                            and a complicated process, whether involving
                            private individuals, companies or financial sponsors.
                        </p>
                    </div>
                </div>
                <div className={styles.rightCol}>
                    <div className={styles.wrapperForm}>{props.renderForm()}</div>
                </div>
            </div>
        </div>
    )
}

FormView.propTypes = {
    role: PropTypes.string.isRequired,
    renderForm: PropTypes.func.isRequired
}
