import http from '../../../shared/http'

//mock.onPost('/createUser').reply(200, {
//    message: 'Ok'
//})

export function addProseller(data) {
    return http.post('/users/admin/create', {
        shouldSendInvitation: !!data.shouldSendInvitation,
        role: data.userType,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        position: data.position,
        telephoneNumber: data.phone
    })
}

//mock.onPut(/\/users\/\w+/).reply(200,{
//    message:'Ok'
//})
    
export function editProSeller(userId, data) {
    return http.put(`/users/admin/${userId}`, 
        {
            shouldSendInvitation: !!data.shouldSendInvitation,
            role: data.userType,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            telephoneNumber: data.phone
        })
}

export function getProSellerInfo(userId) {
    return http.get(`/users/admin/${userId}`)
}

export function getNotAssignedProSellers(projectId, params) {
    return http.get(`/projects/admin/${projectId}/not-assigned-pro-sellers`,{params})
}

export function addAccess(projectId, data) {
    return http.post(`/projects/admin/${projectId}/assigned-pro-sellers`, {
        proSellerId: data
    })
}
    
export function deleteAccess(projectId, data) {
    return http.delete(`/projects/admin/${projectId}/assigned-pro-sellers`, {
        data: {
            proSellerId: data
        }
    })
}