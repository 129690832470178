import React from 'react'
import PropTypes from 'prop-types'

import styles from './ContactUs.module.scss'

export default function ContactUs(props) {
    return (
        <div id="contact" className={styles.root}>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>Contact Us</h2>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

ContactUs.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}
