import axios from 'axios'
import { toast } from 'react-toastify'
import MockAdapter from 'axios-mock-adapter'
import { API_URL } from './config'
import { cleanToken } from './helpers'

const instance = axios.create({
    baseURL: API_URL
})

instance.interceptors.response.use(response => response, error => {
    if (error.response) {
        const { error: errorRes } = error.response.data
        if (error.response.status === 409) {
            errorRes && errorRes.description && toast.warning(errorRes.description)
            toast.warning(error.message)
        }
        else {
            errorRes && errorRes.description && toast.error(errorRes.description)
            toast.error(error.message)
        }

        if (error.response.status === 401) cleanToken() && toast.error(error.message)
    }

    return Promise.reject(error)
})

const authInfo = JSON.parse(localStorage.getItem('auth') || '{}')
if (authInfo.token) instance.defaults.headers['Authorization'] = `Bearer ${authInfo.token}`

export let mock = null
//if (process.env.NODE_ENV === 'development') {
mock = new MockAdapter(instance, {
    delayResponse: 1000,
    onNoMatch: 'passthrough'
})
//} else {
//    mock = new MockAdapter(axios.create({
//        baseURL: API_URL
//    }), { delayResponse: 1000 })
//}

//mock.onGet('/users/me').reply(200, {
//    status: 'ok',
//    shouldShowDisclaimer: false,
//    userProfile: {
//        photoLink: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/e29b7d6e-e0b9-4386-8534-61d135d56963',
//        email: 'google@gmail.com',
//        firstName: 'John',
//        lastName: 'Snow',
//        companyName: 'Microsoft',
//        position: 'CTO',
//        telephoneNumber: '123456789'
//    }
//})

export default instance


