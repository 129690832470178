import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Icon from '../../common/icon/Icon'

import styles from './MenuList.module.scss'

export default function MenuList(props) {
    return (
        <div className={styles.menu}>
            {props.menu.map(link => {
                let IconFile = null
                if(link.isFile) IconFile = link.icon

                return (
                    <NavLink to={link.href}
                        key={link.href}
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}>
                        {link.isFile ? (
                            <IconFile style={{
                                margin:'0 24px 0 0'
                            }} width="16" height="16" fill="#D2E1DF" />
                        ):(
                            <Icon icon={link.icon} color="#D2E1DF"/>
                        )}
                        {link.label}

                        {!!link.value && <span className={styles.menuItemCount}>+{link.value}</span>}
                    </NavLink>
                )
            })}
        </div>
    )
}

MenuList.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
        href: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isFile: PropTypes.bool
    }))
}
