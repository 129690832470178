import React from 'react'
import PropTypes from 'prop-types'

import styles from './NoItems.module.scss'

export default function NoItems(props) {
    if (!props.isShow) return null

    return <p className={styles.root}>{props.text}</p>
}

NoItems.propTypes = {
    text: PropTypes.string.isRequired,
    isShow: PropTypes.bool.isRequired
}
