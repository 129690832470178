import React, { useState, useLayoutEffect } from 'react'
import clsx from 'clsx'

import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './EditorField.module.scss'

const EditorField = props => {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(props.editorText))
        )
    )
    const onEditorStateChange = editorState => {
        setEditorState(editorState)

        return props.onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        )
    }

    useLayoutEffect(() => {
        setEditorState(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(props.editorText)
                )
            )
        )
        props.onChange(props.editorText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editorText])

    return (
        <label className={clsx(styles.root, props.className)}>
            {props.label && (
                <span className={styles.label}>
                    {props.label}
                    {props.isRequired && (
                        <span className={styles.required}>*</span>
                    )}
                </span>
            )}
            <div className={styles.editor}>
                <Editor
                    editorState={editorState}
                    wrapperClassName={styles.wrapper}
                    editorClassName={styles.editorClass}
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
        </label>
    )
}

export default EditorField
