import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from 'react-router-dom'
import { useFirstRender } from '../../../../shared/helpers'

import UploadFile from '../../../UI/containers/UploadFile/UploadFile'
import TextField from '../../../UI/containers/Field/TextField'
import Button from '../../../UI/containers/Button/Button'

import * as service from '../../services/chat'
import socketChat from '../../../../shared/socketChat'

import { ReactComponent as IconCross } from '../../../../assets/icons/cross.svg'

import styles from './ChatForm.module.scss'

export default function ChatForm(props) {
    const [isLoading, setLoading] = useState(false)
    const [uploadFileKey, setUploadFileKey] = useState(0)
    const [file, setFile] = useState(null)
    const [text, setText] = useState(null)
    const { id: projectId } = useParams()
    const isFirstRender = useFirstRender()

    const { register, handleSubmit, errors, reset, trigger } = useForm({
        resolver: zodResolver(z.object({
            message: z.string().refine(val => val || file, {
                message: 'Add message or file'
            })
        }))
    })
    const handleChange = evt => {
        setText(evt.target.value)
    }

    const onSubmit = data => {
        const formData = new FormData()

        formData.append('socketId', socketChat.id)
        data.message && formData.append('message', data.message)
        projectId && formData.append('projectId', projectId)
        file && formData.append('file', file)

        setLoading(true)

        service.createMessage(formData)
            .then(({ data }) => {
                setUploadFileKey(uploadFileKey + 1)
                setFile(null)
                props.onMessage(data.message)
                reset()
            })
            .finally(() => setLoading(false))
    }

    const cleanFile = evt => {
        evt.preventDefault()

        setFile(null)
    }

    const onCheckEnter = evt => {
        if(evt.key === 'Enter') {
            handleSubmit(onSubmit({message:text}))
        }
    }
    useEffect(() => {
        if (!isFirstRender) trigger()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file])

    useEffect(() => {
        setTimeout(() => {
            reset()
        },0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadFileKey])

    return (
        <form className={styles.root} onSubmit={handleSubmit(onSubmit)} onKeyDown={onCheckEnter}>
            <label>
                <span className={styles.nameField}>New Message</span>
                <TextField
                    onChange={handleChange}
                    fieldClass={styles.textarea}
                    ref={register}
                    disabled={isLoading}
                    error={errors.message}
                    isTextarea
                    name="message"
                    placeholder="Type your message"/>
            </label>
            <div className={styles.actions}>
                {!!file && (
                    <button
                        disabled={isLoading}
                        className={styles.actionCleanFile}
                        onClick={cleanFile}
                    >
                        <IconCross />
                    </button>
                )}
                <UploadFile
                    isLoading={isLoading}
                    keyInput={uploadFileKey}
                    accept="*"
                    bgColor="transparent"
                    classNameButton={styles.uploadFileAction}
                    text={file ? <span className={styles.fileName}>{file.name}</span> : 'Attach file'}
                    onFiles={setFile}/>
                <Button isLoading={isLoading} className={styles.submitAction} type="submit">Send</Button>
            </div>
        </form>
    )
}

ChatForm.propTypes = {
    onMessage: PropTypes.func.isRequired
}
