import React from 'react'

import CreateNDAModal from './CreateNDAModal/CreateNDAModal'
import UserModal from './UserModal/UserModal'
import ProjectModal from './ProjectModal/ProjectModal'
import DisclaimerModal from './DisclaimerModal/DisclaimerModal'
import ProfileInfoModal from './ProfileInfoModal/ProfileInfoModal'
import EditOwnProfileModal from './EditOwnProfileModal/EditOwnProfileModal'
import FeedbackModal from './FeedbackModal/FeedbackModal'
import OfferModal from './OfferModal/OfferModal'
import FolderModal from './FolderModal/FolderModal'
import FileUploadModal from './FileIUploadModal/FileUploadModal'
import TeaserModal from './TeaserModal/TeaserModal'
import TeaserLinksModal from './TeaserLinksModal/TeaserLinksModal'
import NdaModal from './NdaModal/NdaModal'
import PostNdaModal from './PostNdaModal/PostNdaModal'
import NdaTemplateModal from './NdaTemplateModal/NdaTemplateModal'
import CreateAccountModal from './AccountModal/AccountModal'
import ProjectAccessModal from './ProjectAccountAccessModal/ProjectAccessModal'
import ProbuyerModal from './ProbuyerModal/ProbuyerModal'
import OfflineNdaModal from './OfflineNdaModal/OfflineNdaModal'
import NotificationModal from './NotificationModal/NotificationModal'
import ArchiveProjectModal from './ArchiveProjectModal/ArchiveProjectModal'
import DeleteUserModal from './DeleteUserModal/DeleteUserModal'
import SellerModal from './SellerModal/SellerModal'
import SellerAccessModal from './ProjectSellerAccessModal/SellerAccessModal'
import LogoutNotificationModal from './LogoutNotificationModal/LogoutNotificationModal'
import YesNoModal from '../components/YesNoModal/YesNoModal'
import ProjectPublishingModal from './ProjectPublishingModal/ProjectPublishingModal'
import TrackerUserListModal from './TrackerModal/TrackerUserListModal'
import TrackerCommentModal from './TrackerModal/TrackerCommentModal'
import ArchiveUserModal from './ArchiveUserModal/ArchiveUserModal'
import CreateAdminModal from './AdminUserModal/AdminUserModal'
import UnArchiveUserModal from './ArchiveUserModal/UnArchiveUserModal'
import DeleteAdminsUserModal from './DeleteUserModal/DeleteAdminsUserModal'
import EmailSettingsModal from './EmailSettingsModal/EmailSettingsModal'
import AdminProfileInfoModal from './AdminProfileInfoModal/AdminProfileInfoModal'

export default function Modals() {
    return (
        <div>
            <EditOwnProfileModal />
            <CreateNDAModal />
            <UserModal />
            <ProjectModal />
            <DisclaimerModal />
            <ProfileInfoModal />
            <FeedbackModal />
            <OfferModal />
            <FolderModal />
            <FileUploadModal />
            <TeaserModal />
            <TeaserLinksModal />
            <NdaModal />
            <PostNdaModal />
            <NdaTemplateModal />
            <CreateAccountModal />
            <ProjectAccessModal />
            <ProbuyerModal />
            <OfflineNdaModal />
            <NotificationModal />
            <ArchiveProjectModal />
            <DeleteUserModal />
            <ArchiveUserModal />
            <UnArchiveUserModal />
            <CreateAdminModal />
            <DeleteAdminsUserModal/>
            <EmailSettingsModal/>
            <SellerModal />
            <SellerAccessModal />
            <LogoutNotificationModal />
            <YesNoModal />
            <ProjectPublishingModal />
            <TrackerUserListModal/>
            <TrackerCommentModal/>
            <AdminProfileInfoModal/>
        </div>
    )
}
