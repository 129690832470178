import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'

import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'
import StatusFilter from '../../UI/containers/StatusFilter/StatusFilter'

import * as service from '../services/offers'

import VisibilityAccess from '../../UI/containers/VisibilityAccess'
import { TOfferStatus, TRoles } from '../../../shared/types'
import { ITEMS_PER_PAGE } from '../../../shared/config'
import Paginate from '../../UI/containers/Paginate/Paginate'
import { Accordion } from 'react-accessible-accordion'
import styles from './OffersList.module.scss'
import OffersItem from './OffersItem'
import eventEmitter, { types } from '../../../shared/eventEmitter'

export default function OfferList(props) {
    const FILTER_TYPES = {
        PENDING: 'PENDING',
        ACCEPTED: 'ACCEPTED',
        DECLINED: 'DECLINED',
    }

    const DEFAULT_FILTERS = {
        [FILTER_TYPES.PENDING]: true,
        [FILTER_TYPES.ACCEPTED]: false,
        [FILTER_TYPES.DECLINED]: false,
    }

    const getActiveFilters = () => {
        return Object.keys(filters).filter(filter => {
            return filters[filter]
        })
    }

    const mapRoleToOfferService = {
        [TRoles.OWNER]: {
            resolve: service.resolveBoOffer,
            fetch: service.getOffers,
        },
        [TRoles.ADMIN]: {
            resolve: service.resolveAdminOffer,
            fetch: service.getAdminOffers,
        },
        [TRoles.SUPER_ADMIN]: {
            resolve: service.resolveAdminOffer,
            fetch: service.getAdminOffers,
        },
        [TRoles.INVESTOR]: {
            fetch: service.getInvestorOffers,
        },
    }

    const mapRoleToShouldDisplayActionButtons = {
        [TRoles.ADMIN]: ({ status }) => {
            return status === TOfferStatus.ADMIN_PENDING
        },
        [TRoles.SUPER_ADMIN]: ({ status }) => {
            return status === TOfferStatus.ADMIN_PENDING
        },
        [TRoles.OWNER]: ({ status }) => {
            return status === TOfferStatus.ADMIN_ACCEPTED
        },
        [TRoles.INVESTOR]: () => {
            return
        },
    }

    useEffect(() => {
        eventEmitter.on(types.closeOfferModal, handleUpdateOffersList)

        return () => eventEmitter.off(types.closeOfferModal, handleUpdateOffersList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderOffers = () => {
        if (offers.length === 0) {
            return (
                <div className={styles.empty}>
                    <p className={styles.emptyDescription}>
            You have no offers at this time
                    </p>
                </div>
            )
        } else {
            return (
                <>
                    <Accordion
                        allowMultipleExpanded
                        allowZeroExpanded
                        className={styles.accordionAdminList}
                    >
                        {offers.map(item => (
                            <OffersItem
                                key={item.id}
                                shouldDisplayActionButtons= {mapRoleToShouldDisplayActionButtons[
                                    props.role
                                ]({ status: item.status })}
                                onActionHandle={mapRoleToOfferService[props.role].resolve}
                                item={item}
                                updateOffers={handleUpdateOffersList}
                            />
                        ))}
                    </Accordion>
                    <Paginate
                        forcePage={page}
                        pageCount={Math.ceil(offersCount / ITEMS_PER_PAGE)}
                        onPageChange={handlePageChange}
                    />
                </>
            )
        }
    }

    const [page, setPage] = useState(0)
    const [offers, setOffers] = useState([])
    const [offersCount, setOffersCount] = useState(0)
    const [filters, setFilter] = useState(DEFAULT_FILTERS)
    const offersHeaderRef = useRef()

    const handlePageChange = async ({ selected }) => {
        setPage(selected)
        await fetchOffers(selected)
        window.scrollTo({
            top: offersHeaderRef.current.offsetTop,
            behavior: 'smooth',
        })
    }

    const fetchOffers = async page => {
        const service = mapRoleToOfferService[props.role].fetch

        if (props.role === TRoles.INVESTOR) {
            service({
                projectId: props.projectId,
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
            }).then(({ data }) => {
                setOffers(data.data.offers)
                setOffersCount(data.data.count)
            })
        } else if (props.role === TRoles.OWNER) {
            service({ filters: getActiveFilters() }).then(({ data }) => {
                setOffers(data.data.offers)
            })
        } else {
            service(props.projectId, {
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
            }).then(({ data }) => {
                setOffers(data.data.offers)
                setOffersCount(data.data.count)
            })
        }
    }

    const handleUpdateOffersList = async () =>
        await handlePageChange({ selected: page })

    const handleFilterChange = ({ filterType }) => {
        setFilter(filter => {
            return {
                ...filter,
                [FILTER_TYPES[filterType]]: !filter[FILTER_TYPES[filterType]],
            }
        })
    }

    useEffect(() => {
        fetchOffers(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    return (
        <div className={styles.root} ref={offersHeaderRef}>
            <VisibilityAccess access={[TRoles.OWNER]}>
                <Header className={styles.headerTitle} title="Offers">
                    <div className={styles.buttons}>
                        <StatusFilter
                            isActive={filters[FILTER_TYPES.PENDING]}
                            onClick={() =>
                                handleFilterChange({ filterType: FILTER_TYPES.PENDING })
                            }
                            text={'Pending offers'}
                        />
                        <StatusFilter
                            isActive={filters[FILTER_TYPES.ACCEPTED]}
                            onClick={() =>
                                handleFilterChange({ filterType: FILTER_TYPES.ACCEPTED })
                            }
                            text={'Active offers'}
                        />
                        <StatusFilter
                            isActive={filters[FILTER_TYPES.DECLINED]}
                            onClick={() =>
                                handleFilterChange({ filterType: FILTER_TYPES.DECLINED })
                            }
                            text={'Declined offers'}
                        />
                    </div>
                </Header>
            </VisibilityAccess>
            <ContentContainer
                className={clsx(styles.container, props.containerClassName)}
            >
                {renderOffers()}
            </ContentContainer>
        </div>
    )
}
