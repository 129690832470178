import React, { useState, useEffect } from 'react'

import Title from '../components/Title'
import AllButton from '../components/AllButton'
import AddButton from '../../UI/containers/AddButton/AddButton'

import stylesCommon from './List.module.scss'
import List from '../components/List'
import AccountsItem from './AccountsItem'
import { TRoutes } from '../../../shared/types'

import * as service from '../../Users/services/users'
import eventEmitter, { types } from '../../../shared/eventEmitter'

export default function AccountsList() {
    const [accounts, setAccounts] = useState([])

    const fetchAccounts = () => {
        service
            .getUsers({
                listType: 'ACCOUNTS',
                limit: 5,
                offset: 0,
            })
            .then(({ data }) => {
                setAccounts(data.data.accounts)
            })
    }

    useEffect(() => {
        fetchAccounts(0)
        eventEmitter.on(types.updateDashboardAccount, fetchAccounts)

        return () =>
            eventEmitter.off(types.updateDashboardAccount, fetchAccounts)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={stylesCommon.root}>
            <Title title="Accounts">
                <AddButton
                    onClick={() =>
                        eventEmitter.emit(types.openCreateAccountModal)
                    }
                    text="Add Account"
                />
            </Title>
            <List
                className={stylesCommon.usersList}
                items={accounts}
                renderEmpty={() => (
                    <div className={stylesCommon.empty}>
                        <p className={stylesCommon.emptyDescription}>
                            You have no accounts at this time
                        </p>
                        <AddButton
                            onClick={() =>
                                eventEmitter.emit(types.openCreateAccountModal)
                            }
                            text="Add Account"
                        />
                    </div>
                )}
            >
                {item => <AccountsItem key={item.id} item={item} />}
            </List>
            <AllButton text="See all accounts" to={TRoutes.ACCOUNTS} />
        </div>
    )
}
