import React from 'react'
import PropTypes from 'prop-types'
import { Else, If, Then } from 'react-if'

import Tag from '../Tag/Tag'

import { TStatusProject } from '../../../../shared/types'

export default function TagStatus(props) {
    return (
        <If condition={props.status === TStatusProject.DRAFT}>
            <Then>
                <Tag
                    className={props.className}
                    text="Draft"
                    bgColor="#f3f4f9"
                    textColor="#a3a8bb"
                />
            </Then>
            <Else>
                <Tag
                    className={props.className}
                    text={props.status}
                    bgColor="#eaf5df"
                    textColor="#9dce6c"
                />
            </Else>
        </If>
    )
}

TagStatus.propTypes = {
    className: PropTypes.string,
    status: PropTypes.string.isRequired
}

TagStatus.defaultProps = {
    className: ''
}
