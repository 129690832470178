import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './TooltipItem.module.scss'

export default function TooltipItem(props) {
    return (
        <button
            className={clsx(styles.root, props.className)}
            onClick={props.onClick}
            disabled={props.disabled}>
            {props.children}
        </button>
    )
}

TooltipItem.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}

TooltipItem.defaultProps = {
    className: '',
    disabled: false,
    onClick: () => {
    }
}
