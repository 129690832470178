import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import CardValue from '../components/CardValue'
import ProfileInfo from '../components/ProfileInfo'

import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'

import NotificationsListMini from '../../PreviewList/containers/NotificationsListMini/NotificationsListMini'

import stylesView from './View.module.scss'
import * as services from '../services/dashboard'

function ViewInvestor(props) {
    const [countProjects, setCountProjects] = useState(0)
    const [countAnswers, setCountAnswers] = useState(0)
    const [countNotifications, setCountNotifications] = useState(0)

    useEffect(() => {
        services.getStatisticDashboard().then(({ data }) => {
            setCountProjects(data.statistic.countPublishProject)
            setCountAnswers(data.statistic.countAnswer)
            setCountNotifications(data.statistic.countNotSeenNotification)
        })
    }, [])

    return (
        <div className={stylesView.root}>
            <Header title="Overview"/>
            <ContentContainer>
                <div className={stylesView.topRow}>
                    <ProfileInfo
                        name={`${props.userProfile.firstName} ${props.userProfile.lastName}`}
                        photoLink={props.userProfile.photoLink}
                    />
                    <div className={stylesView.cards}>
                        <CardValue
                            className={stylesView.card}
                            title="Projects"
                            value={countProjects}
                        />
                        <CardValue
                            className={stylesView.card}
                            title="Answers"
                            value={countAnswers}
                            valueColor="#9dce6b"
                        />
                        <CardValue
                            className={stylesView.card}
                            title="Notifications"
                            value={countNotifications}
                            valueColor="#9dce6b"
                        />
                    </div>
                </div>

                <div className={stylesView.bottomRow}>
                    <NotificationsListMini
                        className={stylesView.notificationsList}
                    />
                </div>
            </ContentContainer>
        </div>
    )
}

const mapStateToProps = state => ({
    userProfile: {
        firstName: state.user.userProfile.firstName,
        lastName: state.user.userProfile.lastName,
        photoLink: state.user.userProfile.photoLink
    }
})

export default connect(mapStateToProps)(ViewInvestor)
