import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { If, Then, Else } from 'react-if'
import { zodResolver } from '@hookform/resolvers/zod'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import clsx from 'clsx'

import TextField from '../../../UI/containers/Field/TextField'
import Button from '../../../UI/containers/Button/Button'
import PhoneField from '../../../UI/containers/Field/PhoneField'

import * as userActions from '../../../../redux/ducks/user'

import styles from './SignUpForm.module.scss'
import { TRoles } from '../../../../shared/types'

function SignUpFormOptional(props) {
    const [isLoading, setLoading] = useState(false)
    const phoneField = useRef(null)
    //const history = useHistory()

    const { register, handleSubmit, control, errors, setValue } = useForm({
        resolver: zodResolver(props.role === TRoles.OWNER ?
            z.object({
                companyName: z.string().nonempty(),
                address: z.string().nonempty(),
                zipCode: z.string().nonempty(),
                town: z.string().nonempty(),
            })
            : z.object({
                address: z.string().nonempty(),
                zipCode: z.string().nonempty(),
                town: z.string().nonempty(),
                telephoneNumber: z
                    .string()
                    .refine(() => phoneField.current.isValid, {
                        message: 'Phone is not valid',
                    }),
            })),
    })

    const onSubmit = data => {
        setLoading(true)
        props.onSubmit(data).finally(() => setLoading(false))
    }

    //    const handleSkip = e => {
    //        e.preventDefault()
    //        props.cleanAuthInvitation()
    //        history.push(TRoutes.LOGIN)
    //    }

    useEffect(() => {
        const { companyName, telephoneNumber } = props.authInvitation

        setValue('companyName', companyName)
        setValue('telephoneNumber', telephoneNumber)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <p className={styles.pleaText}>Just one single small step</p>
            <p className={styles.description}>
                We will be much grateful if you provide additional details about
                yourself
            </p>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <TextField
                    className={styles.field}
                    error={errors.address}
                    label="Address"
                    name="address"
                    isRequired
                    ref={register}
                    placeholder="Address"
                />
                <div className={styles.zipCity}>
                    <TextField
                        className={styles.zipField}
                        error={errors.zipCode}
                        label="Zip code"
                        name="zipCode"
                        isRequired
                        ref={register}
                        placeholder="Zip code"
                    />
                    <TextField
                        className={styles.field}
                        error={errors.town}
                        label="Town"
                        name="town"
                        isRequired
                        ref={register}
                        placeholder="Town"
                    />
                </div>
                <If condition={props.role === TRoles.OWNER}>
                    <Then>
                        <TextField
                            className={styles.field}
                            error={errors.companyName}
                            label="Company"
                            name="companyName"
                            isRequired
                            ref={register}
                            placeholder="Company name"
                        />
                    </Then>
                    <Else>
                        <PhoneField
                            error={errors.telephoneNumber}
                            ref={phoneField}
                            control={control}
                            name="telephoneNumber"
                            label="Phone"
                        />
                    </Else>
                </If>
                <div className={styles.actions}>
                    {/*<Button onClick={handleSkip} className={clsx(styles.action, styles.actionSkipStep)} isLoading={isLoading}
                        bgColor="#f5f6f8">Skip this step</Button>*/}
                    <Button
                        className={clsx(styles.action)}
                        isLoading={isLoading}
                        bgColor="#9dce6c"
                        type="submit"
                    >
                        Log In
                    </Button>
                </div>
            </form>
        </div>
    )
}

SignUpFormOptional.propTypes = {
    authInvitation: PropTypes.shape({
        companyName: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        telephoneNumber: PropTypes.string.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    cleanAuthInvitation: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    authInvitation: {
        companyName: state.user.authInvitation.companyName,
        position: state.user.authInvitation.position,
        telephoneNumber: state.user.authInvitation.telephoneNumber,
    },
})

const mapDispatchToProps = dispatch => ({
    cleanAuthInvitation: () => dispatch(userActions.cleanAuthInvitation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFormOptional)
