import React, { useState } from 'react'
// import { toast } from 'react-toastify'

import { ReactComponent as AcceptIcon } from '../../../../assets/icons/accept.svg'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'

import { types } from '../../../../shared/eventEmitter'
import * as service from '../../services/sendFeedback'

import styles from './FeedbackModal.module.scss'

export default function FeedbackModal() {
    const [isLoading, setLoading] = useState(false)
    const [projectId, setProjectId] = useState(null)
    const [businessType, setBusinessType] = useState('')
    const [isSuccess, setSuccess] = useState(false)

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: zodResolver(
            z.object({
                message: z.string().nonempty(),
            })
        ),
    })

    const handleOpen = ({ projectId, businessType }) => {
        setSuccess(false)
        setProjectId(projectId)
        setBusinessType(businessType)
    }

    const onSubmit = () => data => {
        setLoading(true)
        service
            .sendFeedback(projectId, data.message)
            .then(() => {
                setSuccess(true)
                reset()
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
            .finally(() => setLoading(false))
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            disabledBgClose
            classModalName={styles.modal}
            nameEvent={types.openFeedbackModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title="Leave a feedback"
                    >
                        <p className={styles.codemaster}>{businessType}</p>
                    </ModalHeader>
                    {isSuccess ? (
                        <form autoComplete="off">
                            <div className={styles.notificationMessage}>
                                <div className={styles.messageContainer}>
                                    <div className={styles.acceptIcon}>
                                        <AcceptIcon />
                                    </div>
                                    <span>Your feedback was successfully sent</span>
                                </div>
                                <Button
                                    onClick={() => closeModal()}
                                    className={styles.sendBtn}
                                >
                                    Done
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <form
                            onSubmit={handleSubmit(onSubmit(closeModal))}
                            autoComplete="off"
                        >
                            <div className={styles.container}>
                                <p>
                                    Your feedback will be visible for business
                                    owner only after admin approved.
                                    <br /> Your personal details will be hidden
                                    from end business owners. Feedback will
                                    remain anonymous for project owner.
                                </p>
                                <TextField
                                    fieldClass={styles.textarea}
                                    error={errors.message}
                                    ref={register}
                                    isTextarea
                                    name="message"
                                    placeholder="Type  your feedback..."
                                />
                                <div className={styles.footer}>
                                    <Button
                                        isLoading={isLoading}
                                        className={styles.sendBtn}
                                        type="submit"
                                        bgColor="#9dce6c"
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </>
            )}
        </BaseModal>
    )
}
