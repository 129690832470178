import React from 'react'
import PropTypes from 'prop-types'

import stylesCommon from './List.module.scss'

export default function AccountsItem(props) {
    return (
        <div className={stylesCommon.usersItem}>
            <span className={stylesCommon.boldText}>{props.item.name}</span>
            <span className={stylesCommon.members}>
                {props.item.members.length} Members
            </span>
        </div>
    )
}

AccountsItem.propTypes = {
    className: PropTypes.string,
}

AccountsItem.defaultProps = {
    className: '',
}
