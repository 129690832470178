import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg'

import styles from './Checkbox.module.scss'

export default function Checkbox(props) {
    const checkBoxProps = props.register ? {
        ref: props.register
    } : {
        onChange: props.onChange,
        checked: props.value
    }

    return (
        <label className={clsx(styles.root, props.className)}>
            <span className={styles.checkboxWrapper}>
                <input {...checkBoxProps} name={props.name} className={styles.input} type="checkbox"/>
                <span className={styles.checkbox}>
                    <CheckIcon className={styles.checkboxIcon}/>
                </span>
                <span className={clsx(styles.label, props.labelClassName)}>{props.label}</span>
            </span>
            {props.error && (
                <span className={styles.errorMessage}>
                    {props.error.message}
                </span>
            )}
        </label>
    )
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }),

    className: PropTypes.string,

    value: PropTypes.bool,
    onChange: PropTypes.func,

    register: PropTypes.func
}

Checkbox.defaultProps = {
    className: ''
}
