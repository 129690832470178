import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import { Controller } from 'react-hook-form'
import clsx from 'clsx'

import styles from './PhoneField.module.scss'
import stylesTextField from './TextField.module.scss'

// здесь класс потому что нужен ref
export default class PhoneField extends Component {
    constructor(props) {
        super(props)

        this.isValid = false
    }

    render() {
        const { props } = this

        return (
            <label
                className={clsx(
                    stylesTextField.root,
                    styles.root,
                    props.className
                )}
            >
                {props.label && (
                    <span className={stylesTextField.label}>{props.label}</span>
                )}
                <Controller
                    name={props.name}
                    defaultValue=""
                    control={props.control}
                    render={({ onChange, value, ref }) => (
                        <PhoneInput
                            inputClass={styles.input}
                            buttonClass={styles.dropDownButton}
                            value={value || null}
                            country="dk"
                            onChange={(phone, country, e, formattedValue) => {
                                onChange(formattedValue)
                            }}
                            inputProps={{
                                name: props.name,
                            }}
                            ref={el => {
                                if (!el) return
                                if (el && !el.numberInputRef) return
                                if (typeof ref === 'function') {
                                    ref(el.numberInputRef)
                                } else if (typeof ref === 'object') {
                                    ref.current = el.numberInputRef
                                }
                            }}
                            isValid={(inputNumber, country) => {
                                if (
                                    !inputNumber ||
                                    inputNumber === country.countryCode
                                ) {
                                    this.isValid = true
                                    return true
                                }

                                const lengthNumber =
                                    country.format.split('.').length - 1
                                this.isValid =
                                    inputNumber.startsWith(country.dialCode) &&
                                    inputNumber.length === lengthNumber
                                return true
                            }}
                            placeholder="(XXX) XXX-XX-XX"
                            name={props.name}
                        />
                    )}
                />
                {props.error && (
                    <span className={stylesTextField.errorMessage}>
                        {props.error.message}
                    </span>
                )}
            </label>
        )
    }
}

PhoneField.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    control: PropTypes.object.isRequired,

    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),

    label: PropTypes.string,
    placeholder: PropTypes.string,
}

PhoneField.defaultProps = {
    label: '',
    placeholder: '',
    className: '',
}
