import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import clsx from 'clsx'

import {ReactComponent as ArrowIcon} from '../../../../assets/icons/mini-arrow.svg'

import styles from './Tooltip.module.scss'

export default function Tooltip(props) {
    return (
        <div className={props.className}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
                className={clsx(styles.actionToggle, props.classNameToggle)}
                data-tip=""
                data-for={props.id}
                data-event={props.dataEvent}
            >
                {props.renderIcon ? (
                    props.renderIcon() || null
                ) : (
                    <ArrowIcon className={styles.icon}/>
                )}
            </a>
            <ReactTooltip
                className={styles.content}
                globalEventOff="click"
                id={props.id}
                place={props.place}
                clickable
                eventOff={props.eventOff}
                isCapture={true}
            >
                {props.children}
            </ReactTooltip>
        </div>
    )
}

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    classNameToggle: PropTypes.string,
    className: PropTypes.string,
    iconPath: PropTypes.string,
    renderIcon: PropTypes.func,
    dataEvent: PropTypes.string,
    eventOff:PropTypes.string,
    place: PropTypes.string
}

Tooltip.defaultProps = {
    iconPath: '',
    className: '',
    classNameToggle: '',
    dataEvent: 'click focus',
    place: 'bottom'
}
