import React from 'react'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'
import ProjectView from '../modules/Projects/containers/ProjectView/ProjectView'

export default function Project() {
    return (
        <ContainerMenu>
            <ProjectView/>
        </ContainerMenu>
    )
}
