import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from '../MiniCard/MiniCards.module.scss'

export default function MiniCard(props) {
    return (
        <div className={clsx(styles.root, props.className)}>
            <img className={styles.icon} src={props.path} alt="Icon"/>
            {props.children}
        </div>
    )
}

MiniCard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    path: PropTypes.string.isRequired,

    className: PropTypes.string.isRequired
}

MiniCard.defaultProps = {
    className: ''
}
