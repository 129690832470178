import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'
import QuestionsOwnerView from '../modules/Questions/containers/QuestionsOwnerView'
import { TRoles, TRoutes } from '../shared/types'

function Questions({ role }) {
    if (role === TRoles.OWNER)
        return (
            <ContainerMenu>
                <QuestionsOwnerView />
            </ContainerMenu>
        )
    else return <Redirect to={TRoutes.NOT_FOUND} />
}

Questions.propTypes = {
    role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(Questions)
