import React from 'react'
import styles from './ContentContainer.module.scss'
import clsx from 'clsx'

export default function ContentContainer(props) {
    return (
        <div className={clsx(styles.root, props.className)}>
            {props.children}
        </div>
    )
}
