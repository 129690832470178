import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ReactComponent as InvisibleIcon } from '../../../../assets/icons/invisible.svg'

import styles from './TextField.module.scss'

const TextField = React.forwardRef((props, ref) => {
    const [visiblePassword, setVisiblePassword] = useState(false)
    const Field = props.isTextarea ? 'textarea' : 'input'

    const filedProps = props.isTextarea
        ? {
            className: clsx(
                styles.textarea,
                styles.input,
                !!props.error && styles.inputError,
                props.fieldClass
            ),
        }
        : {
            type: visiblePassword || !props.isPassword ? 'text' : 'password',
            className: clsx(
                styles.input,
                !!props.error && styles.inputError,
                props.fieldClass
            ),
        }

    return (
        <label className={clsx(styles.root, props.className)}>
            {props.label && (
                <span className={styles.label}>
                    {props.label}
                    {props.isRequired && (
                        <span className={styles.required}>*</span>
                    )}
                </span>
            )}
            <Field
                {...filedProps}
                disabled={props.disabled}
                name={props.name}
                ref={ref}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
            {props.isPassword && (
                <button
                    type="button"
                    className={clsx(
                        styles.inputPasswordToggle,
                        !!props.error && styles.inputPasswordToggleError
                    )}
                    onClick={e => {
                        e.preventDefault()
                        setVisiblePassword(!visiblePassword)
                    }}
                >
                    {visiblePassword ? (
                        <InvisibleIcon width="15" />
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="11"
                        >
                            <path d="M15.902 5.839c-.091.21-2.314 5.159-7.923 5.159-5.608 0-7.831-4.949-7.923-5.159-.095-.219-.095-.462 0-.682.092-.21 2.315-5.159 7.923-5.159 5.609 0 7.832 4.949 7.923 5.159.095.22.095.463 0 .682zM7.979 1.832c-3.525 0-5.352 2.702-5.893 3.669.541.977 2.348 3.663 5.893 3.663 3.525 0 5.353-2.702 5.894-3.668-.54-.976-2.347-3.664-5.894-3.664zm0 6.417c-1.649 0-2.999-1.238-2.999-2.75 0-1.513 1.35-2.751 2.999-2.751s2.997 1.238 2.997 2.751c0 1.512-1.348 2.75-2.997 2.75zm0-3.667c-.549 0-.999.412-.999.917 0 .504.45.916.999.916.549 0 .999-.412.999-.916 0-.505-.45-.917-.999-.917z" />
                        </svg>
                    )}
                </button>
            )}
            {props.error && (
                <span className={styles.errorMessage}>
                    {props.error.message}
                </span>
            )}
        </label>
    )
})

TextField.propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),

    disabled: PropTypes.bool.isRequired,
    className: PropTypes.string,
    fieldClass: PropTypes.string,
    label: PropTypes.string,
    isPassword: PropTypes.bool,
    isTextarea: PropTypes.bool,
    placeholder: PropTypes.string,
}

TextField.defaultProps = {
    label: '',
    placeholder: '',
    className: '',
    fieldClass: '',
    isTextarea: false,
    disabled: false,
    isPassword: false,
}

export default TextField
