import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { toast } from 'react-toastify'

import TextField from '../../../UI/containers/Field/TextField'
import Button from '../../../UI/containers/Button/Button'

import * as service from '../../services/editProfile'
import styles from './EditOwnProfileModal.module.scss'

export default function ChangePasswordForm() {
    const [isLoading, setLoading] = useState(false)

    const { register, handleSubmit, reset, errors } = useForm({
        resolver: zodResolver(
            z
                .object({
                    currentPassword: z.string().nonempty().min(5),
                    newPassword: z.string().nonempty().min(5),
                    repeatPassword: z.string().nonempty().min(5),
                })
                .refine(data => data.newPassword === data.repeatPassword, {
                    message: 'Passwords don\'t match',
                    path: ['repeatPassword'],
                })
        ),
    })

    const onSubmit = data => {
        setLoading(true)

        service
            .changePassword(data)
            .then(() => {
                toast.success('Password successfully changed')
                reset()
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
            .finally(() => setLoading(false))
    }

    return (
        <div>
            <form autoComplete="off">
                <TextField
                    error={errors.currentPassword}
                    className={styles.field}
                    isPassword
                    label="Current Password"
                    name="currentPassword"
                    placeholder="Password"
                    ref={register}
                />
                <TextField
                    error={errors.newPassword}
                    className={styles.field}
                    isPassword
                    label="New Password"
                    name="newPassword"
                    placeholder="New password"
                    ref={register}
                />
                <TextField
                    error={errors.repeatPassword}
                    className={styles.field}
                    isPassword
                    label="Repeat New Password"
                    name="repeatPassword"
                    placeholder="Repeat new password"
                    ref={register}
                />

                <Button
                    className={styles.btnChangePass}
                    isLoading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    bgColor="#ffffff"
                >
                    Change Pass
                </Button>
            </form>
        </div>
    )
}

ChangePasswordForm.propTypes = {
    name: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
