import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ReactComponent as IconCross } from '../../../../assets/icons/cross.svg'

import eventEmitter from '../../../../shared/eventEmitter'

import styles from './BaseModal.module.scss'

export default function BaseModal(props) {
    const [modal, setModal] = useState({
        isOpen: false,
        data: null,
    })

    const handleOpen = (...args) => {
        setModal({
            isOpen: true,
            data: args,
        })
    }

    const closeModal = () => {
        if (!props.isLoading)
            setModal({
                isOpen: false,
                data: null,
            })
    }

    useLayoutEffect(() => {
        if (modal.isOpen) props.onOpen(...modal.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal])

    useEffect(() => {
        eventEmitter.on(props.nameEvent, handleOpen)
        return () => {
            eventEmitter.off(props.nameEvent, handleOpen)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nameEvent])

    if (!modal.isOpen) return null

    return (
        <div className={styles.overlay} data-overlay="true">
            <div data-overlay="true" className={styles.wrapper}>
                <div className={clsx(styles.modal, props.classModalName)}>
                    {!props.disabledCrossClose && (
                        <button
                            className={clsx(
                                styles.actionClose,
                                props.classActionClose
                            )}
                            onClick={closeModal}
                        >
                            <IconCross />
                        </button>
                    )}
                    {props.children({ closeModal })}
                </div>
            </div>
        </div>
    )
}

BaseModal.propTypes = {
    children: PropTypes.func.isRequired,
    nameEvent: PropTypes.string.isRequired,
    classModalName: PropTypes.string,
    disabledBgClose: PropTypes.bool,
    onOpen: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    disabledCrossClose: PropTypes.bool,
}

BaseModal.defaultProps = {
    classModalName: '',
    disabledBgClose: false,
    disabledCrossClose: false,
    onOpen: () => {},
    isLoading: false,
}
