import React from 'react'
import PropTypes from 'prop-types'

import styles from './Title.module.scss'

export default function Title(props) {
    return (
        <div className={styles.root}>
            <h3 className={styles.title}>{props.title}</h3>
            {props.children}
        </div>
    )
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}
