import React from 'react'
import clsx from 'clsx'

import Logo from '../../components/Logo/Logo'
import LogInForm from '../LogInForm/LogInForm'

import styles from './LogInView.module.scss'
import stylesLanding from '../../components/Landing.module.scss'

export default function LogInView() {
    return (
        <div className={styles.root}>
            <div className={clsx(stylesLanding.container, styles.container)}>
                <div className={styles.leftCol}>
                    <Logo/>
                    <div className={styles.wrapperTitle}>
                        <h1 className={clsx(stylesLanding.titleMain, styles.titleMain)}>Log In</h1>
                        <p className={styles.paragraph}>Value-added advice based on trust</p>
                    </div>
                </div>
                <div className={styles.rightCol}>
                    <LogInForm/>
                </div>
            </div>
        </div>
    )
}
