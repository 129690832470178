import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'

import ContentContainer from '../../../UI/containers/Container/ContentContainer'

import Header from '../../components/Header/Header'

import Teaser from '../Teaser/Teaser'

import Tracker from '../Tracker/Tracker'

import * as services from '../../services/projects'

import socket from '../../../../shared/socketNda'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

import styles from './ProjectView.module.scss'
import Button from '../../../UI/containers/Button/Button'
import {
    useScrollPosition,
    useScrollDirection,
} from '../../../UI/containers/Footer/useScrollPosition'
import { TRoles } from '../../../../shared/types'
import ProjectChat from '../ProjectChat/ProjectChat'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'
import AccessAccountAccordion from '../../components/AccessAccountAccordion/AccessAccountAccordion'
import AccessSellerAccordion from '../../components/AccessSellerAccordion/AccessSellerAccordion'
import {getAccountProjectsStatisticInfo, getAccountProjectsTrackerInfo} from '../../services/projects'
import {connect} from 'react-redux'

function ProjectView(props) {
    const { id: projectId } = useParams()
    const [sticky, setSticky] = useState(false)
    const [showFooter, setShowFooter] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)

    const [project, setProject] = useState({
        statistics: {
            views: 0,
            questions: 0,
        },
        teaser: {
            url: '',
            urlSource: '',
        },
        project: {
            status: '',
            name: '',
            description: '',
            businessType: '',
            ownerId: '',
            projectId: '',
            projectPhotoLink: '',
            feedback: [],
            NDA: [],
            hasNDATemplate: false,
            accounts: [],
            type: ''
        },
        showNDA: false,
        projectOwner: {
            firstName: '',
            lastName: '',
            companyName: '',
            position: '',
            userPhotoLink: '',
        },
    })

    const [statisticData, setStatisticData ] = useState({
        total_amount: 0,
        active: 0,
        inactive: 0,
        seen_teaser: 0,
        signed_nda: 0,
    })
    const [accounts, setAccounts] = useState([])

    const handleGetAccountProjectsTrackerInfo = () => {
        getAccountProjectsTrackerInfo(projectId).then(({data}) => {
            const activeAccounts = data.data.accountsInfo.filter(item => item.status === 'Active')
            const inactiveAccounts = data.data.accountsInfo.filter(item => item.status === 'Inactive')
            const restAccounts = data.data.accountsInfo.filter(item => item.status === 'Remind' || item.status === 'Invited')
            setAccounts([...activeAccounts, ...restAccounts, ...inactiveAccounts])
        })
    }

    const handleGetAccountProjectsTrackerStatistic = () => {
        getAccountProjectsStatisticInfo(projectId).then(({data}) => {
            setStatisticData({
                ...data.data.statisticInfo
            })
        })
    }

    useScrollPosition(position => {
        setScrollPosition(position)
    })

    useScrollDirection(direction => {
        if (direction === 'SCROLL_DIRECTION_DOWN' && scrollPosition > 255) {
            setShowFooter(true)
        }

        if (direction === 'SCROLL_DIRECTION_UP' && scrollPosition < 255) {
            setShowFooter(false)
        }
    })

    const handleChangeTeaser = data => {
        setProject({
            ...project,
            teaser: {
                ...project.teaser,
                ...data,
            },
        })
    }

    const handleUpdateTeaser = async data => {
        await services.editTeaser(project.project.id, data)

        handleChangeTeaser(data)
    }

    const handleChangeStatus = data => {
        setProject({
            ...project,
            project: {
                ...project.project,
                status: data
            }
        })
    }

    const handleChangeNDATemplate = data => {
        setProject({
            ...project,
            project: {
                ...project.project,
                hasNDATemplate: data
            }
        })
    }

    useScrollPosition(
        ({ prevPos, currPos }) => {
            if (!currPos || !prevPos) return null
            const isShow = currPos.y > prevPos.y
            if (isShow !== sticky) setSticky(isShow)
        },
        [sticky]
    )

    const handleGetProjectFullInfo = () => {
        services.getProjectFullInfo(projectId).then(({ data }) => {
            setProject({
                ...data.projectInfo,
                project: {
                    ...data.projectInfo.project,
                    ...(projectId
                        ? {}
                        : {
                            projectId: data.projectInfo.project.id,
                        }),
                },
            })
        })
    }

    useEffect(() => {
        if([TRoles.ADMIN, TRoles.SUPER_ADMIN].includes(props.role)) handleGetAccountProjectsTrackerInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    useEffect(() => {
        if([TRoles.ADMIN, TRoles.SUPER_ADMIN].includes(props.role)) handleGetAccountProjectsTrackerStatistic()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    useEffect(() => {
        handleGetProjectFullInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    useEffect(() => {
        eventEmitter.on(types.updateProjectView, handleGetProjectFullInfo)

        return () =>
            eventEmitter.off(types.updateProjectView, handleGetProjectFullInfo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if([TRoles.ADMIN, TRoles.SUPER_ADMIN].includes(props.role)) eventEmitter.on(types.updateProjectView, handleGetAccountProjectsTrackerInfo)

        return () =>  eventEmitter.off(types.updateProjectView, handleGetAccountProjectsTrackerInfo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if([TRoles.ADMIN, TRoles.SUPER_ADMIN].includes(props.role)) eventEmitter.on(types.updateProjectView, handleGetAccountProjectsTrackerStatistic)

        return () => eventEmitter.off(types.updateProjectView, handleGetAccountProjectsTrackerStatistic)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleSignedNda = () => {
        eventEmitter.emit(types.handleSocketNDASign)
    }

    useEffect(() => {
        socket.connect()
        socket.on('nda-resolved', handleSignedNda)

        return () => {
            socket.close()
            socket.off('nda-resolved', handleSignedNda)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasTeaser = () => {
        return project.teaser.previewUrl && project.teaser.sourceUrl
    }

    return (
        <>
            <div className={styles.root}>
                <Header
                    project={project.project}
                    projectOwner={project.projectOwner}
                    statistics={project.statistics}
                    showNDA={project.showNDA}
                    onChangeStatus={handleChangeStatus}
                    hasNDATemplate={project.project.hasNDATemplate}
                    handleChangeNDATemplate={handleChangeNDATemplate}
                    handleChangeTeaser={handleUpdateTeaser}
                    hasTeaser={hasTeaser()}
                />
                <ContentContainer>
                    <VisibilityAccess
                        access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                    >
                        {project.project.type === 'M_AND_A' && (
                            <AccessAccountAccordion
                                accounts={project.project.accounts}
                                projectId={
                                    projectId || project.project.projectId
                                }
                            />
                        )}
                        {project.project.type === 'M_AND_A' && (
                            <AccessSellerAccordion
                                sellers={project.project.sellers}
                                projectId={
                                    projectId || project.project.projectId
                                }
                            />
                        )}

                        {project.project.type === 'M_AND_A' && (
                            <Tracker
                                projectId={
                                    projectId || project.project.projectId
                                }
                                statisticData={statisticData}
                                accounts={accounts}
                                updateInfo={handleGetAccountProjectsTrackerInfo}
                                updateStatistic={handleGetAccountProjectsTrackerStatistic}
                            />
                        )}
                    </VisibilityAccess>
                    <Teaser
                        onChange={handleChangeTeaser}
                        projectOwner={project.projectOwner}
                        projectId={projectId || project.project.projectId}
                        teaser={project.teaser}
                        feedback={project.project.feedback}
                        nda={project.project.NDA}
                        type={project.project.type}
                        showDocuments={project.project.showDocuments}
                    />
                </ContentContainer>
                <VisibilityAccess access={[TRoles.INVESTOR]}>
                    <footer
                        className={showFooter ? styles.footer : styles.hidden}
                    >
                        <div className={styles.actions}>
                            <Button
                                bgColor="#fff"
                                className={styles.actionFeedback}
                                onClick={e => {
                                    e.preventDefault()

                                    eventEmitter.emit(types.openFeedbackModal, {
                                        projectId:
                                            projectId ||
                                            project.project.projectId,
                                        businessType:
                                            project.project.businessType,
                                    })
                                }}
                            >
                                Leave Feedback
                            </Button>
                            <Button
                                className={clsx(
                                    styles.actionFeedback,
                                    styles.actionOffer
                                )}
                                onClick={e => {
                                    e.preventDefault()

                                    eventEmitter.emit(types.openOfferModal, {
                                        projectId:
                                            projectId ||
                                            project.project.projectId,
                                        businessType:
                                            project.project.businessType,
                                    })
                                }}
                            >
                                Make an offer
                            </Button>
                        </div>
                    </footer>
                </VisibilityAccess>
            </div>
            <VisibilityAccess
                access={[
                    TRoles.ADMIN,
                    TRoles.SUPER_ADMIN,
                    TRoles.OWNER,
                    TRoles.PRO_SELLER,
                ]}
            >
                <ProjectChat
                    isProjectTypeMA={project.project.type === 'M_AND_A'}
                    projectOwner={{
                        firstName: project.projectOwner.firstName,
                        lastName: project.projectOwner.lastName,
                        userPhotoLink: project.projectOwner.userPhotoLink,
                    }}
                />
            </VisibilityAccess>
        </>
    )
}


const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(ProjectView)