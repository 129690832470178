import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'
import NdaList from '../modules/NdaList/containers/NdaList'
import { TRoles, TRoutes } from '../shared/types'

function NdaListPage({ role }) {
    if (role === TRoles.ADMIN || role === TRoles.SUPER_ADMIN)
        return (
            <ContainerMenu>
                <NdaList/>
            </ContainerMenu>
        )
    else return <Redirect to={TRoutes.NOT_FOUND}/>
}

NdaListPage.propTypes = {
    role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    role: state.user.role
})

export default connect(mapStateToProps)(NdaListPage)
