import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './ModalHeader.module.scss'

export default function ModalHeader(props) {
    return (
        <div className={clsx(props.className, styles.root)}>
            <h3 className={clsx(props.titleClassName, styles.title)}>{props.title}</h3>
            {props.projectTitle && (
                <span className={styles.projectTitle}>
                    {props.projectTitle}
                </span>
            )}
            {props.userCount && (
                <span className={clsx(props.teammatesClassName, styles.root)}>
                    {props.userCount === 1 ? `${props.userCount} teammate` : `${props.userCount} teammates`}</span>
            )}
	    {props.children}
        </div>
    )
}

ModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}
