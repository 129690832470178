import React, { useState, useRef } from 'react'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import { If, Then, Else } from 'react-if'

import PhoneField from '../../../UI/containers/Field/PhoneField'
import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'
import Checkbox from '../../../UI/containers/Checkbox/Checkbox'
import Select from '../../../UI/containers/Field/Select'

import * as service from '../../services/user'
import * as adminService from '../../services/notification'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import { TRoles } from '../../../../shared/types'

import styles from './AdminUserModal.module.scss'

export default function CreateAdminModal() {
    const [isLoading, setLoading] = useState(false)
    const phoneField = useRef(null)
    const [userId, setUserId] = useState('')
    const [isEditMode, setEditMode] = useState(false)

    const {
        register,
        handleSubmit,
        errors,
        control,
        reset,
        setValue,
    } = useForm({
        resolver: zodResolver(
            z.object({
                userType: z.string().nonempty({
                    message: 'You should select user type',
                }),
                firstName: z
                    .string()
                    .regex(/^(?!\s+$)[A-Za-zæøåÆØÅ0-9'\s-]+$/, {
                        message: 'Incorrect name',
                    })
                    .nonempty(),
                lastName: z
                    .string()
                    .regex(/^(?!\s+$)[A-Za-zæøåÆØÅ0-9'\s-]+$/, {
                        message: 'Incorrect name',
                    })
                    .nonempty(),
                email: z.string().email().nonempty(),
                phone: z.string().refine(() => phoneField.current.isValid, {
                    message: 'Phone is not valid',
                }),
                shouldSendInvitation: z.boolean(),
            })
        ),
    })

    // const handleChangeSelect = value => {
    //     setTimeout(() => {
    //         setIsRequired(value === 'SUPER_ADMIN')
    //     }, 0)
    // }

    const handleOpen = info => {
        setEditMode(false)
        if (info) {
            setEditMode(true)
            setUserId(info.userId)
            // setIsRequired(info.role === 'BUSINESS_OWNER')
            service
                .getUserInfo(info.userId)
                .then(({ data }) => {
                    setTimeout(() => {
                        setValue('userType', data.userProfile.role)
                        setValue('firstName', data.userProfile.firstName)
                        setValue('lastName', data.userProfile.lastName)
                        setValue('email', data.userProfile.email)
                        setValue('phone', data.userProfile.telephoneNumber)
                    }, 0)
                })
                .catch(error => toast.error(error.message))
                .finally(() => setLoading(false))
        } else {
            setTimeout(() => {
                setValue('shouldSendInvitation', true)
            }, 0)
        }
    }

    const onSubmit = closeModal => data => {
        setLoading(true)
        if (isEditMode) {
            setLoading(true)

            adminService
                .editAdmin(userId, data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Edit User',
                        message: 'User successfully edited',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateAdminList)
                    eventEmitter.emit(types.updateSuperAdminList)
                })
                .catch(error => console.log('error: ', error.message))
                .finally(() => setLoading(false))
        } else {
            adminService
                .createAdmin(data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Create User',
                        message: 'User successfully created',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateAdminList)
                    eventEmitter.emit(types.updateSuperAdminList)
                })
                .catch(error => toast.error(error.message))
                .finally(() => setLoading(false))
        }
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openCreateAdminModal}
        >
            {({ closeModal }) => (
                <>
                    <If condition={isEditMode}>
                        <Then>
                            <ModalHeader
                                className={styles.header}
                                title="Edit User"
                            />
                        </Then>
                        <Else>
                            <ModalHeader
                                className={styles.header}
                                title="Create New User"
                            />
                        </Else>
                    </If>
                    <form
                        onSubmit={handleSubmit(onSubmit(closeModal))}
                        autoComplete="off"
                    >
                        <div className={styles.content}>
                            <div className={styles.row}>
                                <If condition={isEditMode}>
                                    <Then>
                                        <Select
                                            error={errors.userType}
                                            className={styles.field}
                                            control={control}
                                            label="User Type"
                                            name="userType"
                                            placeholder="Select user type"
                                            isRequired
                                            options={[
                                                {
                                                    value: TRoles.ADMIN,
                                                    label: 'Admin',
                                                },
                                                {
                                                    value: TRoles.SUPER_ADMIN,
                                                    label: 'Super admin',
                                                },
                                            ]}
                                        />
                                    </Then>
                                    <Else>
                                        <Select
                                            error={errors.userType}
                                            className={styles.field}
                                            control={control}
                                            label="User Type"
                                            name="userType"
                                            placeholder="Select user type"
                                            isRequired
                                            options={[
                                                {
                                                    value: TRoles.ADMIN,
                                                    label: 'Admin',
                                                },
                                                {
                                                    value: TRoles.SUPER_ADMIN,
                                                    label: 'Super admin',
                                                },
                                            ]}
                                        />
                                    </Else>
                                </If>
                                <TextField
                                    error={errors.email}
                                    className={styles.field}
                                    label="User Email"
                                    name="email"
                                    placeholder="example@email.com"
                                    isRequired
                                    ref={register}
                                />
                            </div>
                            <div className={styles.row}>
                                <TextField
                                    error={errors.firstName}
                                    className={styles.field}
                                    label="First Name"
                                    name="firstName"
                                    placeholder="First Name"
                                    isRequired
                                    ref={register}
                                />
                                <TextField
                                    error={errors.lastName}
                                    className={styles.field}
                                    label="Last Name"
                                    name="lastName"
                                    placeholder="Last Name"
                                    isRequired
                                    ref={register}
                                />
                            </div>
                            <div className={styles.row}>
                                <PhoneField
                                    error={errors.phone}
                                    ref={phoneField}
                                    control={control}
                                    name="phone"
                                    label="Phone"
                                    className={styles.field}
                                />
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.row}>
                                    <Checkbox
                                        error={errors.shouldSendInvitation}
                                        name="shouldSendInvitation"
                                        register={register}
                                        label="Send Invitational Email"
                                    />
                                    <If condition={isEditMode}>
                                        <Then>
                                            <Button
                                                isLoading={isLoading}
                                                className={styles.actionButton}
                                                bgColor="#9dce6c"
                                                type="submit"
                                            >
                                                Edit User
                                            </Button>
                                        </Then>
                                        <Else>
                                            <Button
                                                isLoading={isLoading}
                                                className={styles.actionButton}
                                                bgColor="#9dce6c"
                                                type="submit"
                                            >
                                                Create User
                                            </Button>
                                        </Else>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
