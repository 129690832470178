import React from 'react'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'

import Tag from '../../../UI/containers/Tag/Tag'
import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import { ReactComponent as ArrowTableIcon } from '../../../../assets/icons/mini-arrow-table.svg'

import { TRoles } from '../../../../shared/types'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

import styles from './UserItem.module.scss'

export default function UserItem({ info }) {
    return (
        <div className={styles.root}>
            <div
                className={clsx(styles.col, styles.colName)}
                onClick={
                    info.role === TRoles.PRO_SELLER
                        ? () => eventEmitter.emit(types.openProfileInfoModal, info)
                        : ''
                }
            >
                <Avatar
                    name={`${info.firstName} ${info.lastName}`}
                    size="40"
                    color="#d2e1df"
                    textSizeRatio={1.75}
                    round={3}
                    fgColor="#9ab8ac"
                    src={info.photoLink}
                />
                <div className={styles.userInfo}>
                    <div className={styles.name}>
                        {info.firstName} {info.lastName}
                    </div>
                    <Tag
                        className={styles.userType}
                        text="ProSeller"
                        bgColor="#eaf5df"
                        textColor="#9dce6c"
                    />
                </div>
            </div>
            <div className={clsx(styles.col, styles.colPhone)}>
                <span>{info.phone}</span>
            </div>
            <div className={clsx(styles.col, styles.colEmail)}>
                <span>{info.email}</span>
            </div>
            <div className={clsx(styles.col, styles.colActions)}>
                {!info.deletedAt ? (
                    <Tooltip
                        renderIcon={() => <ArrowTableIcon/>}
                        className={styles.actionMenu}
                        id={`user-list-${info.userId}`}
                    >
                        <TooltipItem
                            onClick={() =>
                                eventEmitter.emit(types.openCreateSellerModal, info)
                            }
                        >
                            Edit
                        </TooltipItem>
                        <VisibilityAccess access={[ TRoles.SUPER_ADMIN ]}>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openArchiveUserModal, {
                                        userId: info.userId,
                                        header: 'Archive User',
                                        message: `Are you sure you want to archive ${info.firstName} ${info.lastName}?`,
                                    })
                                }
                            >
                                Archive
                            </TooltipItem>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openDeleteUserModal, {
                                        userId: info.userId,
                                        header: 'Delete User',
                                        message: `Are you sure you want to delete ${info.firstName} ${info.lastName}?
                                        All information about this user will be removed from the database.`,
                                    })
                                }
                            >
                                Delete
                            </TooltipItem>
                        </VisibilityAccess>
                    </Tooltip>
                ) : (
                    <Tooltip
                        renderIcon={() => <ArrowTableIcon/>}
                        className={styles.actionMenu}
                        id={`user-list-${info.userId}`}
                    >
                        <VisibilityAccess access={[ TRoles.SUPER_ADMIN ]}>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openUnArchiveUserModal, {
                                        userId: info.userId,
                                        header: 'Archive User',
                                        message: `Are you sure you want to unarchive ${info.firstName} ${info.lastName}?`,
                                    })
                                }
                            >
                                Unarchive
                            </TooltipItem>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openDeleteUserModal, {
                                        userId: info.userId,
                                        header: 'Delete User',
                                        message: `Are you sure you want to delete ${info.firstName} ${info.lastName}?
                                        All information about this user will be removed from the database.                                                               `,
                                    })
                                }
                            >
                                Delete
                            </TooltipItem>
                        </VisibilityAccess>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

UserItem.propTypes = {
    info: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        photoLink: PropTypes.string,
        deletedAt: PropTypes.string,
    }),
}
