import React, {useState} from 'react'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'
import RequirementItem from './RequirementItem/RequirementItem'

import eventEmitter, {types} from '../../../../shared/eventEmitter'

import styles from './ProjectPublishingModal.module.scss'

export default function ProjectPublishingModal() {
    const [headerText, setHeaderText] = useState('')
    const [messageText, setMessageText] = useState('')
    const [noTeaser, setNoTeaser] = useState(false)
    const [noNDATemplate, setNoNDATemplate] = useState(false)
    const [onProjectAddNDATemplate, setOnProjectAddNDATemplate]= useState(() => () => {})
    const [onChangeTeaser, setOnChangeTeaser]= useState(() => () => {})

    const [projectId, setProjectId] = useState(null)
    const [projectName, setProjectName] = useState(null)

    const handleOpen = ({projectId, projectName, header, message, noTeaser, noNDATemplate, onProjectAddNDATemplate, onChangeTeaser}) => {
        setProjectId(projectId)
        setProjectName(projectName)
        setHeaderText(header)
        setMessageText(message)
        setNoTeaser(noTeaser)
        setNoNDATemplate(noNDATemplate)
        setOnProjectAddNDATemplate(() => onProjectAddNDATemplate)
        setOnChangeTeaser(() => onChangeTeaser)
    }

    const handleCreateNDATemplate = ({closeModal}) => {
        closeModal()

        eventEmitter.emit(types.openNdaTemplateModal, {
            projectId,
            name: projectName,
            editMode: false,
            onNDATemplateCreation: () => {
                onProjectAddNDATemplate(true)
            }
        })
    }

    const handleAddTeaser = ({closeModal}) => {
        closeModal()

        eventEmitter.emit(types.openTeaserLinksModal, {
            header: 'Teaser information',
            message: 'Please, make sure both links are added',
            onSubmit: onChangeTeaser
        })
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openProjectPublishingModal}
        >
            {({closeModal}) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title={headerText}
                        titleClassName={styles.headerTitle}
                    />
                    <div className={styles.container}>
                        <form autoComplete="off">
                            <div className={styles.message}>{messageText}</div>
                            <div className={styles.actions}>
                                {noTeaser && <RequirementItem itemText={'Teaser'} buttonText={'Add'}
                                    buttonClassName={styles.action} onClick={() => handleAddTeaser({closeModal})}/>}
                                {noNDATemplate &&
                                <RequirementItem itemText={'Create NDA template'} buttonText={'Create'}
                                    buttonClassName={styles.action} onClick={() => handleCreateNDATemplate({closeModal})}/>}
                            </div>
                        </form>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
