import React from 'react'
import { connect } from 'react-redux'
import styles from './Tracker.module.scss'
import AccountTrackerItem from '../../../Accounts/components/AccountTracker/AccountTrackerItem'
import { useParams } from 'react-router-dom'
import ReactExport from 'react-export-excel-style-fixed'

function Tracker ({statisticData, accounts, updateInfo, updateStatistic}) {
    const { id: projectId } = useParams()
    const ExcelFile = ReactExport.ExcelFile
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

    const excelData = accounts.map(account => {
        let sentInvitation = ''
        let openedProject = ''
        let seenTeaser = ''
        let signedNDA = ''
        let downloadDocs = ''

        account.main_statuses.forEach(item => {
            if(item.activityType === 'Sent invitation') sentInvitation = `${item.activityType}, ${item.activityTime}`
            if(item.activityType === 'Opened project') openedProject = `${item.activityType}, ${item.activityTime}`
            if(item.activityType === 'Looked at teaser') seenTeaser = `${item.activityType}, ${item.activityTime}`
            if(item.activityType === 'Signed NDA') signedNDA = `${item.activityType}, ${item.activityTime}`
            if(item.activityType === 'Downloaded documents') downloadDocs = `${item.activityType}, ${item.activityTime}`
        })

        return [
            { value: account.name },
            {
                value: account.status,
                style: (account.status === 'Remind' ? {bgColor: 'red'} : {})
            },
            { value: account.last_connect },
            { value:`${account.last_activity }, ${account.activity_action}`},
            { value: sentInvitation },
            { value: openedProject },
            { value: seenTeaser },
            { value: signedNDA },
            { value: downloadDocs },
            { value: account.comment || '' }
        ]
    })

    const multiDataSet = [
        {
            columns: [
                {value: 'Account name', widthPx: 356, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF' }}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Status', widthPx: 100, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Last connect', widthPx: 210, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Last activity', widthPx: 350, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Sent invitation', widthPx: 220, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Opened project', widthPx: 220, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Looked at teaser', widthPx: 220, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Signed NDA', widthPx: 220, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Downloaded documents', widthPx: 220, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
                {value: 'Comment', widthPx: 220, style: {font: {bold: true}, fill: { patternType: 'solid', fgColor: {rgb: 'EAF5DF'}}, border: {left:{style:'thin'}, right:{style:'thin'}, top:{style:'thin'}, bottom:{style:'thin'}}}},
            ],
            data: excelData
        }
    ]

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h3 className={styles.title}>Tracker</h3>
                <ExcelFile filename={`Report_${Date.now()}`} element={<button className={styles.actionFile} > Generate Report</button>}>
                    <ExcelSheet dataSet={multiDataSet} name="Tracker report"/>
                </ExcelFile>
            </div>
            <div className={styles.presentationWrapper}>
                {Object.keys(statisticData).length && (
                    <ul className={styles.statisticInfo}>
                        <li>
                            <span>{statisticData.total_amount}</span>
                            <h6>Total amount</h6>
                        </li>
                        <li>
                            <span>{statisticData.active}</span>
                            <h6>Active</h6>
                        </li>
                        <li>
                            <span>{statisticData.inactive}</span>
                            <h6>Inactive</h6>
                        </li>
                        <li>
                            <span>{statisticData.seen_teaser}</span>
                            <h6>Seen Teaser</h6>
                        </li>
                        <li>
                            <span>{statisticData.signed_nda}</span>
                            <h6>Signed NDA</h6>
                        </li>
                    </ul>
                )}
                <section className={styles.mainInfo}>
                    { accounts.length > 0 && <div className={styles.menuItems}>
                        <span style={{marginRight: '20%'}}>Account Name</span>
                        <span style={{marginRight: '5%'}}>Status</span>
                        <span style={{marginRight: '6%'}}>Last contacted</span>
                        <span style={{marginRight: '6%'}}>Last activity</span>
                        <span style={{marginRight: '6%'}}>Main activities</span>
                        <span>Contact</span>
                    </div> }
                    {accounts.length > 0 ? accounts.map(item => (
                        <AccountTrackerItem
                            key={item.account_id}
                            info={{
                                projectId,
                                accountId: item.account_id,
                                name: item.name,
                                status: item.status,
                                last_connect: item.last_connect,
                                last_activity: item.last_activity,
                                activity_action: item.activity_action,
                                main_statuses: item.main_statuses,
                                commentId: item.commentId,
                                comment: item.comment,
                                email: item.email
                            }}
                            updateInfo={updateInfo}
                            updateStatistic={updateStatistic}
                        />
                    )) :
                        <div className={styles.noContent}>
                    No info yet...
                        </div>}
                </section>
            </div>
        </div>

    )
}

Tracker.propTypes = {

}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(Tracker)