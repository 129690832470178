import React from 'react'

import NotificationList from '../../../Notification/containers/NotificationList'
import Header from '../../../UI/containers/Header/Header'

import styles from './NotificationsView.module.scss'

export default function NotificationsView() {
    return (
        <div className={styles.root}>
            <Header title="Notifications" />
            <NotificationList className={styles.list}/>
        </div>
    )
}

