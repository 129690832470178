import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './AddButton.module.scss'

export default function AddButton(props) {
    return (
        <button className={clsx(props.className, styles.root)} onClick={props.onClick}>
            <span className={styles.icon}>+</span>
            {props.text}
        </button>
    )
}

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string
}

AddButton.defaultProps = {
    text: ''
}


