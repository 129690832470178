import React from 'react'
import Avatar from 'react-avatar'
import { If, Then, Else } from 'react-if'
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'

import { ReactComponent as MiniArrowIcon } from '../../../../assets/icons/mini-arrow.svg'

import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/projects'

import { ReactComponent as ArrowTableIcon } from '../../../../assets/icons/mini-arrow-table.svg'

import styles from './AccessSellerAccordion.module.scss'
import Button from '../../../UI/containers/Button/Button'

export default function AccessSellerAccordion(props) {
    const handleUnassign = sellerId => {
        service.deleteSellerAccess(props.projectId, sellerId).then(() => {
            eventEmitter.emit(types.updateProjectList)
            eventEmitter.emit(types.updateProjectView)
        })
    }

    return (
        <Accordion
            className={styles.accordion}
            allowMultipleExpanded
            allowZeroExpanded
        >
            <AccordionItem
                className={styles.accordionItem}
                activeClassName={styles.accordionItemActive}
            >
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.accordionButton}>
                        <MiniArrowIcon className={styles.accordionIcon} />
                        Accessible for prosellers
                        <Button
                            bgColor="#9dce6b"
                            className={styles.inviteAction}
                            onClick={() =>
                                eventEmitter.emit(
                                    types.openSellerAccessModal,
                                    props.projectId
                                )
                            }
                        >
                            Invite
                        </Button>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.accordionPanel}>
                    <If condition={props.sellers.length !== 0}>
                        <Then>
                            {props.sellers.map(item => (
                                <div className={styles.accounts}>
                                    <Avatar
                                        key={item}
                                        className={styles.accountUser}
                                        name={item.fullName}
                                        src={item.photoLink}
                                        size="30"
                                        color="#d2e1df"
                                        textSizeRatio={1.75}
                                        round={true}
                                        fgColor="#9ab8ac"
                                    />
                                    {item.fullName}
                                    <Tooltip
                                        renderIcon={() => <ArrowTableIcon />}
                                        className={styles.actionMenu}
                                        id={`account-list-${item.id}`}
                                    >
                                        <TooltipItem
                                            onClick={() =>
                                                handleUnassign(item.id)
                                            }
                                        >
                                            Unassign
                                        </TooltipItem>
                                    </Tooltip>
                                </div>
                            ))}
                        </Then>
                        <Else>
                            <div className={styles.empty}>
                                <p className={styles.emptyDescription}>
                                    No ProSellers
                                </p>
                            </div>
                        </Else>
                    </If>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    )
}

AccessSellerAccordion.propTypes = {
    info: PropTypes.shape({
        accountId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        usersList: PropTypes.string.isRequired,
    }),
}
