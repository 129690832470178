import http from '../../../shared/http'

//mock.onPost('/createUser').reply(200, {
//    message: 'Ok'
//})

export function createUser(data) {
    return http.post('/users/admin/create', {
        shouldSendInvitation: !!data.shouldSendInvitation,
        role: data.userType,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        companyName: data.companyName,
        position: data.position,
        telephoneNumber: data.phone
    })
}

//mock.onPut(/\/users\/\w+/).reply(200,{
//    message:'Ok'
//})
    
export function editUser(userId, data) {
    return http.put(`/users/admin/${userId}`, 
        {
            shouldSendInvitation: !!data.shouldSendInvitation,
            role: data.userType,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            companyName: data.companyName,
            position: data.position,
            telephoneNumber: data.phone
        })
}

export function getUserInfo(userId) {
    return http.get(`/users/admin/${userId}`)
}

export function getUserProfile(userId) {
    return http.get(`/users/admin/${userId}/profile`)
}