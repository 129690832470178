import http from '../../../shared/http'

/*mock.onPut(/\/projects\/admin\/\w+/).reply(200,{
    message:'Ok'
})*/

export function createProject(data) {
    return http.post('/projects/admin', data)
}

export function editProject(projectId, data) {
    return http.put(`/projects/admin/${projectId}`, data)
}

export function createFolder(projectId, folderName) {
    return http.post(`/projects/${projectId}/folder`, {
        folderName
    })
}

export function loadFile(data) {
    return http.post('/projects/documents/file', data)
}

export function addAccess(projectId, data) {
    return http.post(`/projects/admin/${projectId}/assigned-accounts`, {
        accountId: data
    })
}

export function deleteAccess(projectId, data) {
    return http.delete(`/projects/admin/${projectId}/assigned-accounts`, {
        data: {
            accountId: data
        }
    })
}

export function sendComment(projectId, accountId, commentId = null, data) {
    return http.post(`/projects/${projectId}/comment`, {
        accountId,
        projectId,
        commentId,
        comment: data,
    })
}

export function updateAccountStatus({projectId, accountId, status}) {
    return http.post(`/projects/${projectId}/tracker/statuses`, {
        accountId,
        projectId,
        status,
    })
}

export function updateAccountLastConnect({projectId, accountId}) {
    return http.post(`/projects/${projectId}/tracker/last-connect`, {
        accountId,
        projectId
    })
}