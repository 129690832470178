import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ReactComponent as IconMiniArrow } from '../../../../assets/icons/mini-arrow.svg'

import styles from './Filter.module.scss'

export default function Filter(props) {

    const handleClick = () => {
        if (!props.value) return props.onClick('ASC')
        if (props.value === 'ASC') return props.onClick('DESC')
        if (props.value === 'DESC') return props.onClick('')
    }

    return (
        <button
            onClick={handleClick}
            className={clsx(styles.root, props.value && styles.active)}>
            {props.children}

            <IconMiniArrow className={clsx(styles.iconUp, props.value === 'ASC' && styles.iconActive)}/>
            <IconMiniArrow className={clsx(styles.iconDown, props.value === 'DESC' && styles.iconActive)}/>
        </button>
    )
}

Filter.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    value: PropTypes.string.isRequired // ASC DESC, ''
}
