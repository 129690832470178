import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import Avatar from 'react-avatar'
import { connect } from 'react-redux'
// import { toast } from 'react-toastify'

import Button from '../../../UI/containers/Button/Button'
import TagStatus from '../../../UI/containers/TagStatus/TagStatus'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'
import ContentContainer from '../../../UI/containers/Container/ContentContainer'

import UserAvatar from '../UserAvatar/UserAvatar'
import MiniCard from '../MiniCard/MiniCards'

import eyeImg from '../../../../assets/icons/eye.svg'
import questionImg from '../../../../assets/icons/question.svg'

import { TRoles, TRoutes, TStatusProject } from '../../../../shared/types'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/projects'

import styles from './Header.module.scss'
import Tag from '../../../UI/containers/Tag/Tag'

function Header(props) {
    const history = useHistory()

    const [_isChangingStatus, _setIsChangingStatus] = useState(false)

    const handleOpenOfferModal = e => {
        e.preventDefault()

        eventEmitter.emit(types.openOfferModal, {
            projectId: props.project.id,
            businessType: props.project.businessType,
        })
    }

    const handleOpenFeedbackModal = e => {
        e.preventDefault()

        eventEmitter.emit(types.openFeedbackModal, {
            projectId: props.project.id,
            businessType: props.project.businessType,
        })
    }

    const handleScroll = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        })
    }

    const _generateChangeStatusButtonText = ({status}) => {
        return status === TStatusProject.DRAFT ? 'Publish' : 'Draft'
    }

    const _canBePublished = ({hasNDATemplate, hasTeaser}) => {
        return hasNDATemplate && hasTeaser
    }

    const _handleStatusChangeClick = async () => {
        const {id, status} = props.project

        _setIsChangingStatus(true)
        const isPublishing = status === TStatusProject.DRAFT
        try {
            if (!isPublishing || _canBePublished({
                hasNDATemplate: props.hasNDATemplate,
                hasTeaser: props.hasTeaser
            })) {
                await service.projectPublish(id, isPublishing)
                eventEmitter.emit(
                    types.openNotificationModal,
                    isPublishing
                        ? {
                            header: 'Project Status',
                            message: `Project status successfully changed to ${TStatusProject.PUBLISHED}`,
                        }
                        : {
                            header: 'Project Status',
                            message: `Project status successfully changed to ${TStatusProject.DRAFT}`,
                        }
                )
                props.onChangeStatus(isPublishing ? TStatusProject.PUBLISHED : TStatusProject.DRAFT)
            } else {
                eventEmitter.emit(
                    types.openProjectPublishingModal,
                    {
                        header: 'The project is almost published!',
                        message: 'Please, provide the following information :',
                        projectId: props.project.id,
                        projectName: props.project.name,
                        noTeaser: !props.hasTeaser,
                        noNDATemplate: !props.hasNDATemplate,
                        onProjectAddNDATemplate: props.handleChangeNDATemplate,
                        onChangeTeaser:props.handleChangeTeaser
                    }
                )
            }
        } catch (error) {
            // toast.error(error.message)
            console.log(error.message)
        } finally {
            _setIsChangingStatus(false)
        }
    }

    return (
        <div className={styles.root}>
            <ContentContainer>
                <div className={styles.topRow}>
                    <div>
                        <VisibilityAccess
                            access={[
                                TRoles.SUPER_ADMIN,
                                TRoles.ADMIN,
                                TRoles.OWNER,
                            ]}
                        >
                            {({ role }) => (
                                <h3 className={styles.pageTitle}>
                                    {role === TRoles.ADMIN ||
                                    role === TRoles.SUPER_ADMIN
                                        ? 'Edit Project'
                                        : 'My project'}
                                </h3>
                            )}
                        </VisibilityAccess>
                        {TRoles.OWNER === props.role ? (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                                className={styles.actionBack}
                                onClick={e => {
                                    e.preventDefault()
                                    history.length
                                        ? history.goBack()
                                        : history.push(TRoutes.DASHBOARD)
                                }}
                            >
                                Go back
                            </a>
                        ) : (
                            <Link
                                to={TRoutes.PROJECTS}
                                className={styles.actionBack}
                            >
                                {TRoles.INVESTOR === props.role
                                    ? 'Go back to projects'
                                    : 'Go back'}
                            </Link>
                        )}
                    </div>
                    <VisibilityAccess
                        access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                    >
                        <UserAvatar
                            src={props.projectOwner.userPhotoLink}
                            name={`${props.projectOwner.firstName} ${props.projectOwner.lastName}`}
                        />
                    </VisibilityAccess>
                </div>
                <div className={styles.projectInfo}>
                    <Avatar
                        className={styles.projectAvatar}
                        size="165"
                        color="#d2e1df"
                        textSizeRatio={1.75}
                        name={props.project.name}
                        src={props.project.projectPhotoLink}
                        fgColor="#9ab8ac"
                    />
                    <div className={styles.projectContent}>
                        <div className={styles.projectTitleBlock}>
                            <div className={styles.infoWrapper}>
                                <h2 className={styles.projectTitle}>
                                    {props.project.name}
                                </h2>
                                <VisibilityAccess
                                    access={[
                                        TRoles.ADMIN,
                                        TRoles.SUPER_ADMIN,
                                        TRoles.OWNER,
                                    ]}
                                >
                                    <TagStatus
                                        className={styles.statusTag}
                                        status={props.project.status}
                                    />
                                </VisibilityAccess>
                                <VisibilityAccess
                                    access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                                >
                                    {props.project.type === 'M_AND_A' && (
                                        <Tag
                                            text="M & A"
                                            bgColor="#c3e9fb"
                                            textColor="#6D99D0"
                                        />
                                    )}
                                </VisibilityAccess>
                            </div>
                            <VisibilityAccess
                                access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                            >
                                <div className={styles.actionsWrapper}>
                                    <Button
                                        className={styles.actionEditProject}
                                        onClick={_handleStatusChangeClick}
                                        disabled={_isChangingStatus}
                                    >
                                        {_generateChangeStatusButtonText({
                                            status: props.project.status
                                        })}
                                    </Button>
                                    <Button
                                        className={styles.actionEditProject}
                                        onClick={() =>
                                            eventEmitter.emit(
                                                types.openProjectModal,
                                                {
                                                    ...props.project,
                                                    projectId: props.project.id,
                                                    projectOwnerFullName:
                                                        props.projectOwner
                                                            .firstName +
                                                        ' ' +
                                                        props.projectOwner.lastName,
                                                }
                                            )
                                        }
                                    >
                                        Edit Info
                                    </Button>
                                </div>
                            </VisibilityAccess>
                            <VisibilityAccess access={[TRoles.INVESTOR]}>
                                <UserAvatar
                                    className={styles.projectUserAvatar}
                                    name={`${props.projectOwner.firstName} ${props.projectOwner.lastName}`}
                                    src={props.projectOwner.userPhotoLink}
                                />
                            </VisibilityAccess>
                        </div>
                        <div className={styles.businessBlock}>
                            <div>
                                <span className={styles.businessType}>
                                    {props.project.businessType}
                                </span>
                                <p className={styles.businessDescription}>
                                    {props.project.description}
                                </p>
                            </div>
                            <VisibilityAccess access={[TRoles.INVESTOR]}>
                                <div className={styles.investorActionsWrapper}>
                                    <Button
                                        className={styles.actionMakeOffer}
                                        onClick={handleOpenOfferModal}
                                    >
                                        Make an offer
                                    </Button>
                                    <div className={styles.userActions}>
                                        <Button
                                            bgColor="#fff"
                                            className={styles.userAction}
                                            onClick={handleScroll}
                                        >
                                            Ask a question
                                        </Button>
                                        <Button
                                            bgColor="#fff"
                                            className={styles.userAction}
                                            onClick={handleOpenFeedbackModal}
                                        >
                                            Leave Feedback
                                        </Button>
                                    </div>
                                </div>
                            </VisibilityAccess>
                            <VisibilityAccess
                                access={[
                                    TRoles.SUPER_ADMIN,
                                    TRoles.ADMIN,
                                    TRoles.OWNER,
                                ]}
                            >
                                <div className={styles.statisticCards}>
                                    <MiniCard
                                        className={styles.statisticCard}
                                        path={eyeImg}
                                    >
                                        {props.statistics &&
                                            props.statistics.views}
                                    </MiniCard>
                                    {props.project.type !== 'M_AND_A' && (
                                        <MiniCard path={questionImg}>
                                            {props.statistics &&
                                                props.statistics.questions}
                                        </MiniCard>
                                    )}
                                </div>
                            </VisibilityAccess>
                            <VisibilityAccess access={[TRoles.PRO_BUYER]}>
                                {props.showNDA && (
                                    <div
                                        className={
                                            styles.investorActionsWrapper
                                        }
                                    >
                                        <Button
                                            className={styles.actionMakeOffer}
                                            onClick={() =>
                                                eventEmitter.emit(
                                                    types.openNdaModal,
                                                    {
                                                        projectId:
                                                            props.project.id,
                                                        showNDA: true,
                                                    }
                                                )
                                            }
                                        >
                                            online NDA sign request
                                        </Button>

                                        <div className={styles.userActions}>
                                            <Button
                                                bgColor="#fff"
                                                className={styles.userAction}
                                                onClick={() =>
                                                    eventEmitter.emit(
                                                        types.openOfflineNdaModal,
                                                        {
                                                            project:
                                                                props.project,
                                                        }
                                                    )
                                                }
                                            >
                                                offline NDA sign request
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </VisibilityAccess>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

Header.propTypes = {
    role: PropTypes.string.isRequired,
    projectOwner: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        userPhotoLink: PropTypes.string,
    }).isRequired,
    project: PropTypes.shape({
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        projectPhotoLink: PropTypes.string.isRequired,
        businessType: PropTypes.string.isRequired,
    }).isRequired,
    statistics: PropTypes.shape({
        views: PropTypes.number.isRequired,
        questions: PropTypes.number.isRequired,
    }),
    showNDA: PropTypes.bool,
    hasTeaser: PropTypes.bool.isRequired,
    hasNDATemplate: PropTypes.bool.isRequired,
    onChangeStatus: PropTypes.func.isRequired,
    handleChangeNDATemplate: PropTypes.func.isRequired,
    handleChangeTeaser: PropTypes.func.isRequired
}

Header.defaultProps = {
    showNDA: false,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(Header)
