import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import RSelect from 'react-select'
import { Controller } from 'react-hook-form'

import stylesField from './TextField.module.scss'
import styles from './Select.module.scss'

export default function Select(props) {
    const getValue = value => {
        if (!value) return value
        return props.options.find(item => value === item.value)
    }

    return (
        <label className={clsx(stylesField.root, props.className)}>
            {props.label && (
                <span className={stylesField.label}>
                    {props.label}
                    {props.isRequired && (
                        <span className={stylesField.required}>*</span>
                    )}
                </span>
            )}

            <Controller
                name={props.name}
                defaultValue=""
                control={props.control}
                render={({ onChange, value, ...innerProps }) => (
                    <RSelect
                        {...innerProps}
                        className={clsx(
                            styles.select,
                            props.classNameSelect,
                            !!props.error && styles.inputError
                        )}
                        classNamePrefix="select"
                        options={props.options}
                        isDisabled={props.disabled}
                        placeholder={props.placeholder}
                        components={props.components}
                        onChange={value => {
                            if (props.onChange) props.onChange(value.value)
                            if (value) return onChange(value.value)
                            onChange(value)
                        }}
                        value={getValue(value)}
                    />
                )}
            />
            {props.error && (
                <span className={stylesField.errorMessage}>
                    {props.error.message}
                </span>
            )}
        </label>
    )
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    control: PropTypes.object.isRequired,

    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }),

    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired
    ).isRequired,

    label: PropTypes.string,
    placeholder: PropTypes.string,
}

Select.defaultProps = {
    label: '',
    placeholder: '',
    className: '',
    isDisabled: false,
}
