import http from '../../../shared/http'

//mock.onGet('/feedbacks').reply(200, {
//    status: 'ok',
//    count: 55,
//    feedbacks: new Array(5).fill('').map(() => ({
//        time: '12:02',
//        date: ' Sep, 06, 2020',
//        feedback:'In general Pellentesque porttitor ut diam ac molestie. Curabitur rutrum tortor nisi, non accumsan nibh iaculis at. Mauris at lorem placerat, lobortis leo quis, tincidunt purus. Vestibulum sed augue eu eros mollis ullamcorper. Donec tincidunt neque ac tellus ultrices tempus. Integer pharetra orci quis pulvinar pharetra. Duis facilisis ultricies nisi, sit amet tincidunt sem accumsan ac. Nulla laoreet laoreet massa, a tempus sapien egestas at. Aenean gravida quam id nulla vestibulum, eu venenatis turpis hendrerit. Cras ex mauris, iaculis eu elit sit amet, rutrum imperdiet nunc. ',
//    }))
//})


export function getFeedbacks(params) {
    return http.get('/users/me/feedback', { params })
}

export function changeFeedbackStatus(feedbackId, data) {
    return http.put(`/feedback/admin/${feedbackId}/status`, {
        visibility: data,
    })
}