import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { connect } from 'react-redux'

import Modals from './modules/Modals/containers/Modals'

import Routes from './Routers'

import {
    setShouldShowDisclaimer,
    setUserInfo,
    setCountAllMenu,
} from './redux/ducks/user'

import history from './shared/history'

import http from './shared/http'

import socketChat from './shared/socketChat'

import 'normalize.css/normalize.css'
import 'react-phone-input-2/lib/style.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-accessible-accordion/dist/fancy-example.css'

import styles from './App.module.scss'
import { TRoles } from './shared/types'
import Toast from './modules/UI/containers/Toast/Toast'

function App(props) {
    useEffect(() => {
        if (props.isAuth && !props.name) {
            http.get('/users/me').then(({ data }) => {
                props.setUserInfo(data.userProfile)
                props.setShouldShowDisclaimer(data.shouldShowDisclaimer)

                if (
                    [
                        TRoles.ADMIN,
                        TRoles.SUPER_ADMIN,
                        TRoles.PRO_SELLER,
                        TRoles.OWNER,
                    ].includes(props.role)
                ) {
                    socketChat.connect()
                }
            })

            if (
                [TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.OWNER, TRoles.PRO_SELLER].includes(
                    props.role
                )
            )
                http.get('/notifications/menu-count').then(({ data }) => {
                    props.setCountAllMenu(data.counts)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Router history={history}>
            <div className={styles.app}>
                <Routes />
                <Toast />
                <Modals />
            </div>
        </Router>
    )
}

App.propTypes = {
    isAuth: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,

    setUserInfo: PropTypes.func.isRequired,
    setShouldShowDisclaimer: PropTypes.func.isRequired,
    setCountAllMenu: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    name: state.user.userProfile.firstName,
    isAuth: state.user.isAuth,
    role: state.user.role,
})

const mapDispatchToProps = dispatch => ({
    setUserInfo: data => dispatch(setUserInfo(data)),
    setShouldShowDisclaimer: value => dispatch(setShouldShowDisclaimer(value)),
    setCountAllMenu: value => dispatch(setCountAllMenu(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
