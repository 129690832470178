import http from '../../../shared/http'

export function getAllNotifications({ limit, offset }) {
    return http.get('/notifications', {
        params: {
            limit,
            offset
        }
    })
}

export function markSeenNotifications(ids) {
    return http.put('/notifications/mark-seen', {
        ids
    })
}
