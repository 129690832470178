import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import clsx from 'clsx'

import TextField from '../../../UI/containers/Field/TextField'
import Button from '../../../UI/containers/Button/Button'

import styles from './SignUpForm.module.scss'

export default function SignUpFormPassword(props) {
    const [isLoading, setLoading] = useState(false)

    const { register, handleSubmit, errors } = useForm({
        resolver: zodResolver(
            z
                .object({
                    password: z
                        .string()
                        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, { message: 'Password must be at least 8 characters that contain at least 1 uppercase and 1 lowercase characters, number and special character' }),
                    repeatPassword: z
                        .string()
                        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, { message: 'Password must be at least 8 characters that contain at least 1 uppercase and 1 lowercase characters, number and special character' }),
                })
                .refine(data => data.password === data.repeatPassword, {
                    message: 'Passwords don\'t match',
                    path: ['repeatPassword'],
                })
        ),
    })

    const onSubmit = data => {
        setLoading(true)
        props.onSubmit(data).finally(() => setLoading(false))
    }

    return (
        <div>
            <p className={styles.pleaText}>
                Welcome, {props.name}, your invitation has been confirmed
            </p>
            <p className={styles.description}>
                Please create a password for your account to continue
            </p>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <TextField
                    className={styles.field}
                    error={errors.password}
                    isPassword
                    label="Password"
                    name="password"
                    ref={register}
                    placeholder="Your password"
                />
                <TextField
                    className={styles.field}
                    isPassword
                    error={errors.repeatPassword}
                    label="Repeat password"
                    name="repeatPassword"
                    ref={register}
                    placeholder="Repeat password"
                />
                <div
                    className={clsx(styles.actions, styles.formPasswordActions)}
                >
                    <Button
                        className={clsx(styles.action)}
                        isLoading={isLoading}
                        bgColor="#9dce6c"
                        type="submit"
                    >
                        Continue
                    </Button>
                </div>
            </form>
        </div>
    )
}

SignUpFormPassword.propTypes = {
    name: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
