import React from 'react'
import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import styles from './CreateNDAModal.module.scss'
import { types } from '../../../../shared/eventEmitter'

export default function CreateNDAModal() {
    return (
        <BaseModal nameEvent={types.openCreateNdaModal}>
            {() => (
                <>
                    <ModalHeader title="Create NDA"/>
                    <div className={styles.content}>
                        {/*your code*/}
                    </div>
                    <div className={styles.footer}>
                        {/*Button:delete user*/}
                        {/*Button:save changes*/}
                    </div>
                </>
            )}
        </BaseModal>
    )
}
