import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/project'

import styles from './FileUploadModal.module.scss'
import Select from '../../../UI/containers/Field/Select'
import UploadButton from '../../../UI/containers/UploadButton/UploadButton'
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg'

export default function FileUploadModal() {
    const [isLoading, setLoading] = useState(false)
    const [folders, setFolders] = useState([])

    const { register, handleSubmit, control, errors, reset } = useForm({
        resolver: zodResolver(
            z.object({
                folderId: z.any().refine(data => !!data, {
                    message: 'Not select folder',
                }),
                file: z.any().refine(data => !!data.length, {
                    message: 'Not file',
                }),
            })
        ),
    })

    const handleOpen = ({ folders }) => {
        setFolders(folders)
    }

    const onSubmit = closeModal => data => {
        const formData = new FormData()

        formData.append('folderId', data.folderId)
        formData.append('file', data.file[0])

        setLoading(true)
        service
            .loadFile(formData)
            .then(() => {
                setLoading(false)
                eventEmitter.emit(types.openNotificationModal, {
                    header: 'File Upload',
                    message: 'File successfully uploaded',
                })
                reset()
                closeModal()
                eventEmitter.emit(types.updateFolderList)
            })
            .finally(() => setLoading(false))
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            nameEvent={types.openFileModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title="File upload"
                    />
                    <div className={styles.container}>
                        <form
                            onSubmit={handleSubmit(onSubmit(closeModal))}
                            autoComplete="off"
                        >
                            <Select
                                error={errors.folderId}
                                control={control}
                                label="File upload folder"
                                name="folderId"
                                placeholder="File upload folder"
                                options={folders.map(folder => ({
                                    value: folder.id,
                                    label: folder.name,
                                }))}
                            />
                            <UploadButton
                                error={errors.file}
                                name="file"
                                register={register}
                            >
                                {({ handleClick, isFileLoaded }) => (
                                    <Button
                                        renderIcon={() =>
                                            isFileLoaded ? (
                                                <CheckIcon
                                                    className={
                                                        styles.fileUploadActionIcon
                                                    }
                                                />
                                            ) : null
                                        }
                                        onClick={handleClick}
                                        disabled={isLoading}
                                        bgColor="#9dce6c"
                                        className={styles.fileUploadAction}
                                    >
                                        Upload File
                                    </Button>
                                )}
                            </UploadButton>
                            <div className={styles.actions}>
                                <Button
                                    onClick={() => closeModal()}
                                    bgColor="#ffffff"
                                    className={clsx(
                                        styles.action,
                                        styles.actionCancel
                                    )}
                                    disabled={isLoading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={styles.action}
                                    isLoading={isLoading}
                                    type="submit"
                                >
                                    Done
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
