import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { If, Then, Else } from 'react-if'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { zodResolver } from '@hookform/resolvers/zod'

import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

import NdaInvestor from '../../components/NdaInvestor/NdaInvestor'
import TeaserAccordion from '../../components/TeaserAccordion/TeaserAccordion'

import QuestionAnswers from '../QuestionsAnswers/QuestionsAnswers'

import { TRoles } from '../../../../shared/types'

import * as services from '../../services/projects'

import styles from './Teaser.module.scss'
import Feedbacks from '../Feedbacks/Feedbacks'
import Offers from '../Offers/Offers'
import ResponsibleManager from '../ResponsibleManager/ResponsibleManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
function Teaser(props) {
    const [url, setUrl] = useState('')
    const [urlSource, setUrlSource] = useState('')
    const [isCreateMode, setCreateMode] = useState(false)

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: zodResolver(
            z.object({
                url: z.string().url(),
                urlSource: z.string().url(),
            })
        )
    })
    const onSubmit = data => {
        const preParsedUrl = data.url.replace('/pub', '/embed').split('/')
        preParsedUrl.pop()
        const parsedUrl = `${preParsedUrl.join('/')}/preview`

        const teaser = {
            sourceUrl: data.urlSource,
            previewUrl: parsedUrl,
        }
        setCreateMode(false)
        reset()

        services.editTeaser(props.projectId, teaser).then(() => {
            props.onChange(teaser)
        })
    }

    const handleCancel = e => {
        e.preventDefault()
        reset()
        setCreateMode(false)
    }

    const handleEditTeaser = () => {
        setCreateMode(true)
    }

    useEffect(() => {
        setUrl(props.teaser.previewUrl || '')
        setUrlSource(props.teaser.sourceUrl || '')
    }, [props.teaser])

    if (!props.projectId) return null

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h3 className={styles.title}>Teaser</h3>
                <div className={styles.headerActions}>
                    <VisibilityAccess
                        access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                    >
                        {url && (
                            <Button
                                onClick={handleEditTeaser}
                                className={clsx(
                                    styles.action,
                                    styles.editTeaser
                                )}
                            >
                                Edit teaser
                            </Button>
                        )}
                    </VisibilityAccess>
                    <VisibilityAccess
                        access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                    >
                        {urlSource && (
                            <a
                                rel="noreferrer noopener"
                                href={urlSource}
                                target="_blank"
                                className={clsx(
                                    styles.action,
                                    styles.actionSmall
                                )}
                            >
                                Open Teaser
                            </a>
                        )}
                    </VisibilityAccess>
                </div>
            </div>
            <div className={styles.presentationWrapper}>
                <If condition={isCreateMode}>
                    <Then>
                        <form
                            className={styles.form}
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off"
                        >
                            <TextField
                                className={styles.field}
                                error={errors.url}
                                label="Preview url"
                                name="url"
                                placeholder="Url presentation"
                                ref={register}
                            />
                            <TextField
                                error={errors.url}
                                label="Source url"
                                name="urlSource"
                                placeholder="Source url presentation"
                                ref={register}
                            />
                            <div className={styles.formActions}>
                                <span className={styles.tooltip}>
                                    <FontAwesomeIcon icon={faQuestionCircle} size='lg' style={{color: '#9dce6c'}} data-tip data-for='helper'/>
                                    <ReactTooltip id='helper' place='bottom' effect='solid' backgroundColor='#9dce6c'>
                                        <span>Steps to set up a teaser: </span>
                                        <ul>
                                            <li>upload your file to Google-drive</li>
                                            <li>define access options for chosen file and copy "share" link</li>
                                            <li>paste the link into fields above</li>
                                            <li>press button "Save"</li>
                                        </ul>
                                    </ReactTooltip>
                                </span>

                                <Button
                                    onClick={handleCancel}
                                    bgColor="rgb(212, 144, 144)"
                                    className={styles.action}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className={clsx(
                                        styles.action,
                                        styles.actionSaveUrl
                                    )}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </Then>
                    <Else>
                        <If condition={!!url}>
                            <Then>
                                <iframe
                                    title="Google presentation"
                                    className={styles.presentationFrame}
                                    src={url}
                                    frameBorder="0"
                                    allowFullScreen={true}
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                />
                            </Then>
                            <Else>
                                <p className={styles.descriptionNotTeaser}>
                                    Project teaser is not created yet
                                </p>
                                <VisibilityAccess
                                    access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                                >
                                    <a
                                        onClick={() => setCreateMode(true)}
                                        href="https://docs.google.com/presentation/u/0/"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                        className={clsx(
                                            styles.action,
                                            styles.actionSmall
                                        )}
                                    >
                                        Create Teaser
                                    </a>
                                </VisibilityAccess>
                            </Else>
                        </If>
                    </Else>
                </If>
            </div>
            <TeaserAccordion
                projectId={props.projectId}
                nda={props.nda}
                projectOwner={props.projectOwner}
                showDocuments={props.showDocuments}
            />
            <VisibilityAccess access={[TRoles.INVESTOR, TRoles.PRO_BUYER]}>
                <NdaInvestor nda={props.nda} />
            </VisibilityAccess>
            <VisibilityAccess
                access={[TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.PRO_BUYER]}
            >
                {props.type === 'M_AND_A' && (
                    <ResponsibleManager
                        projectId={props.projectId}
                        projectOwner={props.projectOwner}
                    />
                )}
            </VisibilityAccess>

            <VisibilityAccess
                access={[TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.INVESTOR]}
            >
                {props.type === 'REGULAR' && (
                    <QuestionAnswers
                        projectOwner={props.projectOwner}
                        projectId={props.projectId}
                    />
                )}
            </VisibilityAccess>
            <VisibilityAccess access={[TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.INVESTOR]}>
                {props.type === 'REGULAR' && (
                    <Feedbacks feedback={props.feedback} />
                )}
                {props.type === 'REGULAR' && (
                    <Offers projectId={props.projectId} role={props.role} />
                )}
            </VisibilityAccess>
        </div>
    )
}

Teaser.propTypes = {
    role: PropTypes.string.isRequired,

    projectId: PropTypes.string.isRequired,

    projectOwner: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        userPhotoLink: PropTypes.string,
    }).isRequired,
    
    showDocuments: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
    teaser: PropTypes.shape({
        previewUrl: PropTypes.string,
        sourceUrl: PropTypes.string,
    }),
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(Teaser)
