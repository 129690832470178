import React, { useState, useEffect } from 'react'
import { If, Then, Else } from 'react-if'

import Paginate from '../../UI/containers/Paginate/Paginate'
import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'

import QuestionItem from '../compoents/QuestionsItem'

import { ITEMS_PER_PAGE } from '../../../shared/config'

import * as service from '../services/questions'

import styles from './QuestionsOwnerView.module.scss'

export default function QuestionsOwnerView() {
    const [questions, setQuestions] = useState([])
    const [page, setPage] = useState(0)
    const [questionsCount, setQuestionsCount] = useState(0)

    const fetchQuestions = page => {
        service
            .getQuestionsOwner({
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
            })
            .then(({ data }) => {
                setQuestions(data.questions)
                setQuestionsCount(data.count)
            })
    }

    const handleAnswer = question => {
        const questionsNew = questions.slice()
        const index = questionsNew.findIndex(item => item.id === question.id)

        questionsNew[index] = {
            ...questionsNew[index],
            ...question,
        }

        setQuestions(questionsNew)
    }

    const handlePageChange = ({ selected }) => {
        setPage(selected)
        fetchQuestions(selected)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        fetchQuestions(page)
    }, [page])

    return (
        <div className={styles.root}>
            <Header title="Questions & Answers" />
            <ContentContainer>
                <div className={styles.questionsList}>
                    <If condition={questions.length !== 0}>
                        <Then>
                            {questions.map(item => (
                                <QuestionItem
                                    onAnswer={handleAnswer}
                                    question={{
                                        id: item.id,
                                        answer: item.answer,
                                        answeredAt: item.answeredAt,
                                        createdAt: item.createdAt,
                                        question: item.question,
                                        investorFullName: `${item.investor.firstName} ${item.investor.lastName}`,
                                    }}
                                />
                            ))}
                        </Then>
                        <Else>
                            <div className={styles.empty}>
                                <p className={styles.emptyDescription}>
                                    You have no questions at this time
                                </p>
                            </div>
                        </Else>
                    </If>
                </div>
                <Paginate
                    forcePage={page}
                    pageCount={Math.ceil(questionsCount / ITEMS_PER_PAGE)}
                    onPageChange={handlePageChange}
                />
            </ContentContainer>
        </div>
    )
}
