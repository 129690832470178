import React, { useState, useEffect } from 'react'

import CardValue from '../components/CardValue'

import UsersList from '../../PreviewList/containers/UsersList'
import AccountsList from '../../PreviewList/containers/AccountsList'
import NotificationsListMini from '../../PreviewList/containers/NotificationsListMini/NotificationsListMini'

import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'

import * as services from '../services/dashboard'

import styles from './ViewAdmin.module.scss'
import stylesView from './View.module.scss'
export default function ViewAdmin() {
    const [countOwner, setCountOwner] = useState(0)
    const [countInvestor, setCountInvestor] = useState(0)
    const [countPublishProject, setCountPublishProject] = useState(0)
    const [countAccount, setCountAccount] = useState(0)

    useEffect(() => {

        services.getStatisticDashboard().then(({ data }) => {
            setCountPublishProject(data.statistic.countPublishProject)
            setCountInvestor(data.statistic.countInvestor)
            setCountOwner(data.statistic.countBusinessOwner)
            setCountAccount(data.statistic.countAccount)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={stylesView.root}>
            <Header title="Overview" />
            <ContentContainer>
                <div className={stylesView.topRow}>
                    <NotificationsListMini />
                    <div className={styles.cards}>
                        <CardValue
                            className={styles.card}
                            title="Investors"
                            value={countInvestor}
                        />
                        <CardValue
                            className={styles.card}
                            title="Business Owners"
                            value={countOwner}
                        />
                        <CardValue
                            className={styles.card}
                            title="Accounts"
                            value={countAccount}
                        />
                        <CardValue
                            className={styles.card}
                            title="Visible Projects"
                            value={countPublishProject}
                        />
                    </div>
                </div>
                <div className={stylesView.bottomRow}>
                    <UsersList className={stylesView.usersList} />
                    <AccountsList />
                </div>
            </ContentContainer>
        </div>
    )
}
