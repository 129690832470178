import React, { useState } from 'react'
import clsx from 'clsx'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import { ReactComponent as AcceptIcon } from '../../../../assets/icons/accept.svg'
import Button from '../../../UI/containers/Button/Button'

import { types } from '../../../../shared/eventEmitter'

import styles from './NotificationModal.module.scss'

export default function NotificationModal(props) {
    const [headerTitle, setHeaderTitle] = useState('')
    const [messageText, setMessageText] = useState('')
    const [headerText, setHeaderText] = useState('')

    const handleOpen = ({ header, message, subtitle }) => {
        setHeaderTitle(header)
        setHeaderText(subtitle)
        setMessageText(message)
    }

    const handleClick = closeModal => {
        closeModal()
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={clsx(styles.modal, props.classModalName)}
            classActionClose={styles.close}
            nameEvent={types.openNotificationModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title={headerTitle}
                        titleClassName={styles.headerTitle}
                    >
                        {headerText && (
                            <p className={styles.codemaster}>{headerText}</p>
                        )}
                    </ModalHeader>
                    <form autoComplete="off">
                        <div className={styles.container}>
                            <div className={styles.acceptIcon}>
                                <AcceptIcon />
                            </div>
                            <div className={styles.message}>{messageText}</div>
                        </div>
                        <div className={styles.actionDone}>
                            <Button
                                onClick={() => handleClick(closeModal)}
                                className={clsx(styles.action)}
                            >
                                Done
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
