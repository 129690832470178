import PropTypes from 'prop-types'
import { connect } from 'react-redux'

function VisibilityAccess(props) {
    if (props.access.includes(props.role)) {
        if (typeof props.children === 'function') {
            return props.children({ role: props.role })
        } else return props.children
    } else return null
}

VisibilityAccess.propTypes = {
    access: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    role: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.func
    ]).isRequired
}


const mapStateToProps = state => ({
    role: state.user.role
})

export default connect(mapStateToProps)(VisibilityAccess)
