import React, { useState } from 'react'
import clsx from 'clsx'
import Button from '../../../UI/containers/Button/Button'

import * as service from '../../services/project'

import styles from './ProjectAccessModal.module.scss'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

export default function AccountItem({ info }) {
    const [access, setAccess] = useState(true)

    const handleAdd = () => {
        if (access) {
            service.addAccess(info.projectId, info.accountId).then(() => {
                eventEmitter.emit(types.updateProjectList)
                eventEmitter.emit(types.updateProjectView)
            })
            setAccess(!access)
        } else {
            service.deleteAccess(info.projectId, info.accountId).then(() => {
                eventEmitter.emit(types.updateProjectList)
                eventEmitter.emit(types.updateProjectView)
            })
            setAccess(!access)
        }
    }

    return (
        <div className={styles.accountItem}>
            <span>{info.name}</span>
            <Button
                bgColor={access ? '#9dce6c' : '#ffffff'}
                className={
                    access
                        ? styles.addBtn
                        : clsx(styles.addBtn, styles.removeBtn)
                }
                onClick={handleAdd}
            >
                {access ? 'Add' : 'Remove'}
            </Button>
        </div>
    )
}
