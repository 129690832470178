import React from 'react'

import Logo from '../Logo/Logo'

import styles from './Footer.module.scss'
import stylesLanding from '../Landing.module.scss'

export default function Footer() {
    return (
        <footer className={styles.root}>
            <div className={stylesLanding.container}>
                <div className={styles.topRow}>
                    <Logo/>
                    <div className={styles.socials}>
                        <a className={styles.social} href="https://www.linkedin.com/company/97871" rel="noreferrer noopener" target="_blank">in</a>
                    </div>
                </div>
                <div className={styles.bottomRow}>
                    <p className={styles.rights}>© ATRIUM Partners A/S. All rights reserved.</p>
                    <div>
                        <a className={styles.link} href="https://www.atriumpartners.dk/en/privacy-policy/" rel="noreferrer noopener" target="_blank">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
