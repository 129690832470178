import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import styles from './UploadButton.module.scss'

export default function UploadButton(props) {
    const hiddenFileInput = React.useRef(null)
    const [isFileLoaded, setFileLoaded] = useState(false)

    const handleClick = e => {
        e.preventDefault()
        hiddenFileInput.current.click()
    }

    const handleChange = event => {
        const fileUploaded = event.target.files[0]
        if (props.fileSize && fileUploaded.size > props.fileSize) {
            toast.warning('Please upload a file smaller than 3 MB')
            return false
        }
        props.onFiles && props.onFiles(fileUploaded)
        setFileLoaded(true)
    }

    return (
        <>
            {props.children({ handleClick, isFileLoaded })}
            <input
                key={props.keyInput}
                type="file"
                name={props.name}
                accept={props.accept}
                size="24"
                ref={ref => {
                    hiddenFileInput.current = ref
                    props.register && props.register(ref)
                }}
                onChange={handleChange}
                style={{ display: 'none' }}
            />

            {props.error && (
                <span className={styles.errorMessage}>
                    {props.error.message}
                </span>
            )}
        </>
    )
}

UploadButton.propTypes = {
    onFiles: PropTypes.func,
    name: PropTypes.string,
    children: PropTypes.func.isRequired,
    register: PropTypes.func,
    accept: PropTypes.string,
    error: PropTypes.object,
    keyInput: PropTypes.number,
}

UploadButton.defaultProps = {
    accept: '*',
    name: '',
    fileSize: null
}
