import React, { useState } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
// import { toast } from 'react-toastify'
import { Scrollbars } from 'react-custom-scrollbars'
import HelloSign from 'hellosign-embedded'
import DOMPurify from 'dompurify'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import * as service from '../../services/nda'
import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import styles from './NdaModal.module.scss'

function NdaModal() {
    const [projectId, setProjectId] = useState(null)
    const [businessType, setBusinessType] = useState('')
    const [disclosingParty, setDisclosingParty] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [receivingParty, setReceivingParty] = useState('')
    const [body, setBody] = useState('')

    const handleSubmit = closeModal => {
        setLoading(true)
        service
            .generateUrl(projectId)
            .then(({ data }) => {

                const client = new HelloSign()
                client.open(data.signingUrl, {
                    clientId: '57b50456d01db9510d7e92bc3970c594',
                    // skipDomainVerification: true,
                    timeout: 10000,
                })
                client.on('sign', data => {
                    if (data.signatureId) {
                        eventEmitter.emit(types.openPostNdaModal, {
                            projectId: projectId,
                        })
                    }
                })
                closeModal()
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
    }

    const handleDecline = e => {
        e.preventDefault()
    }

    const handleOpen = ({ projectId, businessType }) => {
        setLoading(false)
        setProjectId(projectId)
        setBusinessType(businessType)
        service.getNdaInfo(projectId).then(({ data }) => {
            setDisclosingParty(data.data.businessOwnerDetails)
            setReceivingParty(data.data.investorDetails)
            setBody(data.data.documentBody)
        })
    }

    return (
        <BaseModal
            disabledBgClose
            disabledCrossClose
            classModalName={styles.modal}
            nameEvent={types.openNdaModal}
            onOpen={handleOpen}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title="Non Disclosure Agreement(NDA)"
                        titleClassName={styles.headerTitle}
                    >
                        <p className={styles.codemaster}>{businessType}</p>
                    </ModalHeader>
                    <div className={styles.infoRow}>
                        <div className={styles.disclosing}>
                            <h3 className={styles.partyTitle}>
                                DISCLOSING PARTY
                            </h3>
                            <p className={styles.partyText}>
                                {disclosingParty}
                            </p>
                        </div>
                        <div className={styles.receiving}>
                            <h3 className={styles.partyTitle}>
                                RECEIVING PARTY
                            </h3>
                            <p className={styles.partyText}>{receivingParty}</p>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <Scrollbars
                            className={styles.wrapperContent}
                            renderThumbVertical={({ style, ...props }) => (
                                <div
                                    style={{
                                        ...style,
                                        backgroundColor: '#587b77',
                                    }}
                                    {...props}
                                />
                            )}
                        >
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(body),
                                }}
                            />
                        </Scrollbars>
                        <div className={styles.row}>
                            <Button
                                className={clsx(
                                    styles.leaveBtn,
                                    styles.continueBtn
                                )}
                                bgColor="#ffffff"
                                onClick={e => {
                                    closeModal()
                                    handleDecline(e)
                                }}
                            >
                                Decline
                            </Button>

                            <Button
                                className={styles.continueBtn}
                                isLoading={isLoading}
                                bgColor="#9dce6c"
                                onClick={() => handleSubmit(closeModal)}
                            >
                                Sign
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </BaseModal>
    )
}

const mapStateToProps = state => ({
    userProfile: {
        firstName: state.user.userProfile.firstName,
        lastName: state.user.userProfile.lastName,
        town: state.user.userProfile.town,
        address: state.user.userProfile.address,
        zipCode: state.user.userProfile.zipCode,
    },
})

export default connect(mapStateToProps)(NdaModal)
