import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { useParams } from 'react-router-dom'
import { parseISO, format as formatDate } from 'date-fns'

import ChatMessage from '../../components/ChatMessage/ChatMessage'
import ChatForm from '../ChatForm/ChatForm'

import Button from '../../../UI/containers/Button/Button'

import * as service from '../../services/chat'

import socketChat from '../../../../shared/socketChat'
import { ITEMS_PER_PAGE } from '../../../../shared/config'
import { addPropToObject } from '../../../../shared/helpers'

import { setCountAllUnreadMessage } from '../../../../redux/ducks/user'

import { ReactComponent as ChatIcon } from '../../../../assets/icons/chat.svg'
import styles from './ProjectChat.module.scss'
import { TRoles } from '../../../../shared/types'

function ProjectChat(props) {
    const [isOpenChat, setOpenChat] = useState(false)
    const [countMessages, setCountMessages] = useState(0)
    const [countUnreadMessages, setCountUnreadMessages] = useState(0)
    const [messages, setMessages] = useState([])
    const scrollbarsRef = useRef()
    const { id: projectId } = useParams()

    const fetchData = () => {
        return service.getMessages({
            ...addPropToObject('projectId', projectId),
            offset: messages.length,
            limit: ITEMS_PER_PAGE
        })
            .then(({ data }) => {
                setCountMessages(data.count)
                setCountUnreadMessages(data.unreadCount)

                const allMessages = data.messages.reverse().concat(messages)
                if(allMessages.length) setMessages(allMessages)
            })
    }

    const onMessage = message => {
        setCountMessages(countMessages + 1)
        !isOpenChat ? setCountUnreadMessages(countUnreadMessages + 1) : markReadMessage()
        setMessages(messages.concat(message))
    }

    const handleLoadMore = evt => {
        evt.preventDefault()
        fetchData()
    }

    const handleNewMessage = ({ message }) => {
        if (props.role === TRoles.OWNER) return onMessage(message)
        if (message.projectId === projectId) return onMessage(message)
    }

    const markReadMessage = () => {
        service.markReadMessage({
            ...addPropToObject('projectId', projectId)
        }).then(({ data }) => {
            setCountUnreadMessages(0)
            props.setCountAllUnreadMessage(data.unreadCountAll)
        })
    }

    useEffect(() => {
        if (isOpenChat && countUnreadMessages) markReadMessage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenChat])

    useEffect(() => {
        setMessages([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    useEffect(() => {
        if(!messages.length) fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages])

    useLayoutEffect(() => {
        if (!scrollbarsRef.current) return
        scrollbarsRef.current.scrollToBottom()
    }, [isOpenChat, messages])

    useEffect(() => {
        socketChat.on('new-message', handleNewMessage)

        return () => {
            socketChat.off('new-message', handleNewMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, countUnreadMessages, isOpenChat, projectId])

    if (!isOpenChat) return (
        <Button
            onClick={() => setOpenChat(true)}
            className={styles.chatOpenAction}
            renderIcon={() => <ChatIcon class={styles.chatIcon}/>}>
            <span className={styles.chatOpenActionText}>
                {!!countUnreadMessages && <span className={styles.countUnreadMessages}>+{countUnreadMessages}</span>}
                <span style={{ marginBottom: 10 }}>Open</span>
                Chat
            </span>
        </Button>
    )

    return (
        <div className={styles.root}>
            <Button
                onClick={() => setOpenChat(false)}
                className={styles.chatCloseAction}
                renderIcon={() => <ChatIcon class={styles.chatIcon}/>}>
                <span>Close Chat</span>
            </Button>
            <div className={styles.messages} id="messages-list">
                <Scrollbars
                    ref={scrollbarsRef}>
                    <div style={{ position: 'relative' }}>
                        {messages.length < countMessages && (
                            <Button
                                onClick={handleLoadMore}
                                bgColor="#fff" className={styles.actionLoadMoreMessages}>Load more</Button>
                        )}
                        {messages.map((message, index) => {
                            const currMessageDate = formatDate(parseISO(message.createdAt), 'dd.MMM.yyyy')
                            let dataLine = null

                            if (index === 0) {
                                dataLine = <span>{currMessageDate}</span>
                            } else {
                                const prevMessageDate = formatDate(parseISO(messages[index - 1].createdAt), 'dd.MMM.yyyy')

                                if (currMessageDate !== prevMessageDate) dataLine = <span>{currMessageDate}</span>
                            }

                            return (
                                <div key={message.id}>
                                    {dataLine && <div className={styles.dateSeparator}>{dataLine}</div>}
                                    <ChatMessage
                                        isProjectTypeMA={props.isProjectTypeMA}
                                        file={message.fileUrl && {
                                            name: message.attachedFileOriginalName,
                                            url: message.fileUrl
                                        }}
                                        time={formatDate(parseISO(message.createdAt), 'hh:mm aaaaa\'m\'')}
                                        projectOwner={props.projectOwner}
                                        isAdminSender={message.isAdminSender}
                                        message={message.message}
                                        key={message.id}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Scrollbars>
            </div>
            <ChatForm onMessage={onMessage}/>
        </div>
    )
}

ProjectChat.propTypes = {
    isProjectTypeMA: PropTypes.bool,
    projectOwner: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        userPhotoLink: PropTypes.string.isRequired
    }).isRequired,

    role: PropTypes.string.isRequired,

    setCountAllUnreadMessage: PropTypes.func.isRequired
}

ProjectChat.defaultProps = {
    isProjectTypeMA: false
}

const mapStateToProps = state => ({
    role: state.user.role
})

const mapDispatchToProps = dispatch => ({
    setCountAllUnreadMessage: value => dispatch(setCountAllUnreadMessage(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectChat)
