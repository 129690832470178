import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import styles from './AccountTrackerItem.module.scss'
import { ReactComponent as SleepActiveModeIcon } from '../../../../assets/icons/active-moon.svg'
import { ReactComponent as SleepModeIcon } from '../../../../assets/icons/moon.svg'
import { ReactComponent as SendMailIcon } from '../../../../assets/icons/mail.svg'
import eventEmitter, {types} from '../../../../shared/eventEmitter'
import {getAccountUserList} from '../../../Modals/services/account'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckCircle, faSlash} from '@fortawesome/free-solid-svg-icons'
import {faCheckCircle as regfaCheckCircle} from '@fortawesome/free-regular-svg-icons'
import {updateAccountLastConnect, updateAccountStatus} from '../../../Modals/services/project'

export default function AccountTrackerItem({ info, updateInfo, updateStatistic }) {
    const _CHANGE_STATUS_HEADER = 'Change status'
    const [userList, setUserList]  = useState( [])
    const [teammatesCount, setTeammatesCount]  = useState( '')
    const handleGetAccountUserList = () => {
        getAccountUserList(info.accountId).then(({data}) => {
            setUserList([
                ...data.userList.users
            ]
            )
            setTeammatesCount(data.userList.teammatesCount)
        })
    }

    const constructChangeStatusModalMessage = ({accountName,status}) => {
        if(status !== 'Inactive') {
            return `Are you sure you want to change ${accountName} status to 'Inactive' ? It will move to the end of the list.`
        }
        if(status === 'Inactive') {
            return `Are you sure you want to change ${accountName} status to 'Active' It will move higher in the list.`
        }
    }
    const handleChangeAccountStatus = async ({projectId, accountId, accountName, status}) => {
        eventEmitter.emit(types.openYesNoModal, {
            handleAccepted: async () => {
                await updateAccountStatus({projectId, accountId, status})
                eventEmitter.emit(types.updateAccountTrackerList, updateInfo)
            },
            headerText: _CHANGE_STATUS_HEADER,
            messageText: constructChangeStatusModalMessage({
                accountName,
                status: info.status,
            }),
        })
    }

    const handleUpdateLastConnect = async ({projectId, accountId}) => {
        await updateAccountLastConnect({projectId, accountId})
        eventEmitter.emit(types.updateAccountTrackerList, updateInfo)
        eventEmitter.emit(types.updateAccountTrackerList, updateStatistic)
    }

    useEffect(() => {
        handleGetAccountUserList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info.accountId])

    useEffect(() => {
        eventEmitter.on(types.updateAccountTrackerList, updateInfo)  &&
        eventEmitter.on(types.updateAccountTrackerList, updateStatistic)

        return () => {
            eventEmitter.off(types.updateAccountTrackerList, updateInfo) &&
            eventEmitter.off(types.updateAccountTrackerList, updateStatistic)
        }
    })

    return(
        <div className={styles.accountList}>
            <div className={styles.accountNameContainer}>
                <button className={styles.accountName}
                    onClick={() =>
                        eventEmitter.emit(types.openTrackerUserListModal, {
                            projectId: info.projectId,
                            accountId: info.accountId,
                            name: info.name,
                            users: userList,
                            teammates: teammatesCount,
                            updateInfo,
                            updateStatistic
                        })
                    }
                >{info.name}</button>
            </div>
            {info.status === 'Invited' ?
                <div className={styles.accountStatus}>{info.status}</div> :
                info.status === 'Active' ?
                    <div className={styles.accountStatusActive}>{info.status}</div> :
                    info.status === 'Remind' ?
                        <div className={styles.accountStatusRemind}>{info.status}</div>:
                        <div className={styles.accountStatusInactive}>{info.status}</div>
            }

            <div className={styles.accountLastConnectInfo}>{info.last_connect}</div>
            <div className={styles.accountActivityInfo}>
                <span className={styles.accountActivityAction}>{info.activity_action}</span>
                <span>{info.last_activity}</span>
            </div>
            <div className={styles.accountMainStatuses}>
                {
                    info.main_statuses.length && info.main_statuses.some(item => item.activityType === 'Sent invitation') ?
                        <span>
                            <FontAwesomeIcon icon={faCheckCircle} data-tip data-for='invitation'/>
                            <ReactTooltip id='invitation' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Sent invitation
                            </ReactTooltip>
                        </span> :
                        <span>
                            <FontAwesomeIcon icon={regfaCheckCircle} title={'Sent invitation'}/>
                            <ReactTooltip id='invitation' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Sent invitation
                            </ReactTooltip>
                        </span>

                }
                <FontAwesomeIcon icon={faSlash} style={{transform: 'rotate(-37.4deg)'}}/>
                {
                    info.main_statuses.length && info.main_statuses.some(item => item.activityType === 'Opened project') ?
                        <span>
                            <FontAwesomeIcon icon={faCheckCircle} data-tip data-for='open_project'/>
                            <ReactTooltip id='open_project' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Opened project
                            </ReactTooltip>
                        </span> :
                        <span>
                            <FontAwesomeIcon icon={regfaCheckCircle} data-tip data-for='open_project'/>
                            <ReactTooltip id='open_project' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Opened project
                            </ReactTooltip>
                        </span>

                }
                <FontAwesomeIcon icon={faSlash} style={{transform: 'rotate(-37.4deg)'}}/>
                {
                    info.main_statuses.length && info.main_statuses.some(item => item.activityType === 'Looked at teaser') ?
                        <span>
                            <FontAwesomeIcon icon={faCheckCircle} data-tip data-for='teaser'/>
                            <ReactTooltip id='teaser' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Looked at teaser
                            </ReactTooltip>
                        </span> :
                        <span>
                            <FontAwesomeIcon icon={regfaCheckCircle} data-tip data-for='teaser'/>
                            <ReactTooltip id='teaser' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Looked at teaser
                            </ReactTooltip>
                        </span>

                }
                <FontAwesomeIcon icon={faSlash} style={{transform: 'rotate(-37.4deg)'}}/>
                {
                    info.main_statuses.length && info.main_statuses.some(item => item.activityType === 'Signed NDA') ?
                        <span>
                            <FontAwesomeIcon icon={faCheckCircle} data-tip data-for='nda'/>
                            <ReactTooltip id='nda' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Signed NDA
                            </ReactTooltip>
                        </span> :
                        <span>
                            <FontAwesomeIcon icon={regfaCheckCircle} data-tip data-for='nda'/>
                            <ReactTooltip id='nda' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Signed NDA
                            </ReactTooltip>
                        </span>

                }
                <FontAwesomeIcon icon={faSlash} style={{transform: 'rotate(-37.4deg)'}}/>
                {
                    info.main_statuses.length && info.main_statuses.some(item => item.activityType === 'Downloaded documents') ?
                        <sapn>
                            <FontAwesomeIcon icon={faCheckCircle} data-tip data-for='docs'/>
                            <ReactTooltip id='docs' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Downloaded documents
                            </ReactTooltip>
                        </sapn> :
                        <span>
                            <FontAwesomeIcon icon={regfaCheckCircle} data-tip data-for='docs'/>
                            <ReactTooltip id='docs' place='top' effect='solid' backgroundColor='#9dce6c'>
                                Downloaded documents
                            </ReactTooltip>
                        </span>
                }
            </div>
            <div className={styles.buttons}>
                <button className={styles.commentButton}
                    onClick={() =>
                        eventEmitter.emit(types.openTrackerCommentModal, {
                            projectId: info.projectId,
                            accountId: info.accountId,
                            commentId: info.commentId,
                            comment: info.comment,
                            projectName: info.name,

                        })
                    }>
                    {info.comment.length > 30 ?`${info.comment.slice(0, 30)}...` : info.comment || '+ Add comment'}
                </button>
                <button className={styles.iconWrapper}
                    onClick={() => handleChangeAccountStatus({
                        projectId: info.projectId,
                        accountId: info.accountId,
                        accountName: info.name,
                        status: info.status
                    })}
                >
                    {info.status === 'Inactive' ? <SleepActiveModeIcon title={'Switch to active'}/> : <SleepModeIcon title={'Switch to inactive'}/>}
                </button>
                <button className={styles.iconWrapper}
                    onClick={() => handleUpdateLastConnect({
                        projectId: info.projectId,
                        accountId: info.accountId,
                    })}
                >
                    <a href={`mailto:${info.email}`} target={'_blank'} rel="noreferrer">
                        <SendMailIcon title={'Send email'}/>
                    </a>
                </button>
            </div>
        </div>
    )
}

AccountTrackerItem.propTypes = {
    accountList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            status: PropTypes.string,
            last_connect: PropTypes.string,
            last_activity: PropTypes.string,
            activity_action: PropTypes.string,
            contact: PropTypes.string,
        })
    ),
    
}