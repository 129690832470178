import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import { TRoutes } from '../../../../shared/types'

import Logo from '../Logo/Logo'

import styles from './Header.module.scss'
import stylesLanding from '../Landing.module.scss'

function Header(props) {
    return (
        <header className={styles.root}>
            <div className={stylesLanding.container}>
                <div className={styles.topRow}>
                    <Logo/>
                    <nav>
                        <HashLink smooth class={styles.navItem} to="/#about">
                            About
                        </HashLink>
                        <HashLink smooth class={styles.navItem} to="/#contact">
                            Contact
                        </HashLink>
                        <Link class={clsx(styles.navItem, styles.navItemBorder)}
                            to={props ? TRoutes.DASHBOARD : TRoutes.LOGIN}>
                            {props.isAuth ? 'Go to dashboard' : 'Log In'}
                        </Link>
                    </nav>
                </div>
                <div className={styles.banner}>
                    <h1 className={styles.title}>
                        <span className={stylesLanding.titleMain}>Growth capital</span>
                        <span className={styles.titleSecondary}><span className={styles.titleColor}>for</span> Danish businesses looking to grow their business</span>
                    </h1>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    isAuth: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isAuth: state.user.isAuth
})

export default connect(mapStateToProps)(Header)
