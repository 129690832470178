import http from '../../../shared/http'
import { setToken } from '../../../shared/helpers'

export function logIn(data) {
    return http.post('/auth/signin', data).then(response => {
        setToken({
            token: response.data.accessToken,
            role: response.data.userRole
        })
        return response
    })
}

export function confirmInvitation({ hash }) {
    return http.post('/auth/confirm-invitation', {
        hash
    })
}

export function setPassword({ hash, password }) {
    return http.post('/auth/signup', {
        password,
        invitationHash: hash
    })
}

export function setOptionalInfo(data) {
    return http.put('/auth/user/preregistration-info', data)
}

export function forgotPassword(data) {
    return http.post('/auth/restore-password', data)
}
