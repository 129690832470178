import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { parseISO, formatDistance, differenceInCalendarDays } from 'date-fns'
import { useDebouncedCallback } from 'use-debounce'
import { connect } from 'react-redux'

import List from '../../PreviewList/components/List'

import Paginate from '../../UI/containers/Paginate/Paginate'
import NotificationItem from '../../UI/containers/NotificationItem/NotificationItem'

import * as service from '../services/notifications'

import { ITEMS_PER_PAGE } from '../../../shared/config'

import { TNotificationsStatus } from '../../../shared/types'

import { setCountAllMenu } from '../../../redux/ducks/user'

import styles from './NotificationList.module.scss'

function NotificationList(props) {
    const [notifications, setNotifications] = useState([])
    const [notificationsCount, setNotificationsCount] = useState([])
    const [page, setPage] = useState(0)

    const handlePageChange = ({ selected }) => {
        setPage(selected)
        fetchNotifications(selected)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const fetchNotifications = useDebouncedCallback(page => {
        service
            .getAllNotifications({
                limit: props.mini ? 5 : ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
            })
            .then(({ data }) => {
                setNotifications(data.notifications)
                setNotificationsCount(data.count)
            })
    }, 300)

    const getSectionDate = index => {
        const currItem = notifications[index]
        const prevItem = notifications[index - 1]

        if (
            index &&
            !differenceInCalendarDays(
                parseISO(currItem.createdAt),
                parseISO(prevItem.createdAt)
            )
        )
            return null

        return formatDistance(new Date(), parseISO(currItem.createdAt)) + ' ago'
    }

    useEffect(() => {
        setPage(0)
        fetchNotifications(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (notifications.length) {
            const notSeenIds = notifications
                .filter(item => item.status === TNotificationsStatus.NOT_SEEN)
                .map(item => item.id)

            if (notSeenIds.length)
                service.markSeenNotifications(notSeenIds).then(({ data }) => {
                    props.setCountAllMenu({
                        countUnreadNotifications:
                            data.count.countUnreadNotifications,
                    })
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications])

    return (
        <div>
            <List
                className={props.className}
                items={notifications}
                renderEmpty={() => (
                    <div className={styles.empty}>
                        <p className={styles.emptyDescription}>
                            You have no notifications at this time
                        </p>
                    </div>
                )}
            >
                {(item, index) => (
                    <React.Fragment key={item.id}>
                        <div className={styles.dateBlock}>
                            {getSectionDate(index)}
                        </div>
                        <NotificationItem
                            actionClassName={props.actionClassName}
                            notification={{
                                createdAt: item.createdAt,
                                info: item.info,
                                type: item.type
                            }}
                        />
                    </React.Fragment>
                )}
            </List>
            {!props.mini && (
                <Paginate
                    forcePage={page}
                    pageCount={Math.ceil(notificationsCount / ITEMS_PER_PAGE)}
                    onPageChange={handlePageChange}
                />
            )}
        </div>
    )
}

NotificationList.propTypes = {
    className: PropTypes.string.isRequired,
    mini: PropTypes.bool.isRequired,
    setCountAllMenu: PropTypes.func.isRequired,
}

NotificationList.defaultProps = {
    className: '',
    mini: false,
}

const mapDispatchToProps = dispatch => ({
    setCountAllMenu: value => dispatch(setCountAllMenu(value)),
})

export default connect(null, mapDispatchToProps)(NotificationList)
