import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './Tag.module.scss'

export default function Tag(props) {
    return (
        <span
            style={{
                color: props.textColor,
                backgroundColor: props.bgColor,
                textColor: props.textColor
            }}
            className={clsx(props.className, styles.root)}
        >
            {props.text}
        </span>
    )
}

Tag.propTypes = {
    className: PropTypes.string,
    bgColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

Tag.defaultProps = {
    className: ''
}
