import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'
import OffersList from '../modules/Offers/containers/OffersList'
import { TRoles, TRoutes } from '../shared/types'

function OffersListPage({ role }) {
    if (role === TRoles.OWNER)
        return (
            <ContainerMenu>
                <OffersList role={TRoles.OWNER} />
            </ContainerMenu>
        )
    else return <Redirect to={TRoutes.NOT_FOUND} />
}

OffersListPage.propTypes = {
    role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(OffersListPage)
