import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import {parseISO, format as formatDate} from 'date-fns'

import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import NoItems from '../../../UI/containers/NoItems/NoItems'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

import {ReactComponent as UploadDocumentIcon} from '../../../../assets/icons/mini-arrow.svg'
import {ReactComponent as FolderOpenIcon} from '../../../../assets/icons/folder-open.svg'
import {ReactComponent as FolderIcon} from '../../../../assets/icons/folder.svg'
import {ReactComponent as FileIcon} from '../../../../assets/icons/file.svg'
import {ReactComponent as DownloadIcon} from '../../../../assets/icons/download.svg'

import {TRoles} from '../../../../shared/types'

import * as services from '../../services/documents'

import {downloadFile} from '../../../../shared/helpers'
import styles from './AsignedDocuments.module.scss'
import {ReactComponent as ArrowTableIcon} from '../../../../assets/icons/mini-arrow-table.svg'
import eventEmitter, {types} from '../../../../shared/eventEmitter'
import {connect} from 'react-redux'

function AsignedDocuments({projectId, role, onLoadFolders, showDocuments}) {
    const _DELETE_FILE_MODAL_HEADER = 'Delete file'
    const _DELETE_FOLDER_MODAL_HEADER = 'Delete folder'

    const [folders, setFolders] = useState([])

    const loadFolders = () => {
        if (
            (role === TRoles.PRO_BUYER && !!showDocuments) ||
            role !== TRoles.PRO_BUYER
        ) {
            services
                .getDocuments(projectId)
                .then(({data}) => setFolders(data.folders))
        }
    }

    const handleUpdateList = () => {
        loadFolders()
    }

    const handleGetDocument = (documentId, filename) => {
        services.getWatermarkedDocuments(documentId).then(({data}) => {
            downloadFile(new Blob([data]), `${filename}`)
        })
        services.registerProjectDocumentsView(projectId)
    }

    const constructDeleteModalMessageText = ({name}) => {
        return `Are you sure you want to delete ${name}?`
    }

    const handleRemoveDocument = async ({ documentId, documentName }) => {
        eventEmitter.emit(types.openYesNoModal, {
            handleAccepted: async () => {
                await services.deleteDocument(documentId)
                eventEmitter.emit(types.updateFolderList)
            },
            headerText: _DELETE_FILE_MODAL_HEADER,
            messageText: constructDeleteModalMessageText({
                name: documentName,
            }),
        })
    }

    const handleRemoveFolder = async ({folderId, folderName}) => {
        eventEmitter.emit(types.openYesNoModal, {
            handleAccepted: async () => {
                await services.deleteFolder(folderId)
                eventEmitter.emit(types.updateFolderList)
            },
            headerText: _DELETE_FOLDER_MODAL_HEADER,
            messageText: constructDeleteModalMessageText({
                name: folderName,
            }),
        })
    }

    //    const handleDownloadFileLink = () => {
    //        if (role === TRoles.INVESTOR)
    //            services.registerProjectDocumentsView(projectId)
    //    }

    const handlePreview = (url, fileName) => {
        if (role === TRoles.INVESTOR)
            services.registerProjectDocumentsView(projectId)

        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'

        xhr.onload = function () {
            const urlCreator = window.URL || window.webkitURL
            const imageUrl = urlCreator.createObjectURL(this.response)
            window.open(imageUrl, fileName)
        }
        xhr.send()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadFolders(), [projectId, showDocuments])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onLoadFolders(folders), [folders])

    useEffect(() => {
        eventEmitter.on(types.updateFolderList, handleUpdateList)
        return () => eventEmitter.off(types.updateFolderList, handleUpdateList)
    })

    return (
        <Accordion
            className={styles.accordion}
            allowMultipleExpanded
            allowZeroExpanded
        >
            <NoItems
                isShow={!folders.length}
                text={
                    role === TRoles.PRO_BUYER
                        ? 'You have no documents at this time. Please, sign NDA'
                        : 'You have no documents at this time'
                }
            />

            {folders.map(folder => {
                return (
                    <AccordionItem
                        className={styles.accordionItem}
                        activeClassName={styles.accordionItemActive}
                        key={folder.id}
                    >
                        <AccordionItemHeading>
                            <AccordionItemButton className={clsx(styles.accordionButton)}>
                                <UploadDocumentIcon className={styles.accordionIcon}/>
                                <FolderIcon className={styles.folderIcon}/>
                                <FolderOpenIcon className={styles.folderOpenIcon}/>
                                <span className={styles.name}>{folder.name}</span>
                                <span className={styles.date}>
                                    {formatDate(parseISO(folder.createdAt), 'MMM, dd, yyyy')}
                                </span>
                                <VisibilityAccess access={[TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.OWNER]}>
                                    <div className={styles.actions}>
                                        <Tooltip
                                            renderIcon={() => <ArrowTableIcon/>}
                                            id={folder.id}
                                        >
                                            <TooltipItem
                                                className={styles.actionRemove}
                                                onClick={async () =>
                                                    await handleRemoveFolder({
                                                        folderId: folder.id,
                                                        folderName: folder.name,
                                                    })
                                                }
                                            >
                                                Remove
                                            </TooltipItem>
                                        </Tooltip>
                                    </div>
                                </VisibilityAccess>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={styles.accordionPanel}>
                            {folder.files.map(file => {
                                return (
                                    <div key={file.id} className={styles.file}>
                                        <FileIcon className={styles.fileIcon}/>
                                        <span
                                            className={clsx(styles.name, styles.fileName)}
                                            onClick={() => handlePreview(file.publicUrl, file.name)}
                                        >
                                            {file.name}
                                        </span>
                                        <span className={clsx(styles.time, styles.fileTime)}>
                                            {formatDate(parseISO(file.createdAt), 'HH:mm')}
                                        </span>
                                        <span className={clsx(styles.date, styles.dateFile)}>
                                            {formatDate(parseISO(file.createdAt), 'MMM, dd, yyyy')}
                                        </span>
                                        <div className={styles.actions}>
                                            <VisibilityAccess access={[TRoles.PRO_BUYER]}>
                                                <button
                                                    onClick={() => handleGetDocument(file.id, file.name)}
                                                    className={styles.actionDownload}
                                                >
                                                    <DownloadIcon/>
                                                </button>
                                            </VisibilityAccess>
                                            <VisibilityAccess
                                                access={[TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.OWNER, TRoles.INVESTOR]}
                                            >
                                                <a
                                                    href={file.publicUrl}
                                                    rel="noreferrer noopener"
                                                    target="_blank"
                                                    className={styles.actionDownload}
                                                >
                                                    <DownloadIcon/>
                                                </a>
                                            </VisibilityAccess>
                                            <VisibilityAccess access={[TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.OWNER]}>
                                                <Tooltip
                                                    renderIcon={() => <ArrowTableIcon/>}
                                                    id={file.id}
                                                >
                                                    <TooltipItem
                                                        className={styles.actionRemove}
                                                        onClick={async () => {
                                                            await handleRemoveDocument({
                                                                documentId: file.id,
                                                                documentName: file.name,
                                                            })
                                                        }}
                                                    >
                                                        Remove
                                                    </TooltipItem>
                                                </Tooltip>
                                            </VisibilityAccess>
                                        </div>
                                    </div>
                                )
                            })}
                        </AccordionItemPanel>
                    </AccordionItem>
                )
            })}
        </Accordion>
    )
}

AsignedDocuments.propTypes = {
    projectId: PropTypes.string.isRequired,
    onLoadFolders: PropTypes.func,
    role: PropTypes.string.isRequired,
    showDocuments: PropTypes.bool,
}

AsignedDocuments.defaultProps = {
    onLoadFolders: () => {
    },
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(AsignedDocuments)
