import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import styles from './UserTrackerItem.module.scss'
import Avatar from 'react-avatar'
import { If, Then} from 'react-if'
import Tag from '../../../UI/containers/Tag/Tag'
import Button from '../../../UI/containers/Button/Button'
import eventEmitter, {types} from '../../../../shared/eventEmitter'
import {updateAccountLastConnect} from '../../../Modals/services/project'

export default function UserTrackerItem({ info, projectId, accountId }) {
    const handleUpdateLastConnect = async ({projectId, accountId}) => {
        await updateAccountLastConnect({projectId, accountId}).then(()=> eventEmitter.emit(types.updateAccountTrackerList))
    }

    return(
        <div className={styles.root}>
            <div  className={clsx(styles.col, styles.colName)}>
                <Avatar
                    name={`${info.firstName} ${info.lastName}`}
                    src={info.photoLink}
                    size="40"
                    color="#d2e1df"
                    textSizeRatio={1.75}
                    round={3}
                    fgColor="#9ab8ac"
                />
                <div className={styles.userInfo}>
                    <div className={styles.name}>
                        {info.firstName} {info.lastName}
                    </div>
                    <If condition={info.role === 'PRO_BUYER'}>
                        <Then>
                            <Tag
                                className={styles.userType}
                                text="ProBuyer"
                                bgColor="#eaf5df"
                                textColor="#9dce6c"
                            />
                        </Then>
                    </If>
                </div>
            </div>
            <div className={clsx(styles.col, styles.colPhone)}>
                <span>{info.phone}</span>
            </div>
            <div className={clsx(styles.col, styles.colEmail)}>
                <span>{info.email}</span>
                <Button
                    className={clsx(styles.button)}

                    onClick={() => handleUpdateLastConnect({
                        projectId,
                        accountId,
                    })}
                ><a href={`mailto:${info.email}`} target={'_blank'} rel="noreferrer">Send</a></Button>
            </div>
        </div>
    )
}

UserTrackerItem.propTypes = {
    info: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }),
}