import http from '../../../shared/http'

/*
mock.onPost('/users/send-contact-us-info').reply(200,{
    message:'Ok'
})
*/

export function sendFeedback(data) {
    return http.post('/users/send-contact-us-info', data)
}
