import React, { useState } from 'react'
import clsx from 'clsx'

import ProjectList from '../ProjectList/ProjectList'

import Header from '../../../UI/containers/Header/Header'
import Button from '../../../UI/containers/Button/Button'
import AddButton from '../../../UI/containers/AddButton/AddButton'
import SearchForm from '../../../UI/containers/SearchForm/SearchForm'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

import { ReactComponent as ArchivedIcon } from '../../../../assets/icons/delete.svg'

import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { TRoles } from '../../../../shared/types'

import styles from './ProjectListAdminView.module.scss'

export default function ProjectListAdminView() {
    const [isArchived, setArchived] = useState(false)
    const [search, setSearch] = useState('')

    return (
        <div className={styles.root}>
            <Header title="Project List">
                <div className={styles.headerActions}>
                    <AddButton
                        onClick={() =>
                            eventEmitter.emit(types.openProjectModal)
                        }
                        text="Create Project"
                    />
                    <div className={styles.filterActions}>
                        <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                            <Button
                                bgColor="#ffffff"
                                className={clsx(
                                    styles.actionArchivedProject,
                                    isArchived &&
                                        styles.actionArchivedProjectActive
                                )}
                                onClick={() => setArchived(!isArchived)}
                                renderIcon={() => (
                                    <ArchivedIcon
                                        className={clsx(
                                            styles.archivedIcon,
                                            isArchived &&
                                                styles.archivedIconActive
                                        )}
                                        width="15"
                                    />
                                )}
                            >
                                Archived Project
                            </Button>
                        </VisibilityAccess>
                        <SearchForm
                            placeholder="Search Projects"
                            onSubmit={value => setSearch(value)}
                            onEmpty={() => setSearch('')}
                        />
                    </div>
                </div>
            </Header>
            <ProjectList isAdmin isArchived={isArchived} search={search} />
        </div>
    )
}
