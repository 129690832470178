import React from 'react'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'

import { If, Then, Else } from 'react-if'

import Tag from '../../../UI/containers/Tag/Tag'
import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import { ReactComponent as ArrowTableIcon } from '../../../../assets/icons/mini-arrow-table.svg'

import styles from './AdminUserItem.module.scss'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'
import { TRoles } from '../../../../shared/types'

export default function AdminUserItem({ info }) {
    return (
        <div className={styles.root}>
            <div
                className={clsx(styles.col, styles.colName)}
                onClick={() => eventEmitter.emit(types.openAdminProfileInfoModal, info)}
            >
                <Avatar
                    name={`${info.firstName} ${info.lastName}`}
                    src={info.photoLink}
                    size="40"
                    color="#d2e1df"
                    textSizeRatio={1.75}
                    round={3}
                    fgColor="#9ab8ac"
                />
                <div className={styles.userInfo}>
                    <div className={styles.name}>
                        {info.firstName} {info.lastName}
                    </div>
                    <If condition={info.role === 'SUPER_ADMIN'}>
                        <Then>
                            <Tag
                                className={styles.userType}
                                text="Super admin"
                                bgColor="#eaf5df"
                                textColor="#9dce6c"
                            />
                        </Then>
                        <Else>
                            <Tag
                                className={styles.userType}
                                text="Admin"
                                bgColor="#eaf5df"
                                textColor="#9dce6c"
                            />
                        </Else>
                    </If>
                </div>
            </div>
            <div className={clsx(styles.col, styles.colPhone)}>
                <span>{info.phone}</span>
            </div>
            <div className={clsx(styles.col, styles.colEmail)}>
                <span>{info.email}</span>
            </div>
            <div className={clsx(styles.col, styles.colActions)}>
                {!info.deletedAt ? (
                    <Tooltip
                        renderIcon={() => <ArrowTableIcon />}
                        className={styles.actionMenu}
                        id={`user-list-${info.userId}`}
                    >
                        <TooltipItem
                            onClick={() => eventEmitter.emit(types.openCreateAdminModal, info)}
                        >
                            Edit
                        </TooltipItem>
                        <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openArchiveUserModal, {
                                        userId: info.userId,
                                        header: 'Archive User',
                                        message: `Are you sure you want to archive ${info.firstName} ${info.lastName}?`,
                                    })
                                }
                            >
                                Archive
                            </TooltipItem>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openAdminDeleteUserModal, {
                                        userId: info.userId,
                                        header: 'Delete User',
                                        message: `Are you sure you want to delete ${info.firstName} ${info.lastName}? 
                                        All information about this user will be removed from the database.`,
                                    })
                                }
                            >
                                Delete
                            </TooltipItem>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openEmailSettingsModal, {
                                        id: info.userId,
                                        emailSettings: info.emailSettings,
                                        header: 'Email settings'
                                    })
                                }
                            >
                                Email settings
                            </TooltipItem>
                        </VisibilityAccess>
                    </Tooltip>
                ) : (
                    <Tooltip
                        renderIcon={() => <ArrowTableIcon />}
                        className={styles.actionMenu}
                        id={`user-list-${info.userId}`}
                    >
                        <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openUnArchiveUserModal, {
                                        userId: info.userId,
                                        header: 'Unarchive User',
                                        message: `Are you sure you want to unarchive ${info.firstName} ${info.lastName}?`,
                                    })
                                }
                            >
                                Unarchive
                            </TooltipItem>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openAdminDeleteUserModal, {
                                        userId: info.userId,
                                        header: 'Delete User',
                                        message: `Are you sure you want to delete ${info.firstName} ${info.lastName}? 
                                        All information about this user will be removed from the database.`,
                                    })
                                }
                            >
                                Delete
                            </TooltipItem>
                        </VisibilityAccess>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

AdminUserItem.propTypes = {
    info: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        deletedAt: PropTypes.string,
    }),
}
