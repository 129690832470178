import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import clsx from 'clsx'

import styles from './Select.module.scss'

export default function SimpleSelect(props) {
    return (
        <Select
            className={clsx(
                styles.select,
                props.className
            )}
            value={props.value}
            classNamePrefix="select"
            onChange={props.onChange}
            defaultValue={props.options ? props.options[0] : null}
            options={props.options}
        />
    )
}

Select.propTypes = {
    value:PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired
    ).isRequired
}

Select.defaultProps = {
    className: ''
}
