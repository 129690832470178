import http from '../../../shared/http'

//mock.onPost('/createUser').reply(200, {
//    message: 'Ok'
//})

export function addProbuyer(accountId, data) {
    return http.post(`/accounts/${accountId}/members/add`, {
        shouldSendInvitation: !!data.shouldSendInvitation,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        position: data.position,
        telephoneNumber: data.phone
    })
}

//mock.onPut(/\/users\/\w+/).reply(200,{
//    message:'Ok'
//})

export function editProBuyer(userId, data) {
    return http.put(`/users/admin/${userId}`,
        {
            shouldSendInvitation: !!data.shouldSendInvitation,
            role: data.userType,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            position: data.position,
            telephoneNumber: data.phone
        })
}

export function getProBuyerInfo(userId) {
    return http.get(`/users/admin/${userId}`)
}

export function getOwnAccount() {
    return http.get('/users/me/account')
}

export function requestSignedNDA({ projectId }) {
    return http.post(`/projects/${projectId}/offline-NDA-request`)
}
