import React from 'react'

import styles from './Offers.module.scss'
import OfferList from '../../../Offers/containers/OffersList'

export default function Offers(props) {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h3 className={styles.title}>Offers</h3>
            </div>
            <OfferList
                projectId={props.projectId}
                listClassName={styles.offers}
                containerClassName={styles.container}
                role={props.role ? props.role : 'ADMIN'}
            />
        </div>
    )
}
