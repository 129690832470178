import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Header from '../modules/UI/containers/Header/Header'
import StatisticView from '../modules/Statistic/containers/StatisticView/StatisticView'
import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'
import ContentContainer from '../modules/UI/containers/Container/ContentContainer'

import { TRoles, TRoutes } from '../shared/types'

function Statistic(props) {
    if ([TRoles.ADMIN,TRoles.SUPER_ADMIN].includes(props.role))
        return (
            <ContainerMenu>
                <div style={{width:'100%'}}>
                    <Header title="Statistic" />
                    <ContentContainer>
                        <StatisticView/>
                    </ContentContainer>
                </div>
            </ContainerMenu>
        )
    else return <Redirect to={TRoutes.NOT_FOUND} />
}

Statistic.propTypes = {
    role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    role: state.user.role
})


export default connect(mapStateToProps)(Statistic)
