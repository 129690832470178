import React, { useState } from 'react'
// import { toast } from 'react-toastify'

import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, Controller } from 'react-hook-form'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import { If, Then, Else } from 'react-if'
import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'
import * as service from '../../services/nda'

import styles from './NdaTemplateModal.module.scss'
import TextField from '../../../UI/containers/Field/TextField'
import EditorField from '../../../UI/containers/Field/EditorField'

export default function NdaTemplateModal() {
    const [isLoading, setLoading] = useState(false)
    const [projectId, setProjectId] = useState('')
    const [projectName, setProjectName] = useState('')
    const [editorText, setEditorText] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [templateId, setTemplateId] = useState('')
    const [onNDATemplateCreation, setOnNDATemplateCreation] = useState(() => () => {})

    const {
        register,
        handleSubmit,
        errors,
        reset,
        control,
        setValue,
    } = useForm({
        resolver: zodResolver(
            z.object({
                disclosingPartyDetails: z.string().nonempty(),
                body: z.string().nonempty(),
            })
        ),
    })

    const handleOpen = ({ projectId, name, isEditMode, templateId, onNDATemplateCreation }) => {
        setEditMode(false)
        setProjectId(projectId)
        setProjectName(name)
        setTemplateId(templateId)
        onNDATemplateCreation && setOnNDATemplateCreation(() => onNDATemplateCreation)
        if (isEditMode) {
            setEditMode(true)
            service.getEditNdaTemplate(templateId).then(({ data }) => {
                setTimeout(() => {
                    setEditorText(data.data.body)
                    setValue(
                        'disclosingPartyDetails',
                        data.data.disclosingPartyDetails
                    )
                }, 0)
            })
        } else {
            service.getNdaTemplate(projectId).then(({ data }) => {
                setTimeout(() => {
                    setEditorText(data.data.documentBody)
                    if (data.data.businessOwnerDetails) {
                        const result = Object.values(
                            data.data.businessOwnerDetails
                        )
                        setValue('disclosingPartyDetails', result.join('\n'))
                    }
                }, 0)
            })
        }
    }

    const onSubmit = closeModal => data => {
        setLoading(true)
        if (editMode) {
            service
                .editNdaTemplate(templateId, data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Edit NDA Document',
                        message: 'NDA Document successfully Save & Publish',
                    })
                    reset()
                    closeModal()
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        } else {
            service
                .createNdaTemplate(projectId, data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Create NDA Document',
                        message: 'NDA Document successfully created',
                    })
                    reset()
                    onNDATemplateCreation({projectId})
                    closeModal()
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        }
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            disabledBgClose
            classModalName={styles.modal}
            nameEvent={types.openNdaTemplateModal}
        >
            {({ closeModal }) => (
                <>
                    <If condition={editMode}>
                        <Then>
                            <ModalHeader
                                className={styles.header}
                                titleClassName={styles.titleHeader}
                                title="Edit NDA Template"
                                projectTitle={projectName}
                            />
                        </Then>
                        <Else>
                            <ModalHeader
                                className={styles.header}
                                titleClassName={styles.titleHeader}
                                title="Create NDA Template"
                                projectTitle={projectName}
                            />
                        </Else>
                    </If>
                    <form autoComplete="off">
                        <div className={styles.container}>
                            <TextField
                                label="Disclosing Party"
                                isRequired
                                fieldClass={styles.textarea}
                                error={errors.disclosingPartyDetails}
                                ref={register}
                                isTextarea
                                name="disclosingPartyDetails"
                                placeholder="Disclosing party details"
                            />
                        </div>
                        <div className={styles.container}>
                            <Controller
                                as={
                                    <EditorField
                                        editorText={editorText}
                                        label="Document Body"
                                    />
                                }
                                name="body"
                                control={control}
                            />
                            <div className={styles.row}>
                                <Button
                                    isLoading={isLoading}
                                    className={styles.saveBtn}
                                    type="submit"
                                    bgColor="#9dce6c"
                                    onClick={handleSubmit(onSubmit(closeModal))}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
