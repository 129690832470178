import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import clxs from 'clsx'

import Button from '../Button/Button'
import UploadButton from '../UploadButton/UploadButton'

import styles from './UploadAvatar.module.scss'

export default function UploadAvatar(props) {
    return (
        <div className={clxs(styles.root, props.className)}>
            <Avatar
                src={props.src}
                name={props.fullName}
                className={styles.avatar}
                size={props.size}
                color="#d2e1df"
                textSizeRatio={1.75}
                round={5}
                fgColor="#9ab8ac"
            />
            <div className={styles.actions}>
                <h3 className={styles.title}>{props.label}</h3>
                <UploadButton
                    accept={props.accept}
                    onFiles={props.onFiles}
                    fileSize={props.fileSize}
                >
                    {({ handleClick }) => (
                        <Button
                            onClick={handleClick}
                            isLoading={props.isLoading}
                            className={styles.actionImage}
                            bgColor="#9dce6c"
                        >
                            Upload Picture
                        </Button>
                    )}
                </UploadButton>
                <Button
                    onClick={e => {
                        e.preventDefault()
                        props.onRemove()
                    }}
                    isLoading={props.isLoading}
                    className={clxs(
                        styles.actionImageRemove,
                        styles.actionImage
                    )}
                    bgColor="#ffffff"
                >
                    Remove Picture
                </Button>
            </div>
        </div>
    )
}

UploadAvatar.propTypes = {
    isLoading: PropTypes.bool,
    fullName: PropTypes.string,
    label: PropTypes.string,
    src: PropTypes.string,
    size: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    onFiles: PropTypes.func.isRequired,
    accept: PropTypes.string,
    fileSize: PropTypes.string,
}

UploadAvatar.defaultProps = {
    isLoading: false,
    fullName: '',
    label: 'Profile Picture',
    size: '165',
    accept: 'image/png,image/gif,image/jpeg',
    fileSize: null
}
