import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as z from 'zod'
import Avatar from 'react-avatar'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { format as formatDate, parseISO } from 'date-fns'

import * as service from '../../services/projects'

import Button from '../../../UI/containers/Button/Button'
import NoItems from '../../../UI/containers/NoItems/NoItems'
import TextField from '../../../UI/containers/Field/TextField'
import Paginate from '../../../UI/containers/Paginate/Paginate'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

import { TRoles } from '../../../../shared/types'

import { ITEMS_PER_PAGE } from '../../../../shared/config'

import styles from './QuestionsAnswers.module.scss'

function QuestionAnswers(props) {
    const [isLoading, setLoading] = useState(false)
    const [questions, setQuestions] = useState([])
    const [page, setPage] = useState(0)
    const [questionsCount, setQuestionsCount] = useState(0)
    const messagesHeaderRef = useRef()

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: zodResolver(
            z.object({
                message: z.string().max(500).nonempty(),
            })
        ),
    })

    const fetchQuestions = () => {
        let request
        if (props.role === TRoles.ADMIN || props.role === TRoles.SUPER_ADMIN)
            request = service.getQuestionsAdmin(props.projectId, {
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
            })
        else request = service.getQuestionsInvestor(props.projectId)

        request.then(({ data }) => {
            if (
                props.role === TRoles.ADMIN ||
                props.role === TRoles.SUPER_ADMIN
            )
                setQuestionsCount(data.count)

            setQuestions(data.questions)
        })
    }

    const handlePageChange = ({ selected }) => {
        setPage(selected)

        setTimeout(() => {
            window.scrollTo({
                top: messagesHeaderRef.current.offsetTop,
                behavior: 'smooth',
            })
        }, 100)
    }

    const onSubmit = data => {
        setLoading(true)

        service
            .sendQuestion(props.projectId, data.message)
            .then(({ data }) => {
                setQuestions([...questions, data.question])
                toast.success('Message was sent successfully')
                reset()
            })
            // .catch(error => toast.error(error.message))
            .catch(error => console.log(error.message))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchQuestions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div className={styles.root}>
            <div className={styles.header} ref={messagesHeaderRef}>
                <h3 className={styles.title}>Questions & Answers</h3>
            </div>
            <NoItems
                isShow={!questions.length}
                text="You have no questions at this time"
            />
            {questions.map(item => (
                <div className={styles.messages} key={item.id}>
                    <div
                        className={clsx(
                            styles.message,
                            styles.messageRight,
                            !item.answer && styles.messagesWithoutAnswer
                        )}
                    >
                        <div className={styles.messageInfo}>
                            <VisibilityAccess
                                access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                            >
                                {() => (
                                    <>
                                        <Avatar
                                            name={`${item.investor.firstName} ${item.investor.lastName}`}
                                            src={item.investor.photoLink}
                                            size="30"
                                            color="#d2e1df"
                                            textSizeRatio={1.75}
                                            round={5}
                                            fgColor="#9ab8ac"
                                        />
                                        <span className={styles.name}>
                                            {item.investor.firstName}{' '}
                                            {item.investor.lastName}
                                        </span>
                                    </>
                                )}
                            </VisibilityAccess>
                            <span className={styles.time}>
                                {formatDate(parseISO(item.createdAt), 'HH:mm')}
                            </span>
                            <span className={styles.date}>
                                {formatDate(
                                    parseISO(item.createdAt),
                                    'MMM, dd, yyyy'
                                )}
                            </span>
                        </div>
                        <p className={styles.messageContent}>{item.question}</p>
                    </div>
                    {!!item.answer && (
                        <div className={styles.message}>
                            <div className={styles.messageInfo}>
                                <Avatar
                                    name={`${props.projectOwner.firstName} ${props.projectOwner.lastName}`}
                                    src={props.projectOwner.userPhotoLink}
                                    size="30"
                                    color="#d2e1df"
                                    textSizeRatio={1.75}
                                    round={5}
                                    fgColor="#9ab8ac"
                                />
                                <span className={styles.name}>
                                    {props.projectOwner.firstName}{' '}
                                    {props.projectOwner.lastName}
                                </span>
                                <span className={styles.time}>
                                    {formatDate(
                                        parseISO(item.answeredAt),
                                        'HH:mm'
                                    )}
                                </span>
                                <span className={styles.date}>
                                    {formatDate(
                                        parseISO(item.answeredAt),
                                        'MMM, dd, yyyy'
                                    )}
                                </span>
                            </div>
                            <p className={styles.messageContent}>
                                {item.answer}
                            </p>
                        </div>
                    )}
                </div>
            ))}
            <Paginate
                forcePage={page}
                pageCount={Math.ceil(questionsCount / ITEMS_PER_PAGE)}
                onPageChange={handlePageChange}
            />
            <VisibilityAccess access={[TRoles.INVESTOR]}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                >
                    <label className={styles.filed}>
                        <span className={styles.nameField}>
                            Ask a question:
                        </span>

                        <TextField
                            fieldClass={styles.textarea}
                            error={errors.message}
                            isTextarea
                            disabled={isLoading}
                            name="message"
                            ref={register}
                            placeholder="Type your question"
                        />
                    </label>
                    <Button
                        isLoading={isLoading}
                        className={styles.submit}
                        type="submit"
                    >
                        Send
                    </Button>
                </form>
            </VisibilityAccess>
        </div>
    )
}

QuestionAnswers.propTypes = {
    role: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,

    projectOwner: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        userPhotoLink: PropTypes.string,
    }).isRequired,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(QuestionAnswers)
