import React from 'react'
import {Link} from 'react-router-dom'

import styles from './NotFoundView.module.scss'
import { TRoutes } from '../../../../shared/types'

export default function NotFoundView() {
    return (
        <div className={styles.root}>
            <p className={styles.title}>Not found page (:</p>
            <Link className={styles.link} to={TRoutes.MAIN}>Go to main page</Link>
        </div>
    )
}
