import React, { useState } from 'react'
import clsx from 'clsx'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import { types } from '../../../../shared/eventEmitter'

import styles from './YesNoModal.module.scss'

export default function YesNoModal() {
    const [isLoading, setLoading] = useState(false)
    const [headerText, setHeaderText] = useState('')
    const [messageText, setMessageText] = useState('')
    const [handleAccepted, setHandleAccepted] = useState(() => () => {})

    const handleOpen = ({ headerText, messageText, handleAccepted }) => {
        setHeaderText(headerText)
        setMessageText(messageText)
        setHandleAccepted(() => handleAccepted)
    }

    const handleClick = async closeModal => {
        setLoading(true)
        try {
            await handleAccepted()
            closeModal()
        } finally {
            setLoading(false)
        }
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openYesNoModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title={headerText}
                        titleClassName={styles.headerTitle}
                    />
                    <div className={styles.container}>
                        <div className={styles.message}>{messageText}</div>
                        <div className={styles.actions}>
                            <Button
                                isLoading={isLoading}
                                onClick={() => closeModal()}
                                bgColor="#ffffff"
                                className={clsx(styles.action, styles.actionCancel)}
                            >
                No
                            </Button>
                            <Button
                                isLoading={isLoading}
                                className={styles.action}
                                onClick={async () => await handleClick(closeModal)}
                            >
                Yes
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
