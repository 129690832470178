import { io } from 'socket.io-client'
import { API_URL } from './config'

const socketChat = io(API_URL + '/chat', {
    autoConnect: false,
    auth: cb => {
        const authInfo = JSON.parse(localStorage.getItem('auth') || '{}')
        cb({
            token: `Bearer ${authInfo.token}`
        })
    }
})

export default socketChat
