import http from '../../../shared/http'

//mock.onGet('/offers').reply(200, {
//    status: 'ok',
//    count: 55,
//    offers: new Array(5).fill('').map(() => ({
//        id: '0cfe3acf-00c8-48a4-983a-8daa8e1f8b13',
//        createdAt: '2021-01-13T13:09:52.843Z',
//        fileName: 'Test.pdf',
//        publicUrl:'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/project_documents/3bcef9ff-819a-4edb-b6ff-9d5cf5f4d357.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIARK2HACVYLKI3QK5A%2F20210113%2Feu-central-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20210113T153619Z&amp;X-Amz-Expires=86400&amp;X-Amz-Signature=62dda763b85f8b687a7e6f93663729fab3fbbea02a7fbd38ce3bd84d567c3633&amp;X-Amz-SignedHeaders=host',
//        comment:'1In general Pellentesque porttitor ut diam ac molestie. Curabitur rutrum tortor nisi, non accumsan nibh iaculis at. Mauris at lorem placerat, lobortis leo quis, tincidunt purus. Vestibulum sed augue eu eros mollis ullamcorper. Donec tincidunt neque ac tellus ultrices tempus. Integer pharetra orci quis pulvinar pharetra. Duis facilisis ultricies nisi, sit amet tincidunt sem accumsan ac. Nulla laoreet laoreet massa, a tempus sapien egestas at. Aenean gravida rper. Donec tincidunt neque ac tellus ultrices tempus. Integer pharetra orci quis pulvinar pharetra. Duis facilisis ultricies nisi, sit amet tincidunt sem accumsan ac. Nulla laoreet laoreet massa, a tempus sapien egestas at. Aenean gravida quam id nulla vestibulum, eu venenatis turpis hendrerit. Cras ex mauris, iaculis eu elit sit amet, rutrum imperdiet nunc. Duis facilisis ultricies nisi, sit amet tincidunt sem accumsan ac. Nulla laoreet laoreet massa, a tempus sapien egestas at. Aenean gravida quam id nulla vestibulum, eu venenatis turpis hendrerit. Cras ex mauris, iaculis eu elit sit amet, rutrum imperdiet nunc. quam id nulla vestibulum, eu venenatis turpis hendrerit. Cras ex mauris, iaculis eu elit sit amet, rutrum imperdiet nunc. Duis facilisis ultricies nisi, sit amet tincidunt sem accumsan ac. Nulla laoreet laoreet massa, a tempus sapien egestas at. Aenean gravida quam id nulla vestibulum, eu venenatis turpis hendrerit. Cras ex mauris, iaculis eu elit sit amet, rutrum imperdiet nunc. ',
//        price: '300 000',
//        share: '50',
//    }))
//})

export function getNdaList(params) {
    return http.get('/nda/admin',{params})
}

export function getAdminOffers(projectId) {
    return http.get(`/projects/admin/${projectId}/offers`)
}

//mock.onPut('/offers/0cfe3acf-00c8-48a4-983a-8daa8e1f8b13/status').reply(200, {
//    status: 'ok'
//})

export function resolveBoOffer(offerId, data) {
    return http.post(`/offers/${offerId}/resolve`, {
        status: data,
    })
}
    
export function resolveAdminOffer(offerId, data) {
    return http.post(`/offers/admin/${offerId}/resolve`, {
        status: data,
    })
}