import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { When } from 'react-if'
import { parseISO, format as formatDate } from 'date-fns'
import { toast } from 'react-toastify'
import Avatar from 'react-avatar'
import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'

import Button from '../../UI/containers/Button/Button'
import Tooltip from '../../UI/containers/Tooltip/Tooltip'

import { TOfferStatus } from '../../../shared/types'

import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'
import { ReactComponent as MiniArrowIcon } from '../../../assets/icons/mini-arrow.svg'

import stylesCommon from './OffersList.module.scss'
import styles from './OffersItem.module.scss'

export default function OffersItem(props) {
    const handleAccepted = data => {
        props.onActionHandle(props.item.id, data).then(() => {
            toast.success('Offer status has been changed')
            props.updateOffers()
        })
    }

    const getClassAndTextByStatus = status => {
        if (status === TOfferStatus.ADMIN_DECLINED)
            return {
                text: 'Admin declined',
                className: styles.offerStatusDeclined,
            }
        else if (status === TOfferStatus.BO_ACCEPTED)
            return {
                text: 'Accepted',
                className: styles.offerStatusAccepted,
            }
        else if (status === TOfferStatus.BO_DECLINED)
            return {
                text: 'Declined',
                className: styles.offerStatusDeclined,
            }
        else if (status === TOfferStatus.ADMIN_PENDING)
            return {
                text: 'Pending',
                className: styles.offerStatusPending,
                acceptButtonText: 'Post',
            }
        else if (status === TOfferStatus.ADMIN_ACCEPTED)
            return {
                text: 'Pending',
                className: styles.offerStatusPending,
                acceptButtonText: 'Accept',
            }
    }

    const mapOldStatusToNew = {
        [TOfferStatus.ADMIN_PENDING]: {
            accept: TOfferStatus.ADMIN_ACCEPTED,
            decline: TOfferStatus.ADMIN_DECLINED,
        },
        [TOfferStatus.ADMIN_ACCEPTED]: {
            accept: TOfferStatus.BO_ACCEPTED,
            decline: TOfferStatus.BO_DECLINED,
        },
    }

    const getLastPartUUID = uuid => {
        const parts = uuid.split('-')

        return parts[parts.length - 1]
    }
    const createdAt = parseISO(props.item.createdAt)

    const {
        text: offerText,
        className: classNameOffer,
        acceptButtonText,
    } = getClassAndTextByStatus(props.item.status)
    return (
        <AccordionItem
            className={styles.accordionItem}
            activeClassName={styles.accordionItemActive}
        >
            <AccordionItemHeading>
                <AccordionItemButton className={styles.accordionButton}>
                    <MiniArrowIcon className={styles.accordionIcon} />
                    <Tooltip
                        className={styles.offerTooltip}
                        place="top"
                        eventOff="mouseleave"
                        dataEvent="mouseover focus"
                        renderIcon={() => (
                            <span>Offer {getLastPartUUID(props.item.id)}</span>
                        )}
                        id={`offers-item-${props.item.id}`}
                    >
                        <div className={styles.tooltipFullId}> {props.item.id}</div>
                    </Tooltip>
                    <div className={styles.user}>
                        <Avatar
                            src={props.item.investorPhotoUrl}
                            name={props.item.investorFullName}
                            size="30"
                            color="#d2e1df"
                            textSizeRatio={1.75}
                            round={true}
                            fgColor="#9ab8ac"
                        />
                        <span className={styles.investorFullName}>
                            {props.item.investorFullName}
                        </span>
                    </div>
                    <div className={styles.flexSpace} />
                    {!props.shouldDisplayActionButtons && (
                        <div className={clsx(styles.offerStatus, classNameOffer)}>
                            {offerText}
                        </div>
                    )}
                    <When condition={props.shouldDisplayActionButtons}>
                        <Button
                            className={clsx(
                                stylesCommon.acceptBtn,
                                styles.actionChangeStatus
                            )}
                            bgColor="#9dce6c"
                            onClick={evt => {
                                evt.stopPropagation()
                                handleAccepted(mapOldStatusToNew[props.item.status].accept)
                            }}
                        >
                            {acceptButtonText}
                        </Button>

                        <Button
                            className={clsx(
                                stylesCommon.acceptBtn,
                                stylesCommon.declineBtn,
                                styles.actionChangeStatus
                            )}
                            bgColor="#ffffff"
                            onClick={evt => {
                                evt.stopPropagation()
                                handleAccepted(mapOldStatusToNew[props.item.status].decline)
                            }}
                        >
              Decline
                        </Button>
                    </When>
                    <span className={styles.offerTime}>
                        {formatDate(createdAt, 'HH:mm')}
                    </span>
                    <span className={styles.offerDate}>
                        {formatDate(createdAt, 'MMM, dd, yyyy')}
                    </span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <div className={styles.priceRow}>
                    <span className={stylesCommon.leftCol}>DKK {props.item.price}</span>
                    <span>{props.item.share} %</span>
                </div>
                <p className={styles.offerComment}>{props.item.comment}</p>
                <div className={styles.fileRow}>
                    <a
                        className={styles.actionDownload}
                        href={props.item.filePublicUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <span className={styles.fileName}>{props.item.fileName}</span>
                        <DownloadIcon />
                    </a>
                </div>
            </AccordionItemPanel>
        </AccordionItem>
    )
}

OffersItem.propTypes = {
    className: PropTypes.string,
    onActionHandle: PropTypes.func.isRequired,
    shouldDisplayActionButtons: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        comment: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        filePublicUrl: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        investorFullName: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        share: PropTypes.string.isRequired,
    }).isRequired,
    updateOffers: PropTypes.func.isRequired,
}

OffersItem.defaultProps = {
    className: '',
}
