import http from '../../../shared/http'

//mock.onPost('/createAccount').reply(200, {
//    message: 'Ok'
//})

export function createAccount(data) {
    return http.post('/accounts/admin/create', {
        shouldSendInvitation: !!data.shouldSendInvitation,
        accountName: data.accountName,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        position: data.position,
        telephoneNumber: data.phone,
        street: data.street,
        city: data.city,
        zipCode: data.zip,
        country: data.country,
    })
}

//mock.onPut(/\/accounts\/\w+/).reply(200,{
//    message:'Ok'
//})
    
export function editAccount(accountId, data) {
    return http.put(`/accounts/admin/${accountId}`, 
        {
            accountName: data.accountName,
            street: data.street,
            zipCode: data.zip,
            city: data.city,
            country: data.country,
        })
}

export function getAccountInfo(accountId) {
    return http.get(`/accounts/admin/${accountId}`)
}

export function getNotAssignedAccounts(projectId, params) {
    return http.get(`/projects/admin/${projectId}/not-assigned-accounts`,{params})
}


export function getAccountUserList(accountId) {
    return http.get(`/accounts/${accountId}/user-list`)
}