import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Avatar from 'react-avatar'
import clsx from 'clsx'

import { TRoles } from '../../../../shared/types'

import { ReactComponent as IconFile } from '../../../../assets/icons/file.svg'
import { ReactComponent as DownloadFile } from '../../../../assets/icons/download-uncolor.svg'

import styles from './ChatMessage.module.scss'

function getMetaInfo(props) {
    const isAdmin = [TRoles.ADMIN, TRoles.SUPER_ADMIN].includes(props.role)

    let isRight = (isAdmin && props.isAdminSender) || (!isAdmin && !props.isAdminSender)

    if (props.isProjectTypeMA) {
        return {
            isRight,
            name: props.isAdminSender ? 'Admins' : 'Pro sellers'
        }
    }

    if (isAdmin) {
        return {
            name: props.isAdminSender ? 'You' : `${props.projectOwner.firstName} ${props.projectOwner.lastName}`,
            isRight
        }
    }

    return {
        name: !props.isAdminSender ? 'You' : 'Admin',
        isRight
    }
}

function ChatMessage(props) {
    const {isRight, name} = getMetaInfo(props)

    const getSrcAvatar = () => {
        const isAdmin = [TRoles.ADMIN, TRoles.SUPER_ADMIN].includes(props.role)
        if (props.isAdminSender) {
            // return isAdmin ? props.userPhotoLink : null
            return isAdmin ? props.projectOwner.userPhotoLink : null
        }

        // return props.projectOwner.userPhotoLink

        if(isAdmin) {
            return null
        }
        return props.userPhotoLink
    }

    return (
        <div className={clsx(styles.root, isRight && styles.rootRight)}>
            <Avatar
                src={getSrcAvatar()}
                className={styles.avatar}
                name={name}
                size="30"
                color="#d2e1df"
                textSizeRatio={1.75}
                round={5}
                fgColor="#9ab8ac"
            />
            <div className={styles.wrapperContent}>
                <span className={styles.userFullName}>{name}</span>
                {props.message && (
                    <p className={styles.content}>
                        {props.message}
                    </p>
                )}
                {!!props.file && (
                    <a
                        className={styles.file}
                        href={props.file.url}
                        rel="noreferrer noopener"
                        target="_blank">
                        <IconFile className={styles.fileIcon}/>
                        <span className={styles.fileName}>{props.file.name}</span>
                        <DownloadFile className={styles.fileIconDownload}/>
                    </a>
                )}
            </div>
            <span className={styles.data}>{props.time}</span>
        </div>
    )
}

ChatMessage.propTypes = {
    isProjectTypeMA: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    isAdminSender: PropTypes.bool.isRequired,
    projectOwner: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        userPhotoLink: PropTypes.string.isRequired
    }).isRequired,
    time: PropTypes.string.isRequired,

    role: PropTypes.string.isRequired,
    userPhotoLink: PropTypes.string.isRequired,

    file: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    })
}

const mapStateToProps = state => ({
    role: state.user.role,
    userPhotoLink: state.user.userProfile.photoLink
})

export default connect(mapStateToProps)(ChatMessage)
