import http from '../../../shared/http'

//mock.onPost('/editprofile').reply(200, {
//    message: 'Ok'
//})

export function editProfile(data) {
    return http.put('/users/me', data)
}

export function changePassword(data) {
    return http.put('/users/me/password', {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword
    })
}