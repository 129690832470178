import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'
import FeedbacksList from '../modules/Feedbacks/containers/FeedbacksList'
import { TRoles, TRoutes } from '../shared/types'

function FeedbacksListPage({ role }) {
    if (role === TRoles.OWNER)
        return (
            <ContainerMenu>
                <FeedbacksList />
            </ContainerMenu>
        )
    else return <Redirect to={TRoutes.NOT_FOUND} />
}

FeedbacksListPage.propTypes = {
    role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(FeedbacksListPage)
