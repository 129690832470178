import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import TextField from '../Field/TextField'

import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross.svg'

import styles from './SearchForm.module.scss'

export default function SearchForm(props) {
    const { register, handleSubmit, watch, errors, reset } = useForm({
        resolver: zodResolver(
            z.object({
                search: props.onEmpty ? z.string() : z.string().nonempty()
            })
        )
    })
    const search = watch('search', '')

    const onSubmit = data => props.onSubmit(data.search)
    const handleClear = e => {
        e.preventDefault()
        reset()
        props.onSubmit('')
    }

    useEffect(() => {
        if (!search.length && props.onEmpty) props.onEmpty()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    return (
        <form
            className={styles.root}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <button className={styles.actionSubmit} type="submit">
                <SearchIcon/>
            </button>
            <TextField
                fieldClass={styles.input}
                className={styles.field}
                error={errors.search}
                name="search"
                placeholder={props.placeholder}
                ref={register}
            />
            <button className={styles.actionCross} onClick={handleClear}>
                <CrossIcon/>
            </button>
        </form>
    )
}

SearchForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onEmpty: PropTypes.func
}
