import React from 'react'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'

import { AsyncPaginate } from 'react-select-async-paginate'

import * as service from '../../services/projects'

import { components } from 'react-select'

import styleSelect from '../../../UI/containers/Field/Select.module.scss'
import styles from './ResponsibleManager.module.scss'
import Tag from '../../../UI/containers/Tag/Tag'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'
import { TRoles } from '../../../../shared/types'

export default function ResponsibleManager(props) {
    const Option = props => {
        return (
            <div className={styles.option}>
                <Avatar
                    className={styles.adminPhoto}
                    name={props.data.label}
                    size="30"
                    color="#d2e1df"
                    textSizeRatio={1.75}
                    round={true}
                    src={props.data.image}
                    fgColor="#9ab8ac"
                />
                <components.Option {...props} />
            </div>
        )
    }

    async function loadOptions(search, loadedOptions) {
        const { data } = await service.getUsers({
            offset: loadedOptions.length,
            limit: 3,
            listType: 'USERS',
            filter: ['ADMIN', 'SUPER_ADMIN'],
        })

        const options = data.data.users.map(item => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            image: item.photoLink,
        }))

        return {
            options: options,
            hasMore: !!data.data.users.length,
        }
    }

    const handleChangeSelect = value => {
        service.changeOwner(props.projectId, value.value).then(() => {
            eventEmitter.emit(types.updateProjectList)
            eventEmitter.emit(types.updateProjectView)
        })
    }

    return (
        <div className={styles.root}>
            <div className={styles.form}>
                <span className={styles.title}>Responsible manager </span>
                <VisibilityAccess access={[TRoles.SUPER_ADMIN, TRoles.ADMIN]}>
                    <AsyncPaginate
                        className={clsx(styleSelect.select, styles.adminSelect)}
                        classNamePrefix="select"
                        placeholder="Choose employee"
                        loadOptions={loadOptions}
                        components={{ Option }}
                        onChange={handleChangeSelect}
                    />
                </VisibilityAccess>
            </div>
            <div className={styles.adminInfo}>
                <Avatar
                    className={styles.accountUser}
                    name={`${props.projectOwner.firstName} ${props.projectOwner.lastName}`}
                    src={props.projectOwner.photoLink}
                    size="40"
                    color="#d2e1df"
                    textSizeRatio={1.75}
                    fgColor="#9ab8ac"
                />

                <div className={styles.userInfo}>
                    <div className={styles.name}>
                        {`${props.projectOwner.firstName} 
                            ${props.projectOwner.lastName}`}
                    </div>
                    <Tag
                        className={styles.userType}
                        text="Project Manager"
                        bgColor="#eaf5df"
                        textColor="#9dce6c"
                    />
                </div>
                <div className={styles.col}>
                    <span>{props.projectOwner.telephoneNumber}</span>
                </div>
                <div className={styles.col}>
                    <span>{props.projectOwner.email}</span>
                </div>
            </div>
        </div>
    )
}

ResponsibleManager.propTypes = {
    info: PropTypes.shape({
        accountId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        usersList: PropTypes.string.isRequired,
    }),
}
