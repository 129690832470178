import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { TRoles, TRoutes } from '../shared/types'
import eventEmitter, { types } from '../shared/eventEmitter'

function PrivateRoute({ isAuth, role, shouldShowDisclaimer, component: Component, ...rest }) {
    useEffect(() => {
        if (!isAuth) return
        if (role === TRoles.INVESTOR && shouldShowDisclaimer) return eventEmitter.emit(types.openDisclaimerModal)
    }, [role, isAuth, shouldShowDisclaimer])

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth ? (
                    <Component/>
                ) : (
                    <Redirect
                        to={{
                            pathname: TRoutes.LOGIN,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
}

PrivateRoute.propTypes = {
    isAuth: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    shouldShowDisclaimer: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isAuth: state.user.isAuth,
    role: state.user.role,
    shouldShowDisclaimer: state.user.shouldShowDisclaimer
})

export default connect(mapStateToProps)(PrivateRoute)
