import React from 'react'
import PropTypes from 'prop-types'

import styles from './ProfileTitle.module.scss'

export default function ProfileTitle(props) {
    return (
        <h3 className={styles.root}>
            Hello, <span className={styles.name}>{props.name}</span>
        </h3>
    )
}

ProfileTitle.propTypes = {
    name: PropTypes.string.isRequired
}
