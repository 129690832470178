import React from 'react'
import styles from './EmailSettingsModal.module.scss'
import Checkbox from '../../../UI/containers/Checkbox/Checkbox'

export default function EmailItem({ emailTypes, changeNotificationList }) {


    return (
        <>
            {emailTypes && emailTypes.map((type, index) => {
                return (
                    <div className={styles.accountItem} key={index}>
                        <span>{type.label}</span>
                        <Checkbox
                            name={type.label}
                            label={''}
                            value={type.value}
                            onChange={e =>
                                changeNotificationList(index, e)
                            }
                        />
                    </div>
                )
            })}
        </>
    )
}
