import React from 'react'

import Button from '../../../../UI/containers/Button/Button'

import styles from './RequirementItem.module.scss'

const RequirementItem = ({itemText, buttonText, buttonClassName, onClick}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>{itemText}</div>
            <Button onClick={onClick} className={buttonClassName}>{buttonText}</Button>
        </div>
    )
}

export default RequirementItem