import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import clsx from 'clsx'

import { If, Then } from 'react-if'
import { parseISO, format as formatDate } from 'date-fns'
import Avatar from 'react-avatar'
import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'
import NdaDoc from '../../Projects/containers/NdaDoc/NdaDoc'

import eventEmitter, { types } from '../../../shared/eventEmitter'
import Tooltip from '../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../UI/containers/Tooltip/TooltipItem'
import { ReactComponent as ArrowTableIcon } from '../../../assets/icons/mini-arrow-table.svg'
import * as service from '../services/ndaList'

import { ReactComponent as MiniArrowIcon } from '../../../assets/icons/mini-arrow.svg'
import styles from './NdaList.module.scss'
import Button from '../../UI/containers/Button/Button'

export default function NdaList() {
    const [signedNda, setSignedNda] = useState(false)
    const [ndaList, setNdaList] = useState([])

    const fetchNda = () => {
        if (signedNda) {
            setNdaList([])
            setSignedNda(false)
        }
    }

    const handleClick = () => {
        if (!signedNda) {
            setNdaList([])
            setSignedNda(true)
        }
    }

    useLayoutEffect(() => {
        if (signedNda) {
            service
                .getNdaList({
                    filter: 'SIGNED',
                })
                .then(({ data }) => {
                    setNdaList(data.projects)
                })
        } else {
            service
                .getNdaList({
                    filter: 'TEMPLATES',
                })
                .then(({ data }) => {
                    setNdaList(data.projects)
                })
        }
    }, [signedNda])

    useEffect(() => {
        fetchNda(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.root}>
            <Header className={styles.headerTitle} title="NDA List">
                <div className={styles.buttons}>
                    <Button
                        bgColor={signedNda ? '#ffffff' : '#9dce6b'}
                        className={
                            signedNda
                                ? clsx(styles.leftBtn, styles.templatesBtn)
                                : clsx(styles.leftBtn, styles.signedBtn)
                        }
                        onClick={() => fetchNda()}
                    >
                        Templates
                    </Button>
                    <Button
                        bgColor={!signedNda ? '#ffffff' : '#9dce6b'}
                        className={
                            !signedNda ? styles.templatesBtn : styles.signedBtn
                        }
                        onClick={() => handleClick()}
                    >
                        Signed
                    </Button>
                </div>
            </Header>
            <ContentContainer>
                <div>
                    <div className={styles.colsName}>
                        <span className={styles.assignedProject}>
                            Assigned Project
                        </span>
                        <span className={styles.disclosingParty}>
                            Disclosing Party
                        </span>
                        <If condition={signedNda}>
                            <Then>
                                <span className={styles.recievingParty}>
                                    Receiving Party
                                </span>
                            </Then>
                        </If>
                    </div>
                    {ndaList.map(nda => (
                        <Accordion
                            className={styles.accordion}
                            allowMultipleExpanded
                            allowZeroExpanded
                            key={nda.id}
                        >
                            <AccordionItem
                                className={styles.accordionItem}
                                activeClassName={styles.accordionItemActive}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        className={styles.accordionButton}
                                    >
                                        <div
                                            className={clsx(
                                                styles.col,
                                                styles.colProject
                                            )}
                                        >
                                            <If condition={signedNda}>
                                                <Then>
                                                    <MiniArrowIcon
                                                        className={
                                                            styles.accordionIcon
                                                        }
                                                    />
                                                </Then>
                                            </If>
                                            <span>{nda.name}</span>
                                        </div>
                                        <div
                                            className={clsx(
                                                styles.col,
                                                styles.colDiscParty
                                            )}
                                        >
                                            <Avatar
                                                className={styles.userAvatar}
                                                src={nda.projectOwner.photoLink}
                                                name={nda.projectOwner.fullName}
                                                size="30"
                                                color="#d2e1df"
                                                textSizeRatio={1.75}
                                                round={true}
                                                fgColor="#9ab8ac"
                                            />
                                            <span>
                                                {nda.projectOwner.fullName}
                                            </span>
                                        </div>
                                        {signedNda ? (
                                            <div
                                                className={clsx(
                                                    styles.col,
                                                    styles.colRecParty
                                                )}
                                            >
                                                {!!nda.signedDocuments &&
                                                    nda.signedDocuments.map(
                                                        item => (
                                                            <Avatar
                                                                key={item}
                                                                className={
                                                                    styles.ndaUser
                                                                }
                                                                name={
                                                                    item
                                                                        .investor
                                                                        .investorFullName ||
                                                                    item
                                                                        .investor
                                                                        .accountName
                                                                }
                                                                src={
                                                                    item
                                                                        .investor
                                                                        .photoLink
                                                                }
                                                                size="30"
                                                                color="#d2e1df"
                                                                textSizeRatio={
                                                                    1.75
                                                                }
                                                                round={true}
                                                                fgColor="#9ab8ac"
                                                            />
                                                        )
                                                    )}
                                            </div>
                                        ) : (
                                            <>
                                                <span className={styles.time}>
                                                    {formatDate(
                                                        parseISO(
                                                            nda.NDATemplate
                                                                .createdAt
                                                        ),
                                                        'HH:mm'
                                                    )}
                                                </span>
                                                <span className={styles.date}>
                                                    {formatDate(
                                                        parseISO(
                                                            nda.NDATemplate
                                                                .createdAt
                                                        ),
                                                        'MMM, dd, yyyy'
                                                    )}
                                                </span>
                                                <Tooltip
                                                    className={styles.action}
                                                    renderIcon={() => (
                                                        <ArrowTableIcon />
                                                    )}
                                                    id={`nda-list-${nda.id}`}
                                                >
                                                    <TooltipItem
                                                        onClick={() =>
                                                            eventEmitter.emit(
                                                                types.openNdaTemplateModal,
                                                                {
                                                                    projectId:
                                                                        nda.id,
                                                                    name: nda.name,
                                                                    isEditMode: true,
                                                                    templateId:
                                                                        nda
                                                                            .NDATemplate
                                                                            .templateId,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Edit NDA template
                                                    </TooltipItem>
                                                </Tooltip>
                                            </>
                                        )}
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <If condition={signedNda}>
                                    <Then>
                                        <AccordionItemPanel>
                                            <NdaDoc
                                                signedDocuments={
                                                    nda.signedDocuments
                                                }
                                            />
                                        </AccordionItemPanel>
                                    </Then>
                                </If>
                            </AccordionItem>
                        </Accordion>
                    ))}
                </div>
            </ContentContainer>
        </div>
    )
}
