import React, { useState } from 'react'
import clsx from 'clsx'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import { types } from '../../../../shared/eventEmitter'

import styles from './LogoutNotificationModal.module.scss'
import { TRoutes } from '../../../../shared/types'
import { cleanToken } from '../../../../shared/helpers'
import { useHistory } from 'react-router-dom'

export default function LogoutNotificationModal() {
    const [headerText, setHeaderText] = useState('')
    const [messageText, setMessageText] = useState('')

    const history = useHistory()

    const handleOpen = ({ header, message }) => {
        setHeaderText(header)
        setMessageText(message)
    }

    const handleSubmit = closeModal => e => {
        e.preventDefault()

        cleanToken()
        history.push(TRoutes.LOGIN)

        closeModal()
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openLogoutNotificationModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title={headerText}
                        titleClassName={styles.headerTitle}
                    />
                    <div className={styles.container}>
                        <div className={styles.message}>{messageText}</div>
                        <div className={styles.actions}>
                            <Button
                                onClick={() => closeModal()}
                                bgColor="#ffffff"
                                className={clsx(
                                    styles.action,
                                    styles.actionCancel
                                )}
                            >
                                No
                            </Button>
                            <Button
                                className={styles.action}
                                onClick={handleSubmit(closeModal)}
                                type="submit"
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
