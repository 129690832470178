import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { TRoutes } from '../../../../shared/types'

import LogoImg from '../../../../assets/images/logo.svg'

import styles from './Logo.module.scss'

export default function Logo(props) {
    return (
        <Link to={TRoutes.MAIN}>
            <img
                className={clsx(styles.root, props.className)}
                src={LogoImg}
                alt="Logo atrium"
            />
        </Link>
    )
}
