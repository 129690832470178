import React from 'react'

import styles from './NdaInvestor.module.scss'

export default function NdaInvestor(props) {

    return (
        <div className={styles.root}>
            <h3 className={styles.title}>NDAs</h3>
            {!!props.nda && (
                <button className={styles.actionReadDocument}>
                    <a
                        href={props.nda.url}
                        rel="noreferrer noopener"
                        target="_blank"
                        className={styles.actionDownload}
                    >
                        Read Document
                    </a>
                </button>
                //<button
                //    className={styles.actionReadDocument}
                //    onClick={() => handlePreview(props.nda.url, props.nda.name)}
                //>
                //    Read Document
                //</button>
            )}
        </div>
    )
}
