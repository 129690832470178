import React from 'react'
import PropTypes from 'prop-types'
import clxs from 'clsx'

import Button from '../Button/Button'
import UploadButton from '../UploadButton/UploadButton'

import { ReactComponent as IconPinned } from '../../../../assets/icons/pinned.svg'

import styles from './UploadFile.module.scss'

export default function UploadFile(props) {
    return (
        <div className={clxs(styles.root, props.className)}>
            <UploadButton keyInput={props.keyInput} accept={props.accept} onFiles={props.onFiles}>
                {({ handleClick }) => (
                    <Button
                        isLoading={props.isLoading}
                        bgColor={props.bgColor}
                        className={clxs(
                            styles.actionImage,
                            props.classNameButton
                        )}
                        onClick={handleClick}
                        renderIcon={() => {
                            return (
                                props.icon || (
                                    <IconPinned className={styles.pinnedIcon} />
                                )
                            )
                        }}
                    >
                        {props.text}
                    </Button>
                )}
            </UploadButton>
        </div>
    )
}

UploadFile.propTypes = {
    onFiles: PropTypes.func.isRequired,
    accept: PropTypes.string,
    text: PropTypes.string,
    classNameButton: PropTypes.string,
    bgColor: PropTypes.string,
    keyInput: PropTypes.number,
    isLoading: PropTypes.bool
}

UploadFile.defaultProps = {
    accept: 'application/pdf',
    text: 'Attach legal document',
    classNameButton: '',
    bgColor: '#f4f4f6',
    isLoading: false
}
