import { ToastContainer, toast } from 'react-toastify'
import styles from './Toast.module.scss'

export default function Toast() {
    const CloseButton = ({ closeToast }) => (
        <div className={styles.closeBtn} onClick={closeToast}>
            CLOSE
        </div>
    )

    return (
        <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            closeButton={CloseButton}
        />
    )
}
