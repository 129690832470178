import React from 'react'
import Avatar from 'react-avatar'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'

import { ReactComponent as MiniArrowIcon } from '../../../../assets/icons/mini-arrow.svg'

import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import { ReactComponent as ArrowTableIcon } from '../../../../assets/icons/mini-arrow-table.svg'

import styles from './AccountItem.module.scss'
import ProBuyerItem from '../ProBuyerItem/ProBuyerItem'
import Button from '../../../UI/containers/Button/Button'
import { TRoles } from '../../../../shared/types'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'
import AddButton from '../../../UI/containers/AddButton/AddButton'

export default function AccountItem({ info }) {
    return (
        <Accordion
            className={styles.accordion}
            allowMultipleExpanded
            allowZeroExpanded
        >
            <AccordionItem
                className={styles.accordionItem}
                activeClassName={styles.accordionItemActive}
            >
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.accordionButton}>
                        <MiniArrowIcon className={styles.accordionIcon} />
                        {info.name}
                        <VisibilityAccess access={[TRoles.PRO_BUYER]}>
                            <Button
                                bgColor="#9dce6b"
                                className={styles.inviteAction}
                                onClick={() =>
                                    eventEmitter.emit(types.openCreateProbuyerModal, info)
                                }
                            >
                Invite
                            </Button>
                        </VisibilityAccess>
                        <div
                            className={clsx(
                                styles.list,
                                info.count && styles.listWithoutBorder
                            )}
                        >
                            {info.count && (
                                <span className={styles.count}>{info.count} teammates</span>
                            )}
                            {info.usersList.slice(0, 5).map(item => (
                                <Avatar
                                    key={item.id}
                                    className={styles.accountUser}
                                    name={`${item.firstName} ${item.lastName}`}
                                    size="30"
                                    color="#d2e1df"
                                    textSizeRatio={1.75}
                                    round={true}
                                    src={item.photoLink}
                                    fgColor="#9ab8ac"
                                />
                            ))}
                            <VisibilityAccess access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}>
                                <AddButton
                                    onClick={() =>
                                        eventEmitter.emit(types.openCreateProbuyerModal, info)
                                    }
                                />
                            </VisibilityAccess>
                        </div>
                        <VisibilityAccess access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}>
                            <Tooltip
                                renderIcon={() => <ArrowTableIcon />}
                                className={styles.actionMenu}
                                id={`user-list-${info.accountId}`}
                            >
                                <TooltipItem
                                    onClick={() =>
                                        eventEmitter.emit(types.openCreateAccountModal, info)
                                    }
                                >
                  Edit
                                </TooltipItem>
                            </Tooltip>
                        </VisibilityAccess>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.accordionPanel}>
                    {info.usersList.map(item => (
                        <ProBuyerItem
                            key={item.id}
                            info={{
                                userId: item.id,
                                firstName: item.firstName,
                                lastName: item.lastName,
                                phone: item.telephoneNumber,
                                photoLink: item.photoLink,
                                email: item.email,
                                role: item.role,
                                deletedAt: item.deletedAt,
                            }}
                        />
                    ))}
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    )
}

AccountItem.propTypes = {
    info: PropTypes.shape({
        accountId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        usersList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                telephoneNumber: PropTypes.string.isRequired,
                photoLink: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                role: PropTypes.string,
            })
        ),
    }),
}
