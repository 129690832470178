import React from 'react'
import './AboutUs.module.scss'

import styles from './AboutUs.module.scss'
import stylesLanding from '../Landing.module.scss'

export default function AboutUs() {
    return (
        <div id="about" className={styles.root}>
            <div className={stylesLanding.container}>
                <h2 className={styles.title}>About us</h2>
                <div className={styles.paragraphs}>
                    <div className={styles.paragraphsCol}>
                        <p className={styles.paragraph}>
                            ATRIUM Connect is a platform where professional investors can get access to carefully selected investment opportunities.
                        </p>
                        <p className={styles.paragraph}>The platform is owned and operated by ATRIUM Partners A/S, an M&A advisor focusing on corporate finance activities in and around Denmark.</p>
                        <p className={styles.paragraph}>
                            The focus of the platform is to provide growth capital for established businesses looking to grow via equity investments.
                        </p>
                    </div>
                    <div className={styles.paragraphsCol}>
                        <p className={styles.paragraph}>
                            The platform is accessible by invitation only, allowing for carefully vetting of both investors and fundraisers.
                        </p>
                        <p className={styles.paragraph}>
                            If you would like to be taken into consideration, please contact ATRIUM Partners via the contact formula below or visit
                            <a  className={styles.link} href='https://www.atriumpartners.dk/en/front-page/#teamet' rel="noreferrer noopener" target="_blank"> www.atriumpartners.dk </a> for direct contact details.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
