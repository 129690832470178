import ee from 'event-emitter'

const Emiter = function () {}

export default ee(Emiter.prototype)

export const types = {
    openEditProfileModal: 'openEditProfileModal',
    openCreateNdaModal: 'openCreateNdaModal',
    openCreateUserModal: 'openCreateUserModal',
    openCreateAdminModal: 'openCreateAdminModal',
    openCreateAccountModal: 'openCreateAccountModal',
    openCreateProbuyerModal: 'openCreateProbuyerModal',
    openProjectModal: 'openProjectModal',
    openDisclaimerModal: 'openDisclaimerModal',
    openProfileInfoModal: 'openProfileInfoModal',
    openFeedbackModal: 'openFeedbackModal',
    openFolderModal: 'openFolderModal',
    openFileModal: 'openFileModal',
    openOfferModal: 'openOfferModal',
    closeOfferModal: 'closeOfferModal',
    openTeaserModal: 'openTeaserModal',
    openTeaserLinksModal: 'openTeaserLinksModal',
    openNdaTemplateModal: 'openNdaTemplateModal',
    openNdaModal: 'openNdaModal',
    openPostNdaModal: 'openPostNdaModal',
    openProjectAccessModal: 'openProjectAccessModal',
    openOfflineNdaModal: 'openOfflineNdaModal',
    openNotificationModal: 'openNotificationModal',
    openArchiveUserModal: 'openArchiveUserModal',
    openUnArchiveUserModal: 'openUnArchiveUserModal',
    openDeleteUserModal: 'openDeleteUserModal',
    openAdminDeleteUserModal: 'openAdminDeleteUserModal',
    openArchiveProjectModal: 'openArchiveProjectModal',
    openCreateSellerModal: 'openCreateSellerModal',
    openSellerAccessModal: 'openSellerAccessModal',
    openLogoutNotificationModal: 'openLogoutNotificationModal',
    openYesNoModal: 'openYesNoModal',
    openProjectPublishingModal: 'openProjectPublishingModal',
    openTrackerUserListModal: 'openTrackerUserListModal',
    openTrackerCommentModal: 'openTrackerCommentModal',
    openEmailSettingsModal: 'openEmailSettingsModal',
    openAdminProfileInfoModal: 'openAdminProfileInfoModal',

    updateInvestorList: 'updateInvestorList',
    updateOwnerList: 'updateOwnerList',
    updateAccountList: 'updateAccountList',
    updateSellerList: 'updateSellerList',
    updateProjectList: 'updateProjectList',
    updateProjectView: 'updateProjectView',
    updateFolderList: 'updateFolderList',
    updateOwnAccount: 'updateOwnAccount',
    updateDashboardUser: 'updateDashboardUser',
    updateDashboardAccount: 'updateDashboardAccount',
    updateAccountTrackerList: 'updateAccountTrackerList',
    updateAdminList: 'updateAdminList',
    updateSuperAdminList: 'updateSuperAdminList',

    handleSocketNDASign: 'handleSocketNDASign',
}
