import http from '../../../shared/http'

export function getMessages(params) {
    return http.get('/chat', { params })
}

export function createMessage(body) {
    return http.post('/chat', body)
}

export function markReadMessage(body) {
    return http.put('/chat/mark-read', body)
}
