import { io } from 'socket.io-client'
import { API_URL } from './config'

const socketNda = io(API_URL + '/nda', {
    autoConnect: false,
    auth: cb => {
        const authInfo = JSON.parse(localStorage.getItem('auth') || '{}')

        cb({
            token: `Bearer ${authInfo.token}`
        })
    }
})


socketNda.on('connect', () => {
    console.log(socketNda)
})

export default socketNda
