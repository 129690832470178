import React from 'react'
import clsx from 'clsx'

import Logo from '../../components/Logo/Logo'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm'

import styles from './ForgotPasswordView.module.scss'
import stylesLanding from '../../components/Landing.module.scss'

export default function ForgotPasswordView() {
    return (
        <div className={styles.root}>
            <div className={clsx(stylesLanding.container, styles.container)}>
                <div className={styles.leftCol}>
                    <Logo />
                    <div className={styles.wrapperTitle}>
                        <h1
                            className={clsx(
                                stylesLanding.titleMain,
                                styles.titleMain
                            )}
                        >
                            Forgot your password?
                        </h1>
                        <p className={styles.paragraph}>
                            Please enter the email address registered on your
                            account
                        </p>
                    </div>
                </div>
                <div className={styles.rightCol}>
                    <ForgotPasswordForm />
                </div>
            </div>
        </div>
    )
}
