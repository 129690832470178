import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'

import NotificationsListMini from '../../PreviewList/containers/NotificationsListMini/NotificationsListMini'

import stylesView from './View.module.scss'
import AccountItem from '../../Accounts/components/AccountItem/AccountItem'

import * as services from '../services/dashboard'
import eventEmitter, { types } from '../../../shared/eventEmitter'
import { TRoles } from '../../../shared/types'

function ViewProBuyer() {
    const [account, setAccount] = useState(0)
    const [count, setCount] = useState(0)

    const handleUpdateOwnAccount = () => {
        services.getOwnAccount().then(({ data }) => {
            setAccount(data.account)
            setCount(data.account.users.length)
        })
    }

    useEffect(() => {
        handleUpdateOwnAccount()

        eventEmitter.on(types.updateOwnAccount, handleUpdateOwnAccount)

        return () =>
            eventEmitter.off(types.updateOwnAccount, handleUpdateOwnAccount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={stylesView.root}>
            <Header title="Overview" />
            <ContentContainer>
                <div className={stylesView.topRow}>
                    {!!account && (
                        <AccountItem
                            key={account.id}
                            info={{
                                accountId: account.id,
                                name: account.name,
                                count: count,
                                usersList: account.users,
                                role: TRoles.PRO_BUYER,
                            }}
                        />
                    )}
                </div>

                <div className={stylesView.bottomRow}>
                    <NotificationsListMini
                        className={stylesView.notificationsList}
                    />
                </div>
            </ContentContainer>
        </div>
    )
}

const mapStateToProps = state => ({
    userProfile: {
        firstName: state.user.userProfile.firstName,
        lastName: state.user.userProfile.lastName,
        photoLink: state.user.userProfile.photoLink,
    },
})

export default connect(mapStateToProps)(ViewProBuyer)
