import React, { useState, useEffect } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { linearGradientDef } from '@nivo/core'
import { AsyncPaginate } from 'react-select-async-paginate'
import clsx from 'clsx'
import {
    format as dateFormat,
    parseISO,
    parse as parseDate,
    sub as dateSub,
    differenceInDays
} from 'date-fns'

import SimpleSelect from '../../../UI/containers/Field/SimpleSelect'
import Button from '../../../UI/containers/Button/Button'

import CardButton from '../../components/СardButton/CardButton'
import CardsAdmin from '../../components/CardsAdmin/CardsAdmin'

import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-uncolor.svg'
import { ReactComponent as ShareIcon } from '../../../../assets/icons/share.svg'

import { getProjects } from '../../services/projects'
import * as service from '../../services/statistic'

import { TStatistic, TProjects } from '../../../../shared/types'
import { downloadFile } from '../../../../shared/helpers'

import styles from './StatisticView.module.scss'
import styleSelect from '../../../UI/containers/Field/Select.module.scss'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

const dateOptions = [
    { value: dateFormat(dateSub(new Date(), { weeks: 1 }), 'dd.MM.yyyy'), label: 'Last Week' },
    { value: dateFormat(dateSub(new Date(), { months: 1 }), 'dd.MM.yyyy'), label: 'Last Month' },
    { value: dateFormat(dateSub(new Date(), { months: 6 }), 'dd.MM.yyyy'), label: 'Last 6 Month' },
    { value: dateFormat(dateSub(new Date(), { years: 1 }), 'dd.MM.yyyy'), label: 'Last Year' }
]

function StatisticView() {
    const [statisticDetailChart, setStatisticDetailChart] = useState([{
        id: 'NDA Signed',
        color: '#9dce6c',
        data: []
    }])
    const [activeTitle, setActiveTitle] = useState('NDA Signed')
    const [typeCardActive, setTypeCardActive] = useState(TStatistic.NDA_SIGNED)
    const [statistic, setStatistic] = useState({
        forAdmin: {
            countNewLastWeek: {}
        }
    })

    const [projectSelect, setProjectSelect] = useState({ label: 'All project', value: 'all' })
    const [dateRange, setDateRange] = useState(dateOptions[0])

    const [isDownloading, setIsDownloading] = useState(false)
    const [isSharing, setIsSharing] = useState(false)

    async function loadOptions(search, loadedOptions) {
        const { data } = await getProjects({
            offset: loadedOptions.length,
            limit: 20,
            search
        })

        const options = data.projects.map(item => ({
            label: item.name,
            value: item.projectId,
            type: item.type
        }))

        return {
            options: loadedOptions.length ? options : [{ label: 'All project', value: 'all' }, ...options],
            hasMore: !!data.projects.length
        }
    }

    function formatChart(title, data) {
        return [
            {
                id: title,
                color: '#9dce6c',
                data
            }
        ]
    }

    const handleDownloadPdf = () => {
        setIsDownloading(true)
        service.getStatisticPdf(getFetchParams())
            .then(({ data }) => {
                let filename = 'all-projects'

                if (projectSelect.value !== 'all') filename = projectSelect.label

                downloadFile(new Blob([data], { type: 'application/pdf' }), `${filename}-statistic.pdf`)
            })
            .finally(() => {
                setIsDownloading(false)
            })
    }

    const getFetchParams = () => {
        const params = {
            toDate: dateFormat(new Date(), 'dd.MM.yyyy'),
            fromDate: dateRange.value
        }

        if (projectSelect.value !== 'all') {
            params.projectId = projectSelect.value
        }

        return params
    }

    const fetchStatistic = () => {
        service.getStatistic(getFetchParams()).then(({ data }) => {
            setStatistic(data.statistic)
        })
    }

    const fetchStatisticDetail = () => {
        const params = {
            toDate: dateFormat(new Date(), 'dd.MM.yyyy'),
            fromDate: dateRange.value
        }

        if (projectSelect.value !== 'all') {
            params.projectId = projectSelect.value
        }

        service.getStatisticDetail({
            ...getFetchParams(),
            type: typeCardActive
        }).then(({ data }) => {
            const dayDiff = differenceInDays(
                new Date(),
                parseDate(dateRange.value, 'dd.MM.yyyy', new Date())
            )

            setStatisticDetailChart(
                formatChart(
                    activeTitle,
                    data.statistic.map(item => ({
                        x: dateFormat(parseISO(item.date), dayDiff < 32 ? 'EEEE' : 'MMMM'),
                        y: item.count
                    }))
                )
            )
        })
    }

    const handleSelectCard = type => ({ title }) => {
        setTypeCardActive(type)
        setActiveTitle(title)
    }

    const handleShareBO = () => {
        const params = {
            toDate: dateFormat(new Date(), 'dd.MM.yyyy'),
            fromDate: dateRange.value,
            projectId: projectSelect.value
        }

        setIsSharing(true)
        service.shareStatisticWithBO(params)
            .then(() => {
                eventEmitter.emit(types.openNotificationModal, {
                    header: 'Statistics',
                    message: 'Statistics was successfully shared',
                })
            })
            .finally(() => setIsSharing(false))
    }

    useEffect(() => {
        fetchStatistic()
        fetchStatisticDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, projectSelect, typeCardActive])

    useEffect(() => {
        fetchStatisticDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTitle])

    return (
        <div>
            <CardsAdmin values={statistic.forAdmin}/>
            <div className={styles.wrapperTitle}>
                <h3>Generate Stats for</h3>
                <SimpleSelect
                    value={dateRange}
                    onChange={option => setDateRange(option)}
                    options={dateOptions}
                    className={styles.selectFilterDate}/>
                <Button
                    onClick={handleDownloadPdf}
                    isLoading={isDownloading}
                    className={styles.downloadFileButton}
                    renderIcon={() => <DownloadIcon fill="#fff" className={styles.downloadIcon}/>}
                >
                    Download as PDF
                </Button>
            </div>
            <div className={styles.filters}>
                <AsyncPaginate
                    className={clsx(
                        styleSelect.select,
                        styles.projectSelect
                    )}
                    classNamePrefix="select"
                    value={projectSelect}
                    loadOptions={loadOptions}
                    onChange={setProjectSelect}
                />
                {(projectSelect && (projectSelect.value !== 'all') && (projectSelect.type !== TProjects.M_AND_A) ) && (
                    <Button
                        onClick={handleShareBO}
                        className={styles.shareBoAction}
                        isLoading={isSharing}
                        isLoadingMessage={'Sharing...'}
                        renderIcon={() => <ShareIcon fill="#fff" className={styles.shareIcon}/>}
                    >
                        Share with BO
                    </Button>
                )}
            </div>
            <div className={styles.buttonCards}>
                <CardButton
                    value={statistic[TStatistic.NDA_SIGNED]}
                    activeTitle={activeTitle} title="NDA Signed"
                    onClick={handleSelectCard(TStatistic.NDA_SIGNED)}
                />
                <CardButton
                    value={statistic[TStatistic.WATCH_TEASER_MODAL]}
                    activeTitle={activeTitle} title="Watch Teaser Module"
                    onClick={handleSelectCard(TStatistic.NDA_SIGNED)}
                />
                <CardButton
                    value={statistic[TStatistic.QUESTIONS_ASKED]}
                    activeTitle={activeTitle} title="Questions Asked"
                    onClick={handleSelectCard(TStatistic.QUESTIONS_ASKED)}/>
                <CardButton
                    value={statistic[TStatistic.DEALS]}
                    activeTitle={activeTitle} title="Deals"
                    onClick={handleSelectCard(TStatistic.DEALS)}/>
                <CardButton
                    value={statistic[TStatistic.PROJECT_VIEWS]}
                    activeTitle={activeTitle} title="Project Views"
                    onClick={handleSelectCard(TStatistic.PROJECT_VIEWS)}/>
                <CardButton
                    value={statistic[TStatistic.FEEDBACKS_LEFT]}
                    activeTitle={activeTitle} title="Feedbacks Left"
                    onClick={handleSelectCard(TStatistic.FEEDBACKS_LEFT)}/>
                <CardButton
                    value={statistic[TStatistic.QUESTIONS_ANSWERED]}
                    activeTitle={activeTitle}
                    title="Questions Answered"
                    onClick={handleSelectCard(TStatistic.QUESTIONS_ANSWERED)}/>
                <CardButton
                    value={statistic[TStatistic.FILE_VIEWS]}
                    activeTitle={activeTitle}
                    title="File Views"
                    onClick={handleSelectCard(TStatistic.FILE_VIEWS)}/>
            </div>
            {!!statisticDetailChart[0].data.length && <h2 className={styles.titleChart}>{activeTitle}</h2>}
            {!!statisticDetailChart[0].data.length && (
                <div className={styles.wrapperChart}>
                    <ResponsiveLine
                        data={statisticDetailChart}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        defs={[
                            linearGradientDef('gradientA', [
                                { offset: 0, color: '#9dce6b' },
                                { offset: 500, color: '#9dce6b' }
                            ])
                        ]}
                        fill={[{ match: '*', id: 'gradientA' }]}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'top',
                                direction: 'column',
                                justify: false,
                                translateX: 0,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                translateY: -44,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
            )}
        </div>
    )
}

export default StatisticView
