import React, { useState } from 'react'
import clsx from 'clsx'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/notification'

import styles from './ArchiveProjectModal.module.scss'

export default function ArchiveProjectModal() {
    const [isLoading, setLoading] = useState(false)
    const [headerText, setHeaderText] = useState('')
    const [messageText, setMessageText] = useState('')

    const [projectId, setProjectId] = useState(null)

    const handleOpen = ({ projectId, header, message }) => {
        setProjectId(projectId)
        setHeaderText(header)
        setMessageText(message)
    }

    const handleSubmit = closeModal => {
        setLoading(true)
        const archivedProject = service.archiveProject(projectId)
        if(archivedProject) {
            setLoading(false)
            closeModal()
            eventEmitter.emit(types.openNotificationModal, {
                header: 'Archive project',
                message: 'Project successfully archived',
            })
            eventEmitter.emit(types.updateProjectList)
            eventEmitter.emit(types.updateProjectView)
        } else {
            setLoading(false)
        }
        /** prev version delete after tests**/
        // service
        // .archiveProject(projectId)
        // .then(() => {
        //     setLoading(false)
        //     closeModal()
        //     eventEmitter.emit(types.openNotificationModal, {
        //         header: 'Archive project',
        //         message: 'Project successfully archived',
        //     })
        //     eventEmitter.emit(types.updateProjectList)
        //     eventEmitter.emit(types.updateProjectView)
        // })
        // .finally(() => setLoading(false))
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openArchiveProjectModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title={headerText}
                        titleClassName={styles.headerTitle}
                    />
                    <div className={styles.container}>
                        <form autoComplete="off">
                            <div className={styles.message}>{messageText}</div>
                            <div className={styles.actions}>
                                <Button
                                    onClick={() => closeModal()}
                                    bgColor="#ffffff"
                                    className={clsx(
                                        styles.action,
                                        styles.actionCancel
                                    )}
                                    disabled={isLoading}
                                >
                                    No
                                </Button>
                                <Button
                                    className={styles.action}
                                    isLoading={isLoading}
                                    onClick={() => handleSubmit(closeModal)}
                                    type="submit"
                                >
                                    Yes
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
