import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from '../Button/Button'

import styles from './ToastNewMessage.module.scss'
import { TRoles, TRoutes } from '../../../../shared/types'

function ToastNewMessage({ projectId, projectName, role }) {
    const history = useHistory()

    return (
        <div className={styles.root}>
            <div>
                <span>You have new message!</span>
                <br/>
                <span>{projectName}</span>
            </div>
            <Button className={styles.actionGoProjects} onClick={() => {
                history.push(role === TRoles.OWNER ? TRoutes.PROJECT : TRoutes.PROJECT_ID(projectId))
            }}>Go to project</Button>
        </div>
    )
}

ToastNewMessage.propTypes = {
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    role: state.user.role
})

export default connect(mapStateToProps)(ToastNewMessage)
