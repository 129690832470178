import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'
import Button from '../../../UI/containers/Button/Button'
import { ReactComponent as AcceptIcon } from '../../../../assets/icons/accept.svg'

import * as service from '../../../Projects/services/projects'

import { TRoles, TRoutes } from '../../../../shared/types'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

import styles from './PostNdaModal.module.scss'

function PostNdaModal(props) {
    const history = useHistory()
    const [projectId, setProjectId] = useState('')
    const [isInvestor, setisInvestor] = useState(null)
    const [signed, setSigned] = useState(false)

    const handleOpen = data => {
        setisInvestor(props.role === TRoles.INVESTOR)
        setProjectId(data.projectId)
    }

    const handleSignedNda = () => {
        setSigned(true)
        if (props.role === TRoles.PRO_BUYER) {
            eventEmitter.emit(types.updateProjectView)
        }
    }

    const handleSubmit = closeModal => {
        history.push(TRoutes.PROJECT_ID(projectId))
        service.postProjectView(projectId)
        closeModal()
        setSigned(false)
        setProjectId('')
    }

    useEffect(() => {
        eventEmitter.on(types.handleSocketNDASign, handleSignedNda)
        return () => {
            eventEmitter.off(types.handleSocketNDASign, handleSignedNda)
        }
    })

    return (
        <BaseModal
            disabledBgClose
            disabledCrossClose
            classModalName={styles.modal}
            nameEvent={types.openPostNdaModal}
            onOpen={handleOpen}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title="Non Disclosure Agreement(NDA)"
                        titleClassName={styles.headerTitle}
                    />

                    {signed ? (
                        <div className={styles.container}>
                            <AcceptIcon />
                            <Button
                                className={styles.continueBtn}
                                bgColor="#9dce6c"
                                onClick={() => handleSubmit(closeModal)}
                            >
                                {isInvestor
                                    ? 'Go to project'
                                    : 'Receive documents'}
                            </Button>
                        </div>
                    ) : (
                        <div className={styles.container}>
                            <ClipLoader
                                color="#587b77"
                                loading="true"
                                css={styles.loader}
                                size={44}
                            />
                            Getting your files
                        </div>
                    )}
                </>
            )}
        </BaseModal>
    )
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(PostNdaModal)
