import http from '../../../shared/http'

/*
// /\/users\/\w+/
mock.onGet('/projects/documents').reply(200, {
    status: 'ok',
    nodes: [
        {
            name: 'Agreements',
            nodeType: 'FOLDER',
            createdAt: '1605270489696',
            files: [
                {
                    nodeType: 'FILE',
                    name: 'report',
                    location: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/32efe547-541b-401f-a6ce-d14a73f51e56',
                    createdAt: '1605270489696'
                },
                {
                    nodeType: 'FILE',
                    name: 'report',
                    location: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/32efe547-541b-401f-a6ce-d14a73f51e56',
                    createdAt: '1605270489696'
                },
                {
                    nodeType: 'FILE',
                    name: 'report',
                    location: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/32efe547-541b-401f-a6ce-d14a73f51e56',
                    createdAt: '1605270489696'
                }
            ]
        },
        {
            name: 'Agreements',
            nodeType: 'FOLDER',
            createdAt: '1605270489696',
            files: [
                {
                    nodeType: 'FILE',
                    name: 'report',
                    location: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/32efe547-541b-401f-a6ce-d14a73f51e56',
                    createdAt: '1605270489696'
                },
                {
                    nodeType: 'FILE',
                    name: 'report',
                    location: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/32efe547-541b-401f-a6ce-d14a73f51e56',
                    createdAt: '1605270489696'
                },
                {
                    nodeType: 'FILE',
                    name: 'report',
                    location: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/32efe547-541b-401f-a6ce-d14a73f51e56',
                    createdAt: '1605270489696'
                }
            ]
        }
    ]
})
*/

export function getDocuments(projectId) {
    return http.get(`/projects/${projectId}/documents`)
}

export async function deleteDocument(documentId) {
    return http.delete(`/projects/documents/${documentId}`)
}

export async function deleteFolder(folderId) {
    return http.delete(`/projects/documents/folders/${folderId}`)
}

export function registerProjectDocumentsView(projectId) {
    return http.put(`/statistic/${projectId}/project-documents-view`)
}

export function getWatermarkedDocuments(documentId) {
    return http.get(`/projects/documents/${documentId}/watermarked`, {
        responseType: 'blob',
    })
}
