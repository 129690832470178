import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import AnimatedNumber from 'animated-number-react'

import styles from './CardButton.module.scss'

function CardButton(props) {
    return (
        <button
            onClick={() => props.onClick({ title: props.title })}
            className={clsx(styles.root, props.activeTitle === props.title && styles.activeCard)}>
            <span className={styles.value}>
                <AnimatedNumber
                    formatValue={value => Math.trunc(value)}
                    value={props.value}
                />
            </span>
            <span className={styles.description}>{props.title}</span>
        </button>
    )
}

CardButton.propTypes = {
    title: PropTypes.string.isRequired,
    activeTitle: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

CardButton.defaultProps = {
    value: 0
}


export default CardButton
