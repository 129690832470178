import React, { useState } from 'react'
import clsx from 'clsx'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import { types } from '../../../../shared/eventEmitter'

import styles from './TeaserLinksModal.module.scss'

import TextField from '../../../UI/containers/Field/TextField'
import * as z from 'zod'

export default function TeaserLinksModal() {
    const [headerText, setHeaderText] = useState('')
    const [messageText, setMessageText] = useState('')
    const [onSubmit, setOnSubmit] = useState(() => () => {})

    const { register, handleSubmit, errors} = useForm({
        resolver: zodResolver(
            z.object({
                url: z.string().url(),
                urlSource: z.string().url(),
            })
        ),
    })

    const handleOpen = ({ header, message, onSubmit }) => {
        setHeaderText(header)
        setMessageText(message)
        setOnSubmit(() => onSubmit)
    }

    const handleCancel = ({closeModal}) => {
        closeModal()
    }

    const handleFormSubmit = async ({data, closeModal}) => {
        const teaser = {
            sourceUrl: data.urlSource,
            previewUrl: data.url.replace('/pub', '/embed'),
        }

        await onSubmit(teaser)

        closeModal()
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openTeaserLinksModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title={headerText}
                        titleClassName={styles.headerTitle}
                    />
                    <div className={styles.container}>
                        <div className={styles.message}>{messageText}</div>
                        <form
                            className={styles.form}
                            onSubmit={handleSubmit(async data => {
                                await handleFormSubmit({data, closeModal})
                            })}
                            autoComplete="off"
                        >
                            <TextField
                                className={styles.field}
                                error={errors.url}
                                label="Preview url"
                                name="url"
                                placeholder="Url presentation"
                                ref={register}
                            />
                            <TextField
                                error={errors.url}
                                label="Source url"
                                name="urlSource"
                                placeholder="Source url presentation"
                                ref={register}
                            />
                            <div className={styles.formActions}>
                                <Button
                                    onClick={() => handleCancel({closeModal})}
                                    bgColor="rgb(212, 144, 144)"
                                    className={styles.action}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className={clsx(
                                        styles.action,
                                        styles.actionSaveUrl
                                    )}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
