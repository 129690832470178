import http from '../../../shared/http'

//mock.onPost('/leave-feedback').reply(200,{
//    message:'Ok'
//})


export function sendFeedback(projectId, data) {
    return http.post(`/projects/${projectId}/feedback`, {
        text: data
    })
}