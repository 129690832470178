import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'

import styles from './NotificationContent.module.scss'

export default function NotificationContent(props) {
    return (
        <div className={styles.notification}>
            <Avatar
                name={props.name}
                className={styles.userAvatar}
                size="30"
                src={props.photoLink}
                color="#d2e1df"
                textSizeRatio={1.75}
                round={true}
                fgColor="#9ab8ac"
            />
            {props.children}
        </div>
    )
}

NotificationContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    name: PropTypes.string.isRequired,
    photoLink: PropTypes.string
}
