import React, { useState } from 'react'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
// import { toast } from 'react-toastify'

import { If, Then, Else } from 'react-if'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'
import UploadAvatar from '../../../UI/containers/UploadAvatar/UploadAvatar'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/project'

import styles from './ProjectModal.module.scss'
import { readURL } from '../../../../shared/helpers'
import Select from '../../../UI/containers/Field/Select'

export default function ProjectModal() {
    const [isLoading, setLoading] = useState(false)
    const [projectId, setProjectId] = useState('')
    const [avatarSrc, setAvatarSrc] = useState('')
    const [isEditMode, setEditMode] = useState(false)
    const [markRemovePhoto, setMarkRemovePhoto] = useState(false)
    const [file, setFile] = useState(null)

    const { register, handleSubmit, errors, control, reset, setValue } =
        useForm({
            resolver: zodResolver(
                z.object({
                    projectType: isEditMode
                        ? z.string().optional()
                        : z.string().nonempty({
                            message: 'You should select project type',
                        }),
                    businessType: z.string().nonempty(),
                    name: z.string().nonempty(),
                    description: z.string().nonempty(),
                })
            ),
        })

    const handleOpen = info => {
        setAvatarSrc('')
        setFile([])
        setEditMode(false)
        if (info) {
            setEditMode(true)
            setProjectId(info.projectId)
            setTimeout(() => {
                setAvatarSrc(info.projectPhotoLink)
                setValue('projectOwnerFullName', info.projectOwnerFullName)
                setValue('businessType', info.businessType)
                setValue('name', info.name)
                setValue('description', info.description)
            }, 0)
        }
    }

    const onSubmit = closeModal => data => {
        setLoading(true)
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('description', data.description)
        formData.append('businessType', data.businessType)
        file && formData.append('photoFile', file)
        formData.append('shouldDeletePhoto', markRemovePhoto)

        if (isEditMode) {
            service
                .editProject(projectId, formData)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Edit Project',
                        message: 'Project successfully edited',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateProjectList)
                    eventEmitter.emit(types.updateProjectView)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        } else {
            service
                .createProject(formData)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Create Project',
                        message: 'Project successfully created',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateProjectList)
                    eventEmitter.emit(types.updateProjectView)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        }
    }

    const handleRemoveFile = () => {
        setAvatarSrc('')
        setFile([])
        setMarkRemovePhoto(true)
    }

    const handleLoadFiles = file => {
        if (!file) return

        setAvatarSrc('')
        setMarkRemovePhoto(false)
        setFile(file)

        readURL(file).then(src => setAvatarSrc(src))
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openProjectModal}
        >
            {({ closeModal }) => (
                <>
                    <If condition={isEditMode}>
                        <Then>
                            <ModalHeader
                                className={styles.header}
                                titleClassName={styles.titleHeader}
                                title="Edit Project"
                            />
                        </Then>
                        <Else>
                            <ModalHeader
                                className={styles.header}
                                titleClassName={styles.titleHeader}
                                title="Create Project"
                            />
                        </Else>
                    </If>

                    <form
                        onSubmit={handleSubmit(onSubmit(closeModal))}
                        autoComplete="off"
                    >
                        <div className={styles.content}>
                            <div className={styles.column}>
                                <If condition={!isEditMode}>
                                    <Then>
                                        <Select
                                            error={errors.projectType}
                                            className={styles.field}
                                            control={control}
                                            label="Project Type"
                                            name="projectType"
                                            placeholder="Select project type"
                                            isRequired
                                            options={[
                                                {
                                                    value: 'M_&_A',
                                                    label: 'M & A',
                                                },
                                            ]}
                                        />
                                        {/*<Select
                                            error={errors.projectOwner}
                                            className={styles.field}
                                            control={control}
                                            label="Project Owner"
                                            name="projectOwner"
                                            placeholder="Select user"
                                            isRequired
                                            options={[
                                                {
                                                    value: 'User 1',
                                                    label: 'User 1',
                                                },
                                            ]}
                                        />*/}
                                    </Then>
                                    <Else>
                                        <TextField
                                            className={styles.field}
                                            label="Project Owner"
                                            name="projectOwnerFullName"
                                            disabled
                                            isRequired
                                            placeholder="Your project owner"
                                            ref={register}
                                        />
                                    </Else>
                                </If>
                                <TextField
                                    error={errors.businessType}
                                    className={styles.field}
                                    label="Business Type"
                                    name="businessType"
                                    isRequired
                                    placeholder="Business Type"
                                    ref={register}
                                />
                                <UploadAvatar
                                    src={avatarSrc}
                                    onFiles={handleLoadFiles}
                                    onRemove={handleRemoveFile}
                                    fileSize="3145728"
                                    size="120"
                                    label="Project Picture"
                                />
                            </div>
                            <div className={styles.column}>
                                <TextField
                                    error={errors.name}
                                    className={styles.field}
                                    label="Project Name"
                                    name="name"
                                    isRequired
                                    placeholder="Your project name"
                                    ref={register}
                                />
                                <TextField
                                    error={errors.description}
                                    className={styles.field}
                                    fieldClass={styles.textarea}
                                    label="Short Description"
                                    isTextarea
                                    name="description"
                                    isRequired
                                    placeholder="Short Description"
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <Button
                                isLoading={isLoading}
                                bgColor="#9dce6c"
                                className={styles.submit}
                                type="submit"
                            >
                                {isEditMode ? 'Save Project' : 'Create Project'}
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
