import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { TRoles } from '../../../../shared/types'

import styles from './CardsAdmin.module.scss'

function CardsAdmin({ values }) {
    const { countNewLastWeek } = values

    const typeUserOptions = [
        {
            label: 'All users',
            value: 'ALL'
        },
        {
            label: 'Investor',
            value: TRoles.INVESTOR
        },
        {
            label: 'Business Owner',
            value: TRoles.OWNER
        }
    ]

    const [typeUser, setTypeUser] = useState(typeUserOptions[0])

    const getValuesForUser = () => {
        switch (typeUser.value) {
            case 'ALL': {
                return {
                    userNew: (countNewLastWeek.INVESTOR || 0) + (countNewLastWeek.BUSINESS_OWNER || 0),
                    user: (values.INVESTOR || 0) + (values.BUSINESS_OWNER || 0)
                }
            }
            case TRoles.INVESTOR : {
                return {
                    userNew: (countNewLastWeek.INVESTOR || 0),
                    user: (values.INVESTOR || 0)
                }
            }
            case TRoles.OWNER: {
                return {
                    userNew: (countNewLastWeek.BUSINESS_OWNER || 0),
                    user: (values.BUSINESS_OWNER || 0)
                }
            }
            default: {
                return {}
            }
        }
    }
    const user = getValuesForUser()

    return (
        <div className={styles.root}>
            <div className={styles.card}>
                <span className={styles.cardPlusValue}>+{countNewLastWeek.PROJECTS || 0}</span>
                <span className={styles.cardValue}>{values.PROJECTS || 0}</span>
                <h4 className={styles.cardTitle}>Projects</h4>
            </div>
            <div className={styles.card}>
                <span className={styles.cardPlusValue}>+{user.userNew}</span>
                <span className={styles.cardValue}>{user.user}</span>
                <h4 className={styles.cardTitle}>Users</h4>

                <Select
                    className={styles.select}
                    value={typeUser}
                    classNamePrefix="select"
                    onChange={setTypeUser}
                    options={typeUserOptions}
                />
            </div>
            <div className={styles.card}>
                <span className={styles.cardPlusValue}>+{countNewLastWeek.ACCOUNTS || 0}</span>
                <span className={styles.cardValue}>{values.ACCOUNTS || 0}</span>
                <h4 className={styles.cardTitle}>Accounts</h4>
            </div>
        </div>
    )
}

CardsAdmin.propTypes = {
    values: PropTypes.shape({
        ACCOUNTS: PropTypes.number,
        BUSINESS_OWNER: PropTypes.number,
        INVESTOR: PropTypes.number,
        PROJECTS: PropTypes.number,
        countNewLastWeek: PropTypes.shape({
            BUSINESS_OWNER: PropTypes.number,
            INVESTOR: PropTypes.number,
            ACCOUNTS: PropTypes.number,
            PROJECTS: PropTypes.number
        }).isRequired
    }).isRequired
}

export default CardsAdmin
