import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Case } from 'react-if'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import ProjectListAdminView from '../modules/Projects/containers/ProjectListAdminView/ProjectListAdminView'
import ProjectListInvestorView from '../modules/Projects/containers/ProjectListInvestorView/ProjectListInvestorView'
import ProjectListProSellerView from '../modules/Projects/containers/ProjectListProSellerView/ProjectListProSellerView'
import ProjectListProBuyerView from '../modules/Projects/containers/ProjectListProBuyerView/ProjectListProBuyerView'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'

import { TRoles, TRoutes } from '../shared/types'

function Projects(props) {
    return (
        <ContainerMenu>
            <Switch>
                <Case condition={props.role === TRoles.INVESTOR}>
                    <ProjectListInvestorView />
                </Case>
                <Case condition={props.role === TRoles.PRO_BUYER}>
                    <ProjectListProBuyerView />
                </Case>
                <Case condition={props.role === TRoles.PRO_SELLER}>
                    <ProjectListProSellerView />
                </Case>
                <Case condition={props.role === TRoles.OWNER}>
                    <Redirect to={TRoutes.PROJECT} />
                </Case>
                <Case
                    condition={
                        props.role === TRoles.ADMIN ||
                        props.role === TRoles.SUPER_ADMIN
                    }
                >
                    <ProjectListAdminView />
                </Case>
            </Switch>
        </ContainerMenu>
    )
}

Projects.propTypes = {
    role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(Projects)
