import React from 'react'
import PropTypes from 'prop-types'

import { convertNameRole } from '../../../shared/helpers'

import stylesCommon from './List.module.scss'
export default function UsersItem(props) {
    return (
        <div className={stylesCommon.usersItem}>
            <span className={stylesCommon.boldText}>
                {props.item.firstName} {props.item.lastName}
            </span>
            <span className={stylesCommon.members}>
                {convertNameRole(props.item.role)}
            </span>
        </div>
    )
}

UsersItem.propTypes = {
    className: PropTypes.string,
}

UsersItem.defaultProps = {
    className: '',
}
