import http from '../../../shared/http'

//var textArray = ['REGULAR', 'M_AND_A']

//mock.onGet('/projects').reply(200, {
//    status: 'ok',
//    count: 55,
//    projects: new Array(20).fill('').map((_, index) => {
//        const random = Math.floor(Math.random()*textArray.length)
//        const projectId = random ? 'ee4d3340-f7e1-42d6-8f67-3da389166e2c' : '0276bd4b-c31c-4505-ac5e-d4a722c8953d'
//        return {
//            projectId: projectId,
//            projectTypeMA: textArray[Math.floor(Math.random()*textArray.length)],
//            name: 'Coca-Cola' + Math.floor(Math.random() * 90 + 10) + index,
//            description: 'Snow',
//            status: 'DRAFT',
//            businessType: 'Car industry',
//            projectPhotoLink: 'string',
//            projectOwnerId: 'fa98299a-fd2d-463b-95fa-e1af7e0b8f9b',
//            projectOwnerFullName: 'John Snow'
//        }
//    })
//})

export function getProjects(params) {
    return http.get('/projects', { params })
}

export function getAssignedProjects(params) {
    return http.get('/projects/me/assigned', { params })
}

export function projectPublish(projectId, value) {
    return http.post(`/projects/admin/${projectId}/publish`, {
        publish: value,
    })
}

export function registerTeaserView(projectId) {
    return http.get(`/statistic/${projectId}/project-modal-teaser-view`)
}

// '/projects/id-hash/full-info'
/*mock.onGet(/\/projects\/\w+\/full-info/).reply(200, {
    status: 'ok',
    projectInfo: {
        statistic: {
            views: 222,
            questions: 23
        },
        teaser: {
            url: 'https://docs.google.com/presentation/d/e/2PACX-1vRVrCmOA7ciDLhO3QpdXy6YO0wvhSUcAVIQc9sSXbEmAHVWRRsBsefVAi5Yo-N14HXpjYBoldO-Za2d/embed?start=false&loop=false&delayms=3000',
            // only admin
            urlSource: 'https://docs.google.com/presentation/d/1OWHqECC4hAYmhI8uDX_Fix3TGwdwhIL4Jklkyk5OHtc/edit?usp=sharing'
        },
        project: {
            status: 'DRAFT',
            name: 'Coca-Cola',
            description: 'Snow',
            businessType: 'Car industry',
            ownerId: 'fa98299a-fd2d-463b-95fa-e1af7e0b8f9b',
            projectId: 'fa98299a-fd2d-463b-95fa-e1af7e0b8f9b',
            projectPhotoLink: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/project_photos/ab087a57-0124-4bc7-87af-0e60d2d9baa3.png'
        },
        projectOwner: {
            firstName: 'John',
            lastName: 'Snow',
            companyName: 'Microsoft',
            position: 'CTO',
            userPhotoLink: 'https://atrium-partners-private-dev.s3.eu-central-1.amazonaws.com/project_photos/ab087a57-0124-4bc7-87af-0e60d2d9baa3.png'
        }
    }
})*/

export function getProjectFullInfo(projectId) {
    return http.get(projectId ? `/projects/${projectId}` : '/users/me/project')
}

export function editTeaser(projectId, { sourceUrl, previewUrl }) {
    return http.put(`/projects/admin/${projectId}/teaser`, {
        sourceUrl,
        previewUrl,
    })
}

export function sendQuestion(projectId, message) {
    return http.post(`/projects/${projectId}/question`, {
        projectId,
        message,
    })
}

export function getQuestionsInvestor(projectId) {
    return http.get(`/projects/${projectId}/questions/investor`, {
        projectId,
    })
}

export function getQuestionsAdmin(projectId, params) {
    return http.get('/projects/questions', {
        params: {
            ...params,
            projectId,
        },
    })
}

export function deleteAccountAccess(projectId, accountId) {
    return http.delete(`/projects/admin/${projectId}/assigned-accounts`, {
        data: {
            accountId: accountId,
        },
    })
}

export function deleteSellerAccess(projectId, sellerId) {
    return http.delete(`/projects/admin/${projectId}/assigned-pro-sellers`, {
        data: {
            proSellerId: sellerId,
        },
    })
}

export function provideFileAccess(accountId, projectId, data) {
    return http.put(
        `/accounts/admin/${accountId}/projects/${projectId}/docs-access`,
        {
            documentsVisible: data,
        }
    )
}

export function getUsers(params) {
    return http.get('/users/admin', { params })
}

export function changeOwner(projectId, ownerId) {
    return http.put(`/projects/admin/${projectId}/owner`, {
        ownerId: ownerId,
    })
}

export function postProjectView(projectId) {
    return http.post(`/projects/${projectId}/views`, {
        projectId,
    })
}

export function getAccountProjectsTrackerInfo(projectId) {
    return http.get(`/projects/${projectId}/tracker/accounts-list`, {
        projectId,
    })
}
export function getAccountProjectsStatisticInfo(projectId) {
    return http.get(`/projects/${projectId}/tracker/statistic`, {
        projectId,
    })
}
