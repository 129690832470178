import React from 'react'
import PropTypes from 'prop-types'

const Icon = props => {
    const styles = {
        svg: {
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: props.margin
        },
        path: {
            fill: props.color
        }
    }

    return (
        <svg
            className={props.className}
            style={styles.svg}
            width={`${props.size}px`}
            height={`${props.size}px`}
        >
            <path
                style={styles.path}
                d={props.icon}
            />
        </svg>
    )
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string
}

Icon.defaultProps = {
    size: 16,
    margin: '0 24px 0 0'
}

export default Icon
