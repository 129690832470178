import React from 'react'

import ContactUsForm from './ContactUs/ContactUsForm'

import Header from '../components/Header/Header'
import AboutUs from '../components/AboutUs/AboutUs'
import ContactUs from '../components/ContactUs/ContactUs'
import Footer from '../components/Footer/Footer'
// import ComingSoonPage from './ComingSoon/ComingSoonPage'


export default function LandingView() {
    return (
        <div>
            {/*<ComingSoonPage/>*/}
            <Header/>
            <AboutUs/>
            <ContactUs>
                <ContactUsForm/>
            </ContactUs>
            <Footer/>
        </div>
    )
}
