import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import {If, Then, Else} from 'react-if'

import Avatar from 'react-avatar'
import Tag from '../../../UI/containers/Tag/Tag'
import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'
import Button from '../../../UI/containers/Button/Button'

import eventEmitter, {types} from '../../../../shared/eventEmitter'
import {TRoles, TRoutes, TStatusProject} from '../../../../shared/types'

import AddButton from '../../../UI/containers/AddButton/AddButton'
import {ReactComponent as ArrowTableIcon} from '../../../../assets/icons/mini-arrow-table.svg'

import styles from './ProjectItemAdmin.module.scss'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

export default function ProjectItemAdmin({info, ...props}) {
    const _getStatusChangeButtonText = ({status}) => {
        return status === TStatusProject.DRAFT
            ? 'Publish'
            : 'Draft'
    }

    const _getCreateNDATemplateButtonStatus = ({hasNDATemplate}) => {
        return hasNDATemplate
    }

    const _getStatusChangeButtonStatus = ({canBePublished, status}) => {
        return status === TStatusProject.DRAFT ? !canBePublished : false
    }

    return (
        <div className={styles.root}>
            <div className={clsx(styles.col, styles.colName)}>
                <Link
                    className={styles.projectTitleLink}
                    to={TRoutes.PROJECT_ID(info.projectId)}
                >
                    <h3>{info.name}</h3>
                </Link>
                <If condition={info.projectTypeMA === 'M_AND_A'}>
                    <Then>
                        <sup>
                            <Tag
                                text="M & A"
                                bgColor="#c3e9fb"
                                textColor="#6D99D0"
                                className={styles.tag}
                            />
                        </sup>
                    </Then>
                </If>
            </div>
            <div className={clsx(styles.col, styles.colOwner)}>
                <span>{info.projectOwnerFullName}</span>
            </div>
            <div className={clsx(styles.col, styles.colState)}>
                <If condition={info.status === TStatusProject.DRAFT}>
                    <Then>
                        <Tag
                            text="Draft"
                            bgColor="#f3f4f9"
                            textColor="#a3a8bb"
                        />
                    </Then>
                    <Else>
                        <Tag
                            text={info.status}
                            bgColor="#eaf5df"
                            textColor="#9dce6c"
                        />
                    </Else>
                </If>
            </div>
            <div className={clsx(styles.col, styles.colAccessible)}>
                <If condition={info.projectTypeMA === 'M_AND_A'}>
                    <Then>
                        {info.accounts &&
                        info.accounts.map((item, i) => (
                            <Avatar
                                key={i}
                                className={styles.ndaUser}
                                src={item.photoLink}
                                name={item.name}
                                size="30"
                                color="#d2e1df"
                                textSizeRatio={1.75}
                                round={true}
                                fgColor="#9ab8ac"
                            />
                        ))}

                        {info.status !== TStatusProject.ARCHIVED && (
                            <AddButton
                                onClick={() =>
                                    eventEmitter.emit(
                                        types.openProjectAccessModal,
                                        info.projectId
                                    )
                                }
                            />
                        )}
                    </Then>
                </If>
            </div>
            <div className={clsx(styles.col, styles.colActions)}>
                <div className={styles.actionBtns}>
                    <Button className={styles.actionBtn}
                        disabled={_getCreateNDATemplateButtonStatus({hasNDATemplate: info.hasNDATemplate})}
                        onClick={() =>
                            eventEmitter.emit(types.openNdaTemplateModal, {
                                projectId: info.projectId,
                                name: info.name,
                                onNDATemplateCreation: props.onNDATemplateCreation
                            })
                        }>NDA</Button>
                    <Button className={styles.actionBtn} onClick={() =>
                        props.onPublish({
                            projectId: info.projectId,
                            isPublishing:
                                info.status === TStatusProject.DRAFT,
                        })
                    } disabled={_getStatusChangeButtonStatus({
                        canBePublished: info.canBePublished,
                        status: info.status
                    })}>{_getStatusChangeButtonText({status: info.status})}</Button>
                </div>
                <Tooltip
                    renderIcon={() => <ArrowTableIcon/>}
                    className={styles.actionMenu}
                    id={`project-list-${info.projectId}`}
                >
                    <TooltipItem
                        onClick={() =>
                            eventEmitter.emit(types.openProjectModal, info)
                        }
                    >
                        Edit
                    </TooltipItem>
                    {info.status !== 'ARCHIVED' && (
                        <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(
                                        types.openArchiveProjectModal,
                                        {
                                            projectId: info.projectId,
                                            header: 'Archive project',
                                            message: `Are you sure you want to archive ${info.name}?`,
                                        }
                                    )
                                }
                            >
                                Archive
                            </TooltipItem>
                        </VisibilityAccess>
                    )}
                </Tooltip>
            </div>
        </div>
    )
}

ProjectItemAdmin.propTypes = {
    onPublish: PropTypes.func.isRequired,
    onNDATemplateCreation: PropTypes.func.isRequired,
    info: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        businessType: PropTypes.string.isRequired,
        projectOwnerFullName: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }),
}
