import React, { useState } from 'react'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/notification'

import Scrollbars from 'react-custom-scrollbars'

import styles from './EmailSettingsModal.module.scss'
import EmailItem from './EmailItem'

const initialState = [{label: 'Contact us email notification', name: 'contactUsEmail', value: false}]

export default function EmailSettingsModal() {
    const [emailTypes, setEmailTypes] = useState(initialState)
    const [userId, setUserId] = useState('')

    const handleOpen = ({ id, emailSettings }) => {
        setUserId(id)
        if(emailSettings === null) {
            setEmailTypes(initialState)
        } else {
            setEmailTypes(emailSettings)
        }
    }

    const changeNotificationList = (propsIndex, e) => {
        setEmailTypes(
            emailTypes.map((item, index) => {
                return index === propsIndex ? {...item, value: e.target.checked } : item
            })
        )
    }
    const handleChange = closeModal => {
        const emailSettingsToJSON = JSON.stringify(emailTypes)
        service
            .editUserEmailSettings(userId, emailSettingsToJSON)
            .then(() => {
                eventEmitter.emit(types.openNotificationModal, {
                    header: 'Edit User',
                    message: 'User successfully edited',
                })
                closeModal()
                eventEmitter.emit(types.updateAdminList)
                eventEmitter.emit(types.updateSuperAdminList)
            })
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openEmailSettingsModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        titleClassName={styles.titleHeader}
                        title="Add email notifications for user"
                    />

                    <form autoComplete="off">
                        <div className={styles.content}>
                            <Scrollbars
                                className={styles.wrapperContent}
                                renderThumbVertical={({ style, ...props }) => (
                                    <div
                                        style={{
                                            ...style,
                                            backgroundColor: '#9dce6c',
                                        }}
                                        {...props}
                                    />
                                )}
                            >
                                <EmailItem
                                    emailTypes={emailTypes}
                                    changeNotificationList={changeNotificationList}
                                />
                            </Scrollbars>
                        </div>
                        <div className={styles.footer}>
                            <Button
                                bgColor="#9dce6c"
                                className={styles.submit}
                                type="submit"
                                onClick={() => handleChange(closeModal)}
                            >
                                Continue
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
