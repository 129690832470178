import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import TextField from '../../../UI/containers/Field/TextField'
import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/project'

import styles from './FolderModal.module.scss'

export default function FolderModal() {
    const [isLoading, setLoading] = useState(false)
    const [projectId, setProjectId] = useState(null)

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: zodResolver(
            z.object({
                name: z
                    .string()
                    .nonempty()
                    .refine(
                        val =>
                            val.length === val.trim().length &&
                            val.trim().length,
                        {
                            message: 'Spaces are not allowed',
                        }
                    ),
            })
        ),
    })

    const handleOpen = ({ projectId }) => {
        setProjectId(projectId)
    }

    const onSubmit = closeModal => data => {
        setLoading(true)
        service
            .createFolder(projectId, data.name)
            .then(() => {
                setLoading(false)
                eventEmitter.emit(types.openNotificationModal, {
                    header: 'Create Folder',
                    message: 'New folder successfully created',
                })
                reset()
                closeModal()
                eventEmitter.emit(types.updateFolderList)
            })
            .finally(() => setLoading(false))
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            nameEvent={types.openFolderModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title="Create folder"
                    />
                    <div className={styles.container}>
                        <form
                            onSubmit={handleSubmit(onSubmit(closeModal))}
                            autoComplete="off"
                        >
                            <TextField
                                disabled={isLoading}
                                className={styles.field}
                                error={errors.name}
                                ref={register}
                                name="name"
                                placeholder="New folder name"
                            />
                            <div className={styles.actions}>
                                <Button
                                    onClick={() => closeModal()}
                                    bgColor="#ffffff"
                                    className={clsx(
                                        styles.action,
                                        styles.actionCancel
                                    )}
                                    disabled={isLoading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={styles.action}
                                    isLoading={isLoading}
                                    type="submit"
                                >
                                    Done
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
