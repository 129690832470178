import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import FormView from '../../components/FormView/FormView'
import SignUpFormOptional from '../SignUpForm/SignUpFormOptional'

import * as userActions from '../../../../redux/ducks/user'
import { TRoutes } from '../../../../shared/types'
import { useQuery } from '../../../../shared/helpers'

import * as service from '../../services/auth'

function SignUpOptionalView(props) {
    const history = useHistory()
    const query = useQuery()

    const handleSubmit = data => {
        return service.setOptionalInfo({
            hash: query.get('t'),
            ...data
        }).then(() => {
            props.cleanAuthInvitation()
            history.push(TRoutes.LOGIN)
        })
    }

    useEffect(() => {
        const token = query.get('t')
        if (!token) {
            toast.error('Token is not valid')
            return history.push(TRoutes.MAIN)
        }

        if (!props.authInvitation.hasPassword) {
            return history.push({
                pathname: TRoutes.SIGN_UP_PASSWORD,
                search: history.location.search
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FormView role={props.authInvitation.role} renderForm={() => <SignUpFormOptional role={props.authInvitation.role} onSubmit={handleSubmit}/>}/>
    )
}

SignUpOptionalView.propTypes = {
    authInvitation: PropTypes.shape({
        hasPassword: PropTypes.bool.isRequired,
        role: PropTypes.string.isRequired
    }).isRequired,
    cleanAuthInvitation: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    authInvitation: {
        hasPassword: state.user.authInvitation.hasPassword,
        role: state.user.authInvitation.role
    }
})

const mapDispatchToProps = dispatch => ({
    cleanAuthInvitation: () => dispatch(userActions.cleanAuthInvitation())
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpOptionalView)
