import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as service from '../services/feedbacks'

import { ReactComponent as VisibleIcon } from '../../../assets/icons/eye.svg'
import { ReactComponent as InVisibleIcon } from '../../../assets/icons/eye-no.svg'
import { parseISO, format as formatDate } from 'date-fns'
import stylesCommon from './FeedbacksList.module.scss'
import VisibilityAccess from '../../UI/containers/VisibilityAccess'
import { TRoles } from '../../../shared/types'

export default function FeedbacksItem(props) {
    const [visibleFeedback, setVisibleFeedback] = useState(
        props.item.visibility === 'VISIBLE'
    )

    const handleAction = () => {
        service
            .changeFeedbackStatus(
                props.item.id,
                visibleFeedback ? 'HIDDEN' : 'VISIBLE'
            )
            .then(({ data }) => {
                setVisibleFeedback(
                    data.data.visibility === 'VISIBLE' ? true : false
                )
            })
    }

    return (
        <div className={stylesCommon.offersItem}>
            <div className={stylesCommon.header}>
                <div className={stylesCommon.colName}>
                    <span className={stylesCommon.colNumber}>
                        Feedback {props.number}
                    </span>
                </div>
                <div className={stylesCommon.info}>
                    <span className={stylesCommon.col}>
                        {props.item.investorFullName}
                    </span>
                    <span className={stylesCommon.col}>
                        {formatDate(parseISO(props.item.createdAt), 'HH:mm')}
                    </span>
                    <span className={stylesCommon.col}>
                        {formatDate(
                            parseISO(props.item.createdAt),
                            'MMM, dd, yyyy'
                        )}
                    </span>
                    <VisibilityAccess
                        access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}
                    >
                        <button
                            className={stylesCommon.visibilityIcon}
                            onClick={handleAction}
                        >
                            {visibleFeedback ? (
                                <VisibleIcon width="15" />
                            ) : (
                                <InVisibleIcon width="15" />
                            )}
                        </button>
                    </VisibilityAccess>
                </div>
            </div>
            <div className={stylesCommon.text}>
                <span>{props.item.text}</span>
            </div>
        </div>
    )
}

FeedbacksItem.propTypes = {
    className: PropTypes.string,
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        investorFullName: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    }).isRequired,
    number: PropTypes.number.isRequired,
}

FeedbacksItem.defaultProps = {
    className: '',
}
