import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

import List from '../../PreviewList/components/List'

import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'
import styles from './FeedbacksList.module.scss'

import * as service from '../services/feedbacks'

import FeedbacksItem from './FeedbacksItem'
import VisibilityAccess from '../../UI/containers/VisibilityAccess'
import { TRoles } from '../../../shared/types'
import Paginate from '../../UI/containers/Paginate/Paginate'
import { ITEMS_PER_PAGE } from '../../../shared/config'

export default function FeedbacksList(props) {
    const [page, setPage] = useState(0)
    const [feedbacks, setFeedbacks] = useState([])
    const [feedbacksCount, setFeedbacksCount] = useState(0)
    const itemsPerPage = props.isAdmin ? 3 : ITEMS_PER_PAGE

    const handlePageChange = ({ selected }) => {
        setPage(selected)
        fetchFeedbacks(selected)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const fetchFeedbacks = () => {
        props.feedback
            ? setFeedbacks(props.feedback)
            : service
                .getFeedbacks({
                    limit: itemsPerPage,
                    offset: page * itemsPerPage,
                })
                .then(({ data }) => {
                    setFeedbacks(data.data)
                    setFeedbacksCount(data.count)
                })
    }

    useEffect(() => {
        setPage(0)
        fetchFeedbacks(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feedback])

    return (
        <div className={styles.root}>
            <VisibilityAccess access={[TRoles.OWNER]}>
                <Header title="Feedbacks"></Header>
            </VisibilityAccess>
            <ContentContainer
                className={clsx(styles.container, props.containerClassName)}
            >
                <List
                    className={clsx(styles.list, props.listClassName)}
                    items={feedbacks}
                    renderEmpty={() => (
                        <div className={styles.empty}>
                            <p className={styles.emptyDescription}>
                               You have no feedbacks at this time
                            </p>
                        </div>
                    )}
                >
                    {(item, i) => <FeedbacksItem item={item} number={i + 1} />}
                </List>
                <Paginate
                    forcePage={page}
                    pageCount={Math.ceil(feedbacksCount / itemsPerPage)}
                    onPageChange={handlePageChange}
                />
            </ContentContainer>
        </div>
    )
}
