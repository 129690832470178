import http from '../../../shared/http'

export function getStatistic(params) {
    return http.get('/statistic', { params })
}

export function getStatisticDetail(params) {
    return http.get('/statistic/detail', { params })
}

export function getStatisticPdf(params) {
    return http.get('/statistic/pdf', {
        responseType: 'blob',
        params
    })
}

export function shareStatisticWithBO(params) {
    return http.get('/statistic/share-with-bo', { params })
}
