import React from 'react'
import PropTypes from 'prop-types'
import { If, Then, Else } from 'react-if'
import clsx from 'clsx'

import styles from './List.module.scss'

export default function List(props) {
    return (
        <div className={clsx(styles.root, props.className)}>
            <If condition={!!props.items.length}>
                <Then>
                    {props.items.map(props.children)}
                </Then>
                <Else>
                    {props.renderEmpty()}
                </Else>
            </If>
        </div>
    )
}

List.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    className: PropTypes.string,
    children: PropTypes.func.isRequired,
    renderEmpty: PropTypes.func
}

List.defaultProps = {
    className: '',
    renderEmpty: () => {
    }
}
