import React, { useState } from 'react'
import clsx from 'clsx'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as service from '../../services/notification'

import styles from './ArchiveUserModal.module.scss'

export default function ArchiveUserModal() {
    const [isLoading, setLoading] = useState(false)
    const [headerText, setHeaderText] = useState('')
    const [messageText, setMessageText] = useState('')

    const [userId, setUserId] = useState(null)

    const handleOpen = ({ userId, header, message }) => {
        setUserId(userId)
        setHeaderText(header)
        setMessageText(message)
    }

    const handleSubmit = closeModal => {
        setLoading(true)
        service
            .archiveUser(userId)
            .then(() => {
                setLoading(false)
                closeModal()
                eventEmitter.emit(types.openNotificationModal, {
                    header: 'Archive User',
                    message: 'User successfully archived',
                })
                eventEmitter.emit(types.updateAdminList)
                eventEmitter.emit(types.updateSuperAdminList)
                eventEmitter.emit(types.updateAccountList)
                eventEmitter.emit(types.updateSellerList)
                eventEmitter.emit(types.updateOwnerList)
                eventEmitter.emit(types.updateInvestorList)
            })
            .finally(() => setLoading(false))
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openArchiveUserModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title={headerText}
                        titleClassName={styles.headerTitle}
                    />
                    <div className={styles.container}>
                        <form autoComplete="off">
                            <div className={styles.message}>{messageText}</div>
                            <div className={styles.actions}>
                                <Button
                                    onClick={() => closeModal()}
                                    bgColor="#ffffff"
                                    className={clsx(
                                        styles.action,
                                        styles.actionCancel
                                    )}
                                    disabled={isLoading}
                                >
                                    No
                                </Button>
                                <Button
                                    className={styles.action}
                                    isLoading={isLoading}
                                    onClick={() => handleSubmit(closeModal)}
                                    type="submit"
                                >
                                    Yes
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </BaseModal>
    )
}
