import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import FormView from '../../components/FormView/FormView'

import SignUpFormPassword from '../SignUpForm/SignUpFormPassword'

import * as service from '../../services/auth'

import { TRoles, TRoutes } from '../../../../shared/types'
import * as userActions from '../../../../redux/ducks/user'
import { useQuery } from '../../../../shared/helpers'

function SignUpPasswordView(props) {
    const history = useHistory()
    const query = useQuery()

    useEffect(() => {
        const token = query.get('t')
        if (!token) {
            toast.error('Token is not valid')
            return history.push(TRoutes.MAIN)
        }

        if (props.authInvitation.role && props.authInvitation.hasPassword) {
            return history.push({
                pathname: TRoutes.SIGN_UP_OPTIONAL,
                search: history.location.search,
            })
        }

        service
            .confirmInvitation({ hash: token })
            .then(({ data }) => {
                const { userData } = data
                if (userData.hasPassword) {
                    toast.warning('You already have a password')
                    return history.push(TRoutes.LOGIN)
                }
                props.setAuthInvitation(userData)
            })
            .catch(() => history.push(TRoutes.MAIN))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = ({ password }) => {
        return service
            .setPassword({ hash: query.get('t'), password })
            .then(() => {
                props.setAuthInvitation({ hasPassword: true })
                props.authInvitation.role === TRoles.PRO_SELLER ||
                props.authInvitation.role === TRoles.PRO_BUYER
                    ? history.push(TRoutes.LOGIN)
                    : history.push({
                        pathname: TRoutes.SIGN_UP_OPTIONAL,
                        search: history.location.search,
                    })
            })
    }

    return (
        <FormView
            role={props.authInvitation.role}
            renderForm={() => (
                <SignUpFormPassword
                    name={props.authInvitation.name}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

SignUpPasswordView.propTypes = {
    authInvitation: PropTypes.shape({
        name: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        hasPassword: PropTypes.bool.isRequired,
    }).isRequired,
    setAuthInvitation: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    authInvitation: {
        name: state.user.authInvitation.name,
        role: state.user.authInvitation.role,
        hasPassword: state.user.authInvitation.hasPassword,
    },
})

const mapDispatchToProps = dispatch => ({
    setAuthInvitation: data => dispatch(userActions.setAuthInvitation(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPasswordView)
