import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import clsx from 'clsx'

import styles from './UserAvatar.module.scss'

export default function UserAvatar(props) {
    return (
        <div className={clsx(styles.root, props.className)}>
            <Avatar
                name={props.name}
                size="30"
                color="#d2e1df"
                textSizeRatio={1.75}
                round={5}
                src={props.src}
                fgColor="#9ab8ac"
            />
            <span className={styles.userFullName}>{props.name}</span>
        </div>
    )
}

UserAvatar.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,

    src: PropTypes.string
}

UserAvatar.defaultProps = {
    className: ''
}
