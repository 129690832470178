import React, { useState, useRef } from 'react'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
// import { toast } from 'react-toastify'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import { If, Then, Else } from 'react-if'

import PhoneField from '../../../UI/containers/Field/PhoneField'
import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'
import Checkbox from '../../../UI/containers/Checkbox/Checkbox'

import * as service from '../../services/account'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import styles from './AccountModal.module.scss'

export default function CreateAccountModal() {
    const [isLoading, setLoading] = useState(false)
    const phoneField = useRef(null)
    const [accountId, setAccountId] = useState('')
    const [isEditMode, setEditMode] = useState(false)

    const {
        register,
        handleSubmit,
        errors,
        control,
        reset,
        setValue,
    } = useForm({
        resolver: zodResolver(
            z.object(!isEditMode ?  {
                accountName: z.string().nonempty(),
                street: z.string().nonempty(),
                zip: z.string().nonempty(),
                city: z.string().nonempty(),
                country: z.string().nonempty(),
                firstName: z
                    .string()
                    .regex(/^(?!\s+$)[A-Za-zæøåÆØÅ0-9'\s-]+$/, {
                        message: 'Incorrect name',
                    })
                    .nonempty(),
                lastName: z
                    .string()
                    .regex(/^(?!\s+$)[A-Za-zæøåÆØÅ0-9'\s-]+$/, {
                        message: 'Incorrect name',
                    })
                    .nonempty(),
                email: z.string().email().nonempty(),
                phone: z.string().refine(() => phoneField.current.isValid, {
                    message: 'Phone is not valid',
                }),
                position: z.string().optional(),
                shouldSendInvitation: z.boolean(),
            } : {
                accountName: z.string().nonempty(),
                street: z.string().nonempty(),
                zip: z.string().nonempty(),
                city: z.string().nonempty(),
                country: z.string().nonempty()})
        ),
    })

    const handleOpen = info => {
        setEditMode(false)
        if (info) {
            setEditMode(true)
            setAccountId(info.accountId)
            service
                .getAccountInfo(info.accountId)
                .then(({ data }) => {
                    setTimeout(() => {
                        setValue('accountName', data.account.name)
                        setValue('zip', data.account.zipCode)
                        setValue('street', data.account.street)
                        setValue('city', data.account.city)
                        setValue('country', data.account.country)
                    }, 0)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        } else {
            setTimeout(() => {
                setValue('shouldSendInvitation', true)
            }, 0)
        }
    }

    const onSubmit = closeModal => data => {
        setLoading(true)
        if (isEditMode) {
            setLoading(true)
            service
                .editAccount(accountId, data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Edit User',
                        message: 'Account successfully changed',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateAccountList)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        } else {
            service
                .createAccount(data)
                .then(() => {
                    eventEmitter.emit(types.openNotificationModal, {
                        header: 'Create Account',
                        message: 'Account successfully created',
                    })
                    reset()
                    closeModal()
                    eventEmitter.emit(types.updateAccountList)
                    eventEmitter.emit(types.updateDashboardAccount)
                })
                // .catch(error => toast.error(error.message))
                .catch(error => console.log(error.message))
                .finally(() => setLoading(false))
        }
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openCreateAccountModal}
        >
            {({ closeModal }) => (
                <>
                    <If condition={isEditMode}>
                        <Then>
                            <ModalHeader
                                className={styles.header}
                                titleClassName={styles.headerTitle}
                                title="Edit Account"
                            />
                        </Then>
                        <Else>
                            <ModalHeader
                                className={styles.header}
                                titleClassName={styles.headerTitle}
                                title="Create New Account"
                            />
                        </Else>
                    </If>
                    <form
                        onSubmit={handleSubmit(onSubmit(closeModal))}
                        autoComplete="off"
                    >
                        <div className={styles.content}>
                            <div className={styles.row}>
                                <TextField
                                    error={errors.accountName}
                                    className={styles.field}
                                    label="Account Name"
                                    name="accountName"
                                    placeholder="Account Name"
                                    isRequired
                                    ref={register}
                                />
                            </div>
                            <div className={styles.row}>
                                <TextField
                                    error={errors.street}
                                    className={styles.field}
                                    label="Street"
                                    name="street"
                                    placeholder="Address Line"
                                    isRequired
                                    ref={register}
                                />
                                <TextField
                                    error={errors.city}
                                    className={styles.field}
                                    label="City"
                                    name="city"
                                    placeholder="City"
                                    isRequired
                                    ref={register}
                                />
                            </div>
                            <div className={styles.row}>
                                <TextField
                                    error={errors.zip}
                                    className={styles.field}
                                    label="Zip Code"
                                    name="zip"
                                    placeholder="Zip"
                                    isRequired
                                    ref={register}
                                />
                                <TextField
                                    error={errors.country}
                                    className={styles.field}
                                    label="Country"
                                    name="country"
                                    placeholder="Country"
                                    isRequired
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className={styles.content}>
                            {!isEditMode && (
                                <>
                                    <div className={styles.row}>
                                        <TextField
                                            error={errors.firstName}
                                            className={styles.field}
                                            label="First Name"
                                            name="firstName"
                                            placeholder="First Name"
                                            isRequired
                                            ref={register}
                                        />
                                        <TextField
                                            error={errors.lastName}
                                            className={styles.field}
                                            label="Last Name"
                                            name="lastName"
                                            placeholder="Last Name"
                                            isRequired
                                            ref={register}
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <PhoneField
                                            error={errors.phone}
                                            ref={phoneField}
                                            control={control}
                                            name="phone"
                                            label="Phone"
                                            className={styles.field}
                                        />
                                        <TextField
                                            error={errors.email}
                                            className={styles.field}
                                            label="User Email"
                                            name="email"
                                            placeholder="example@email.com"
                                            isRequired
                                            ref={register}
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <TextField
                                            error={errors.position}
                                            className={styles.field}
                                            label="Position"
                                            name="position"
                                            placeholder="User Position (optional)"
                                            ref={register}
                                        />
                                    </div>
                                </>
                            )}
                            <div className={styles.footer}>
                                <div className={styles.row}>
                                    <If condition={isEditMode}>
                                        <Then>
                                            <Button
                                                isLoading={isLoading}
                                                className={styles.actionButton}
                                                bgColor="#9dce6c"
                                                type="submit"
                                            >
                                                Edit Account
                                            </Button>
                                        </Then>
                                        <Else>
                                            <Checkbox
                                                error={
                                                    errors.shouldSendInvitation
                                                }
                                                labelClassName={styles.checkbox}
                                                name="shouldSendInvitation"
                                                register={register}
                                                label="Send Invitational Email"
                                            />
                                            <Button
                                                isLoading={isLoading}
                                                className={styles.actionButton}
                                                bgColor="#9dce6c"
                                                type="submit"
                                            >
                                                Create Account
                                            </Button>
                                        </Else>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}
