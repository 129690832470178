import http from '../../../shared/http'

//mock.onPost('/nda/8e22cef0-f914-457c-a6e3-e6fe7143a7ff/create').reply(200, {
//    message: 'Ok'
//})

export function createNdaTemplate(projectId, data) {
    return http.post(`/projects/admin/${projectId}/NDA-template`, data)
}

export function editNdaTemplate(templateId, data) {
    return http.put(`/nda/admin/${templateId}`, data)
}

export function getNdaTemplate(projectId) {
    return http.get(`/projects/admin/${projectId}/NDA-template-data`)
}

export function getEditNdaTemplate(templateId) {
    return http.get(`/nda/admin/${templateId}`)
}

//mock.onPost('/nda/sign').reply(200, {
//    message: 'Ok'
//})
    
//export function signNda(data) {
//    return http.post('/nda/sign', data)
//}

//mock.onPost('/nda/7e6983df-73cb-4892-ba24-a8fce86bde9b').reply(200, {
//    message: 'Ok'
//})

export function getNdaInfo(projectId) {
    return http.get(`/projects/${projectId}/NDA-data`)
}

export function generateUrl(projectId) {
    return http.post(`/projects/${projectId}/generate-NDA-signing-url`)
}