import React, { useState } from 'react'

import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { ReactComponent as AcceptIcon } from '../../../../assets/icons/accept.svg'
import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'
import TextField from '../../../UI/containers/Field/TextField'

import eventEmitter, { types } from '../../../../shared/eventEmitter'
import * as service from '../../services/sendOffer'

import styles from './OfferModal.module.scss'
import UploadFile from '../../../UI/containers/UploadFile/UploadFile'

export default function OfferModal() {
    const [isLoading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const [projectId, setProjectId] = useState(null)
    const [businessType, setBusinessType] = useState('')
    const [isSuccess, setSuccess] = useState(false)

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: zodResolver(
            z.object({
                share: z
                    .string()
                    .regex(/^[0-9]+(\.\d{1,2})?$/, {
                        message: 'Incorrect value',
                    })
                    .nonempty()
                    .refine(
                        val => val >= 0.01 && val <= 100,
                        'Company share should be more than 1% and less than 100%'
                    ),
                comment: z
                    .string()
                    .nonempty()
                    .refine(val => val.length <= 10000, {
                        message: 'Can\'t be more than 10000 characters',
                    }),
                price: z
                    .string()
                    .regex(/^\d+$/, { message: 'Invalid number' })
                    .nonempty(),
            })
        ),
    })

    const handleOpen = ({ projectId, businessType }) => {
        setSuccess(false)
        setProjectId(projectId)
        setBusinessType(businessType)
        setFile(null)
        setFileName('')
    }

    const handleLoadFiles = file => {
        if (!file) return
        setFile(file)
        setFileName(file.name)
    }

    const onSubmit = () => data => {
        const formData = new FormData()
        formData.append('share', data.share)
        formData.append('comment', data.comment)
        formData.append('price', data.price)
        file && formData.append('file', file)
        setLoading(true)
        service
            .sendOffer(projectId, formData)
            .then(() => {
                setSuccess(true)
                eventEmitter.emit(types.closeOfferModal)
                reset()
            })
            .finally(() => setLoading(false))
    }



    return (
        <BaseModal
            onOpen={handleOpen}
            disabledBgClose
            classModalName={styles.modal}
            nameEvent={types.openOfferModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        title="Make an Offer"
                    >
                        <p className={styles.codemaster}>{businessType}</p>
                    </ModalHeader>
                    {isSuccess ? (
                        <form autoComplete="off">
                            <div className={styles.notificationMessage}>
                                <div className={styles.messageContainer}>
                                    <div className={styles.acceptIcon}>
                                        <AcceptIcon />
                                    </div>
                                    <span>Your offer was successfully sent</span>
                                </div>
                                <Button
                                    onClick={() => closeModal()}
                                    className={styles.sendBtn}
                                >
                                    Done
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <form
                            onSubmit={handleSubmit(onSubmit(closeModal))}
                            autoComplete="off"
                        >
                            <div className={styles.container}>
                                <div className={styles.row}>
                                    <TextField
                                        error={errors.share}
                                        className={styles.field}
                                        label="Company share"
                                        isRequired
                                        name="share"
                                        placeholder="Select company share(%)"
                                        ref={register}
                                    />
                                    <TextField
                                        error={errors.price}
                                        className={styles.field}
                                        label="Price"
                                        isRequired
                                        name="price"
                                        placeholder="DKK 0"
                                        ref={register}
                                    />
                                </div>

                                <TextField
                                    label="Comment"
                                    isRequired
                                    fieldClass={styles.textarea}
                                    error={errors.comment}
                                    ref={register}
                                    isTextarea
                                    name="comment"
                                    placeholder="Our offer is..."
                                />
                                <div className={styles.footer}>
                                    <UploadFile
                                        onFiles={handleLoadFiles}
                                        className={styles.upload}
                                    />
                                    <div className={styles.filename}>
                                        {fileName}
                                    </div>
                                    <Button
                                        isLoading={isLoading}
                                        isLoadingMessage='Sharing...'
                                        className={styles.sendBtn}
                                        type="submit"
                                        bgColor="#9dce6c"
                                    >
                                        Suggest
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </>
            )}
        </BaseModal>
    )
}
