import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Case } from 'react-if'
import { connect } from 'react-redux'

import ViewAdmin from '../modules/Dashboad/containers/ViewAdmin'
import ViewOwner from '../modules/Dashboad/containers/ViewOwner'
import ViewInvestor from '../modules/Dashboad/containers/ViewInvestor'
import ViewProBuyer from '../modules/Dashboad/containers/ViewProBuyer'

import ContainerMenu from '../modules/UI/containers/Container/ContainerMenu'

import { TRoles } from '../shared/types'

function Dashboard(props) {
    return (
        <ContainerMenu>
            <Switch>
                <Case condition={props.role === TRoles.INVESTOR}>
                    <ViewInvestor />
                </Case>
                <Case condition={props.role === TRoles.OWNER}>
                    <ViewOwner />
                </Case>
                <Case
                    condition={
                        props.role === TRoles.ADMIN ||
                        props.role === TRoles.SUPER_ADMIN
                    }
                >
                    <ViewAdmin />
                </Case>
                <Case condition={props.role === TRoles.PRO_BUYER}>
                    <ViewProBuyer />
                </Case>
            </Switch>
        </ContainerMenu>
    )
}

Dashboard.propTypes = {
    role: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(Dashboard)
