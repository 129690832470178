import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import styles from './AllButton.module.scss'

export default function AllButton(props) {
    return (
        <Link className={styles.root} to={props.to}>
            {props.text}
        </Link>
    )
}


AllButton.propTypes = {
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
}
