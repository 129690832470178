import React, { useState } from 'react'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import { types } from '../../../../shared/eventEmitter'

import styles from './TrackerModal.module.scss'
import UserTrackerItem from '../../../Users/components/UserTrackerItem/UserTrackerItem'

export default function TrackerUserListModal() {
    const [projectName, setProjectName] = useState('')
    const [users, setUsers] = useState('')
    const [teammates, setTeammatesCount] = useState('')
    const [projectId, setProjectId] = useState('')
    const [accountId, setAccountId] = useState('')

    const handleOpen = ({projectId, accountId, users, name, teammates}) => {
        setProjectName(name)
        setUsers(users)
        setTeammatesCount(teammates)
        setProjectId(projectId)
        setAccountId(accountId)
    }
    return(
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openTrackerUserListModal}
        >

            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        titleClassName={styles.headerTitle}
                        teammatesClassName={styles.teammatesHolder}
                        title={`${projectName}`}
                        userCount={teammates}
                    />
                    <div className={styles.content}>
                        {users &&
                        users.map(item => (
                            <UserTrackerItem
                                key={item.id}
                                info={{
                                    userId: item.id,
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    role: item.role,
                                    photoLink: item.photoLink,
                                    phone: item.telephoneNumber,
                                    email: item.email,
                                }}
                                projectId={projectId}
                                accountId={accountId}
                            />
                        ))}
                    </div>
                    <div className={styles.footer}>
                        <Button
                            onClick={() => closeModal()}
                            bgColor="#9dce6c"
                            className={styles.submit}
                            type="submit"
                        >
                            Done
                        </Button>
                    </div>
                </>
            )}
        </BaseModal>
    )
}