import React, { useState, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import Header from '../../UI/containers/Header/Header'
import Paginate from '../../UI/containers/Paginate/Paginate'
import AddButton from '../../UI/containers/AddButton/AddButton'
import SearchForm from '../../UI/containers/SearchForm/SearchForm'
import ContentContainer from '../../UI/containers/Container/ContentContainer'

import UserItem from '../components/UserItem/UserItem'
import UserHeader from '../components/UserHeader/UserHeader'

import * as service from '../services/users'

import { TRoles, TUsersActivityStatusFilter } from '../../../shared/types'
import { ITEMS_PER_PAGE } from '../../../shared/config'
import { addPropToObject } from '../../../shared/helpers'
import eventEmitter, { types } from '../../../shared/eventEmitter'

import styles from './UserListView.module.scss'

export default function UserListView() {
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(1)
    const [usersCount, setUsersCount] = useState(0)
    const [activeFilter, setActiveFilter] = useState({ name: '', order: '' })
    const [search, setSearch] = useState('')
    const [activeUserType, setActiveUserType] = useState(TRoles.INVESTOR)
    const [userActivityFilter, setUserActivityFilter] = useState(
        TUsersActivityStatusFilter.ACTIVE
    )

    const handlePageChange = ({ selected }) => {
        setPage(selected)
        fetchUsers(selected)

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const fetchUsers = useDebouncedCallback(page => {
        service
            .getUsers({
                listType: 'USERS',
                filter: [activeUserType],
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
                deletedOnly: userActivityFilter === TUsersActivityStatusFilter.DELETED,
                ...addPropToObject('search', search),
                ...addPropToObject(
                    'sortBy',
                    activeFilter.order && activeFilter.name.toUpperCase()
                ),
                ...addPropToObject('sortType', activeFilter.order),
            })
            .then(({ data }) => {
                setUsers(data.data.users)
                setUsersCount(data.data.count)
            })
    }, 300)

    const handleUpdateInvestorList = () =>
        TRoles.INVESTOR === activeUserType && handlePageChange({ selected: page })
    const handleUpdateOwnerList = () =>
        TRoles.OWNER === activeUserType && handlePageChange({ selected: page })

    useEffect(() => {
        setPage(0)
        fetchUsers(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter, search, activeUserType, userActivityFilter])

    useEffect(() => {
        eventEmitter.on(types.updateInvestorList, handleUpdateInvestorList)
        eventEmitter.on(types.updateOwnerList, handleUpdateOwnerList)

        return () => {
            eventEmitter.off(types.updateInvestorList, handleUpdateInvestorList)
            eventEmitter.off(types.updateOwnerList, handleUpdateOwnerList)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    return (
        <div className={styles.root}>
            <Header title="Connect Users List">
                <div className={styles.headerActions}>
                    <div className={styles.headerBtn}>
                        <AddButton
                            onClick={() => eventEmitter.emit(types.openCreateUserModal)}
                            text="Create User"
                        />
                    </div>
                    <SearchForm
                        onEmpty={() => setSearch('')}
                        onSubmit={value => setSearch(value)}
                        placeholder="Search Users"
                    />
                </div>
            </Header>
            <ContentContainer>
                <UserHeader
                    onSelectUser={setActiveUserType}
                    activeUserType={activeUserType}
                    onFilter={setActiveFilter}
                    activeFilter={activeFilter}
                    userActivityFilter={userActivityFilter}
                    onChangeUserActivityFilter={setUserActivityFilter}
                />
                <div className={styles.list}>
                    {users &&
            users.map(item => (
                <UserItem
                    key={item.id}
                    info={{
                        userId: item.id,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        photoLink: item.photoLink,
                        role: item.role,
                        phone: item.telephoneNumber,
                        position: item.position,
                        companyName: item.companyName,
                        email: item.email,
                        activeProject: item.activeProject,
                        feedbacks: item.feedbacks,
                        deletedAt: item.deletedAt,
                    }}
                />
            ))}
                </div>
                <Paginate
                    forcePage={page}
                    pageCount={Math.ceil(usersCount / ITEMS_PER_PAGE)}
                    onPageChange={handlePageChange}
                />
            </ContentContainer>
        </div>
    )
}
