import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import { useHistory } from 'react-router-dom'

import Button from '../../../UI/containers/Button/Button'

import { registerTeaserView } from '../../services/projects'

import eventEmitter, { types } from '../../../../shared/eventEmitter'
import { TRoles, TRoutes } from '../../../../shared/types'

import * as service from '../../services/projects'

import styles from './ProjectItemInvestor.module.scss'

export default function ProjectItemInvestor({ info }) {
    const history = useHistory()

    const handlerWatchTeaser = e => {
        e.preventDefault()

        eventEmitter.emit(types.openTeaserModal, {
            name: info.name,
            url: info.teaserPreviewUrl,
        })

        registerTeaserView(info.projectId)
    }

    const handlerOpenProject = e => {
        e.preventDefault()
        if (info.role === TRoles.INVESTOR && info.showNDA) {
            eventEmitter.emit(types.openNdaModal, {
                projectId: info.projectId,
                showNDA: info.showNDA,
                businessType: info.businessType
            })
        } else {
            history.push(TRoutes.PROJECT_ID(info.projectId))
            const isPermission = [TRoles.PRO_BUYER].includes(
                info.role
            )
            if (isPermission) service.postProjectView(info.projectId)
        }
    }

    return (
        <div className={styles.root}>
            <Avatar
                className={styles.preview}
                name={info.name}
                size="165"
                color="#d2e1df"
                textSizeRatio={1.75}
                src={info.src}
                fgColor="#9ab8ac"
            />
            <div className={styles.content}>
                <h3 className={styles.title}>{info.name}</h3>
                <p className={styles.businessType}>{info.businessType}</p>
                <p className={styles.projectDescription}>{info.description}</p>
                <div className={styles.actions}>
                    <Button
                        onClick={handlerWatchTeaser}
                        className={styles.action}
                    >
                        Watch Teaser
                    </Button>
                    <Button
                        className={clsx(styles.action, styles.openBtn)}
                        bgColor="#fff"
                        onClick={handlerOpenProject}
                    >
                        Open project
                    </Button>
                </div>
            </div>
        </div>
    )
}

ProjectItemInvestor.propTypes = {
    info: PropTypes.shape({
        src: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        businessType: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        teaserPreviewUrl: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
    }),
}
