import React, { useState } from 'react'
import clxs from 'clsx'

import { connect } from 'react-redux'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import * as services from '../../services/probuyer'

import styles from './OfflineNdaModal.module.scss'
import Avatar from 'react-avatar'
import Tag from '../../../UI/containers/Tag/Tag'

function OfflineNdaModal(props) {
    const [isLoading, setLoading] = useState(false)
    const [projectName, setProjectName] = useState('')
    const [projectId, setProjectId] = useState(null)
    const [accountName, setAccountName] = useState('')
    const [buyerName, setBuyerName] = useState(null)

    const handleOpen = ({ project }) => {
        setProjectName(project.name)
        setLoading(false)
        setProjectId(project.id)

        setTimeout(() => {
            setBuyerName(
                `${props.userProfile.firstName} ${props.userProfile.lastName}`
            )
        }, 0)
        services.getOwnAccount().then(({ data }) => {
            setAccountName(data.account.name)
        })
    }

    const onSubmit = closeModal => {
        setLoading(true)

        services.requestSignedNDA({ projectId }).then(() => {
            closeModal()

            eventEmitter.emit(
                types.openNotificationModal,
                {
                    header: 'Offline NDA',
                    message: 'Offline NDA sign request was sent successfully',
                }
            )
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            classActionClose={styles.close}
            nameEvent={types.openOfflineNdaModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader
                        className={styles.header}
                        titleClassName={styles.titleHeader}
                        title="Offline NDA sign request"
                    />

                    <form autoComplete="off">
                        <div className={styles.content}>
                            <div className={styles.user}>
                                <div className={styles.colName}>
                                    <Avatar
                                        name={buyerName}
                                        size="40"
                                        color="#d2e1df"
                                        textSizeRatio={1.75}
                                        round={3}
                                        fgColor="#9ab8ac"
                                    />
                                    <div className={styles.userInfo}>
                                        <div className={styles.name}>
                                            {buyerName}
                                        </div>
                                        <Tag
                                            className={styles.userType}
                                            text="ProBuyer"
                                            bgColor="#eaf5df"
                                            textColor="#9dce6c"
                                        />
                                    </div>
                                </div>
                                <div
                                    className={clxs(
                                        styles.boldText,
                                        styles.accountInfo
                                    )}
                                >
                                    {accountName}
                                </div>
                            </div>
                            <div className={styles.title}>
                                Request offline NDA access to project
                            </div>
                            <div className={styles.accountItem}>
                                <span>{projectName}</span>
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <Button
                                isLoading={isLoading}
                                bgColor="#9dce6c"
                                className={styles.submitBtn}
                                type="submit"
                                onClick={evt => {
                                    evt.preventDefault()
                                    onSubmit(closeModal)
                                }}
                            >
                                Send
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </BaseModal>
    )
}

const mapStateToProps = state => ({
    userProfile: {
        firstName: state.user.userProfile.firstName,
        lastName: state.user.userProfile.lastName
    }
})

export default connect(mapStateToProps)(OfflineNdaModal)
