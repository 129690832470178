import React, { useState, useEffect } from 'react'

import CardValue from '../components/CardValue'
import ProfileInfo from '../components/ProfileInfo'

import { connect } from 'react-redux'
import Header from '../../UI/containers/Header/Header'
import ContentContainer from '../../UI/containers/Container/ContentContainer'
import NotificationsListMini from '../../PreviewList/containers/NotificationsListMini/NotificationsListMini'

import * as services from '../services/dashboard'

import stylesView from './View.module.scss'

function ViewOwner(props) {
    const [countViews, setCountViews] = useState(0)
    const [countQuestions, setCountQuestions] = useState(0)
    const [countNotifications, setCountNotifications] = useState(0)

    useEffect(() => {
        services.getStatisticDashboard().then(({ data }) => {
            setCountViews(data.statistic.countProjectView)
            setCountQuestions(data.statistic.countQuestion)
            setCountNotifications(data.statistic.countNotSeenNotification)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={stylesView.root}>
            <Header title="Overview"/>
            <ContentContainer>
                <div className={stylesView.topRow}>
                    <ProfileInfo
                        name={`${props.userProfile.firstName} ${props.userProfile.lastName}`}
                        photoLink={props.userProfile.photoLink}
                        role="BUSINESS_OWNER"
                    />
                    <div className={stylesView.cards}>
                        <CardValue
                            className={stylesView.card}
                            title="Views"
                            value={countViews}
                        />
                        <CardValue
                            className={stylesView.card}
                            title="Questions"
                            value={countQuestions}
                        />
                        <CardValue
                            className={stylesView.card}
                            title="Notifications"
                            value={countNotifications ? `+${countNotifications}` : countNotifications}
                            valueColor={'#9dce6b'}
                        />
                    </div>
                </div>
                <div className={stylesView.bottomRow}>
                    <NotificationsListMini
                        className={stylesView.notificationsList}
                    />
                </div>
            </ContentContainer>
        </div>
    )
}

const mapStateToProps = state => ({
    userProfile: {
        firstName: state.user.userProfile.firstName,
        lastName: state.user.userProfile.lastName,
        photoLink: state.user.userProfile.photoLink
    }
})

export default connect(mapStateToProps)(ViewOwner)
