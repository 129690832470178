import React, { useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useHistory } from 'react-router-dom'

import BaseModal from '../../components/BaseModal/BaseModal'
import ModalHeader from '../../components/ModalHeader/ModalHeader'

import Button from '../../../UI/containers/Button/Button'
import Checkbox from '../../../UI/containers/Checkbox/Checkbox'

import eventEmitter, { types } from '../../../../shared/eventEmitter'
import * as service from '../../services/disclaimer'

import { cleanToken } from '../../../../shared/helpers'
import { TRoles, TRoutes } from '../../../../shared/types'

import styles from './DisclaimerModal.module.scss'
import http from '../../../../shared/http'
import socketChat from '../../../../shared/socketChat'
import PropTypes from 'prop-types'
import { setShouldShowDisclaimer, setUserInfo } from '../../../../redux/ducks/user'
import { connect } from 'react-redux'

function DisclaimerModal(props) {
    const history = useHistory()
    const [confirmAgreement, setConfirmAgreement] = useState(false)

    const handleSubmit = closeModal => {
        service.checkAgreement().then(() => {
            eventEmitter.emit(types.openNotificationModal, {
                header: 'Disclaimer',
                message: 'Confirmed success!',
            })
            http.get('/users/me').then(({ data }) => {
                props.setUserInfo(data.userProfile)
                props.setShouldShowDisclaimer(data.shouldShowDisclaimer)

                if (
                    [
                        TRoles.ADMIN,
                        TRoles.SUPER_ADMIN,
                        TRoles.PRO_SELLER,
                        TRoles.OWNER,
                    ].includes(props.role)
                ) {
                    socketChat.connect()
                }
            })
            closeModal()
        })
    }

    const handleLogout = e => {
        e.preventDefault()

        cleanToken()

        history.push(TRoutes.LOGIN)
    }

    return (
        <BaseModal
            disabledBgClose
            disabledCrossClose
            classModalName={styles.modal}
            nameEvent={types.openDisclaimerModal}
        >
            {({ closeModal }) => (
                <>
                    <ModalHeader className={styles.header} title="Disclaimer" />
                    <div className={styles.container}>
                        <Scrollbars
                            className={styles.wrapperContent}
                            renderThumbVertical={({ style, ...props }) => (
                                <div
                                    style={{
                                        ...style,
                                        backgroundColor: '#587b77',
                                    }}
                                    {...props}
                                />
                            )}
                        >
                            <div className={styles.content}>
                                <p>
                                    ATRIUM Partners A/S (“ATRIUM”) owns and
                                    operates this platform, www.atriumconnect.dk
                                    (“the Platform”). Via this Platform, ATRIUM
                                    has been authorised by shareholders to issue
                                    various material, solely for information
                                    purposes, and the information is furnished
                                    to carefully selected parties (the
                                    “Investor”), who potentially has an interest
                                    in submitting a proposal in relation to a
                                    potential investment in each of the
                                    investment opportunities presented (the
                                    “Investment Opportunity”). The information
                                    is provided for investment purposes only,
                                    and the Investor is deemed able to assess
                                    the risks involved in each Investment
                                    Opportunity.
                                    <p>
                                        The information contained herein has
                                        been prepared and structured to assist
                                        the Investor in making an own evaluation
                                        of the Investment Opportunities and does
                                        not purport to contain all the
                                        information that the Investor may
                                        require. In all cases, the Investor
                                        should conduct its own investigation and
                                        analysis of the Investment Opportunity,
                                        and the data set provided on the
                                        Platform. Thus, ATRIUM makes no
                                        representation or warranty as to the
                                        entirety of the information contained on
                                        this Platform or made available in
                                        connection with any further
                                        investigation of the Investment
                                        Opportunity, including, without
                                        limitation, any estimates or
                                        projections.
                                    </p>
                                    The terms and conditions under which this
                                    information is provided are governed by
                                    Danish law.
                                </p>
                            </div>
                        </Scrollbars>
                        <Checkbox
                            className={styles.checkbox}
                            label="I hereby confirm that I have read and voluntarily signed this waiver and I confirm my agreement to assume the entire risk of any loss"
                            name="confirm"
                            value={confirmAgreement}
                            onChange={() =>
                                setConfirmAgreement(!confirmAgreement)
                            }
                        />
                        <div className={styles.row}>
                            <Button
                                className={styles.leaveBtn}
                                bgColor="#ffffff"
                                onClick={e => {
                                    closeModal()
                                    handleLogout(e)
                                }}
                            >
                                I wish to leave
                            </Button>

                            <Button
                                className={styles.continueBtn}
                                bgColor="#9dce6c"
                                disabled={!confirmAgreement}
                                onClick={() => handleSubmit(closeModal)}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </BaseModal>
    )
}

DisclaimerModal.propTypes = {
    setUserInfo: PropTypes.func.isRequired,
    setShouldShowDisclaimer: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
    setUserInfo: data => dispatch(setUserInfo(data)),
    setShouldShowDisclaimer: value => dispatch(setShouldShowDisclaimer(value)),
})

export default connect(null, mapDispatchToProps)(DisclaimerModal)