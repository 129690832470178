import React, { useState, useEffect } from 'react'
import { If, Then, Else } from 'react-if'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import ProjectItemInvestor from '../../components/ProjectItemInvestor/ProjectItemInvestor'
import ProjectItemAdmin from '../../components/ProjectItemAdmin/ProjectItemAdmin'

import Filter from '../../../UI/containers/Filter/Filter'
import Paginate from '../../../UI/containers/Paginate/Paginate'
import ContentContainer from '../../../UI/containers/Container/ContentContainer'

import * as service from '../../services/projects'

import { ITEMS_PER_PAGE } from '../../../../shared/config'
import { addPropToObject } from '../../../../shared/helpers'
import { TRoles, TStatusProject } from '../../../../shared/types'
import eventEmitter, { types } from '../../../../shared/eventEmitter'

import styles from './ProjectList.module.scss'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

function ProjectList(props) {
    const [projects, setProjects] = useState([])
    const [page, setPage] = useState(0)
    const [projectsCount, setProjectsCount] = useState(0)
    const [nameFilter, setNameFilter] = useState('')
    const [userRole, setUserRole] = useState(0)
    const Item = props.isAdmin ? ProjectItemAdmin : ProjectItemInvestor

    const handlePageChange = ({ selected }) => {
        setPage(selected)
        fetchProjects(selected)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const fetchProjects = useDebouncedCallback(page => {
        setUserRole(props.role)
        const isProUser = [TRoles.PRO_SELLER,TRoles.PRO_BUYER].includes(props.role)
	
        isProUser
            ? service
                .getAssignedProjects({
                    limit: ITEMS_PER_PAGE,
                    offset: page * ITEMS_PER_PAGE,
                    ...addPropToObject('search', props.search),
                    ...addPropToObject('isArchived', props.isArchived),
                    ...addPropToObject('nameSort', nameFilter),
                })
                .then(({ data }) => {
                    setProjects(data.projects)
                    setProjectsCount(data.count)
                })
            : service
                .getProjects({
                    limit: ITEMS_PER_PAGE,
                    offset: page * ITEMS_PER_PAGE,
                    ...addPropToObject('search', props.search),
                    ...addPropToObject('isArchived', props.isArchived),
                    ...addPropToObject('nameSort', nameFilter),
                })
                .then(({ data }) => {
                    setProjects(data.projects)
                    setProjectsCount(data.count)
                })
    }, 300)

    const fetchArchivedProjects = useDebouncedCallback(page => {
        service
            .getProjects({
                limit: ITEMS_PER_PAGE,
                offset: page * ITEMS_PER_PAGE,
                archived: true,
                ...addPropToObject('search', props.search),
                ...addPropToObject('isArchived', props.isArchived),
                ...addPropToObject('nameSort', nameFilter),
            })
            .then(({ data }) => {
                setProjects(data.projects)
                setProjectsCount(data.count)
            })
    }, 300)

    const handleUpdateProjectList = () => handlePageChange({ selected: page })

    const handlePublish = ({ projectId, isPublishing }) => {
        service.projectPublish(projectId, isPublishing).then(() => {
            const _projects = projects.slice()
            const index = _projects.findIndex(
                item => projectId === item.projectId
            )

            _projects[index] = {
                ..._projects[index],
                status: isPublishing
                    ? TStatusProject.PUBLISHED
                    : TStatusProject.DRAFT,
            }

            setProjects(_projects)
            eventEmitter.emit(
                types.openNotificationModal,
                isPublishing
                    ? {
                        header: 'Project Status',
                        message: `Project status successfully changed to ${TStatusProject.PUBLISHED}`,
                    }
                    : {
                        header: 'Project Status',
                        message: `Project status successfully changed to ${TStatusProject.DRAFT}`,
                    }
            )

            if(isPublishing === true) {
                setPage(0)
                fetchProjects(0)
            }
        })
    }

    const handleNDATemplateCreation = ({ projectId }) => {
        const _projects = projects.slice()
        const index = _projects.findIndex(
            item => projectId === item.projectId
        )

        _projects[index] = {
            ..._projects[index],
            hasNDATemplate: true,
        }

        setProjects(_projects)
    }

    useEffect(() => {
        setPage(0)
        fetchProjects(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.search])

    useEffect(() => {
        setPage(0)
        fetchProjects(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameFilter])

    useEffect(() => {
        setPage(0)
        props.isArchived ? fetchArchivedProjects(0) : fetchProjects(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isArchived])

    useEffect(() => {
        eventEmitter.on(types.updateProjectList, handleUpdateProjectList)

        return () =>
            eventEmitter.off(types.updateProjectList, handleUpdateProjectList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ContentContainer>
            <VisibilityAccess access={[TRoles.ADMIN, TRoles.SUPER_ADMIN]}>
                <div className={styles.colNames}>
                    <div className={clsx(styles.colName, styles.colNameName)}>
                        <Filter
                            onClick={value => setNameFilter(value)}
                            value={nameFilter}
                        >
                            Name
                        </Filter>
                    </div>
                    <div className={clsx(styles.colName, styles.colNameOwner)}>
                        Owner
                    </div>
                    <div className={clsx(styles.colName, styles.colNameState)}>
                        State
                    </div>
                    <div className={clsx(styles.colName, styles.colNameAccess)}>
                        Accessible
                    </div>
                </div>
            </VisibilityAccess>
            <div className={styles.list}>
                <If condition={projects.length !== 0}>
                    <Then>
                        {projects.map(item => (
                            <Item
                                key={item.projectId}
                                {...(props.isAdmin
                                    ? {
                                        onPublish: handlePublish,
                                        onNDATemplateCreation: handleNDATemplateCreation
                                    }
                                    : {})}
                                info={
                                    props.isAdmin
                                        ? {
                                            projectId: item.projectId,
                                            projectTypeMA: item.type,
                                            description: item.description,
                                            src: item.photoLink,
                                            status: item.status,
                                            name: item.name,
                                            businessType: item.businessType,
                                            projectOwnerFullName:
                                                item.projectOwnerFullName,
                                            accounts: item.Accounts,
                                            hasNDATemplate: item.hasNDATemplate,
                                            canBePublished: item.canBePublished
                                        }
                                        : {
                                            projectId: item.projectId,
                                            src: item.photoLink,
                                            name: item.name,
                                            description: item.description,
                                            status: item.status,
                                            businessType: item.businessType,
                                            teaserPreviewUrl:
                                                item.teaserPreviewUrl,
                                            showNDA: item.showNDA,
                                            role: userRole,
                                        }
                                }
                            />
                        ))}
                    </Then>
                    <Else>
                        <div className={styles.empty}>
                            <p className={styles.emptyDescription}>
                                You have no projects at this time
                            </p>
                        </div>
                    </Else>
                </If>
            </div>
            <Paginate
                forcePage={page}
                pageCount={Math.ceil(projectsCount / ITEMS_PER_PAGE)}
                onPageChange={handlePageChange}
            />
        </ContentContainer>
    )
}

ProjectList.propTypes = {
    isAdmin: PropTypes.bool,
    search: PropTypes.string,
    isArchived: PropTypes.bool,
}

ProjectList.defaultProps = {
    isAdmin: false,
    search: '',
    isArchived: false,
}

const mapStateToProps = state => ({
    role: state.user.role,
})

export default connect(mapStateToProps)(ProjectList)
