import React from 'react'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'

import Tag from '../../../UI/containers/Tag/Tag'
import Tooltip from '../../../UI/containers/Tooltip/Tooltip'
import TooltipItem from '../../../UI/containers/Tooltip/TooltipItem'

import eventEmitter, { types } from '../../../../shared/eventEmitter'

import { ReactComponent as ArrowTableIcon } from '../../../../assets/icons/mini-arrow-table.svg'

import styles from './ProBuyerItem.module.scss'
import { TRoles } from '../../../../shared/types'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

export default function ProBuyerItem({ info }) {
    const handleShowUserInfo = () => {
        if (info.role !== TRoles.PRO_BUYER)
            eventEmitter.emit(types.openProfileInfoModal, info)
    }

    return (
        <div
            className={
                info.isAccountList ? clsx(styles.root, styles.accountUser) : styles.root
            }
        >
            <div
                className={clsx(styles.col, styles.colName)}
                onClick={() => handleShowUserInfo()}
            >
                <Avatar
                    name={`${info.firstName} ${info.lastName}`}
                    className={styles.buyerAvatar}
                    size="40"
                    color="#d2e1df"
                    textSizeRatio={1.75}
                    src={info.photoLink}
                    fgColor="#9ab8ac"
                />
                <div className={styles.userInfo}>
                    <div className={styles.name}>
                        {info.firstName} {info.lastName}
                    </div>
                    <Tag
                        className={styles.userType}
                        text="ProBuyer"
                        bgColor="#eaf5df"
                        textColor="#9dce6c"
                    />
                </div>
            </div>
            <div className={clsx(styles.col, styles.colPhone)}>
                <span>{info.phone}</span>
            </div>
            <div className={clsx(styles.col, styles.colEmail)}>
                <span>{info.email}</span>
            </div>
            <div className={clsx(styles.col, styles.colActions)}>
                <VisibilityAccess access={[TRoles.SUPER_ADMIN, TRoles.ADMIN]}>
                    {!info.deletedAt ? (
                        <Tooltip
                            renderIcon={() => <ArrowTableIcon />}
                            className={styles.actionMenu}
                            id={`user-list-${info.userId}`}
                        >
                            <TooltipItem
                                onClick={() =>
                                    eventEmitter.emit(types.openCreateProbuyerModal, info)
                                }
                            >
                Edit
                            </TooltipItem>
                            <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                                <TooltipItem
                                    onClick={() =>
                                        eventEmitter.emit(types.openArchiveUserModal, {
                                            userId: info.userId,
                                            header: 'Archive User',
                                            message: `Are you sure you want to archive ${info.firstName} ${info.lastName}?`,
                                        })
                                    }
                                >
                                    Archive
                                </TooltipItem>
                                <TooltipItem
                                    onClick={() =>
                                        eventEmitter.emit(types.openDeleteUserModal, {
                                            userId: info.userId,
                                            header: 'Delete User',
                                            message: `Are you sure you want to delete ${info.firstName} ${info.lastName}?
                                            All information about this user will be removed from the database.`,
                                        })
                                    }
                                >
                  Delete
                                </TooltipItem>
                            </VisibilityAccess>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            renderIcon={() => <ArrowTableIcon />}
                            className={styles.actionMenu}
                            id={`user-list-${info.userId}`}
                        >
                            <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                                <TooltipItem
                                    onClick={() =>
                                        eventEmitter.emit(types.openUnArchiveUserModal, {
                                            userId: info.userId,
                                            header: 'Unarchive User',
                                            message: `Are you sure you want to unarchive ${info.firstName} ${info.lastName}?`,
                                        })
                                    }
                                >
                                    Unarchive
                                </TooltipItem>
                                <TooltipItem
                                    onClick={() =>
                                        eventEmitter.emit(types.openDeleteUserModal, {
                                            userId: info.userId,
                                            header: 'Delete User',
                                            message: `Are you sure you want to delete ${info.firstName} ${info.lastName}?
                                        All information about this user will be removed from the database.                                                               `,
                                        })
                                    }
                                >
                                    Delete
                                </TooltipItem>
                            </VisibilityAccess>
                        </Tooltip>
                    )}
                </VisibilityAccess>
            </div>
        </div>
    )
}

ProBuyerItem.propTypes = {
    info: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        deletedAt: PropTypes.string,
    }),
}
