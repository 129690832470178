import React, { useState } from 'react'
import clsx from 'clsx'
import BaseModal from '../../components/BaseModal/BaseModal'
import { Scrollbars } from 'react-custom-scrollbars'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'
import { Switch, Case, Default } from 'react-if'
import { If, Then, Else } from 'react-if'

import { TRoles } from '../../../../shared/types'
import styles from './ProfileInfoModal.module.scss'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import Button from '../../../UI/containers/Button/Button'

import * as service from '../../services/user'

import Tag from '../../../UI/containers/Tag/Tag'
import CardValue from '../../../Dashboad/components/CardValue'
import VisibilityAccess from '../../../UI/containers/VisibilityAccess'

export default function ProfileInfoModal() {
    const [userInfo, setUserInfo] = useState('')
    const [signedNDAWith, setSignedNDAWith] = useState([])
    const [projects, setProjects] = useState([])
    const [statistics, setStatistics] = useState('')

    const handleOpen = info => {
        info && setUserInfo(info)
        info.userId &&
            service.getUserProfile(info.userId).then(({ data }) => {
                if (data.profile.assignedProjectsOwners) {
                    setSignedNDAWith(data.profile.assignedProjectsOwners)
                } else {
                    setSignedNDAWith(data.profile.signedNdaWith)
                }
                setProjects(data.profile.projects)
                setStatistics(data.profile.statistics)
            })
    }

    const handleEditPRofile = role => {
        if (role === TRoles.INVESTOR || role === TRoles.OWNER)
            return types.openCreateUserModal
        else if (role === TRoles.PRO_SELLER) return types.openCreateSellerModal
        else return types.openCreateProbuyerModal
    }

    return (
        <BaseModal
            onOpen={handleOpen}
            classModalName={styles.modal}
            nameEvent={types.openProfileInfoModal}
        >
            {({ closeModal }) => (
                <>
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <div className={styles.profileInfo}>
                                <Avatar
                                    name={`${userInfo.firstName} ${userInfo.lastName}`}
                                    src={userInfo.photoLink}
                                    size="82"
                                    color="#d2e1df"
                                    textSizeRatio={1.75}
                                    round={5}
                                    fgColor="#9ab8ac"
                                />
                                <div className={styles.name}>
                                    <span className={styles.firstLastName}>
                                        {userInfo.firstName} {userInfo.lastName}
                                    </span>
                                    <Switch>
                                        <Case
                                            condition={
                                                userInfo.role ===
                                                TRoles.INVESTOR
                                            }
                                        >
                                            <Tag
                                                className={styles.role}
                                                text="Investor"
                                                bgColor="#eaf5df"
                                                textColor="#9dce6c"
                                            />
                                        </Case>
                                        <Case
                                            condition={
                                                userInfo.role === TRoles.OWNER
                                            }
                                        >
                                            <Tag
                                                className={styles.role}
                                                text="Business Owner"
                                                bgColor="#eaf5df"
                                                textColor="#9dce6c"
                                            />
                                        </Case>
                                        <Case
                                            condition={
                                                userInfo.role ===
                                                TRoles.PRO_SELLER
                                            }
                                        >
                                            <Tag
                                                className={styles.role}
                                                text="ProSeller"
                                                bgColor="#eaf5df"
                                                textColor="#9dce6c"
                                            />
                                        </Case>
                                        <Default>
                                            <Tag
                                                className={styles.role}
                                                text="ProBuyer"
                                                bgColor="#eaf5df"
                                                textColor="#9dce6c"
                                            />
                                        </Default>
                                    </Switch>
                                    <h3 className={styles.position}>
                                        {userInfo.position}
                                    </h3>
                                    <h3 className={styles.company}>
                                        {userInfo.companyName}
                                    </h3>
                                </div>
                            </div>
                            <div className={styles.profileBtns}>
                                <Button
                                    className={styles.btnImage}
                                    bgColor="#9dce6c"
                                    onClick={() => {
                                        closeModal()
                                        eventEmitter.emit(
                                            handleEditPRofile(userInfo.role),
                                            userInfo
                                        )
                                    }}
                                >
                                    Edit Profile
                                </Button>
                                <VisibilityAccess access={[TRoles.SUPER_ADMIN]}>
                                    <Button
                                        className={clsx(
                                            styles.btn,
                                            styles.btnImage
                                        )}
                                        bgColor="#ffffff"
                                        onClick={() => {
                                            closeModal()
                                            eventEmitter.emit(
                                                types.openArchiveUserModal,
                                                {
                                                    userId: userInfo.userId,
                                                    header: 'Archive User',
                                                    message: `Are you sure you want to archive ${userInfo.firstName} ${userInfo.lastName}?`,
                                                }
                                            )
                                        }}
                                    >
                                        Archive User
                                    </Button>
                                </VisibilityAccess>
                            </div>
                        </div>

                        <div className={styles.content}>
                            <div className={styles.contactInfo}>
                                <p className={styles.infoTitle}>
                                    contact Information:
                                </p>
                                <p className={styles.contactPhone}>
                                    {userInfo.phone}
                                </p>
                                <p className={styles.contactEmail}>
                                    {userInfo.email}
                                </p>
                            </div>
                            <div className={styles.ndaInfo}>
                                <p className={styles.infoTitle}>
                                    {userInfo.role === TRoles.INVESTOR ||
                                    userInfo.role === TRoles.OWNER
                                        ? 'signed NDA with'
                                        : 'Contacts with'}
                                </p>

                                {signedNDAWith &&
                                    signedNDAWith.map(item => (
                                        <Avatar
                                            key={item.id}
                                            className={styles.ndaUser}
                                            name={`${item.firstName} ${item.lastName}`}
                                            size="30"
                                            color="#d2e1df"
                                            textSizeRatio={1.75}
                                            round={true}
                                            src={item.photoLink}
                                            fgColor="#9ab8ac"
                                        />
                                    ))}
                            </div>
                        </div>
                        <If condition={userInfo.role === 'BUSINESS_OWNER'}>
                            <Then>
                                <div className={styles.content}>
                                    <div className={styles.projects}>
                                        <p className={styles.infoTitle}>
                                            User Projects
                                        </p>

                                        {projects &&
                                            projects.map(item => (
                                                <div
                                                    className={
                                                        styles.projectItem
                                                    }
                                                >
                                                    <span>{item.name}</span>
                                                    <Tag
                                                        className={
                                                            styles.projectStatus
                                                        }
                                                        text={item.status}
                                                        bgColor="#eaf5df"
                                                        textColor="#9dce6c"
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                {statistics && (
                                    <div className={styles.footer}>
                                        <CardValue
                                            className={styles.card}
                                            cardClassName={styles.cardTitle}
                                            title="Project Views"
                                            value={statistics.views}
                                        />
                                        <CardValue
                                            className={styles.card}
                                            cardClassName={styles.cardTitle}
                                            title="Questions"
                                            value={statistics.questions}
                                        />
                                        <CardValue
                                            className={styles.card}
                                            cardClassName={styles.cardTitle}
                                            title="Offers Pending"
                                            value={statistics.pendingOffers}
                                        />
                                    </div>
                                )}
                            </Then>
                            <Else>
                                <Scrollbars
                                    className={styles.wrapperContent}
                                    renderThumbVertical={({
                                        style,
                                        ...props
                                    }) => (
                                        <div
                                            style={{
                                                ...style,
                                                backgroundColor: '#587b77',
                                            }}
                                            {...props}
                                        />
                                    )}
                                >
                                    <div
                                        className={clsx(
                                            styles.infoTitle,
                                            styles.partisipatedTitle
                                        )}
                                    >
                                        Partiсipated
                                    </div>
                                    <div className={styles.partisipated}>
                                        {projects &&
                                            projects.map(item => (
                                                <div className={styles.col}>
                                                    <div
                                                        className={clsx(
                                                            styles.partisipatedItem,
                                                            styles.projectItem
                                                        )}
                                                    >
                                                        <span
                                                            className={
                                                                styles.projectTitle
                                                            }
                                                        >
                                                            {item.name}
                                                        </span>
                                                        <Tag
                                                            text={item.status}
                                                            bgColor="#eaf5df"
                                                            textColor="#9dce6c"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </Scrollbars>
                            </Else>
                        </If>
                    </div>
                </>
            )}
        </BaseModal>
    )
}

ProfileInfoModal.propTypes = {
    info: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }),
}
