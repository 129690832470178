import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as z from 'zod'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useHistory } from 'react-router-dom'

import TextField from '../../../UI/containers/Field/TextField'
import Button from '../../../UI/containers/Button/Button'

import * as service from '../../services/auth'

import { TRoles, TRoutes } from '../../../../shared/types'
import http from '../../../../shared/http'

import { setCountAllMenu, setUserInfo } from '../../../../redux/ducks/user'
import * as userActions from '../../../../redux/ducks/user'

import styles from './LogInForm.module.scss'
import socketChat from '../../../../shared/socketChat'

function LogInForm(props) {
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)

    const { register, handleSubmit, errors } = useForm({
        resolver: zodResolver(
            z.object({
                email: z.string().email().nonempty(),
                password: z.string().nonempty()
            })
        )
    })

    const onSubmit = data => {
        setLoading(true)
        service
            .logIn(data)
            .then(({ data }) => {
                props.logInAction({
                    role: data.userRole,
                    shouldShowDisclaimer: data.shouldShowDisclaimer
                })

                http.get('/users/me').then(({ data }) => {
                    props.setUserInfo(data.userProfile)
                })

                if (
                    [
                        TRoles.ADMIN,
                        TRoles.SUPER_ADMIN,
                        TRoles.PRO_SELLER,
                        TRoles.OWNER,
                    ].includes(data.userRole)
                ) {
                    socketChat.connect()
                }

                data.userRole === TRoles.PRO_SELLER
                    ? history.push(TRoutes.PROJECTS)
                    : history.push(TRoutes.DASHBOARD)

                if ([TRoles.ADMIN, TRoles.SUPER_ADMIN, TRoles.OWNER, TRoles.PRO_SELLER].includes(data.userRole))
                    http.get('/notifications/menu-count').then(({ data }) => {
                        props.setCountAllMenu(data.counts)
                    })
            })
            .finally(() => setLoading(false))
    }

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <TextField
                className={styles.filed}
                error={errors.email}
                label="Email"
                name="email"
                ref={register}
                placeholder="Your email"
            />
            <TextField
                isPassword
                error={errors.password}
                label="Password"
                name="password"
                placeholder="Password"
                ref={register}
            />
            <div className={styles.actions}>
                <Link
                    tabIndex="-1"
                    className={styles.link}
                    to={TRoutes.FORGOT_PASSWORD}
                >
                    <Button
                        className={styles.actionForgotPassword}
                        bgColor="transparent"
                    >
                        Forgot password?
                    </Button>
                </Link>
                <Button isLoading={isLoading} bgColor="#9dce6c" type="submit">
                    Log In
                </Button>
            </div>
        </form>
    )
}

LogInForm.propTypes = {
    user: PropTypes.shape({
        isAuth: PropTypes.bool.isRequired,
        shouldShowDisclaimer: PropTypes.bool.isRequired
    }).isRequired,

    logInAction: PropTypes.func.isRequired,
    setUserInfo: PropTypes.func.isRequired,
    setCountAllMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    logInAction: data => dispatch(userActions.logIn(data)),
    setUserInfo: data => dispatch(setUserInfo(data)),
    setCountAllMenu: value => dispatch(setCountAllMenu(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm)
