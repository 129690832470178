import React from 'react'
import PropTypes from 'prop-types'
import clxs from 'clsx'

import styles from './CardValue.module.scss'

export default function CardValue(props) {
    return (
        <div className={clxs(styles.root, props.className)}>
            <span
                className={clxs(styles.value, props.cardClassName)}
                style={{ color: props.valueColor }}
            >
                {props.value}
            </span>
            <h6 className={styles.title}>{props.title}</h6>
        </div>
    )
}

CardValue.propTypes = {
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,

    valueColor: PropTypes.string,
    className: PropTypes.string,
}

CardValue.defaultProps = {
    valueColor: '#4b736e',
    className: '',
}
